import React from "react";
import { Fab } from "@material-ui/core";
import clsx from "clsx";

import useStyles from "./styles";

const AppBtnFab = ({
  label,
  onClick,
  type,
  className = {},
  disabled = false,
  btnType = "primary",
  size = "large",
}) => {
  const { btn, light, green } = useStyles();
  return (
    <Fab
      type={type}
      variant="extended"
      size={size}
      disabled={disabled}
      className={clsx(className, {
        [btn]: btnType === "primary",
        [light]: btnType === "light",
        [green]: btnType === "green",
      })}
      onClick={onClick}
    >
      {label}
    </Fab>
  );
};

export default AppBtnFab;
