import React, { useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Typography, Box } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Formik } from "formik";
import {
  StyledTextField,
  AppSelect,
  AppBtnFab,
  Datepicker,
  AppAutocomplete,
} from "components/commons";
import {
  getLicenceByIdRequest,
  licenceByIdSelector,
  clearLicenceById,
} from "redux/licenceById";
import { getDevicesListRequest, devicesListSelector } from "redux/devicesList";
import {
  getProductTypesRequest,
  productTypeListSelector,
} from "redux/productTypeList";
import _ from "lodash";
import {
  plotsListWithoutAutopilotLicenceSelector,
  getPlotsListWithoutAutopilotLicenceRequest,
} from "redux/plotsListWithoutAutopilotLicence";
import useStyles from "./styles";
import PermissionsService from "../../../services/PermissionsService";

const EditLicenceForm = ({
  licenceId,
  handleCloseEditLicenceForm,
  handleEditLicence,
  licenceData,
  getLicenceById,
  devicesList,
  getDevicesList,
  clearLicenceById,
  getProductTypes,
  productTypeList,
  handleEditAutopilotLicence,
  getPlotsListWithoutAutopilotLicence,
  plotsListWithoutAutopilotLicence,
}) => {
  const { t } = useTranslation();
  const {
    container,
    header,
    headerTitle,
    closeIcon,
    userForm,
    btnContainer,
    cancelBtn,
    saveBtn,
    startDateInputMargin,
    marginBottomInput,
    fieldName,
  } = useStyles();
  useEffect(() => {
    return () => {
      clearLicenceById();
    };
  }, []);
  useEffect(() => {
    if (licenceId) {
      getProductTypes();
      getLicenceById({ licenceId });
      getDevicesList();
      getPlotsListWithoutAutopilotLicence();
    }
  }, [licenceId]);

  const prepareProductTypes = (productType) => {
    return {
      label: productType.name,
      value: productType.id,
    };
  };
  return (
    <Box className={container}>
      <Box className={header}>
        <Typography className={headerTitle} variant="h6">
          {t("editLicenceFormTitle")}
        </Typography>
        <CloseIcon onClick={handleCloseEditLicenceForm} className={closeIcon} />
      </Box>
      {licenceData && licenceData.device && (
        <Box className={fieldName}>
          {licenceData.device.field.name} - {licenceData.device.plot.name}
        </Box>
      )}
      {licenceData && licenceData.product.type === "autopilot" ? (
        <Formik
          initialValues={{
            licenceType: "mottechAutopilot",
            licenceId: licenceData.uniqueKey,
            startDate: licenceData.startDate,
            endDate: licenceData.endDate,
            plot: licenceData.plot || {
              name: t("selectPlot"),
              id: null,
            },
            enabled: licenceData.activated,
          }}
          validate={(values) => {
            const errors = {};
            if (!values.licenceId) {
              errors.licenceId = "Required";
            }
            if (!values.startDate) {
              errors.startDate = "Required";
            }
            if (!values.endDate) {
              errors.endDate = "Required";
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            handleEditAutopilotLicence(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <form className={userForm} onSubmit={handleSubmit}>
              <StyledTextField
                label={t("licenceId")}
                name="licenceId"
                value={values.licenceId}
                touched={touched.licenceId}
                error={errors.licenceId}
                disabled={true}
                className={marginBottomInput}
                handleChange={handleChange}
                handleBlur={handleBlur}
              />
              <AppSelect
                label={t("licenceType")}
                name="licenceType"
                items={[
                  { value: "mottechAutopilot", label: t("mottechAutopilot") },
                ]}
                value={values.licenceType}
                touched={touched.licenceType}
                error={errors.licenceType}
                disabled={licenceData.status === "auto"}
                className={marginBottomInput}
                handleChange={handleChange}
                handleBlur={handleBlur}
              />
              <Box display="flex" className={marginBottomInput}>
                <Datepicker
                  label={t("startDate")}
                  name="startDate"
                  value={values.startDate}
                  touched={touched.startDate}
                  error={errors.startDate}
                  maxDate={values.endDate}
                  className={startDateInputMargin}
                  disabled={
                    !PermissionsService.isAllowed("LICENCE_EDIT_PERIOD") ||
                    licenceData.status === "auto"
                  }
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />
                <Datepicker
                  label={t("endDate")}
                  name="endDate"
                  value={values.endDate}
                  touched={touched.endDate}
                  error={errors.endDate}
                  minDate={values.startDate}
                  disabled={
                    !PermissionsService.isAllowed("LICENCE_EDIT_PERIOD") ||
                    licenceData.status === "auto"
                  }
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />
              </Box>
              <AppAutocomplete
                label={t("attachLicenceToPlot")}
                name="plot"
                value={values.plot}
                touched={touched.plot}
                error={errors.plot}
                className={marginBottomInput}
                handleChange={handleChange}
                handleBlur={handleBlur}
                optionLabelKey="name"
                disabled={
                  !PermissionsService.isAllowed("AUTOPILOT_ATTACH_LICENCE_TO_PLOT")
                }
                options={[
                  { name: t("selectPlot"), id: null, disabled: true },
                  { name: t("none"), id: null, disabled: false },
                  ...plotsListWithoutAutopilotLicence
                ]}
              />
              <AppSelect
                label={t("enabled")}
                name="enabled"
                items={[
                  {
                    label: t("yes"),
                    value: true,
                  },
                  {
                    label: t("no"),
                    value: false,
                  },
                ]}
                value={values.enabled}
                touched={touched.enabled}
                error={errors.enabled}
                className={marginBottomInput}
                disabled={licenceData.status === "auto"}
                handleChange={handleChange}
                handleBlur={handleBlur}
              />
              <Box className={btnContainer}>
                <AppBtnFab
                  className={cancelBtn}
                  btnType="light"
                  label={t("cancel")}
                  onClick={handleCloseEditLicenceForm}
                />
                <AppBtnFab
                  className={saveBtn}
                  type="submit"
                  label={t("save")}
                  disabled={!_.isEmpty(errors)}
                />
              </Box>
            </form>
          )}
        </Formik>
      ) : (
        licenceData && (
          <Formik
            initialValues={{
              licenceId: licenceData.uniqueKey,
              licenceType: licenceData.product.id,
              startDate: licenceData.startDate,
              endDate: licenceData.endDate,
              device: licenceData.device || {
                name: t("selectDevice"),
                id: null,
              },
              enabled: licenceData.activated,
            }}
            validate={(values) => {
              const errors = {};
              if (!values.licenceId) {
                errors.licenceId = "Required";
              }
              if (!values.licenceType) {
                errors.licenceType = "Required";
              }
              if (!values.startDate) {
                errors.startDate = "Required";
              }
              if (!values.endDate) {
                errors.endDate = "Required";
              }
              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              if (
                !_.isEmpty(values.device) &&
                ((!licenceData.device && values.device.id !== null) ||
                  (values.device.id !== null &&
                    values.device.id !== licenceData.device.id))
              ) {
                handleEditLicence(values, true);
              } else {
                handleEditLicence(values, false);
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <form className={userForm} onSubmit={handleSubmit}>
                <StyledTextField
                  label={t("licenceId")}
                  name="licenceId"
                  value={values.licenceId}
                  touched={touched.licenceId}
                  error={errors.licenceId}
                  disabled={true}
                  className={marginBottomInput}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />
                <AppSelect
                  label={t("licenceType")}
                  name="licenceType"
                  items={productTypeList.map(prepareProductTypes)}
                  value={values.licenceType}
                  touched={touched.licenceType}
                  error={errors.licenceType}
                  disabled={licenceData.status === "auto"}
                  className={marginBottomInput}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />
                <Box display="flex" className={marginBottomInput}>
                  <Datepicker
                    label={t("startDate")}
                    name="startDate"
                    value={values.startDate}
                    touched={touched.startDate}
                    error={errors.startDate}
                    maxDate={values.endDate}
                    className={startDateInputMargin}
                    disabled={
                      !PermissionsService.isAllowed("LICENCE_EDIT_PERIOD") ||
                      licenceData.status === "auto"
                    }
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                  />
                  <Datepicker
                    label={t("endDate")}
                    name="endDate"
                    value={values.endDate}
                    touched={touched.endDate}
                    error={errors.endDate}
                    minDate={values.startDate}
                    disabled={
                      !PermissionsService.isAllowed("LICENCE_EDIT_PERIOD") ||
                      licenceData.status === "auto"
                    }
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                  />
                </Box>
                <AppAutocomplete
                  label={t("attachToAnotherDevice")}
                  name="device"
                  value={values.device}
                  touched={touched.device}
                  error={errors.device}
                  className={marginBottomInput}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  optionLabelKey="name"
                  options={[
                    { name: t("selectDevice"), id: null, disabled: true },
                    { name: t("none"), id: null, disabled: false },
                    ...devicesList,
                  ]}
                />
                <AppSelect
                  label={t("enabled")}
                  name="enabled"
                  items={[
                    {
                      label: t("yes"),
                      value: true,
                    },
                    {
                      label: t("no"),
                      value: false,
                    },
                  ]}
                  value={values.enabled}
                  touched={touched.enabled}
                  error={errors.enabled}
                  className={marginBottomInput}
                  disabled={licenceData.status === "auto"}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />
                <Box className={btnContainer}>
                  <AppBtnFab
                    className={cancelBtn}
                    btnType="light"
                    label={t("cancel")}
                    onClick={handleCloseEditLicenceForm}
                  />
                  <AppBtnFab
                    className={saveBtn}
                    type="submit"
                    label={t("save")}
                    disabled={!_.isEmpty(errors)}
                  />
                </Box>
              </form>
            )}
          </Formik>
        )
      )}
    </Box>
  );
};

const mapStateToProps = (state) => ({
  licenceData: licenceByIdSelector(state),
  devicesList: devicesListSelector(state),
  productTypeList: productTypeListSelector(state),
  plotsListWithoutAutopilotLicence: plotsListWithoutAutopilotLicenceSelector(
    state
  ),
});

const mapDispatchToProps = (dispatch) => ({
  getLicenceById: bindActionCreators(getLicenceByIdRequest, dispatch),
  getDevicesList: bindActionCreators(getDevicesListRequest, dispatch),
  clearLicenceById: bindActionCreators(clearLicenceById, dispatch),
  getProductTypes: bindActionCreators(getProductTypesRequest, dispatch),
  getPlotsListWithoutAutopilotLicence: bindActionCreators(
    getPlotsListWithoutAutopilotLicenceRequest,
    dispatch
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditLicenceForm);
