import Languages from "constants/languages";

const getAppDirection = () => {
  const language = localStorage.getItem("language");
  const langData = Languages.find((lang) => lang.lang === language);
  if (langData) {
    return langData.direction;
  } else {
    return "ltr";
  }
};

export default getAppDirection;
