import React, { useEffect, useRef } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Box, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import { StyledTextField, AppBtnFab, AppSelect } from "components/commons";

import {
  activationProductsListSelector,
  getActivationProductsListRequest,
} from "redux/activationProductsList";
import {
  activatedDevicesSelector,
  activateDeviceRequest,
  isLoadingSelector,
} from "redux/activateDevice";

import useStyles from "./styles";

const DeviceActivate = ({
  getActivationProductsList,
  productsList,
  activatedDevices,
  activateDevice,
  isLoading,
}) => {
  const infoBoxRef = useRef(null);
  const { t } = useTranslation();
  const {
    container,
    content,
    btnContainer,
    row,
    marginInlineEnd,
    marginInlineStart,
    infoBox,
    infoRow,
    infoLabel,
    infoValue,
    rowMarginTop,
    infoValueError,
  } = useStyles();
  useEffect(() => {
    getActivationProductsList();
  }, []);
  useEffect(() => {
    if (infoBoxRef) {
      infoBoxRef.current.scrollTo({
        top: infoBoxRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [infoBoxRef, activatedDevices]);
  const prepareProductsList = (item) => {
    return {
      label: item.productName,
      value: item.productId,
    };
  };
  const formIsValid = (values) => {
    return (
      values.deviceName &&
      values.deviceSerialNumber &&
      values.licencePeriod &&
      values.productId
    );
  };
  const handleAllTestsPassed = (device) => {
    const {
      particleClaimed,
      particleNameAdded,
      ubidotsGenerated,
      simActivated,
      subscriptionPeriod,
      firmware,
      batteryLevel,
    } = device;
    return (
      particleClaimed &&
      particleNameAdded &&
      ubidotsGenerated &&
      simActivated &&
      subscriptionPeriod &&
      firmware &&
      batteryLevel
    );
  };
  return (
    <Box className={container}>
      <Typography variant="h5">{t("deviceActivation")}</Typography>
      <Box className={content}>
        <Formik
          initialValues={{
            deviceName: "",
            deviceSerialNumber: "",
            licencePeriod: 3,
            productId: "",
          }}
          validate={(values) => {
            const errors = {};
            if (!values.deviceName) {
              errors.deviceName = "Required";
            }
            if (!values.deviceSerialNumber) {
              errors.deviceSerialNumber = "Required";
            }
            if (!values.licencePeriod) {
              errors.licencePeriod = "Required";
            }
            if (!values.productId) {
              errors.productId = "Required";
            }
            return errors;
          }}
          onSubmit={(values, { resetForm }) => {
            activateDevice(values, resetForm);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <form
              onSubmit={handleSubmit}
              style={{ flex: 1, marginInlineEnd: "10px" }}
            >
              <StyledTextField
                label={t("deviceName")}
                name="deviceName"
                value={values.deviceName}
                touched={touched.deviceName}
                error={errors.deviceName}
                handleChange={handleChange}
                handleBlur={handleBlur}
              />
              <Box className={row}>
                <StyledTextField
                  label={t("scanParticleSerial")}
                  name="deviceSerialNumber"
                  value={values.deviceSerialNumber}
                  touched={touched.deviceSerialNumber}
                  error={errors.deviceSerialNumber}
                  className={marginInlineEnd}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />
              </Box>
              <Box className={row}>
                <AppSelect
                  label={t("product")}
                  name="productId"
                  defaultItem={t("selectProduct")}
                  className={marginInlineEnd}
                  items={productsList.map(prepareProductsList)}
                  value={values.productId}
                  touched={touched.productId}
                  error={errors.productId}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />
                <AppSelect
                  label={t("subscription")}
                  name="licencePeriod"
                  className={marginInlineStart}
                  items={[
                    {
                      label: t("1year"),
                      value: 1,
                    },
                    {
                      label: t("numberYearsForSelect", { number: 2 }),
                      value: 2,
                    },
                    {
                      label: t("numberYearsForSelect", { number: 3 }),
                      value: 3,
                    },
                    {
                      label: t("numberYearsForSelect", { number: 4 }),
                      value: 4,
                    },
                    {
                      label: t("numberYearsForSelect", { number: 5 }),
                      value: 5,
                    },
                  ]}
                  value={values.licencePeriod}
                  touched={touched.licencePeriod}
                  error={errors.licencePeriod}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />
              </Box>
              <Box className={btnContainer}>
                <AppBtnFab
                  type="submit"
                  label={t("activate")}
                  disabled={!formIsValid(values) || isLoading}
                />
              </Box>
            </form>
          )}
        </Formik>
        <Box className={infoBox} ref={infoBoxRef}>
          {activatedDevices.map((device) => (
            <Box key={Math.random()} style={{ marginBottom: "30px" }}>
              <Box className={infoRow}>
                <Box className={infoLabel}>{t("particleClamed")}</Box>
                <Box
                  className={
                    device.particleClaimed ? infoValue : infoValueError
                  }
                >
                  {device.particleClaimed ? "V" : "X"}
                </Box>
              </Box>
              <Box className={infoRow}>
                <Box className={infoLabel}>{t("particleNameAdded")}</Box>
                <Box
                  className={
                    device.particleNameAdded ? infoValue : infoValueError
                  }
                >
                  {device.particleNameAdded ? "V" : "X"}
                </Box>
              </Box>
              <Box className={infoRow}>
                <Box className={infoLabel}>{t("ubidotsGenerate")}</Box>
                <Box
                  className={
                    device.ubidotsGenerated ? infoValue : infoValueError
                  }
                >
                  {device.ubidotsGenerated ? "V" : "X"}
                </Box>
              </Box>
              <Box className={infoRow}>
                <Box className={infoLabel}>{t("simActivated")}:</Box>
                <Box className={infoValue}>{device.simActivated}</Box>
              </Box>
              <Box className={infoRow}>
                <Box className={infoLabel}>{t("subscritpion")}:</Box>
                <Box className={infoValue}>
                  {device.subscriptionPeriod}
                </Box>
              </Box>
              <Box className={infoRow}>
                <Box className={infoLabel}>{t("firmware")}:</Box>
                <Box className={infoValue}>{device.firmware}</Box>
              </Box>
              <Box className={infoRow}>
                <Box className={infoLabel}>{t("batteryLevel")}</Box>
                <Box className={infoValue}>{device.batteryLevel}%</Box>
              </Box>
              <Box className={infoRow}>
                <Box className={infoLabel}>{t("deviceId")}</Box>
                <Box className={infoValue}>{device.deviceId}</Box>
              </Box>
              <Box className={infoRow}>
                <Box className={infoLabel}>{t("deviceOS")}</Box>
                <Box className={infoValue}>{device.deviceOS}</Box>
              </Box>
              <Box className={rowMarginTop}>
                <Box className={infoLabel}>{t("tests")}</Box>
                <Box
                  className={
                    handleAllTestsPassed(device) ? infoValue : infoValueError
                  }
                >
                  {handleAllTestsPassed(device) ? t("passed") : t("failed")}
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  productsList: activationProductsListSelector(state),
  activatedDevices: activatedDevicesSelector(state),
  isLoading: isLoadingSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  getActivationProductsList: bindActionCreators(
    getActivationProductsListRequest,
    dispatch
  ),
  activateDevice: bindActionCreators(activateDeviceRequest, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeviceActivate);
