import React from "react";
import { Box } from "@material-ui/core";
import useStyles from "./styles";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const Datepicker = ({
  margin = "normal",
  type = "text",
  className = "",
  disabled = false,
  minDate = (new Date()).setFullYear((new Date()).getFullYear() - 10),
  maxDate = (new Date()).setFullYear((new Date()).getFullYear() + 10),
  label,
  touched,
  error,
  value,
  handleChange,
  handleBlur,
  name,
}) => {
  const {
    cssFocused,
    cssFocusedLabel,
    fieldContainer,
    errorStyles,
  } = useStyles();
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Box className={fieldContainer}>
        <KeyboardDatePicker
          disableToolbar
          variant="inline"
          format="dd/MM/yyyy"
          label={label}
          value={value}
          type={type}
          disabled={disabled}
          minDate={minDate}
          maxDate={maxDate}
          onChange={(value) => {
            const event = {
              target: {
                name: name,
                value: value,
              },
            };
            handleChange(event);
          }}
          onBlur={handleBlur}
          name={name}
          className={className}
          error={!!(touched && error)}
          InputProps={{
            classes: {
              root: cssFocused,
            },
          }}
          InputLabelProps={{
            classes: {
              focused: cssFocusedLabel,
            },
          }}
          margin={margin}
        />
        {touched && error && <small className={errorStyles}>{error}</small>}
      </Box>
    </MuiPickersUtilsProvider>
  );
};

export default Datepicker;
