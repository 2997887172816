import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box, IconButton, Menu, MenuItem } from "@material-ui/core";
import moment from "moment";
import clsx from "clsx";

import {
  getAirTemperatureSensorRequest,
  airTemperatureSelector,
  errorMsgSelector,
} from "redux/airTemperatureSensor";
import { AirTemperatureSensorGraph } from "components/commons";
import useStyles from "./styles";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ExportIcon from "media/exportIcon.svg";
import { settingsSelector } from "redux/dashboardSettings";
import { saveDashboardSettingsRequest } from "redux/saveDashboardSettings";
import DeleteIcon from "media/deleteIcon.svg";
import AirTemperatureSensorIcon from "media/airTemperatureSensor.png";

const AirTemperatureSensor = ({
  plotId,
  deviceId,
  daterange,
  device,
  getAirTemperatureSensor,
  airTemperature,
  errorMsg,
  saveDashboardSettings,
  dashboardSettings,
}) => {
  const { t } = useTranslation();
  const {
    title,
    container,
    subtitle,
    header,
    deviceInfo,
    exportText,
  } = useStyles();
  useEffect(() => {
    if (deviceId) {
      getAirTemperatureSensor({
        deviceId: deviceId,
        from: moment(daterange[0]).valueOf(),
        to: moment(daterange[1]).valueOf(),
        isForInsight: false,
      });
    }
  }, [deviceId, daterange]);
  const generateDepth = (device) => {
    const rootenseSensors = device.sensors.filter(
      (sensor) =>
        sensor && sensor.sensorType && sensor.sensorType.type === "rootense"
    );
    const depths = {};
    rootenseSensors.forEach((sensor) => {
      if (sensor) {
        depths[`depth${sensor.port}`] = sensor.settings.depth;
      }
    });
    return depths;
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const exportToCsv = () => {
    const data = [
      airTemperature[deviceId].schema.map((item) => item.name).join(","),
      ...airTemperature[deviceId].data.map(([time, ...rest]) =>
        [moment(time).format("DD/MM/YYYY HH:mm"), ...rest].join(",")
      ),
    ];
    const csv = data.join("\r\n");
    let link = document.createElement("a");
    link.setAttribute(
      "href",
      "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURIComponent(csv)
    );
    link.setAttribute(
      "download",
      `${t("airTemperatureSensorWidget")} (${device.name})`
    );
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const deleteWidget = () => {
    const index = dashboardSettings.findIndex(
      (item) => item.key === `airTemperatureSensorWidget-${deviceId}`
    );
    dashboardSettings[index].enabled = false;
    saveDashboardSettings({
      plotId: plotId,
      settings: dashboardSettings,
      updateData: true,
    });
  };
  const open = Boolean(anchorEl);
  return (
    <Box className={container}>
      <Box className={clsx("widgetHeader", header)}>
        <Box className={deviceInfo}>
          <img
            width="32px"
            height="32px"
            src={AirTemperatureSensorIcon}
            alt="icon"
          />
          <Box padding="0 15px">
            <Box className={title}>
              {device.name} ({device.externalName || "-"})
            </Box>
            <Box className={subtitle}>{t("airTemperatureSensorWidget")}</Box>
          </Box>
        </Box>
        <IconButton onClick={handleClick} size="small">
          <MoreVertIcon />
        </IconButton>
      </Box>
      <div className="no-draggable" style={{ height: "80%" }}>
        <AirTemperatureSensorGraph
          airTemperature={airTemperature}
          depths={generateDepth(device)}
          daterange={daterange}
          deviceId={deviceId}
          errorMsg={errorMsg}
        />
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <MenuItem
            style={{
              width: "200px",
            }}
            onClick={exportToCsv}
          >
            <img src={ExportIcon} alt="Export" />
            <Box className={exportText}>{t("export")}</Box>
          </MenuItem>
          <MenuItem
            style={{
              width: "200px",
            }}
            onClick={deleteWidget}
          >
            <img src={DeleteIcon} alt="DeleteIcon" />
            <Box className={exportText}>{t("delete")}</Box>
          </MenuItem>
        </Menu>
      </div>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  airTemperature: airTemperatureSelector(state),
  errorMsg: errorMsgSelector(state),
  dashboardSettings: settingsSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  getAirTemperatureSensor: bindActionCreators(
    getAirTemperatureSensorRequest,
    dispatch
  ),
  saveDashboardSettings: bindActionCreators(
    saveDashboardSettingsRequest,
    dispatch
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AirTemperatureSensor);
