import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import { Add } from "@material-ui/icons";
import {
  Box,
  Typography,
  ListItemIcon,
  Divider,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import history from "history.js";
import { useTranslation } from "react-i18next";
import { accountsCountSelector } from "redux/accountsCount";
import {
  getExpiringLicencesRequest,
  expiringLicencesSelector,
} from "redux/expiringLicences";

import clsx from "clsx";
import { menuItems } from "constants/menuItems";
import AppLogoText from "media/logoWithText.svg";
import AppLogo from "media/logo.svg";
import CloseMenu from "media/closeMenu.svg";
import OpenMenu from "media/openMenu.svg";

import useStyles from "./styles";
import PermissionsService from "../../../services/PermissionsService";

const SideBar = ({
  toggleDrawer,
  isOpen,
  accountsCount,
  getExpiringLicences,
  expiringLicences,
}) => {
  const { path } = useRouteMatch();
  const { t } = useTranslation();
  const {
    hideScroll,
    logoContainer,
    routeName,
    routeRow,
    icon,
    activeRoute,
    activeRouteName,
    accountNameText,
    accountNameCollapsed,
    menuToggle,
    disabledRoute,
    addBtn,
    addIcon,
    accountBox,
    badge,
    routeLabelContainer,
  } = useStyles({ isOpen });
  const [currentAccount, setCurrentAccount] = useState(null);

  useEffect(() => {
    const currentAccount = localStorage.getItem("accountData");
    if (currentAccount) setCurrentAccount(JSON.parse(currentAccount));
  }, []);
  useEffect(() => {
    if (currentAccount) {
      getExpiringLicences();
    }
  }, [currentAccount]);
  const handlePressRoute = (route) => {
    history.push(route);
    if (window.outerWidth <= 414) {
      toggleDrawer();
    }
  };
  const getFirstLetter = (str) => str[0];
  const generateShortAccountName = () => {
    return currentAccount
      ? currentAccount.name
          .split(" ")
          .slice(0, 2)
          .map(getFirstLetter)
          .join("")
      : t("allAccounts")
          .split(" ")
          .slice(0, 2)
          .map(getFirstLetter)
          .join("");
  };
  const handleCreateAccount = () => {
    history.push(`/create-account`);
  };
  return (
    <Box className={hideScroll}>
      <Box className={logoContainer}>
        <img src={isOpen ? AppLogoText : AppLogo} height="70%" alt="logo" />
        <img
          src={isOpen && window.outerWidth > 414 ? CloseMenu : OpenMenu}
          className={menuToggle}
          alt="toogleMenu"
          onClick={toggleDrawer}
        />
      </Box>
      <Divider />
      <Box className={accountBox}>
        {isOpen ? (
          <Typography className={accountNameText}>
            {currentAccount ? currentAccount.name : t("allAccounts")}
          </Typography>
        ) : (
          <Typography className={accountNameCollapsed}>
            {generateShortAccountName()}
          </Typography>
        )}
        {PermissionsService.isAllowed("ACCOUNT_CREATE") && (
          <Tooltip title={t("createAccount")}>
            <IconButton className={addBtn} onClick={handleCreateAccount}>
              <Add className={addIcon} />
            </IconButton>
          </Tooltip>
        )}
      </Box>
      <Box flex={1}>
        {menuItems.map((item) => {
          const isActivePath = path.includes(item.link);
          let displayFlag = true;
          if (item.needFewAccounts) {
            displayFlag = accountsCount > 1;
          }
          if (
            (!item.permission ||
              PermissionsService.isAllowed(item.permission)) &&
            displayFlag
          ) {
            return (
              <Box
                onClick={() =>
                  (!item.onlyWithSelectedAccount || currentAccount) &&
                  handlePressRoute(item.link)
                }
                key={Math.random()}
                className={clsx(routeRow, {
                  [activeRoute]: isActivePath,
                  [disabledRoute]:
                    item.onlyWithSelectedAccount && !currentAccount,
                })}
              >
                <Box className={routeLabelContainer}>
                  <Tooltip title={t(item.title)}>
                    <ListItemIcon className={icon}>
                      <item.icon isActive={isActivePath} />
                    </ListItemIcon>
                  </Tooltip>
                  {isOpen && (
                    <Typography
                      variant="subtitle2"
                      className={clsx(routeName, {
                        [activeRouteName]: isActivePath,
                      })}
                    >
                      {t(item.title)}
                    </Typography>
                  )}
                </Box>
                {item.badge &&
                  item.badgeInfo === "expiringLicences" &&
                  expiringLicences &&
                  expiringLicences.licencesAboutToExpire > 0 && (
                    <Box className={badge}>
                      {expiringLicences.licencesAboutToExpire}
                    </Box>
                  )}
              </Box>
            );
          }
          return null;
        })}
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  accountsCount: accountsCountSelector(state),
  expiringLicences: expiringLicencesSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  getExpiringLicences: bindActionCreators(getExpiringLicencesRequest, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SideBar);
