import { createMuiTheme } from "@material-ui/core/styles";

export default createMuiTheme({
  headerHeight: "56px",
  headerContainerMobile: "112px",
  typography: {
    fontFamily: ["Roboto"],
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    h1: {
      fontStyle: "normal",
      fontWeight: 300,
      fontSize: 96,
      lineHeight: "112px",
      letterSpacing: -1.5,
    },
    h2: {
      fontStyle: "normal",
      fontWeight: 300,
      fontSize: 60,
      lineHeight: "72px",
      letterSpacing: -0.5,
    },
    h3: {
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: 48,
      lineHeight: "56px",
    },
    h4: {
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: 34,
      lineHeight: "36px",
    },
    h5: {
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: 28,
      lineHeight: "28px",
      letterSpacing: 0.18,
    },
    h6: {
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: 20,
      lineHeight: "24px",
      letterSpacing: 0.15,
    },
    subtitle1: {
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: 16,
      lineHeight: "24px",
      letterSpacing: 0.15,
    },
    subtitle2: {
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: 14,
      lineHeight: "24px",
      letterSpacing: 0.1,
    },
    body1: {
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: 16,
      lineHeight: "24px",
      letterSpacing: 0.5,
    },
    body2: {
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: 14,
      lineHeight: "20px",
      letterSpacing: 0.25,
    },
    button: {
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: 14,
      lineHeight: "26px",
      letterSpacing: 1.25,
    },
    caption: {
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: 12,
      lineHeight: "16px",
      letterSpacing: 0.4,
    },
    overline: {
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: 10,
      lineHeight: "16px",
      letterSpacing: 1.5,
      textTransform: "uppercase",
    },
  },
  palette: {
    primary: {
      main: "#70b7e1",
    },
    secondary: {
      main: "#91B54D",
    },
    text: {
      primary: "#363740",
      white: "#FFFFFF",
      lightGray: "#828B93",
    },
    background: {
      default: "#FFFFFF",
      paper: "#FFFFFF",
      sidebar: "#FFFFFF",
    },
    error: {
      main: "#B00020",
    },
    success: {
      main: "#91B54E",
    },
    warning: {
      main: "#E8E25A",
    },
  },
});
