import React from "react";
import { SvgIcon } from "@material-ui/core";

const Accounts = ({ isActive }) => (
  <SvgIcon viewBox="0 0 20 18">
    <path
      d="M15.7143 13.4286H20V17.7143H15.7143V13.4286ZM7.85714 13.4286H12.1429V17.7143H7.85714V13.4286ZM0 13.4286H4.28571V17.7143H0V13.4286ZM10.7143 7.14286H18.5714V12H17.1429V8.57143H10.7143V12H9.28571V8.57143H2.85714V12H1.42857V7.14286H9.28571V5.71429H10.7143V7.14286ZM7.85714 0H12.1429V4.28571H7.85714V0Z"
      fill={isActive ? "#70b7e1" : "#828B93"}
    />
  </SvgIcon>
);

const Devices = ({ isActive }) => (
  <SvgIcon viewBox="0 0 23 22">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.26667 0C5.01482 0 4 0.89543 4 2V15C4 16.1046 5.01482 17 6.26667 17H18.7333C19.9852 17 21 16.1046 21 15V2C21 0.895431 19.9852 0 18.7333 0H6.26667ZM13.146 8.83865C11.3328 9.7765 10.6616 10.5171 9.94999 12.1393C7.39319 9.19796 10.0816 4.25155 17 5.09552C17 5.09552 16.351 6.41684 16.1111 7.29121C15.9697 7.80662 15.9565 8.45772 15.9433 9.10823C15.9262 9.95531 15.909 10.8014 15.6088 11.3455C14.1518 13.986 10.7678 12.6741 10.7678 12.6741C11.4423 11.1615 11.9372 10.3203 13.146 8.83865Z"
      fill={isActive ? "#70b7e1" : "#828B93"}
    />
    <path d="M11 17H14V22H11V17Z" fill={isActive ? "#70b7e1" : "#828B93"} />
  </SvgIcon>
);

const Users = ({ isActive }) => (
  <SvgIcon viewBox="0 0 22 14">
    <path
      d="M15 8C14.71 8 14.38 8 14.03 8.05C15.19 8.89 16 10 16 11.5V14H22V11.5C22 9.17 17.33 8 15 8ZM7 8C4.67 8 0 9.17 0 11.5V14H14V11.5C14 9.17 9.33 8 7 8ZM7 6C7.79565 6 8.55871 5.68393 9.12132 5.12132C9.68393 4.55871 10 3.79565 10 3C10 2.20435 9.68393 1.44129 9.12132 0.87868C8.55871 0.316071 7.79565 0 7 0C6.20435 0 5.44129 0.316071 4.87868 0.87868C4.31607 1.44129 4 2.20435 4 3C4 3.79565 4.31607 4.55871 4.87868 5.12132C5.44129 5.68393 6.20435 6 7 6ZM15 6C15.7956 6 16.5587 5.68393 17.1213 5.12132C17.6839 4.55871 18 3.79565 18 3C18 2.20435 17.6839 1.44129 17.1213 0.87868C16.5587 0.316071 15.7956 0 15 0C14.2044 0 13.4413 0.316071 12.8787 0.87868C12.3161 1.44129 12 2.20435 12 3C12 3.79565 12.3161 4.55871 12.8787 5.12132C13.4413 5.68393 14.2044 6 15 6Z"
      fill={isActive ? "#70b7e1" : "#828B93"}
    />
  </SvgIcon>
);

const MyProducts = ({ isActive }) => (
  <SvgIcon>
    <path
      d="M14.23 16L10 13.45L5.77 16L6.89 11.19L3.16 7.96L8.08 7.54L10 3L11.92 7.53L16.84 7.95L13.11 11.18L14.23 16ZM10 0C4.47 0 0 4.5 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C3.85752 17.9997 4.95991 18.7362 6.17317 19.2388C7.38642 19.7413 8.68678 20 10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 8.68678 19.7413 7.38642 19.2388 6.17317C18.7362 4.95991 17.9997 3.85752 17.0711 2.92893C16.1425 2.00035 15.0401 1.26375 13.8268 0.761205C12.6136 0.258658 11.3132 0 10 0Z"
      fill={isActive ? "#70b7e1" : "#828B93"}
    />
  </SvgIcon>
);

const Dashboard = ({ isActive }) => (
  <SvgIcon viewBox="0 0 18 18">
    <path
      d="M10 0V6H18V0H10ZM10 18H18V8H10V18ZM0 18H8V12H0V18ZM0 10H8V0H0V10Z"
      fill={isActive ? "#70b7e1" : "#828B93"}
    />
  </SvgIcon>
);

export const menuItems = [
  {
    title: "dashboard",
    icon: Dashboard,
    link: "/dashboard",
    onlyWithSelectedAccount: true,
    permission: null,
    needFewAccounts: false,
    badge: false,
    badgeInfo: null,
  },
  {
    title: "accounts",
    icon: Accounts,
    link: "/accounts",
    onlyWithSelectedAccount: false,
    permission: null,
    needFewAccounts: true,
    badge: false,
    badgeInfo: null,
  },
  {
    title: "devices",
    icon: Devices,
    link: "/activate",
    onlyWithSelectedAccount: false,
    permission: "DEVICE_ACTIVATION_TOOL",
    needFewAccounts: false,
    badge: false,
    badgeInfo: null,
  },
  {
    title: "users",
    icon: Users,
    link: "/users",
    onlyWithSelectedAccount: false,
    permission: null,
    needFewAccounts: false,
    badge: false,
    badgeInfo: null,
  },
  {
    title: "myProducts",
    icon: MyProducts,
    link: "/my-products",
    onlyWithSelectedAccount: true,
    permission: "PRODUCT_VIEW",
    needFewAccounts: false,
    badge: true,
    badgeInfo: "expiringLicences",
  },
];
