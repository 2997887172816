import { put, takeEvery, all, call, select } from "redux-saga/effects";
import { userIdSelector } from "./me";
import { getUserAccountsRequest } from "./accounts";
import { enableAccounts } from "api";
/* *
 * Constants
 * */
export const moduleName = "enableAccounts";
const prefix = `${process.env.REACT_APP_NAME}/${moduleName}`;

export const INIT = `${prefix}/INIT`;
export const SUCCESS = `${prefix}/SUCCESS`;
export const REJECT = `${prefix}/REJECT`;

/* *
 * Reducer
 * */
const initialState = {
  loading: false,
  error: {},
  success: false,
};

export default function reducer(
  state = initialState,
  { type, payload, meta, ...rest }
) {
  switch (type) {
    case INIT:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };
    case REJECT:
      return {
        ...state,
        loading: false,
        success: false,
        error: payload.error,
      };
    default:
      return state;
  }
}

/* *
 * Selectors
 * */
export const moduleSelector = (state) => state[moduleName];

export const isLoadingSelector = (state) => moduleSelector(state).loading;

export const isSuccessSelector = (state) => moduleSelector(state).success;

/* *
 * Action Creators
 * */
export const enableAccountsRequest = (accounts, tableState) => ({
  type: INIT,
  accounts,
  tableState,
});

export const successAction = (payload) => ({
  type: SUCCESS,
  payload,
});

export const errorAction = (error) => ({
  type: REJECT,
  payload: { error },
});

/* *
 * Sagas
 * */
export function* enableAccountsSaga({ accounts, tableState }) {
  try {
    const userId = yield select(userIdSelector);
    const result = yield call(enableAccounts, userId, accounts);
    yield put(successAction(result));
    yield put(
      getUserAccountsRequest(
        tableState.search,
        tableState.pagination,
        tableState.sort,
        tableState.parentFilter
      )
    );
  } catch (error) {
    yield put(errorAction(error.message));
  }
}

export function* saga() {
  yield all([takeEvery(INIT, enableAccountsSaga)]);
}
