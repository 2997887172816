import { put, takeEvery, all, call } from "redux-saga/effects";
import { getFieldWeatherForecast } from "api";
import _ from "lodash";
import moment from "moment";
/* *
 * Constants
 * */
export const moduleName = "weatherForecast";
const prefix = `${process.env.REACT_APP_NAME}/${moduleName}`;

export const INIT = `${prefix}/INIT`;
export const SUCCESS = `${prefix}/SUCCESS`;
export const REJECT = `${prefix}/REJECT`;

const weatherCodesByPriority = [
  "snow_heavy",
  "snow",
  "snow_light",
  "flurries",
  "freezing_drizzle",
  "ice_pellets_heavy",
  "ice_pellets",
  "ice_pellets_light",
  "freezing_rain_heavy",
  "freezing_rain",
  "freezing_rain_light",
  "tstorm",
  "rain_heavy",
  "rain",
  "rain_light",
  "drizzle",
  "fog_light",
  "fog",
  "cloudy",
  "mostly_cloudy",
  "partly_cloudy",
  "mostly_clear",
  "clear",
];

/* *
 * Reducer
 * */
const initialState = {
  weatherForecast: null,
  loading: false,
  error: {},
  success: false,
};

export default function reducer(
  state = initialState,
  { type, payload, meta, ...rest }
) {
  switch (type) {
    case INIT:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        weatherForecast: payload,
      };
    case REJECT:
      return {
        ...state,
        loading: false,
        success: false,
        error: payload.error,
      };
    default:
      return state;
  }
}

/* *
 * Selectors
 * */
export const moduleSelector = (state) => state[moduleName];

export const weatherForecastSelector = (state) =>
  moduleSelector(state).weatherForecast;

export const isLoadingSelector = (state) => moduleSelector(state).loading;

export const isSuccessSelector = (state) => moduleSelector(state).success;

/* *
 * Action Creators
 * */
export const getWeatherForecastRequest = (fieldId) => ({
  type: INIT,
  fieldId,
});

export const successAction = (payload) => ({
  type: SUCCESS,
  payload,
});

export const errorAction = (error) => ({
  type: REJECT,
  payload: { error },
});

/* *
 * Sagas
 * */
export function* getWeatherForecastSaga({ fieldId }) {
  try {
    const accountFromStorage = localStorage.getItem("accountData");
    const res = yield call(
      getFieldWeatherForecast,
      JSON.parse(accountFromStorage).id,
      fieldId
    );
    let groupedPerDay = _.groupBy(res, (data) =>
      moment(+`${data.time}000`).startOf('day').valueOf()
    );
    let results = {};
    Object.keys(groupedPerDay).forEach((date) => {
      if (!results[date]) {
        results[date] = {};
      }
      results[date].max = groupedPerDay[date].reduce(
        (max, p) => (!max || p.temperature > max ? p.temperature : max),
        groupedPerDay[date][0].temperature
      );
      results[date].min = groupedPerDay[date].reduce(
        (min, p) => (!min || p.temperature < min ? p.temperature : min),
        groupedPerDay[date][0].temperature
      );
      results[date].humidity = groupedPerDay[date].reduce(
        (avg, data, _, { length }) => {
          if(data.humidity) {
            return +(avg + data.humidity / length).toFixed();
          } else {
            return avg;
          }
        },
        0
      );
      results[date].windSpeed = groupedPerDay[date].reduce(
        (avg, data, _, { length }) => {
          if(data.windSpeed) {
            return +(avg + data.windSpeed / length).toFixed();
          } else {
            return avg;
          }
        },
        0
      );
      for (let j = 0; j < weatherCodesByPriority.length; j++) {
        if (
          groupedPerDay[date].some(
            (data) => data.weatherCode === weatherCodesByPriority[j]
          )
        ) {
          results[date].weatherCode = weatherCodesByPriority[j];
          break;
        }
      }
    });
    yield put(successAction(results));
  } catch (error) {
    yield put(errorAction(error.message));
  }
}

export function* saga() {
  yield all([takeEvery(INIT, getWeatherForecastSaga)]);
}
