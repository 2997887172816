import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  mainContainer: {
    height: "100%",
    overflow: "auto"
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    "@media (max-width: 414px)": {
      height: "auto",
    },
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "60%",
    backgroundColor: "white",
    "@media (max-width: 414px)": {
      height: "100%",
      backgroundColor: "transparent",
      width: "90%",
      paddingBottom: "100px",
    },
  },
  header: {
    height: "97px",
    width: "100%",
    backgroundColor: "white",
    display: "flex",
    alignItems: "center",
    "@media (max-width: 414px)": {
      height: "40px",
    },
  },
  logoStyle: {
    width: "197px",
    height: "80%",
    margin: "0 20%",
  },
  title: {
    color: "rgba(0, 0, 0, 0.6)",
    fontSize: "36px",
    lineHeight: "42px",
    paddingTop: "69px",
    paddingBottom: "50px",
    "@media (max-width: 414px)": {
      paddingTop: "30px",
      paddingBottom: "20px",
      fontSize: "20px",
      lineHeight: "23px",
    },
  },
  subscriptionBox: {
    display: "flex",
    "@media (max-width: 414px)": {
      flexDirection: "column",
      paddingTop: "20px",
    },
  },
  subscriptionInfo: {
    display: "flex",
    flexDirection: "column",
    padding: "0 38px",
    "@media (max-width: 414px)": {
      padding: "20px 15px",
    },
  },
  subscriptionTitle: {
    fontSize: "24px",
    lineHeight: "28px",
    color: "rgba(0, 0, 0, 0.6)",
    paddingBottom: "20px",
  },
  listItem: {
    fontSize: "16px",
    lineHeight: "20px",
    color: "rgba(0, 0, 0, 0.6)",
  },
  select: {
    marginTop: "37px",
  },
  nextRenewal: {
    fontSize: "16px",
    lineHeight: "20px",
    color: "rgba(0, 0, 0, 0.6)",
    paddingTop: "90px",
    "@media (max-width: 414px)": {
      padding: "20px",
    },
  },
  addBtn: {
    marginTop: "20px",
  },
  termsLink: {
    fontSize: "18px",
    lineHeight: "21px",
    textDecorationLine: "underline",
    color: "#5ab3e8",
    marginTop: "24px",
    marginBottom: "56px",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    padding: "54px",
    "@media (max-width: 414px)": {
      padding: "20px",
    },
  },
  successTitle: {
    fontSeight: "bold",
    fontSize: "24px",
    lineHeight: "36px",
    color: "#91b54d",
    "@media (max-width: 414px)": {
      fontSize: "18px",
    },
  },
  successMsg: {
    fontSize: "14px",
    lineHeight: "150%",
    color: "#828b93",
    "@media (max-width: 414px)": {
      display: "none",
    },
  },
  flexRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  infoLabel: {
    flex: 1,
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "36px",
    textAlign: "right",
    color: "#828b93",
    padding: "0 5px",
    "@media (max-width: 414px)": {
      fontSize: "14px",
    },
  },
  infoValue: {
    flex: 1,
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "36px",
    color: "#4a4b54",
    padding: "0 5px",
    "@media (max-width: 414px)": {
      fontSize: "14px",
    },
  },
  nextBtn: {
    "& button": {
      width: "150px",
    },
  },
  mobileSuccessMsg: {
    color: "#4a4b54",
    fontSize: "14px",
    lineHeight: "150%",
    fontWeight: "bold",
    textAlign: "center"
  }
}));
