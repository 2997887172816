import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  container: {
    background: "#EBEDF3",
    height: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  content: {
    background: "#FFFFFF",
    borderRadius: "32px",
    padding: "60px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    maxWidth: "600px"
  },
  title: {
    fontSize: "20px",
    lineHeight: "150%",
    textAlign: "center",
    color: "#4A4B54",
    marginTop: "24px",
    fontWeight: "bold",
  },
  msg: {
    fontSize: "16px",
    lineHeight: "150%",
    textAlign: "center",
    color: "#4A4B54",
    marginTop: "24px",
  },
  msg2: {
    fontSize: "16px",
    lineHeight: "150%",
    textAlign: "center",
    color: "#4A4B54",
  }
}));
