import { makeStyles } from "@material-ui/core";
import BackgroundImage from "./../../../media/login-background.png";

export default makeStyles((theme) => ({
  container: {
    flexGrow: 1,
    display: "flex",
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: "cover",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  content: {
    display: "flex",
    flex: 1,
    width: `calc(90vw - ${theme.spacing(4) * 2}px)`,
    maxWidth: "546px",
    margin: "37px 0",
    backgroundColor: "white",
    borderRadius: "8px",
    padding: theme.spacing(3.5, 3.5),
    border: "1px solid #D7D9DF",
    flexDirection: 'column'
  },
  btnContainer: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    padding: "31px 25% 0 25%",
  },
  inputMargin: {
    marginTop: "2em",
    marginBottom: "1em"
  },
  alignCenter: {
    justifyContent: "center",
    alignItems: "center"
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));
