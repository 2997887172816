import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  getProductsCartListRequest,
  clearProductsListData,
  productsCartListSelector,
} from "redux/productsCartList";
import { removeProductFromCartRequest } from "redux/removeProductFromCart";
import { default as Table } from "./table";
import { AppLoader } from "components/commons";
import {
  Box,
  Divider,
  Paper,
  TextField,
  MenuItem,
  Button,
  IconButton,
  Checkbox,
} from "@material-ui/core";
import { CART_COLUMNS } from "constants/cartTableColumns";
import { getCartTotalsRequest, cartTotalsSelector } from "redux/cartTotals";
import { payOfflineRequest } from "redux/payOffline";
import {
  getFastSpringSessionRequest,
  fastSpringSessionSelector,
  clearFastSpringSession,
} from "redux/fastSpringSession";
import LockIcon from "@material-ui/icons/Lock";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import history from "history.js";
import PermissionsService from "services/PermissionsService";
import GA4React, { useGA4React } from "ga-4-react";

import useStyles from "./styles";

const ga4react = new GA4React("G-88NZ1FTTBQ");
ga4react.initialize();

const Cart = ({
  productsCartList,
  getProductsCartList,
  clearProductsListData,
  removeProductFromCart,
  cartTotals,
  getCartTotals,
  fastSpringSession,
  getFastSpringSession,
  clearFastSpringSession,
  payOffline,
}) => {
  const { t } = useTranslation();
  const ga = useGA4React();
  const {
    container,
    content,
    tableColumn,
    totalColumn,
    tableTitle,
    selectLabel,
    summeryTitle,
    amount,
    licenceRenewal,
    select,
    divider,
    payBtn,
    backBtn,
    backRow,
    backTitle,
    payOfflineBtn,
    privacyPolicyStyles,
    payIcon,
    checkboxContainer,
    checkboxLabel,
  } = useStyles();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("licenceEnd");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [subscribeChecked, triggerSubscribe] = useState(true);
  const [period, setPeriod] = useState(1);

  useEffect(() => {
    return () => {
      clearFastSpringSession();
      clearProductsListData();
    };
  }, []);

  useEffect(() => {
    if (ga) {
      ga.gtag("event", "view_cart", {});
    }
  }, [ga]);

  useEffect(() => {
    getCartTotals({ period });
  }, [period]);

  useEffect(() => {
    if (!document.getElementById("fsc-api")) {
      const script = document.createElement("script");
      script.src =
        "https://d1f8f9xcsvx3ha.cloudfront.net/sbl/0.8.5/fastspring-builder.min.js";
      script.dataset.storefront = process.env.REACT_APP_FASTSPRING;
      script.id = "fsc-api";
      script.dataset.continuous = "true";
      window.onPopupClosed = (data) => {
        if (data) {
          ga4react.gtag("event", "purchase", {
            currency: cartTotals.currency,
            type: "online",
          });
          history.push("/my-products");
        }
      };
      script.dataset.popupClosed = "onPopupClosed";
      document.body.appendChild(script);
    }
    if (fastSpringSession) {
      setTimeout(() => {
        const { builder } = window.fastspring;
        builder.push(fastSpringSession);
        builder.checkout();
      }, 1000);
    }
  }, [fastSpringSession]);

  useEffect(() => {
    if (orderBy === "newDate") {
      getProductsCartList(
        {
          limit: rowsPerPage,
          page: page + 1,
        },
        [`licenceEnd,${order.toUpperCase()}`]
      );
    } else {
      getProductsCartList(
        {
          limit: rowsPerPage,
          page: page + 1,
        },
        [`${orderBy},${order.toUpperCase()}`]
      );
    }
  }, [page, rowsPerPage, orderBy, order]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleRemoveProduct = (licenceId) => {
    ga.gtag("event", "remove_from_cart", {
      currency: cartTotals.currency,
      items: licenceId,
    });
    removeProductFromCart(
      licenceId,
      {
        limit: rowsPerPage,
        page: page + 1,
      },
      [`${orderBy},${order.toUpperCase()}`],
      period
    );
  };
  const handlePressPay = () => {
    ga.gtag("event", "begin_checkout", {
      currency: cartTotals.currency,
      items: productsCartList.data,
      value: cartTotals.amount,
    });
    // begin_checkout
    getFastSpringSession({ period, subscribeChecked });
  };
  const handlePressPayOffline = () => {
    payOffline({
      period,
      callback: () => {
        ga.gtag("event", "purchase", {
          currency: cartTotals.currency,
          items: productsCartList.data,
          value: cartTotals.amount,
          type: "offline",
        });
        history.push("/my-products");
      },
    });
  };
  const handleSubscribeClick = () => {
    triggerSubscribe(!subscribeChecked);
  };
  if (!productsCartList) {
    return <AppLoader />;
  } else {
    return (
      <Box className={container}>
        <Box className={backRow}>
          <IconButton
            onClick={() => history.push(`/my-products`)}
            className={backBtn}
            aria-label="back"
          >
            <ArrowBackIcon id="arrowBack" />
          </IconButton>
          <Box className={backTitle}>{t("backFromCartBtn")}</Box>
        </Box>
        <Paper className={content}>
          <Box className={tableColumn}>
            <Box className={tableTitle}>{t("cartTableTitle")}</Box>
            <Table
              cells={CART_COLUMNS}
              data={productsCartList.data || []}
              order={order}
              orderBy={orderBy}
              page={page}
              rowsPerPage={rowsPerPage}
              handleRequestSort={handleRequestSort}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              pageCount={productsCartList.total || 0}
              handleRemoveProduct={handleRemoveProduct}
              period={period}
            />
          </Box>
          <Divider orientation="vertical" flexItem />
          <Box className={totalColumn}>
            {cartTotals && (
              <Box className={summeryTitle}>
                {cartTotals.productsNr === 1
                  ? t("1product")
                  : t("productsNumber", {
                      number: cartTotals.productsNr,
                    })}
              </Box>
            )}
            <Box className={selectLabel}>{t("time")}</Box>
            <TextField
              select
              value={period}
              onChange={(e) => setPeriod(e.target.value)}
              variant="outlined"
              className={select}
            >
              <MenuItem key={1} value={1}>
                {t("1year")}
              </MenuItem>
              <MenuItem key={2} value={2}>
                {t("numberYearsForSelect", { number: 2 })}
              </MenuItem>
            </TextField>
            <Divider className={divider} flexItem />
            <Box className={licenceRenewal}>{t("licenseRenewal")}</Box>
            {cartTotals && (
              <Box className={amount}>
                {new Intl.NumberFormat(cartTotals.countryCode, {
                  style: "currency",
                  currency: cartTotals.currency,
                }).format(cartTotals.amount)}
              </Box>
            )}
            <Box className={checkboxContainer} onClick={handleSubscribeClick}>
              <Checkbox name="subscribe" checked={subscribeChecked} />
              <Box className={checkboxLabel}>{t("subscribeCheckbox")}</Box>
            </Box>
            <Button
              variant="contained"
              className={payBtn}
              onClick={handlePressPay}
              startIcon={<LockIcon className={payIcon} />}
            >
              {`${t("pay")} ${
                cartTotals
                  ? new Intl.NumberFormat(cartTotals.countryCode, {
                      style: "currency",
                      currency: cartTotals.currency,
                    }).format(cartTotals.amount)
                  : new Intl.NumberFormat(cartTotals.countryCode, {
                      style: "currency",
                      currency: cartTotals.currency,
                    }).format(0)
              }`}
            </Button>
            {PermissionsService.isAllowed("PRODUCT_PAY_OFFLINE") && (
              <Button
                variant="contained"
                className={payOfflineBtn}
                onClick={handlePressPayOffline}
              >
                {t("payOffline")}
              </Button>
            )}
            <Box
              className={privacyPolicyStyles}
              dangerouslySetInnerHTML={{
                __html: t("agreeingViridixPrivacyPolicy", {
                  privacyUrl: "https://www.viridix.com/privacy-policy/",
                  conditionsUrl: "https://www.viridix.com/terms-conditions/",
                }),
              }}
            />
          </Box>
        </Paper>
      </Box>
    );
  }
};

const mapStateToProps = (state) => ({
  productsCartList: productsCartListSelector(state),
  cartTotals: cartTotalsSelector(state),
  fastSpringSession: fastSpringSessionSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  getProductsCartList: bindActionCreators(getProductsCartListRequest, dispatch),
  clearProductsListData: bindActionCreators(clearProductsListData, dispatch),
  removeProductFromCart: bindActionCreators(
    removeProductFromCartRequest,
    dispatch
  ),
  getCartTotals: bindActionCreators(getCartTotalsRequest, dispatch),
  getFastSpringSession: bindActionCreators(
    getFastSpringSessionRequest,
    dispatch
  ),
  clearFastSpringSession: bindActionCreators(clearFastSpringSession, dispatch),
  payOffline: bindActionCreators(payOfflineRequest, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Cart);
