import React, { useEffect } from "react";
import { Box, makeStyles } from "@material-ui/core";
import { AppLoader } from "components/commons";
import history from "history.js"

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
}));

const Checkout = ({location}) => {
  const { container } = useStyles();
  useEffect(() => {
    var urlParams = new URLSearchParams(location.search);
    const organizationId = urlParams.get("organizationId");
    const token = localStorage.getItem("token");
    if (token) {
      history.push(`/trial/${organizationId}?isFromMobile=true`)
    } else {
      history.push(`/login?isGetSubscription=true&&userOrganization=${organizationId}&&isFromMobile=true`)
    }
  }, [])
  return (
    <Box className={container}>
      <AppLoader />
    </Box>
  );
};

export default Checkout;
