import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  container: {
    padding: "24px",
    height: "100%"
  },
  title: {
    color: "#4A4B54",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "16px",
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  exportText: {
    color: "#828B93",
    fontSize: "14px",
    padding: "0 10px"
  }
}));
