import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  exportText: {
    color: "#828B93",
    fontSize: "14px",
    padding: "0 10px",
  },
  linkToRenew: {
    textDecoration: "underline",
    cursor: "pointer",
    "&:hover": {
      opacity: "0.7",
    },
  },
  renewLicenseMsg: {
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    color: "#828B93",
    fontSize: "16px",
    fontWeight: "500",
  },
}));
