import { put, takeEvery, all, call } from "redux-saga/effects";
import { getExpirationStatus } from "api";
/* *
 * Constants
 * */
export const moduleName = "expirationStatus";
const prefix = `${process.env.REACT_APP_NAME}/${moduleName}`;

export const INIT = `${prefix}/INIT`;
export const CLEAR = `${prefix}/CLEAR`;
export const SUCCESS = `${prefix}/SUCCESS`;
export const REJECT = `${prefix}/REJECT`;

/* *
 * Reducer
 * */
const initialState = {
  expirationStatus: null,
  loading: false,
  error: {},
  success: false,
};

export default function reducer(
  state = initialState,
  { type, payload, meta, ...rest }
) {
  switch (type) {
    case INIT:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        expirationStatus: payload,
      };
    case CLEAR:
      return initialState;
    case REJECT:
      return {
        ...state,
        loading: false,
        success: false,
        error: payload.error,
      };
    default:
      return state;
  }
}

/* *
 * Selectors
 * */
export const moduleSelector = (state) => state[moduleName];

export const expirationStatusSelector = (state) => moduleSelector(state).expirationStatus;

export const isLoadingSelector = (state) => moduleSelector(state).loading;

export const isSuccessSelector = (state) => moduleSelector(state).success;

/* *
 * Action Creators
 * */
export const getExpirationStatusRequest = () => ({
  type: INIT,
});

export const clearExpirationStatus = () => ({
  type: CLEAR,
});

export const successAction = (payload) => ({
  type: SUCCESS,
  payload,
});

export const errorAction = (error) => ({
  type: REJECT,
  payload: { error },
});

/* *
 * Sagas
 * */
export function* getExpirationStatusSaga() {
  try {
    let accountId = null;
    let res = null;
    const accountFromStorage = localStorage.getItem("accountData");
    accountId = JSON.parse(accountFromStorage).id;
    if (accountId) {
      res = yield call(getExpirationStatus, accountId);
    }
    yield put(successAction(res));
  } catch (error) {
    yield put(errorAction(error.message));
  }
}

export function* saga() {
  yield all([takeEvery(INIT, getExpirationStatusSaga)]);
}
