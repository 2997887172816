const isAllowed = (permissionKey) => {
  const permissions = localStorage.getItem("permissions");
  if (permissions) {
    const parsedPermissons = JSON.parse(permissions);
    return parsedPermissons.includes(permissionKey);
  } else {
    return true;
  }
};

export default { isAllowed };
