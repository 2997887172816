import { put, takeEvery, all, call, select } from "redux-saga/effects";
import { getAccountsList } from "api";
import { userIdSelector } from "./me";
import history from "history.js";
/* *
 * Constants
 * */
export const moduleName = "accountsCount";
const prefix = `${process.env.REACT_APP_NAME}/${moduleName}`;

export const INIT = `${prefix}/INIT`;
export const SUCCESS = `${prefix}/SUCCESS`;
export const REJECT = `${prefix}/REJECT`;

/* *
 * Reducer
 * */
const initialState = {
  accountsCount: 0,
  loading: false,
  error: {},
  success: false,
};

export default function reducer(
  state = initialState,
  { type, payload, meta, ...rest }
) {
  switch (type) {
    case INIT:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        accountsCount: payload,
      };
    case REJECT:
      return {
        ...state,
        loading: false,
        success: false,
        error: payload.error,
      };
    default:
      return state;
  }
}

/* *
 * Selectors
 * */
export const moduleSelector = (state) => state[moduleName];

export const accountsCountSelector = (state) => moduleSelector(state).accountsCount;

export const isLoadingSelector = (state) => moduleSelector(state).loading;

export const isSuccessSelector = (state) => moduleSelector(state).success;

/* *
 * Action Creators
 * */
export const getAccountsCountRequest = (
  byCurrentAccount = false,
  selectDefaultAccount = false
) => ({
  type: INIT,
  byCurrentAccount,
  selectDefaultAccount,
});

export const successAction = (payload) => ({
  type: SUCCESS,
  payload,
});

export const errorAction = (error) => ({
  type: REJECT,
  payload: { error },
});

/* *
 * Sagas
 * */
export function* getAccountsCountSaga({
  byCurrentAccount,
  selectDefaultAccount,
}) {
  try {
    let filterAccountsIds = [];
    if (byCurrentAccount) {
      const accountFromStorage = localStorage.getItem("accountData");
      filterAccountsIds.push(JSON.parse(accountFromStorage).id);
    }
    const userId = yield select(userIdSelector);
    const res = yield call(getAccountsList, userId, filterAccountsIds);
    if (selectDefaultAccount) {
      if (res.length === 1) {
        history.push(
          `/account-login?organizationId=${res[0].id}&name=${
            res[0].name
          }&parentId=${res[0].parentId}&redirectUrl=/users`
        );
      }
    }
    yield put(successAction(res.length));
  } catch (error) {
    yield put(errorAction(error.message));
  }
}

export function* saga() {
  yield all([takeEvery(INIT, getAccountsCountSaga)]);
}
