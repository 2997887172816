import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box, IconButton, Menu, MenuItem } from "@material-ui/core";
import {
  getDevicesCellstateLastDataRequest,
  devicesCellstateLastDataSelector,
} from "redux/devicesCellstateLastData";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { AppLoader } from "components/commons";

import useStyles from "./styles";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import clsx from "clsx";
import { settingsSelector } from "redux/dashboardSettings";
import { saveDashboardSettingsRequest } from "redux/saveDashboardSettings";
import DeleteIcon from "media/deleteIcon.svg";
import ChargingIcon from "media/chargingIcon.svg";

const DeviceIcon = ({color}) => {
  return (
    <svg
      width="17"
      height="22"
      viewBox="0 0 17 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="8.5" cy="8.5" r="8.5" fill={color} />
      <path
        d="M11.934 10.5868C10.0507 12.5386 8.4915 14.0857 8.4915 14.0857C7.01099 12.6372 6.08349 9.70839 7.73816 7.90635C10.0507 5.38783 11.5661 3.88571 11.5661 3.88571C13.2517 5.39459 13.8254 8.62655 11.934 10.5868Z"
        stroke="white"
      />
      <path
        d="M5.06601 10.5868C6.94934 12.5386 8.50856 14.0857 8.50856 14.0857C9.98907 12.6372 10.9166 9.70839 9.26189 7.90635C6.94934 5.38783 5.43391 3.88571 5.43391 3.88571C3.74837 5.39459 3.17462 8.62655 5.06601 10.5868Z"
        stroke="white"
      />
      <path d="M8.5 22L11.5311 18.25H5.46891L8.5 22Z" fill={color} />
    </svg>
  );
};


const DevicesCellstateLastData = ({
  plotId,
  devices,
  getDevicesCellstate,
  devicesCellstate,
  saveDashboardSettings,
  dashboardSettings,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { t } = useTranslation();
  const {
    title,
    container,
    circleProgress,
    content,
    percents,
    deviceName,
    deviceInfo,
    header,
    menuItemText,
  } = useStyles();
  useEffect(() => {
    if (devices) {
      getDevicesCellstate(devices);
    }
  }, [devices]);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const deleteWidget = () => {
    const index = dashboardSettings.findIndex(
      (item) => item.key === "batteryStatus"
    );
    dashboardSettings[index].enabled = false;
    saveDashboardSettings({
      plotId: plotId,
      settings: dashboardSettings,
      updateData: true,
    });
  };
  const open = Boolean(anchorEl);
  return (
    <Box className={container}>
      <Box className={clsx("widgetHeader", header)}>
        <Box style={{ display: "flex", alignItems: "center" }}>
          <img
            width="32px"
            height="32px"
            style={{ marginInlineEnd: "10px" }}
            src={ChargingIcon}
            alt="icon"
          />
          <Box className={title}>{t("batteriesStatus")}</Box>
        </Box>
        <IconButton onClick={handleClick} size="small">
          <MoreVertIcon />
        </IconButton>
      </Box>
      {devicesCellstate ? (
        <Box className={content}>
          {// eslint-disable-next-line
          devicesCellstate.map((deviceData) => {
            if (typeof deviceData !== "string") {
              return (
                <Box key={Math.random()} className={deviceInfo}>
                  <Box className={circleProgress}>
                    <CircularProgressbarWithChildren
                      value={deviceData.cellstate.lastValues.cellstate}
                      styles={buildStyles({
                        pathColor: deviceData.device.color,
                      })}
                    >
                      <DeviceIcon color={deviceData.device.color} />
                    </CircularProgressbarWithChildren>
                  </Box>
                  <Box className={percents}>
                    {deviceData.cellstate.lastValues.cellstate}%
                  </Box>
                  <Box className={deviceName}>{deviceData.device.name}</Box>
                </Box>
              );
            }
          })}
        </Box>
      ) : (
        <Box height="80%">
          <AppLoader />
        </Box>
      )}
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem
          style={{
            width: "200px",
          }}
          onClick={deleteWidget}
        >
          <img src={DeleteIcon} alt="DeleteIcon" />
          <Box className={menuItemText}>{t("delete")}</Box>
        </MenuItem>
      </Menu>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  devicesCellstate: devicesCellstateLastDataSelector(state),
  dashboardSettings: settingsSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  getDevicesCellstate: bindActionCreators(
    getDevicesCellstateLastDataRequest,
    dispatch
  ),
  saveDashboardSettings: bindActionCreators(
    saveDashboardSettingsRequest,
    dispatch
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DevicesCellstateLastData);
