export default [
  {
    lang: "en",
    direction: "ltr",
    label: "English",
    shortName: "ENG",
    countryCode: "US",
  },
  {
    lang: "ar",
    direction: "rtl",
    label: "Arabic",
    shortName: "AR",
    countryCode: "AR",
  },
  {
    lang: "he",
    direction: "rtl",
    label: "Hebrew",
    shortName: "HE",
    countryCode: "IL",
  },
  {
    lang: "de",
    direction: "ltr",
    label: "German",
    shortName: "DE",
    countryCode: "DE",
  },
  {
    lang: "it",
    direction: "ltr",
    label: "Italian",
    shortName: "IT",
    countryCode: "IT",
  },
  {
    lang: "es",
    direction: "ltr",
    label: "Spanish",
    shortName: "ES",
    countryCode: "ES",
  },
  {
    lang: "tr",
    direction: "ltr",
    label: "Turkish",
    shortName: "TR",
    countryCode: "TR",
  },
  {
    lang: "ro",
    direction: "ltr",
    label: "Romanian",
    shortName: "RO",
    countryCode: "RO",
  },
  {
    lang: "pt",
    direction: "ltr",
    label: "Portuguese",
    shortName: "PT",
    countryCode: "PT",
  },
];
