import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  fieldContainer: {
    display: "flex",
    flexDirection: "column",
  },
  errorStyles: {
    fontSize: "16px",
    lineHeight: "16px",
  },
}));
