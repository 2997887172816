import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  container: {
    borderTop: "1px solid #EBEDF3",
    position: window.outerWidth > 414 && "fixed",
    bottom: 0,
    padding: "13px 0px",
    backgroundColor: "#f4f4f7"
  },
  content: {
    display: "flex",
    flexDirection: window.outerWidth > 414 ? "row" : "column",
    padding: "0 30.4px",
  },
  contentBox: {
    flex: 1,
    display: "flex",
    padding: "16px 0px"
  },
  contentTextBox: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    padding: "0 16px",
  },
  title: {
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "16px",
    color: "#363740",
  },
  msg: {
    fontSize: "14px",
    lineHeight: "16px",
    color: "#828B93",
  },
  intercomBtn: {
    backgroundColor: theme.palette.primary.main,
    width: "60px",
    height: "60px",
    marginInlineStart: "auto",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      opacity: 0.8,
    },
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: "underline",
    cursor: "pointer",
    "&:hover": {
      opacity: .8
    }
  }
}));
