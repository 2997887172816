import { css } from "emotion";

const globalStyle = css`
  html[dir="rtl"] {
    #arrowBack {
      transform: rotate(180deg)
    }
    .MuiTableCell-root {
      text-align: right;
    }
    .widgetHeader {
      direction: rtl;
    }
    .autopilotWidget {
      direction: rtl;
    }
    .MuiMenuItem-root, .MuiSelect-selectMenu {
      direction: rtl;
      text-align: right;
    }
  }
  .MuiMenuItem-root, .MuiSelect-selectMenu {
    unicode-bidi: plaintext;
  }
  .deviceInfoMapContainer {
    width: 290px;
    padding: 18px;
    line-height: 1.35;
    overflow: hidden;
    white-space: nowrap;
    .deviceInfoRow {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      .deviceInfoLabel {
        font-size: 14px;
        line-height: 150%;
        color: #828B93;
      }
      .deviceInfoValue {
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #4A4B54;
      }
    }
  }
  .no-draggable {
    cursor: auto;
  }
  body {
    margin: 0;
    padding: 0;
    font-family: Roboto;
    height: 100%;
    background-color: #f4f4f7;
    color: #363740;
  }
  .grecaptcha-badge {
    visibility: hidden;
  }
  #root {
    height: 100%;
    overflow: hidden;
  }
  html {
    height: 100%;
    .react-code-input {
      direction: ltr;
    }
  }
  .MuiTablePagination-toolbar {
    direction: ltr
  }
  html[dir="rtl"] {
    label {
      width: 100%;
      transform-origin: top right;
    }
    div[role="radiogroup"] {
      label {
        width: initial;
      }
    }
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .ReactTable {
    border: none !important;
    .rt-table {
      .-header {
        box-shadow: none !important;
        border-bottom: solid 1px rgba(0, 0, 0, 0.05);
        .rt-th {
          align-items: center;
          justify-content: flex-start;
          display: flex;
          border-right: none !important;
          font-size: 13px;
          font-weight: bold;
          height: 50px;
          &:last-child {
            justify-content: center;
          }
        }
      }
      .rt-td {
        border-right: none !important;
      }
      .-filters {
        .rt-th {
          align-items: center;
          justify-content: flex-start;
          display: flex;
          border-right: none !important;
        }
      }
    }
    select {
      background: transparent !important;
      border: none !important;
      font-family: Roboto;
      font-size: 13px !important;
      font-weight: normal !important;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: -0.1px;
      color: rgba(0, 0, 0, 0.4);
    }
    input {
      background: transparent !important;
      border: 1px solid rgba(0, 0, 0, 0.1);
      padding: 5px 7px;
      font-size: inherit;
      border-radius: 3px;
      font-weight: normal;
      outline: none;
      &:focus {
        border-color: #70b7e1 !important;
      }
      &:disabled {
        border: none !important;
      }
      &::placeholder {
        font-family: Roboto;
        font-size: 13px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: -0.1px;
        color: rgba(0, 0, 0, 0.4);
      }
    }
    .rt-tbody {
      .rt-tr {
        min-height: 50px;
        .rt-td {
          display: flex;
          align-items: center;
        }
      }
    }
    .-pageInfo {
      font-family: Roboto;
      font-size: 13px !important;
      font-weight: normal !important;
    }
    button {
      font-size: 14px !important;
    }
  }
  .drp-buttons {
    .cancelBtn {
      background-color: white;
      border: 1px solid #a8d8f4;
      font-family: "Roboto";
      font-weight: normal !important;
      border-radius: 3px;
      color: #363740;
      cursor: pointer;
    }
    .cancelBtn:hover {
      opacity: 0.7;
    }
    .applyBtn:hover {
      opacity: 0.7;
    }
    .applyBtn {
      background-color: white;
      font-family: "Roboto";
      font-weight: normal !important;
      border-radius: 3px;
      color: #70b7e1;
      border: 1px solid #70b7e1;
      cursor: pointer;
    }
  }
  .daterangepicker::before {
    left: 171px !important;
  }
  .daterangepicker::after {
    left: 172px !important;
  }
`;

export default globalStyle;
