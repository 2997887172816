import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  modalContainer: {
    padding: "31px",
  },
  modalContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  modalTitle: {
    fontWeight: "bold",
    fontSize: "25px",
    color: "#4A4B54",
    marginTop: "13px",
    marginBottom: "34px",
  },
  loginBtn: {
    width: "50%",
  },
  loginBtnContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "14px",
  },
  errorMsg: {
    fontSize: "14px",
    color: "#E62121",
    textAlign: "center",
    marginTop: "18px",
  },
  helpMsg: {
    marginTop: "25px",
    fontSize: "12px",
    color: "#A1A8AE",
    textAlign: "center",
  },
  closeIcon: {
    position: "absolute",
    top: "10px",
    right: "10px",
    cursor: "pointer",
    "&:hover": {
      opacity: 0.7,
    },
  },
}));
