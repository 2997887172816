export const CART_COLUMNS = [
  {
    id: "product",
    numeric: false,
    disablePadding: false,
    label: "prooductId",
    sortable: true,
    filterable: false,
    minWidth: null,
    maxWidth: null,
    width: null
  },
  {
    id: "licenceEnd",
    numeric: false,
    disablePadding: false,
    label: "licenceEnd",
    sortable: true,
    filterable: false,
    minWidth: null,
    maxWidth: null,
    width: null
  },
  {
    id: "newDate",
    numeric: false,
    disablePadding: false,
    label: "newDate",
    sortable: true,
    filterable: false,
    minWidth: null,
    maxWidth: null,
    width: null
  },
  {
    id: "price",
    numeric: false,
    disablePadding: false,
    label: "price",
    sortable: true,
    filterable: false,
    minWidth: null,
    maxWidth: null,
    width: null
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: false,
    label: "actions",
    sortable: false,
    filterable: false,
    minWidth: null,
    maxWidth: null,
    borderStart: false,
    width: null
  },
];
