import { put, takeEvery, all, call } from "redux-saga/effects";
import { getMe } from "api";
import { getAccountsCountRequest } from "./accountsCount";
/* *
 * Constants
 * */
export const moduleName = "userData";
const prefix = `${process.env.REACT_APP_NAME}/${moduleName}`;

export const INIT = `${prefix}/INIT`;
export const SUCCESS = `${prefix}/SUCCESS`;
export const REJECT = `${prefix}/REJECT`;
export const RESET_STATUS_OTP = `${prefix}/RESET_STATUS_OTP`;

/* *
 * Reducer
 * */
const initialState = {
  user: {},
  loading: false,
  error: {},
  success: false,
};

export default function reducer(
  state = initialState,
  { type, payload, meta, ...rest }
) {
  switch (type) {
    case INIT:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        user: payload,
      };
    case REJECT:
      return {
        ...state,
        loading: false,
        success: false,
        error: payload.error,
      };
    default:
      return state;
  }
}

/* *
 * Selectors
 * */
export const moduleSelector = (state) => state[moduleName];

export const userNameSelector = (state) => moduleSelector(state).user.name;

export const userIdSelector = (state) => moduleSelector(state).user.id;

export const defaultAccountSelector = (state) =>
  moduleSelector(state).user.defaultOrganization;

export const isLoadingSelector = (state) => moduleSelector(state).loading;

export const isSuccessSelector = (state) => moduleSelector(state).success;

/* *
 * Action Creators
 * */
export const getMeRequest = () => ({
  type: INIT,
});

export const successAction = (payload) => ({
  type: SUCCESS,
  payload,
});

export const errorAction = (error) => ({
  type: REJECT,
  payload: { error },
});

const logOut = () => ({
  type: "LOGOUT",
});

/* *
 * Sagas
 * */
export function* getMeSaga() {
  try {
    const res = yield call(getMe);
    yield put(successAction(res));
    yield put(getAccountsCountRequest(false, true));
  } catch (error) {
    if (error.response.data.error === "USER_DELETED") {
      yield put(logOut());
    }
    if (error) yield put(errorAction(error.message));
  }
}

export function* saga() {
  yield all([takeEvery(INIT, getMeSaga)]);
}
