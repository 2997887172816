import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  header: {
    padding: "24px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  widgetTitle: {
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "16px",
    color: "#4A4B54",
  },
  deviceInfo: {
    fontSize: "12px",
    lineHeight: "140%",
    color: "#4A4B54",
  },
  menuItem: {
    color: "#828B93",
    fontSize: "14px",
    padding: "0 10px"
  }
}));
