import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Button } from "@material-ui/core";
import { Close } from "@material-ui/icons";

import useStyles from "./styles";

const getNumberOfDays = (end) => {
  const date1 = new Date();
  const date2 = new Date(end);

  const oneDay = 1000 * 60 * 60 * 24;
  const diffInTime = date2.getTime() - date1.getTime();
  const diffInDays = Math.round(diffInTime / oneDay);

  return diffInDays;
};

const SubscriptionAlertBar = ({ closeAlertBar, expirationStatus, handlePressRenewNow }) => {
  const { t } = useTranslation();
  const { container, content, msg, btn, closeIcon } = useStyles();
  return (
    <Box className={container}>
      <Box />
      <Box className={content}>
        {expirationStatus.expired && (
          <Box className={msg}>{t("notificationBarExpiredLicence")}</Box>
        )}
        {expirationStatus.aboutToExpire && (
          <Box className={msg}>
            {t("notificationBarAboutToExpireLicence", {
              daysleft: getNumberOfDays(expirationStatus.aboutToExpire),
            })}
          </Box>
        )}
        <Button onClick={handlePressRenewNow} className={btn}>
          {t("renewPlan")}
        </Button>
      </Box>
      <Close onClick={closeAlertBar} className={closeIcon} />
    </Box>
  );
};

export default SubscriptionAlertBar;
