import React from "react";
import { TextField, MenuItem } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Languages from "constants/languages";
import useStyles from "./styles";
import moment from "moment";

const LanguagePicker = ({ whitePicker }) => {
  const { i18n } = useTranslation();
  const { picker } = useStyles({ whitePicker });
  const onChange = ({ target: { value } }) => {
    const lang = Languages.find((lang) => lang.lang === value);
    i18n.changeLanguage(value);
    const body = document.querySelector("html");
    body.dir = lang.direction;
    body.lang = value;
    moment.locale(value);
    localStorage.setItem("language", value);
  };
  return (
    <TextField
      id="lang"
      select
      SelectProps={{
        className: picker,
      }}
      margin="none"
      value={i18n.language}
      onChange={onChange}
    >
      {Languages.map((lang) => (
        <MenuItem value={lang.lang} key={lang.lang}>
          {lang.shortName}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default LanguagePicker;
