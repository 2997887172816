import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { AppLoader } from "components/commons";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import history from "history.js";
import { clearAccountHierarchy } from "redux/accountHierarchy";
import { clearExpiringLicences } from "redux/expiringLicences";
import { getExpirationStatusRequest, clearExpirationStatus } from "redux/expirationStatus";
import { addProductsToCartRequest } from "redux/addProductsToCart";
import {
  getPermissionsRequest,
  permissionsSelector,
  clearPermissons,
} from "redux/permissions";
import { useGA4React } from "ga-4-react";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
}));

const AccountLogin = ({
  location,
  clearAccountHierarchy,
  getPermissions,
  permissions,
  clearPermissonsData,
  clearExpiringLicences,
  getExpirationStatus,
  addProductsToCart,
  clearExpirationStatus
}) => {
  const { container } = useStyles();
  const [authenticated, setAuthenticated] = useState(false);
  const ga = useGA4React();
  useEffect(() => {
    const token = localStorage.getItem("token");
    const urlParams = new URLSearchParams(location.search);
    const organizationId = urlParams.get("organizationId");
    const name = urlParams.get("name");
    const parentId = urlParams.get("parentId");
    const licenceIds = urlParams.get("licenceIds");
    if (!token && licenceIds) {
      history.push(
        `/login?organizationId=${organizationId}&name=${name}&parentId=${parentId}&redirectUrl=/my-products&fromEmail=true&isFromPaymentEmail=true&licenceIds=${licenceIds}`
      );
    } else if (!token && !licenceIds) {
      history.push(
        `/login?organizationId=${organizationId}&name=${name}&parentId=${parentId}&redirectUrl=/my-products&fromEmail=true&isFromPaymentEmail=true`
      );
    } else {
      setAuthenticated(true);
    }
  }, []);
  useEffect(() => {
    if (authenticated) {
      const urlParams = new URLSearchParams(location.search);
      const clearAccount = urlParams.get("clearAccount");
      if (clearAccount) {
        clearAccountHierarchy();
        clearExpiringLicences();
        clearExpirationStatus();
        localStorage.removeItem("permissions");
        localStorage.removeItem("accountData");
        history.push("/accounts");
      } else {
        const accountData = {
          id: urlParams.get("organizationId"),
          name: urlParams.get("name"),
          parentId:
            urlParams.get("parentId") === "null"
              ? null
              : urlParams.get("parentId"),
        };
        getPermissions(accountData.id);
        localStorage.setItem("accountData", JSON.stringify(accountData));
        getExpirationStatus();
      }
    }
  }, [authenticated]);
  useEffect(() => {
    var urlParams = new URLSearchParams(location.search);
    const redirectUrl = urlParams.get("redirectUrl");
    const accountName = urlParams.get("name");
    const fromEmail = urlParams.get("fromEmail");
    const licenceIds = urlParams.get("licenceIds");
    const accountId = urlParams.get("organizationId");
    if (ga) {
      ga.gtag("event", "select_content", {
        content_type: "account",
        item_id: accountName,
      });
    }
    if (ga && fromEmail === "true") {
      ga.gtag("event", "press_renew_from_email", {});
    }
    if (licenceIds) {
      addProductsToCart({
        productIds: licenceIds.split(",").map((id) => +id),
        callback: () => history.push("/cart"),
        accountId: accountId,
      });
    }
    if (redirectUrl && permissions) {
      history.push(redirectUrl);
    } else if (permissions) {
      history.push("/accounts");
    }
  }, [permissions]);
  useEffect(() => {
    return () => {
      clearPermissonsData();
    };
  }, []);
  return (
    <Box className={container}>
      <AppLoader />
    </Box>
  );
};

const mapStateToProps = (state) => ({
  permissions: permissionsSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  clearPermissonsData: bindActionCreators(clearPermissons, dispatch),
  clearAccountHierarchy: bindActionCreators(clearAccountHierarchy, dispatch),
  getPermissions: bindActionCreators(getPermissionsRequest, dispatch),
  clearExpiringLicences: bindActionCreators(clearExpiringLicences, dispatch),
  getExpirationStatus: bindActionCreators(getExpirationStatusRequest, dispatch),
  addProductsToCart: bindActionCreators(addProductsToCartRequest, dispatch),
  clearExpirationStatus: bindActionCreators(clearExpirationStatus, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountLogin);
