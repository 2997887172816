import React, { useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box, Dialog } from "@material-ui/core";
import { useFormik } from "formik";
import { StyledTextField, AppBtnFab } from "components/commons";
import MottechFullLogo from "media/mottechFullLogo.svg";
import AutopilotLogo from "media/blackLogoAutopilot.svg";
import {
  mottechLoginRequest,
  isLoadingSelector,
  errorSelector,
  isSuccessSelector,
} from "redux/mottechLogin";
import { Close } from "@material-ui/icons";

import useStyles from "./styles";

const MottechLoginModal = ({
  plotId,
  handleClose,
  open,
  mottechLogin,
  loading,
  success,
  error,
}) => {
  const { t } = useTranslation();
  const {
    modalContainer,
    modalContent,
    modalTitle,
    loginBtn,
    loginBtnContainer,
    errorMsg,
    helpMsg,
    closeIcon
  } = useStyles();
  const {
    setFieldValue,
    handleSubmit,
    errors,
    touched,
    values,
    handleChange,
    handleBlur,
    isValid,
  } = useFormik({
    initialValues: {
      serverUrl: "",
      username: "",
      password: "",
    },
    isInitialValid: false,
    validate: (values) => {
      const errors = {};
      if (!values.serverUrl) {
        errors.serverUrl = "Required";
      }
      if (!values.username) {
        errors.username = "Required";
      }
      if (!values.password) {
        errors.password = "Required";
      }
      return errors;
    },
    onSubmit: (values, { setSubmitting }) => {
      mottechLogin({
        plotId,
        serverUrl: values.serverUrl,
        username: values.username,
        password: values.password,
      });
    },
  });
  useEffect(() => {
    if (success) {
      setFieldValue("serverUrl", "");
      setFieldValue("username", "");
      setFieldValue("password", "");
      handleClose();
    }
  }, [success]);
  return (
    <Dialog
      PaperProps={{
        style: { width: "100%" },
      }}
      open={open}
      onClose={handleClose}
    >
      <Box className={modalContainer}>
        <img src={MottechFullLogo} alt="logo" />
        <Box className={modalContent}>
          <img src={AutopilotLogo} alt="logo" />
          <Close className={closeIcon} onClick={handleClose} />
          <Box className={modalTitle}>{t("mottechLoginModalTitle")}</Box>
          <form style={{ width: "100%" }} onSubmit={handleSubmit}>
            <StyledTextField
              label={t("serverUrl")}
              name="serverUrl"
              value={values.serverUrl}
              touched={touched.serverUrl}
              placeholder={"https://icctest.aqualtd.co.il"}
              error={errors.serverUrl}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
            <StyledTextField
              label={t("username")}
              name="username"
              value={values.username}
              touched={touched.username}
              error={errors.username}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
            <StyledTextField
              type="password"
              label={t("password")}
              name="password"
              value={values.password}
              touched={touched.password}
              error={errors.password}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
            {error &&
              Array.isArray(error.message) &&
              error.message.map((err) => (
                <Box key={Math.random()} className={errorMsg}>
                  {err}
                </Box>
              ))}
            {error && typeof error.message === "string" && (
              <Box key={Math.random()} className={errorMsg}>
                {t(error.message)}
              </Box>
            )}
            <Box className={loginBtnContainer}>
              <AppBtnFab
                disabled={!isValid || loading}
                type="submit"
                className={loginBtn}
                label={t("login")}
              />
            </Box>
            <Box className={helpMsg}>
              {t("mottechLoginModalHelpMsg")}
            </Box>
          </form>
        </Box>
      </Box>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  loading: isLoadingSelector(state),
  success: isSuccessSelector(state),
  error: errorSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  mottechLogin: bindActionCreators(mottechLoginRequest, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MottechLoginModal);
