import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  container: {
    padding: "18.5px",
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  deviceName: {
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "16px",
    color: "#4A4B54",
  },
  variable: {
    fontSize: "12px",
    lineHeight: "140%",
    color: "#4A4B54",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
    flex: 1,
  },
  lastValue: {
    fontSize: "14px",
    lineHeight: "150%",
    color: "#828B93",
  },
  row: {
    display: "flex",
    alignItems: "center"
  },
  value: {
    fontWeight: "bold",
    fontSize: "28px",
    lineHeight: "36px",
    color: "#4A4B54",
  },
  sensorDepth: {
    fontSize: "14px",
    lineHeight: "150%",
    color: "#4A4B54",
    paddingInlineStart: "10px"
  },
  lastUpdated: {
    fontSize: "14px",
    lineHeight: "150%",
    color: "#828B93",
  },
  lastUpdatedDate: {
    fontSize: "14px",
    lineHeight: "150%",
    color: "#828B93",
    paddingBottom: "50px"
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  menuItemText: {
    color: "#828B93",
    fontSize: "14px",
    padding: "0 10px",
  }
}));
