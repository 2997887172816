import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import AppBtnFab from "../appBtnFab";
import {
  getStatisticsByPlotsRequest,
  statisticsByPlotsSelector,
  isLoadingSelector,
} from "redux/statisticsByPlots";
import {
  getPlotsIrrigationStatusRequest,
  plotsIrrigationStatusSelector,
} from "redux/plotsIrrigationStatus";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import {
  Box,
  Typography,
  ButtonGroup,
  Button,
  Popover,
  FormControlLabel,
  Checkbox,
  Tooltip,
} from "@material-ui/core";
import { AppLoader } from "components/commons";
import useStyles from "./styles";
import clsx from "clsx";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import SearchIcon from "@material-ui/icons/Search";
import AlertIcon from "media/alertIcon.svg";
import IrrigationIcon from "media/irrigationIcon.svg";
import GridViewUtils from "utils/gridView";
import FarmerCircle from "media/farmer-circle-white.svg";
import history from "history.js";
const SEVERITY_LEVEL_COLOR = {
  "-1": "rgba(152, 165, 166)",
  0: "rgba(145, 181, 77, 0.75)",
  1: "rgba(145, 181, 77, 0.75)",
  2: "rgba(244, 166, 35, 0.75)",
  3: "rgba(244, 166, 35, 0.75)",
  4: "rgba(218, 80, 98, 0.75)",
  5: "rgba(218, 80, 98, 0.75)",
};

const defaultFilterSettings = {
  redCode: true,
  orangeCode: true,
  greenCode: true,
  timeRange: 7,
};

const PlotGridView = ({
  getStatisticsByPlots,
  statisticsByPlots,
  getPlotsIrrigationStatus,
  plotsIrrigationStatus,
  selectedPlot,
  selectPlot,
  setPlotsData,
  loading,
  hideHeader = false,
}) => {
  const { t } = useTranslation();
  const {
    container,
    gridItem,
    plotStyle,
    gridTitle,
    cropName,
    plotName,
    wp,
    selectedPlotStyle,
    btnSelected,
    btn,
    header,
    filterBtn,
    filterContent,
    rowSpaceBetween,
    resetBtn,
    issuesFilter,
    timeBtn,
    timeBtnSelected,
    applyBtn,
    searchBtn,
    icon,
    visibilityVisible,
    checkboxLabel,
    checkboxLabelChecked,
    regionBtn,
    cropBtn,
    plotDataContainer,
    irrigationIcon,
    goToAccountsBtn,
  } = useStyles();
  let interval = null;
  let getPlotsIrrigationStatusInterval = null;
  const [filter, setFilter] = useState("field");
  const [selectedRange, selectRange] = useState(
    defaultFilterSettings.timeRange
  );
  const [filterAnchorEl, setFilterAnchorEl] = React.useState(null);
  const [groupedPlotsData, setGroupedPlots] = useState([]);
  const [selectedIssuesCode, selectIssueCode] = useState(
    GridViewUtils.generateSeverityCodesArray(defaultFilterSettings)
  );
  const [filtersSettings, setSettingsFilter] = useState(defaultFilterSettings);
  useEffect(() => {
    getPlotsIrrigationStatus();
    getPlotsIrrigationStatusInterval = setInterval(() => {
      getPlotsIrrigationStatus();
    }, 60000);
    return () => {
      clearInterval(interval);
      clearInterval(getPlotsIrrigationStatusInterval);
    };
  }, []);
  useEffect(() => {
    getStatisticsByPlots(selectedRange);
    interval = setInterval(() => {
      getStatisticsByPlots(selectedRange);
    }, 60 * 1000 * 5);
  }, [selectedRange]);

  useEffect(() => {
    if (statisticsByPlots && statisticsByPlots.length) {
      const devicesByPlot = _.groupBy(statisticsByPlots, "plotId");
      const plots = Object.keys(devicesByPlot).map((value) =>
        GridViewUtils.buildPlotData(devicesByPlot[value])
      );
      const groupedPlots = GridViewUtils.groupBy(plots, filter);
      setGroupedPlots(groupedPlots);
      setPlotsData(plots);
    }
  }, [statisticsByPlots, filter]);

  const handleFilterOpen = (event) => {
    setFilterAnchorEl(event.currentTarget);
  };
  const handleFilterClose = () => {
    setFilterAnchorEl(null);
  };

  const selectIssuesCode = (code) => {
    setSettingsFilter({ ...filtersSettings, [code]: !filtersSettings[code] });
  };

  const selectTimerange = (timeRange) => {
    setSettingsFilter({ ...filtersSettings, timeRange: timeRange });
  };

  const applyFilters = () => {
    selectIssueCode(GridViewUtils.generateSeverityCodesArray(filtersSettings));
    selectRange(filtersSettings.timeRange);
    handleFilterClose();
  };
  const resetFilters = () => {
    setSettingsFilter(defaultFilterSettings);
    selectIssueCode(
      GridViewUtils.generateSeverityCodesArray(defaultFilterSettings)
    );
    selectRange(defaultFilterSettings.timeRange);
    handleFilterClose();
  };
  const getPlotIrrigationStatus = (plotId) => {
    const irrigationData = plotsIrrigationStatus.find(
      (item) => +item.plotId === +plotId
    );
    if (irrigationData) {
      return irrigationData.irrigationStatus;
    } else {
      return "OFF";
    }
  };
  return (
    <Box className={container}>
      {!hideHeader && (
        <Box className={header}>
          <Button style={{ visibility: "hidden" }} className={searchBtn}>
            <SearchIcon />
          </Button>
          <ButtonGroup disableElevation variant="contained">
            <Button
              onClick={() => setFilter("field")}
              className={clsx(btn, regionBtn, {
                [btnSelected]: filter === "field",
              })}
            >
              {t("region")}
            </Button>
            <Button
              onClick={() => setFilter("crops")}
              className={clsx(btn, cropBtn, {
                [btnSelected]: filter === "crops",
              })}
            >
              {t("crop")}
            </Button>
          </ButtonGroup>
          <Button className={filterBtn} onClick={handleFilterOpen}>
            {t("filter")}
            {`(${(selectedIssuesCode.length - 1) / 2})`}
          </Button>
        </Box>
      )}
      {!groupedPlotsData.length && loading ? (
        <Box height="60%">
          <AppLoader />
        </Box>
      ) : groupedPlotsData.length ? (
        groupedPlotsData.map((item, i) => (
          <Box
            key={Math.random()}
            style={{
              marginBottom: i === groupedPlotsData.length - 1 ? "100px" : "0px",
            }}
          >
            {item.plots.some((plot) =>
              selectedIssuesCode.includes(+plot.severity)
            ) && <Typography className={gridTitle}>{item.name}</Typography>}
            <Box className={gridItem}>
              {item.plots.map(
                (plot) =>
                  selectedIssuesCode.includes(+plot.severity) && (
                    <Box key={plot.plotId} className={plotStyle}>
                      <Box
                        style={{
                          backgroundColor: SEVERITY_LEVEL_COLOR[plot.severity],
                        }}
                        onClick={() => {
                          selectPlot(plot);
                        }}
                        className={clsx(plotDataContainer, {
                          [selectedPlotStyle]:
                            selectedPlot && plot.plotId === selectedPlot.plotId,
                        })}
                      >
                        <img
                          className={clsx(icon, {
                            [visibilityVisible]: plot.hasAlerts,
                          })}
                          src={AlertIcon}
                          alt="hasAlerts"
                        />
                        <Tooltip
                          title={
                            filter === "crops"
                              ? plot.field
                              : plot.crops.join(",")
                          }
                        >
                          <Typography className={cropName}>
                            {filter === "crops"
                              ? plot.field
                              : plot.crops.join(",")}
                          </Typography>
                        </Tooltip>
                        <Tooltip title={plot.plot}>
                          <Typography className={plotName}>
                            {plot.plot}
                          </Typography>
                        </Tooltip>
                        <Typography className={wp}>
                          {!isNaN(plot.wp) ? `${plot.wp}cb` : "-"}
                        </Typography>
                        {getPlotIrrigationStatus(plot.plotId) === "watering" && (
                          <img
                            className={irrigationIcon}
                            src={IrrigationIcon}
                            alt="irrigationIcon"
                          />
                        )}
                      </Box>
                    </Box>
                  )
              )}
            </Box>
          </Box>
        ))
      ) : (
        <Box
          height="60%"
          style={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <img src={FarmerCircle} alt="FarmerCircle" />
          <Box
            style={{
              color: "#828B93",
              fontWeight: 700,
              fontSize: "18px",
              textAlign: "center",
              marginTop: "16px",
            }}
          >
            {t("noPlotsError")}
          </Box>
          <Button
            className={goToAccountsBtn}
            onClick={() => history.push("/accounts")}
          >
            {t("goToAccounts")}
          </Button>
        </Box>
      )}
      <Popover
        open={Boolean(filterAnchorEl)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        anchorEl={filterAnchorEl}
        onClose={handleFilterClose}
      >
        <Box className={filterContent}>
          <Box className={rowSpaceBetween}>
            <Typography type="subtitle1">{t("filters")}</Typography>
            <Button
              onClick={resetFilters}
              className={resetBtn}
              startIcon={<RotateLeftIcon />}
            >
              {t("reset")}
            </Button>
          </Box>
          <Typography className={issuesFilter}>{t("issues")}</Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={filtersSettings.redCode}
                onChange={() => selectIssuesCode("redCode")}
                name="redCode"
                color="primary"
              />
            }
            className={clsx(checkboxLabel, {
              [checkboxLabelChecked]: filtersSettings.redCode,
            })}
            label={t("redCode")}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={filtersSettings.orangeCode}
                onChange={() => selectIssuesCode("orangeCode")}
                name="orangeCode"
                color="primary"
              />
            }
            className={clsx(checkboxLabel, {
              [checkboxLabelChecked]: filtersSettings.orangeCode,
            })}
            label={t("orangeCode")}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={filtersSettings.greenCode}
                onChange={() => selectIssuesCode("greenCode")}
                name="greenCode"
                color="primary"
              />
            }
            className={clsx(checkboxLabel, {
              [checkboxLabelChecked]: filtersSettings.greenCode,
            })}
            label={t("greenCode")}
          />
          <Typography className={issuesFilter}>{t("time")}</Typography>
          <Box
            className={clsx(timeBtn, {
              [timeBtnSelected]: filtersSettings.timeRange === 1,
            })}
            onClick={() => selectTimerange(1)}
          >
            <Typography>{t("dailySummary")}</Typography>
          </Box>
          <Box
            className={clsx(timeBtn, {
              [timeBtnSelected]: filtersSettings.timeRange === 7,
            })}
            onClick={() => selectTimerange(7)}
          >
            <Typography>{t("weeklySummary")}</Typography>
          </Box>
          <Box
            className={clsx(timeBtn, {
              [timeBtnSelected]: filtersSettings.timeRange === 30,
            })}
            onClick={() => selectTimerange(30)}
          >
            <Typography>{t("monthlySummary")}</Typography>
          </Box>
          <AppBtnFab
            className={applyBtn}
            type="submit"
            onClick={applyFilters}
            label={t("applyFilters")}
          />
        </Box>
      </Popover>
    </Box>
  );
};
const mapStateToProps = (state) => ({
  statisticsByPlots: statisticsByPlotsSelector(state),
  plotsIrrigationStatus: plotsIrrigationStatusSelector(state),
  loading: isLoadingSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  getStatisticsByPlots: bindActionCreators(
    getStatisticsByPlotsRequest,
    dispatch
  ),
  getPlotsIrrigationStatus: bindActionCreators(
    getPlotsIrrigationStatusRequest,
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(PlotGridView);
