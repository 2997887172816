import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import {
  getWaterPotentialRequest,
  waterPotentialSelector,
} from "redux/waterPotential";
import { connect } from "react-redux";
import ReactEcharts from "echarts-for-react";
import { AppLoader } from "components/commons";

const WPMiniChart = ({ deviceId, getWPdata, waterPotential, daterange }) => {
  const [chartData, setData] = useState();
  useEffect(() => {
    getWPdata({
      deviceId: deviceId,
      from: daterange[0].valueOf(),
      to: daterange[1].valueOf(),
      isForInsight: true,
    });
  }, [deviceId, daterange]);
  useEffect(() => {
    if (waterPotential && waterPotential[`${deviceId}-insight`]) {
      const deviceData = waterPotential[`${deviceId}-insight`];
      setData(deviceData);
    }
  }, [waterPotential]);
  if (chartData) {
    return (
      <ReactEcharts
        style={{ height: "60px" }}
        option={{
          legend: {
            show: false,
          },
          color: ["#FF3B30", "#70b8e1"],
          grid: {
            show: false,
          },
          axisLabel: { hideOverlap: true },
          xAxis: {
            type: "time",
            show: false,
          },
          yAxis: {
            type: "value",
            show: false,
          },
          dataset: {
            source: chartData.data,
            dimensions: ["timestamp", "rt1-cent", "rt2-cent"],
          },
          series: [
            {
              type: "line",
              symbol: "none",
              connectNulls: true,
              encode: {
                x: "timestamp",
                y: "rt1-cent",
              },
            },
            {
              type: "line",
              symbol: "none",
              connectNulls: true,
              encode: {
                x: "timestamp",
                y: "rt2-cent",
              },
            },
          ],
        }}
      />
    );
  } else {
    return (
      <div style={{ height: "60px" }}>
        <AppLoader />
      </div>
    );
  }
};

const mapStateToProps = (state) => ({
  waterPotential: waterPotentialSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  getWPdata: bindActionCreators(getWaterPotentialRequest, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WPMiniChart);
