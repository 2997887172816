import React, { useEffect, useState } from "react";
import ReactEcharts from "echarts-for-react";
import { useTranslation } from "react-i18next";
import { AppLoader } from "components/commons";
import moment from "moment";
import _ from "lodash";
import history from "history.js";
import { Box } from "@material-ui/core";
import { formatNumber } from "../../../services/NumberFormatterService";

const UNITS_BY_SERIES_INDEX_MOTTECH = {
  0: "mm",
  1: "mm",
  2: "mm",
};

const UNITS_BY_SERIES_INDEX_TALGIL = {
  0: "㎥",
  1: "㎥",
  2: "㎥",
};

const IrrigationHistoryGraph = ({
  irrigationHistory,
  daterange,
  errorMsg,
  withScroll = false,
}) => {
  const { t } = useTranslation();
  const [chartData, setData] = useState();
  useEffect(() => {
    if (irrigationHistory) {
      const precision = 6 * 60 * 60 * 1000;
      const groupedWeatherBy6Hour = _.groupBy(
        irrigationHistory.weather,
        (item) =>
          moment(Math.floor(item.time / precision) * precision).valueOf()
      );
      let irrigationData = [];
      const rainData = Object.keys(groupedWeatherBy6Hour).map((key) => {
        return [
          +key,
          +groupedWeatherBy6Hour[key]
            .reduce((acc, value) => {
              return acc + value.precipitation;
            }, 0)
            .toFixed(2),
          null,
          null,
        ];
      });
      if (irrigationHistory.irrigation) {
        irrigationData = irrigationHistory.irrigation.values.map((item) => [
          item[0],
          null,
          formatNumber(item[1], 2),
          item[2] ? formatNumber(item[2], 2) : formatNumber(item[1], 2),
        ]);
      }
      setData([...rainData, ...irrigationData]);
    }
  }, [irrigationHistory]);
  if (errorMsg && errorMsg === "AUTOPILOT_LICENCE_NOT_FOUND") {
    return (
      <Box
        style={{
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          color: "#828B93",
          fontSize: "16px",
          fontWeight: "500",
          marginTop: "-28px",
        }}
      >
        {t("licenseExpired")}&nbsp;
        <span
          style={{
            textDecoration: "underline",
            cursor: "pointer",
            "&:hover": {
              opacity: "0.7",
            },
          }}
          onClick={() => history.push("/my-products")}
        >
          {t("clickToRenewLink")}
        </span>
      </Box>
    );
  } else if (chartData) {
    return (
      <ReactEcharts
        style={{ height: "100%" }}
        option={{
          tooltip: {
            trigger: "axis",
            appendToBody: true,
            formatter: (data) => {
              if (!data.length) {
                data = [data];
              }
              let tooltip = `${moment(data[0].value[data[0].encode.x]).format(
                "DD-MM YYYY HH:mm"
              )} <br/>`;
              data.forEach(
                ({ value, marker, seriesName, seriesIndex, encode: { y } }) => {
                  tooltip += `<div style="display: flex; align-items: center; direction: ltr;">${marker}<div style="padding-inline-end: 5px">${seriesName}:</div><div style="direction: ltr">${
                    value[y] === null ? "-" : value[y]
                  }${
                    irrigationHistory.irrigation &&
                    irrigationHistory.irrigation.columns.includes(
                      "irrigationVolume"
                    )
                      ? UNITS_BY_SERIES_INDEX_TALGIL[seriesIndex]
                      : UNITS_BY_SERIES_INDEX_MOTTECH[seriesIndex]
                  }</div></div>`;
                }
              );
              return tooltip;
            },
          },
          axisLabel: { hideOverlap: true },
          grid: {
            top: "50",
            left: "50",
            right: "50",
            show: true,
          },
          color: [
            "rgba(232, 235, 189, 1)",
            "rgba(199, 205, 240, 1)",
            "rgba(234, 236, 249, 1)",
          ],
          legend: {
            bottom: 10,
          },
          dataZoom: withScroll && [
            {
              type: "inside",
              realtime: true,
              start: 0,
              end: 100,
            },
          ],
          toolbox: {
            show: false,
          },
          xAxis: {
            type: "time",
            boundaryGap: false,
            min: daterange[0].valueOf(),
            max: daterange[1].valueOf(),
            axisLabel: {
              formatter: function (value) {
                return moment(value).format("DD-MM YYYY");
              },
            },
          },
          yAxis: {
            type: "value",
            // name: t("irrigationHistoryYAxisTitle"),
            nameLocation: "center",
            nameTextStyle: {
              color: "#828B93",
              fontSize: "12px",
            },
            nameGap: 40,
            min: 0,
          },
          dataset: {
            source: chartData.sort((a, b) => b[0] - a[0]),
            dimensions: [
              "timestamp",
              t("rain"),
              t("irrigation"),
              // t("potentialOptimization"), disable potential optimization
            ],
          },
          series: [
            {
              name: t("rain"),
              type: "bar",
              symbol: "none",
              barWidth: "10",
              tooltip: {
                trigger: "item",
              },
              encode: {
                x: "timestamp",
                y: t("rain"),
              },
            },
            {
              name: t("irrigation"),
              type: "bar",
              symbol: "none",
              barWidth: "10",
              tooltip: {
                trigger: "item",
              },
              encode: {
                x: "timestamp",
                y: t("irrigation"),
              },
            },
            // disable potential optimization
            // {
            //   name: t("potentialOptimization"),
            //   type: "bar",
            //   symbol: "none",
            //   barWidth: "10",
            //   tooltip: {
            //     trigger: "item",
            //   },
            //   encode: {
            //     x: "timestamp",
            //     y: t("potentialOptimization"),
            //   },
            // },
          ],
        }}
      />
    );
  } else {
    return <AppLoader />;
  }
};

export default IrrigationHistoryGraph;
