import React from "react";
import { Box, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import useStyles from "./styles";

const AppAutocomplete = ({
  margin = "normal",
  className = "",
  disabled = false,
  label,
  touched,
  error,
  value,
  handleChange,
  handleBlur,
  name,
  options,
  optionLabelKey,
}) => {
  const { fieldContainer, errorStyles } = useStyles();
  return (
    <Box className={fieldContainer}>
      <Autocomplete
        defaultValue={value ? value : null}
        disabled={disabled}
        onBlur={handleBlur}
        onChange={(e, value) => {
          const event = {
            target: {
              value: value || {},
              name: name,
            },
          };
          handleChange(event);
        }}
        name={name}
        options={options}
        getOptionLabel={(option) => {
          return option[optionLabelKey];
        }}
        getOptionSelected={(option) => option.id}
        getOptionDisabled={(option) => option.disabled}
        margin={margin}
        renderInput={(params) => {
          if (!params.inputProps.value) {
            params.inputProps.value = value ? value[optionLabelKey] : "";
          }
          return (
            <TextField
              margin={margin}
              className={className}
              error={!!(touched && error)}
              {...params}
              label={label}
            />
          );
        }}
      />
      {touched && error && <small className={errorStyles}>{error}</small>}
    </Box>
  );
};

export default AppAutocomplete;
