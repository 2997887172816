export function getPrevValue(rt, index, array) {
  if (index === 0) {
    return 0;
  }
  if (array[index - 1][rt] === null) {
    return getPrevValue(rt, index - 1, array);
  } else {
    return array[index - 1][rt];
  }
}

export function normalizeData(data, isRt3Sensor = false) {
  if(isRt3Sensor) {
    return data.reduce(
      (acc, [ts, rt1, rt2, rt3], index, array) => [
        ...acc,
        [
          ts,
          rt1 === null ? getPrevValue(1, index, array) : rt1,
          rt2 === null ? getPrevValue(2, index, array) : rt2,
          rt3 === null ? getPrevValue(3, index, array) : rt3,
        ],
      ],
      []
    );
  } else {
    return data.reduce(
      (acc, [ts, rt1, rt2], index, array) => [
        ...acc,
        [
          ts,
          rt1 === null ? getPrevValue(1, index, array) : rt1,
          rt2 === null ? getPrevValue(2, index, array) : rt2,
        ],
      ],
      []
    );
  }
  
}

// // callculates the color by range, y1,y2 is the range of the color, x1,x2 is the range the sensor mesutrment
// // returns the color as a function of the sensor mesurment
export function calcY(y1, y2, x1, x2, value) {
  var x = value;
  var a = (y2 - y1) / (x2 - x1);
  //var b = 0 - (maxStress*a)
  var b = y2 - x2 * a;
  var y = x * a + b;
  if (y >= 255) y = 255;
  if (y <= 0) y = 0;
  return y;
}
