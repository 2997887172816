import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  tableStyle: {
    minWidth: 750,
  },
  selectedRow: {
    backgroundColor: `rgba(112, 183, 225, 0.1) !important`,
  },
  headerLabel: {
    fontWeight: "bold",
    color: theme.palette.text.lightGray,
  },
  icon: {
    width: "30px",
    height: "30px",
    "&:hover": {
      opacity: 0.6,
    },
  },
  headerTable: {
    display: "flex",
    justifyContent: "space-between",
    padding: "12.5px 16px",
    borderBottom: "1px solid #D7D9DF",
  },
  headerColumn: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  actionBtn: {
    minWidth: "auto",
    padding: 0,
    height: "30px",
    "&:disabled": {
      "& path": {
        fill: "#C8D1D9"
      }
    }
  },
  search: {
    height: "40px",
    width: "220px",
    backgroundColor: "#F7F8FC",
    "&:>fieldset": {
      border: "1px solid #EBEDF3",
    },
  },
  searchIcon: {
    color: theme.palette.text.lightGray,
  },
  borderStart: {
    borderInlineStart: "1px solid #EBEDF3",
  },
  errorBox: {
    height: "440px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  errContent: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
  },
  noAccessMsg: {
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "16px",
    textAlign: "center",
    color: "#828B93",
    padding: "16px 0",
  },
  active: {
    background: "rgba(145, 181, 77, 0.1)",
    border: "1px solid rgba(145, 181, 77, 0.3)",
    borderRadius: "4px",
    padding: "6px 12px",
    textTransform: "uppercase",
    display: "flex",
    alignItems: "center",
    "& > p": {
      color: "#91B54D",
      fontWeight: "bold",
      fontSize: "12px",
      lineHeight: "14px",
    },
    "& > img": {
      marginInlineEnd: "6px",
    },
  },
  expired: {
    background: "rgba(218, 80, 98, 0.1)",
    border: "1px solid rgba(218, 80, 98, 0.3)",
    borderRadius: "4px",
    padding: "6px 12px",
    textTransform: "uppercase",
    display: "flex",
    alignItems: "center",
    "& > p": {
      color: "#DA5062",
      fontWeight: "bold",
      fontSize: "12px",
      lineHeight: "14px",
    },
    "& > img": {
      marginInlineEnd: "6px",
    },
  },
  ends: {
    background: "rgba(244, 166, 35, 0.1)",
    border: "1px solid rgba(244, 166, 35, 0.3)",
    borderRadius: "4px",
    padding: "6px 12px",
    textTransform: "uppercase",
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
    "& > p": {
      color: "#E0920F",
      fontWeight: "bold",
      fontSize: "12px",
      lineHeight: "14px",
    },
    "& > img": {
      marginInlineEnd: "6px",
    },
  },
  productColumn: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "16px",
  },
  tableColumn: {
    color: "#4A4B54",
    fontSize: "14px",
    lineHeight: "150%",
  },
  expiredLicence: {
    color: "#DA5062",
  },
  endingLicence: {
    color: "#E0920F",
  },
  activeLicence: {
    color: "#828B93",
  },
  regionAndPlotLink: {
    color: "#298BC5",
    fontSize: "14px",
    lineHeight: "150%",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
      opacity: 0.7,
    },
  },
  regionAndPlot: {
    color: "#298BC5",
    fontSize: "14px",
    lineHeight: "150%",
  },
  deviceId: {
    color: "#828B93",
    fontSize: "14px",
    lineHeight: "150%",
  },
  productNameStyle: {
    color: theme.palette.primary.main
  },
  accountName: {
    color: "#70b7e1",
    textDecoration: "underline",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
      opacity: 0.7,
    },
  }
}));
