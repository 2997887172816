import React, { useEffect } from "react";
import {
  Box,
  MenuItem,
  Menu,
  Tooltip,
  ListItemText,
  Checkbox,
} from "@material-ui/core";
import DropsSvg from "media/drops.svg";
import DeviceSensors2 from "media/deviceSensors2.svg";
import TwoLayersSoil from "media/twoLayersSoil.svg";
import IrrigationGif from "media/irrigationGif.gif";
import RulerImg from "media/ruler.svg";
import IrrigationDevice from "media/irrigationDevice.svg";
import MottechLogoWidget from "media/mottechLogoWidget.svg";
import moment from "moment";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import TalgilLogo from "media/talgilIcon.svg";

const Drops = ({ position }) => {
  return (
    <Box
      style={{
        position: "absolute",
        bottom: position.bottom,
        left: position.left,
        width: "100%",
        height: "9px",
        backgroundImage: `url(${DropsSvg})`,
      }}
    />
  );
};

const Device = ({ position, startIrrigationRules, stopIrrigationRules }) => {
  return (
    <Box
      style={{
        position: "absolute",
        bottom: position.bottom,
        right: position.right,
      }}
    >
      <Box
        style={{
          position: "absolute",
          top: "133px",
          left: "10px",
          color: "#F1F2F2",
          fontWeight: "bold",
          fontSize: "14px",
        }}
      >
        {startIrrigationRules.lastValue}cb
      </Box>
      <Box
        style={{
          position: "absolute",
          top: "205px",
          left: "10px",
          color: "#F1F2F2",
          fontWeight: "bold",
          fontSize: "14px",
        }}
      >
        {stopIrrigationRules.lastValue}cb
      </Box>
      <Box
        style={{
          width: "89px",
          height: "264px",
          backgroundRepeat: "no-repeat",
          backgroundImage: `url(${DeviceSensors2})`,
        }}
      />
    </Box>
  );
};

const IrrigationGifComponent = ({ position, irrigationActive }) => {
  return (
    <Box
      style={{
        position: "absolute",
        bottom: position.bottom,
        width: "100%",
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        height: irrigationActive ? "69px" : "49px",
      }}
    >
      <img
        style={{ width: "246px" }}
        src={irrigationActive ? IrrigationGif : IrrigationDevice}
        alt="irrigation"
      />
    </Box>
  );
};

const Ruler = ({ position, startIrrigationRules, stopIrrigationRules }) => {
  return (
    <Box
      style={{
        height: "170px",
        position: "absolute",
        bottom: position.bottom,
        width: "51px",
        left: position.left,
        backgroundImage: `url(${RulerImg})`,
        backgroundRepeat: "repeat-y",
        backgroundPositionX: "right",
        backgroundColor: "rgba(0, 0, 0, 0.20)",
      }}
    >
      <Box
        style={{
          position: "absolute",
          top: "35px",
          left: "8px",
          color: "#F1F2F2",
          fontWeight: "bold",
          fontSize: "12px",
        }}
      >
        {Math.round(startIrrigationRules.depth)}cm
      </Box>
      <Box
        style={{
          position: "absolute",
          top: "119px",
          left: "8px",
          color: "#F1F2F2",
          fontWeight: "bold",
          fontSize: "12px",
        }}
      >
        {Math.round(stopIrrigationRules.depth)}cm
      </Box>
    </Box>
  );
};

const StartIrrigationInfo = ({
  position,
  irrigationActive,
  threshold,
  period,
  sensors,
  programName,
  plotSensorList,
  updateIrrigationSettings,
  plotId,
  allowChangeSettings,
  startIrrigationRules,
  groupedBy,
  sensorName,
}) => {
  const { t } = useTranslation();
  const choosesThreshold = Array.from(Array(250), (x, i) => ({
    value: -(i + 1),
    label: `-${i + 1}`,
  }));
  const [anchorElSelectSensor, setAnchorElSelectSensor] = React.useState(null);
  const [anchorElSelectThreshold, setAnchorElSelectThreshold] =
    React.useState(null);
  const [anchorElSelectTime, setAnchorElSelectTime] = React.useState(null);
  const [anchorElSelectGroupedBy, setAnchorElSelectGroupedBy] =
    React.useState(null);
  const [selectedSensors, setSelectedSensors] = React.useState([]);

  useEffect(() => {
    if (sensors) {
      setSelectedSensors(sensors.map((sensor) => sensor.sensorId));
    }
  }, [sensors]);

  const handleOpenSelectSensor = (event) => {
    setAnchorElSelectSensor(event.currentTarget);
  };

  const handleOpenSelectGroupedBy = (event) => {
    setAnchorElSelectGroupedBy(event.currentTarget);
  };

  const handleCloseSelectSensor = () => {
    const sensorsData = plotSensorList.filter((sensor) =>
      selectedSensors.includes(sensor.id)
    );
    if (
      !_.isEqual(
        selectedSensors,
        sensors.map((sensor) => sensor.sensorId)
      )
    ) {
      updateIrrigationSettings({
        plotId,
        irrigationRules: [
          {
            irrigationCommand: "start",
            irrigationType: "bySensor",
            sensors: sensorsData.map((sensor) => ({
              sensorId: sensor.id,
              deviceId: sensor.deviceId,
            })),
            irrigationNotification: startIrrigationRules.irrigationNotification,
            settings: {
              sensorCondition: "lte",
              thresholdRange: threshold,
              atLeastTime: period,
              groupedBy: sensorsData.length > 1 ? groupedBy || "average" : null,
            },
          },
        ],
      });
    }
    setAnchorElSelectSensor(null);
  };

  const handleOpenSelectThreshold = (event) => {
    setAnchorElSelectThreshold(event.currentTarget);
  };

  const handleCloseSelectThreshold = () => {
    setAnchorElSelectThreshold(null);
  };

  const handleOpenSelectTime = (event) => {
    setAnchorElSelectTime(event.currentTarget);
  };

  const handleCloseSelectTime = () => {
    setAnchorElSelectTime(null);
  };

  const handleCloseSelectGropedBy = () => {
    setAnchorElSelectGroupedBy(null);
  };

  const buildPeriods = () => {
    const start = 5;
    const end = 300;
    const step = 5;
    const arrayLength = Math.floor((end - start) / step) + 1;
    const range = [...Array(arrayLength).keys()].map((x) => ({
      value: x * step + start,
      label: `${x * step + start}${t("minute")}`,
    }));
    return range;
  };

  const handleSelectSensor = (sensor) => {
    if (selectedSensors.includes(sensor.id)) {
      setSelectedSensors(selectedSensors.filter((item) => item !== sensor.id));
    } else {
      setSelectedSensors([...selectedSensors, sensor.id]);
    }
  };

  const handleSelectThreshold = (value) => {
    updateIrrigationSettings({
      plotId,
      irrigationRules: [
        {
          irrigationCommand: "start",
          irrigationType: "bySensor",
          irrigationNotification: startIrrigationRules.irrigationNotification,
          sensors: sensors,
          settings: {
            sensorCondition: "lte",
            thresholdRange: value,
            atLeastTime: period,
            groupedBy: groupedBy,
          },
        },
      ],
    });
    setAnchorElSelectThreshold(null);
  };

  const handleSelectGroupedBy = (value) => {
    updateIrrigationSettings({
      plotId,
      irrigationRules: [
        {
          irrigationCommand: "start",
          irrigationType: "bySensor",
          irrigationNotification: startIrrigationRules.irrigationNotification,
          sensors: sensors,
          settings: {
            sensorCondition: "lte",
            thresholdRange: threshold,
            atLeastTime: period,
            groupedBy: value,
          },
        },
      ],
    });
    setAnchorElSelectGroupedBy(null);
  };

  const handleSelectTime = (time) => {
    updateIrrigationSettings({
      plotId,
      irrigationRules: [
        {
          irrigationCommand: "start",
          irrigationType: "bySensor",
          sensors: sensors,
          irrigationNotification: startIrrigationRules.irrigationNotification,
          settings: {
            sensorCondition: "lte",
            thresholdRange: threshold,
            atLeastTime: time,
            groupedBy: groupedBy,
          },
        },
      ],
    });
    setAnchorElSelectTime(null);
  };

  const getSensorName = () => {
    const startSensorsData = plotSensorList.filter((item) =>
      sensors.some((sensor) => sensor.sensorId === item.id)
    );
    return startSensorsData.map((sensor) => sensor.sensorName).join(", ");
  };

  return (
    <Box
      style={{
        position: "absolute",
        top: position.top,
        left: position.left,
        display: "flex",
        flexDirection: "row",
      }}
    >
      <Box
        style={{
          width: "16px",
          height: "16px",
          backgroundImage: `url('data:image/svg+xml;utf8,<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><g opacity="0.7"><path opacity="0.2" d="M8.00065 0.0834961C3.63398 0.0834961 0.0839844 3.6335 0.0839844 8.00016C0.0839844 12.3668 3.63398 15.9168 8.00065 15.9168C12.3673 15.9168 15.9173 12.3668 15.9173 8.00016C15.9173 3.6335 12.3673 0.0834961 8.00065 0.0834961Z" fill="white"/><path d="M11.1839 7.31653L6.85052 4.63319C6.31719 4.29986 5.61719 4.68319 5.61719 5.31653V10.6665C5.61719 11.2999 6.30052 11.6832 6.85052 11.3499L11.1839 8.66653C11.7005 8.36653 11.7005 7.63319 11.1839 7.31653Z" fill="${
            irrigationActive ? "rgba(163, 248, 3, 1)" : "white"
          }"/></g></svg>')`,
        }}
      />
      {sensors ? (
        <Box style={{ marginInlineStart: "8px" }}>
          <Box
            style={{
              fontSize: "14px",
              color: "#F1F2F2",
              lineHeight: "150%",
              display: "inline-block",
              width: "255px",
            }}
          >
            {t("startWhenThisSensor")}
            <br />
            <div style={{ display: "flex" }}>
              <Tooltip title={getSensorName()}>
                {sensorName ? (
                  <span
                    style={{
                      textDecoration: "underline",
                      color: "white",
                      paddingInlineEnd: "3px",
                      cursor: allowChangeSettings ? "pointer" : "initial",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      maxWidth: "203px",
                      display: "inline-block",
                      overflow: "hidden",
                    }}
                    onClick={allowChangeSettings && handleOpenSelectSensor}
                  >
                    {getSensorName()}
                  </span>
                ) : (
                  <span
                    style={{
                      textDecoration: "underline",
                      color: "white",
                      paddingInlineEnd: "3px",
                      cursor: allowChangeSettings ? "pointer" : "initial",
                      display: "inline-block",
                    }}
                    onClick={allowChangeSettings && handleOpenSelectSensor}
                  >
                    {t("groupedBy")}
                  </span>
                )}
              </Tooltip>
              {selectedSensors.length > 1 && (
                <span
                  style={{
                    textDecoration: "underline",
                    color: "white",
                    paddingInlineEnd: "3px",
                    cursor: allowChangeSettings ? "pointer" : "initial",
                    display: "inline-block",
                  }}
                  onClick={allowChangeSettings && handleOpenSelectGroupedBy}
                >
                  {t(groupedBy)}
                </span>
              )}
              <span>{t("goes")}</span>
            </div>
          </Box>
          <Box
            style={{ fontSize: "14px", color: "#F1F2F2", lineHeight: "150%" }}
          >
            {t("below")}
            <span
              style={{
                textDecoration: "underline",
                color: "white",
                cursor: allowChangeSettings ? "pointer" : "initial",
                padding: "0px 3px",
                unicodeBidi: "plaintext",
              }}
              onClick={allowChangeSettings && handleOpenSelectThreshold}
            >
              {threshold}
            </span>
            {t("for")}
            <span
              style={{
                textDecoration: "underline",
                color: "white",
                cursor: allowChangeSettings ? "pointer" : "initial",
                padding: "0px 3px",
              }}
              onClick={allowChangeSettings && handleOpenSelectTime}
            >
              {period} {t("minute")}
            </span>
          </Box>
        </Box>
      ) : (
        <Box style={{ marginInlineStart: "8px" }}>
          <Box
            style={{
              fontSize: "14px",
              color: "#F1F2F2",
              lineHeight: "150%",
              display: "inline-block",
              width: "255px",
            }}
          >
            {t("startsBy")}
            <br />
            <span
              style={{
                textDecoration: "underline",
                color: "white",
                paddingInlineEnd: "3px",
              }}
            >
              {programName}
            </span>
          </Box>
        </Box>
      )}
      <Menu
        id="select-sensor"
        anchorEl={anchorElSelectSensor}
        keepMounted
        open={Boolean(anchorElSelectSensor)}
        onClose={handleCloseSelectSensor}
      >
        {plotSensorList.map((item) => (
          <MenuItem
            key={Math.random()}
            onClick={() => handleSelectSensor(item)}
          >
            <Checkbox
              color="primary"
              checked={selectedSensors.includes(item.id)}
            />
            <ListItemText>{item.sensorName}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
      <Menu
        id="select-threshold"
        anchorEl={anchorElSelectThreshold}
        keepMounted
        open={Boolean(anchorElSelectThreshold)}
        onClose={handleCloseSelectThreshold}
      >
        {choosesThreshold.map((item) => (
          <MenuItem
            key={Math.random()}
            onClick={() => handleSelectThreshold(item.value)}
          >
            {item.label}
          </MenuItem>
        ))}
      </Menu>
      <Menu
        id="select-time"
        anchorEl={anchorElSelectTime}
        keepMounted
        open={Boolean(anchorElSelectTime)}
        onClose={handleCloseSelectTime}
      >
        {buildPeriods().map((item) => (
          <MenuItem
            key={Math.random()}
            onClick={() => handleSelectTime(item.value)}
          >
            {item.label}
          </MenuItem>
        ))}
      </Menu>
      <Menu
        id="select-grouped-by"
        anchorEl={anchorElSelectGroupedBy}
        keepMounted
        open={Boolean(anchorElSelectGroupedBy)}
        onClose={handleCloseSelectGropedBy}
      >
        <MenuItem onClick={() => handleSelectGroupedBy("average")}>
          {t("average")}
        </MenuItem>
        <MenuItem onClick={() => handleSelectGroupedBy("median")}>
          {t("median")}
        </MenuItem>
      </Menu>
    </Box>
  );
};
const StopIrrigationInfo = ({
  position,
  irrigationFinished,
  threshold,
  period,
  sensors,
  programName,
  plotSensorList,
  updateIrrigationSettings,
  plotId,
  allowChangeSettings,
  stopIrrigationRules,
  groupedBy,
  sensorName,
}) => {
  const { t } = useTranslation();
  const choosesThreshold = Array.from(Array(250), (x, i) => ({
    value: -(i + 1),
    label: `-${i + 1}`,
  }));
  const [anchorElSelectSensor, setAnchorElSelectSensor] = React.useState(null);
  const [anchorElSelectThreshold, setAnchorElSelectThreshold] =
    React.useState(null);
  const [anchorElSelectTime, setAnchorElSelectTime] = React.useState(null);
  const [anchorElSelectGroupedBy, setAnchorElSelectGroupedBy] =
    React.useState(null);
  const [selectedSensors, setSelectedSensors] = React.useState([]);

  useEffect(() => {
    if (sensors) {
      setSelectedSensors(sensors.map((sensor) => sensor.sensorId));
    }
  }, [sensors]);

  const handleOpenSelectSensor = (event) => {
    setAnchorElSelectSensor(event.currentTarget);
  };

  const handleOpenSelectGroupedBy = (event) => {
    setAnchorElSelectGroupedBy(event.currentTarget);
  };

  const handleCloseSelectSensor = () => {
    const sensorsData = plotSensorList.filter((sensor) =>
      selectedSensors.includes(sensor.id)
    );
    if (
      !_.isEqual(
        selectedSensors,
        sensors.map((sensor) => sensor.sensorId)
      )
    ) {
      updateIrrigationSettings({
        plotId,
        irrigationRules: [
          {
            irrigationCommand: "stop",
            irrigationType: "bySensor",
            sensors: sensorsData.map((sensor) => ({
              sensorId: sensor.id,
              deviceId: sensor.deviceId,
            })),
            irrigationNotification: stopIrrigationRules.irrigationNotification,
            settings: {
              sensorCondition: "gte",
              thresholdRange: threshold,
              atLeastTime: period,
              groupedBy: sensorsData.length > 1 ? groupedBy || "average" : null,
            },
          },
        ],
      });
    }
    setAnchorElSelectSensor(null);
  };

  const handleOpenSelectThreshold = (event) => {
    setAnchorElSelectThreshold(event.currentTarget);
  };

  const handleCloseSelectThreshold = () => {
    setAnchorElSelectThreshold(null);
  };

  const handleOpenSelectTime = (event) => {
    setAnchorElSelectTime(event.currentTarget);
  };

  const handleCloseSelectTime = () => {
    setAnchorElSelectTime(null);
  };

  const handleCloseSelectGropedBy = () => {
    setAnchorElSelectGroupedBy(null);
  };

  const buildPeriods = () => {
    const start = 5;
    const end = 300;
    const step = 5;
    const arrayLength = Math.floor((end - start) / step) + 1;
    const range = [...Array(arrayLength).keys()].map((x) => ({
      value: x * step + start,
      label: `${x * step + start}${t("minute")}`,
    }));
    return range;
  };

  const handleSelectSensor = (sensor) => {
    if (selectedSensors.includes(sensor.id)) {
      setSelectedSensors(selectedSensors.filter((item) => item !== sensor.id));
    } else {
      setSelectedSensors([...selectedSensors, sensor.id]);
    }
  };

  const handleSelectThreshold = (value) => {
    updateIrrigationSettings({
      plotId,
      irrigationRules: [
        {
          irrigationCommand: "stop",
          irrigationType: "bySensor",
          sensors: sensors,
          irrigationNotification: stopIrrigationRules.irrigationNotification,
          settings: {
            sensorCondition: "gte",
            thresholdRange: value,
            atLeastTime: period,
            groupedBy: groupedBy,
          },
        },
      ],
    });
    setAnchorElSelectThreshold(null);
  };

  const handleSelectGroupedBy = (value) => {
    updateIrrigationSettings({
      plotId,
      irrigationRules: [
        {
          irrigationCommand: "stop",
          irrigationType: "bySensor",
          irrigationNotification: stopIrrigationRules.irrigationNotification,
          sensors: sensors,
          settings: {
            sensorCondition: "gte",
            thresholdRange: threshold,
            atLeastTime: period,
            groupedBy: value,
          },
        },
      ],
    });
    setAnchorElSelectGroupedBy(null);
  };

  const handleSelectTime = (time) => {
    updateIrrigationSettings({
      plotId,
      irrigationRules: [
        {
          irrigationCommand: "stop",
          irrigationType: "bySensor",
          sensors: sensors,
          irrigationNotification: stopIrrigationRules.irrigationNotification,
          settings: {
            sensorCondition: "gte",
            thresholdRange: threshold,
            atLeastTime: time,
            groupedBy: groupedBy,
          },
        },
      ],
    });
    setAnchorElSelectTime(null);
  };

  const getSensorName = () => {
    const stopSensorsData = plotSensorList.filter((item) =>
      sensors.some((sensor) => sensor.sensorId === item.id)
    );
    return stopSensorsData.map((sensor) => sensor.sensorName).join(", ");
  };

  return (
    <Box
      style={{
        position: "absolute",
        top: position.top,
        left: position.left,
        display: "flex",
        flexDirection: "row",
      }}
    >
      <Box
        style={{
          width: "16px",
          height: "16px",
          backgroundImage: `url('data:image/svg+xml;utf8,<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><g opacity="0.7"><path opacity="0.2" d="M8.00065 0.0834961C3.63398 0.0834961 0.0839844 3.6335 0.0839844 8.00016C0.0839844 12.3668 3.63398 15.9168 8.00065 15.9168C12.3673 15.9168 15.9173 12.3668 15.9173 8.00016C15.9173 3.6335 12.3673 0.0834961 8.00065 0.0834961Z" fill="white"/><path d="M5 6C5 5.44772 5.44772 5 6 5H10C10.5523 5 11 5.44772 11 6V10C11 10.5523 10.5523 11 10 11H6C5.44772 11 5 10.5523 5 10V6Z" fill="${
            irrigationFinished ? "rgba(248, 35, 63, 1)" : "white"
          }"/></g></svg>')`,
        }}
      />
      {sensors ? (
        <Box style={{ marginInlineStart: "8px" }}>
          <Box
            style={{
              fontSize: "14px",
              color: "#F1F2F2",
              lineHeight: "150%",
              display: "inline-block",
              width: "255px",
            }}
          >
            {t("stopWhenThisSensor")}
            <br />
            <div style={{ display: "flex" }}>
              <Tooltip title={getSensorName()}>
                {sensorName ? (
                  <span
                    style={{
                      textDecoration: "underline",
                      color: "white",
                      paddingInlineEnd: "3px",
                      cursor: allowChangeSettings ? "pointer" : "initial",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      maxWidth: "203px",
                      display: "inline-block",
                      overflow: "hidden",
                    }}
                    onClick={allowChangeSettings && handleOpenSelectSensor}
                  >
                    {getSensorName()}
                  </span>
                ) : (
                  <span
                    style={{
                      textDecoration: "underline",
                      color: "white",
                      paddingInlineEnd: "3px",
                      cursor: allowChangeSettings ? "pointer" : "initial",
                      display: "inline-block",
                    }}
                    onClick={allowChangeSettings && handleOpenSelectSensor}
                  >
                    {t("groupedBy")}
                  </span>
                )}
              </Tooltip>
              {selectedSensors.length > 1 && (
                <span
                  style={{
                    textDecoration: "underline",
                    color: "white",
                    paddingInlineEnd: "3px",
                    cursor: allowChangeSettings ? "pointer" : "initial",
                    display: "inline-block",
                  }}
                  onClick={allowChangeSettings && handleOpenSelectGroupedBy}
                >
                  {t(groupedBy)}
                </span>
              )}
              <span>{t("goes")}</span>
            </div>
          </Box>
          <Box
            style={{ fontSize: "14px", color: "#F1F2F2", lineHeight: "150%" }}
          >
            {t("above")}
            <span
              style={{
                textDecoration: "underline",
                color: "white",
                cursor: allowChangeSettings ? "pointer" : "initial",
                padding: "0px 3px",
                unicodeBidi: "plaintext",
              }}
              onClick={allowChangeSettings && handleOpenSelectThreshold}
            >
              {threshold}
            </span>
            {t("for")}
            <span
              style={{
                textDecoration: "underline",
                color: "white",
                cursor: allowChangeSettings ? "pointer" : "initial",
                padding: "0px 3px",
              }}
              onClick={allowChangeSettings && handleOpenSelectTime}
            >
              {period} {t("minute")}
            </span>
          </Box>
        </Box>
      ) : (
        <Box style={{ marginInlineStart: "8px" }}>
          <Box
            style={{
              fontSize: "14px",
              color: "#F1F2F2",
              lineHeight: "150%",
              display: "inline-block",
              width: "255px",
            }}
          >
            {t("stopsBy")}
            <br />
            <span
              style={{
                textDecoration: "underline",
                color: "white",
                paddingInlineEnd: "3px",
              }}
            >
              {programName}
            </span>
          </Box>
        </Box>
      )}
      <Menu
        id="select-sensor"
        anchorEl={anchorElSelectSensor}
        keepMounted
        open={Boolean(anchorElSelectSensor)}
        onClose={handleCloseSelectSensor}
      >
        {plotSensorList.map((item) => (
          <MenuItem
            key={Math.random()}
            onClick={() => handleSelectSensor(item)}
          >
            <Checkbox
              color="primary"
              checked={selectedSensors.includes(item.id)}
            />
            <ListItemText>{item.sensorName}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
      <Menu
        id="select-threshold"
        anchorEl={anchorElSelectThreshold}
        keepMounted
        open={Boolean(anchorElSelectThreshold)}
        onClose={handleCloseSelectThreshold}
      >
        {choosesThreshold.map((item) => (
          <MenuItem
            key={Math.random()}
            onClick={() => handleSelectThreshold(item.value)}
          >
            {item.label}
          </MenuItem>
        ))}
      </Menu>
      <Menu
        id="select-time"
        anchorEl={anchorElSelectTime}
        keepMounted
        open={Boolean(anchorElSelectTime)}
        onClose={handleCloseSelectTime}
      >
        {buildPeriods().map((item) => (
          <MenuItem
            key={Math.random()}
            onClick={() => handleSelectTime(item.value)}
          >
            {item.label}
          </MenuItem>
        ))}
      </Menu>
      <Menu
        id="select-grouped-by"
        anchorEl={anchorElSelectGroupedBy}
        keepMounted
        open={Boolean(anchorElSelectGroupedBy)}
        onClose={handleCloseSelectGropedBy}
      >
        <MenuItem onClick={() => handleSelectGroupedBy("average")}>
          {t("average")}
        </MenuItem>
        <MenuItem onClick={() => handleSelectGroupedBy("median")}>
          {t("median")}
        </MenuItem>
      </Menu>
    </Box>
  );
};

const WaterLevel = ({
  position,
  startIrrigationRules,
  stopIrrigationRules,
}) => {
  const calcOpacity = (x1, x2, maxOpacity, value) => {
    if (value > x1) {
      return maxOpacity;
    } else if (value < x2) {
      return 0;
    }
    const diff = -x2 - -x1;
    const a = maxOpacity / diff;
    const opacity = maxOpacity - (-value - -x1) * a;
    if (opacity < 0) {
      return 0;
    } else {
      return opacity;
    }
  };
  const getStartIrrigationThreshold = () => {
    let threshold = -50;
    if (
      startIrrigationRules.settings &&
      startIrrigationRules.settings.thresholdRange
    ) {
      threshold = startIrrigationRules.settings.thresholdRange;
    } else if (startIrrigationRules.deviceThreshold) {
      threshold = startIrrigationRules.deviceThreshold;
    }
    return threshold;
  };
  const getStopIrrigationThreshold = () => {
    let threshold = -5;
    if (
      stopIrrigationRules.settings &&
      stopIrrigationRules.settings.thresholdRange
    ) {
      threshold = stopIrrigationRules.settings.thresholdRange;
    } else if (stopIrrigationRules.deviceThreshold) {
      threshold = stopIrrigationRules.deviceThreshold;
    }
    return threshold;
  };
  return (
    <Box
      style={{
        position: "absolute",
        bottom: position.bottom,
        left: position.left,
        width: "100%",
        height: "172px",
        backgroundImage: `url('data:image/svg+xml;utf8,${encodeURIComponent(
          `<svg width="448" height="172" viewBox="0 0 448 172" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M0.978516 0.995938C24.0991 1.84956 77.2655 2.01823 94.7739 0.995938C112.282 -0.0263579 136.45 0.382548 151.254 1.70131C158.79 2.37261 174.589 2.09558 191.641 1.79659C208.088 1.50821 225.7 1.1994 238.187 1.70131C263.621 2.72361 311.238 3.60791 321.884 2.13069C330.956 0.871786 353.108 1.84767 369.471 2.56857L369.472 2.56858C372.309 2.69359 374.973 2.81094 377.364 2.90763C387.056 3.29955 395.593 2.30484 402.866 1.45731C407.735 0.889996 412.037 0.388627 415.742 0.413222C424.981 0.47456 448.57 1.70131 448.57 1.70131V24.7502V29.0556V74.5867V91.0587V171.712H0.978516V92.4202V72.9714V21.3572L0.989191 21.3584L0.978516 0.995938Z" fill="url(#paint0_linear_8648_21460)"/><defs><linearGradient id="paint0_linear_8648_21460" x1="224.774" y1="0" x2="224.774" y2="172" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="rgb(56, 134, 177)" stop-opacity="${calcOpacity(
            getStopIrrigationThreshold(),
            getStartIrrigationThreshold(),
            0.75,
            startIrrigationRules.lastValue
          )}"/><stop offset="1" stop-color="rgb(56, 134, 177)" stop-opacity="${calcOpacity(
            getStopIrrigationThreshold(),
            getStartIrrigationThreshold(),
            0.75,
            stopIrrigationRules.lastValue
          )}"/></linearGradient></defs></svg>`
        )}')`,
      }}
    />
  );
};

const SoilLayers = ({ position }) => {
  return (
    <Box
      style={{
        position: "absolute",
        bottom: position.bottom,
        left: position.left,
        width: "100%",
        height: "172px",
        backgroundImage: `url(${TwoLayersSoil})`,
      }}
    />
  );
};

const AutopilotLogo = ({ provider, position }) => {
  return (
    <img
      src={
        provider === "mottech"
          ? MottechLogoWidget
          : provider === "talgil"
          ? TalgilLogo
          : null
      }
      alt="mottechLogoWidget"
      style={{
        position: "absolute",
        top: position.top,
        left: position.left,
        height: provider === "talgil" ? "30px" : "auto",
      }}
    />
  );
};

const IrrigationInfo = ({ position, lastIrrigation, irrigationStatus }) => {
  const { t } = useTranslation();
  return (
    <Box
      style={{
        position: "absolute",
        top: position.top,
        left: position.left,
      }}
    >
      <Box
        style={{
          fontSize: "12px",
          lineHeight: "140%",
          color: "#4A4B54",
        }}
      >
        {t("lastIrrigation")}
        <span style={{ fontWeight: "bold", padding: "0 3px" }}>
          {moment(lastIrrigation).utc().fromNow()}
        </span>
      </Box>
      <Box
        style={{
          fontSize: "12px",
          lineHeight: "140%",
          color: "#4A4B54",
        }}
      >
        {t("irrigationStatus")}
        <span
          style={{
            color: irrigationStatus === "watering" ? "rgb(135, 193, 25)" : "#F8233F",
            fontWeight: "bold",
            padding: "0 3px",
          }}
        >
          {t(irrigationStatus)}
        </span>
      </Box>
    </Box>
  );
};

export {
  Drops,
  Device,
  IrrigationGifComponent,
  Ruler,
  StartIrrigationInfo,
  StopIrrigationInfo,
  WaterLevel,
  SoilLayers,
  AutopilotLogo,
  IrrigationInfo,
};
