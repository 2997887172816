import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  container: {
    display: "flex",
    height: "53px",
    backgroundColor: "#ffffff",
    borderBottom: "1px solid #EBEDF3",
    padding: "27px",
    justifyContent: "space-between",
  },
  plotData: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  plotNameStyle: {
    fontWeight: "bold",
    fontSize: "18px",
  },
  cropsName: {
    fontWeight: "normal",
    fontSize: "16px",
  },
  selectDateRange: {
    display: "flex",
    alignItems: "center",
    padding: "8px",
    border: "1px solid #A8D8F4",
    backgroundColor: "#F7F8FC",
    borderRadius: "3px",
  },
  selectDateBtn: {
    display: "flex",
    alignItems: "center",
    padding: "8px",
    border: "1px solid #A8D8F4",
    backgroundColor: "#F7F8FC",
    borderRadius: "3px",
    cursor: "pointer",
    "&:hover": {
      opacity: 0.7,
    },
  },
  dateRange: {
    fontWeight: "normal",
    fontSize: "12px",
    paddingInlineStart: "8px",
  },
  rightBox: {
    display: "flex",
    alignItems: "center",
  },
  actionsBtn: {
    marginInlineStart: "10px",
  },
  addWidgetBtn: {
    backgroundColor: theme.palette.primary.main,
    color: "#FFFFFF",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      opacity: 0.7,
    },
  },
  datepickerContent: {
    width: "240px",
    padding: "14px"
  },
  pickerRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "pointer",
    "&:hover": {
      opacity: 0.7,
    },
  },
  timeBtn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "14px",
    height: "28px",
    fontWeight: "normal",
    cursor: "pointer",
    borderRadius: "3px",
    marginTop: "16px",
    border: "1px solid transparent",
    "&:hover": {
      border: "1px solid #A8D8F4",
    },
  },
  timeBtnSelected: {
    border: "1px solid #A8D8F4",
    background: "#F7F8FC",
    fontWeight: "bold",
  },
}));
