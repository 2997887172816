import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  "@keyframes blink": {
    "0%": {
      opacity: 1,
    },
    "50%": {
      opacity: 0,
    },
    "100%": {
      opacity: 1,
    },
  },
  container: {
    height: "100%",
    overflow: "auto",
  },
  gridItem: {
    display: "flex",
    width: "100%",
    height: "auto",
    flexDirection: "row",
    flexWrap: "wrap",
    flexShrink: 1,
  },
  plotStyle: {
    display: "flex",
    height: "124px",
    flex: 1,
    flexBasis: "33%",
    borderWidth: 0.25,
  },
  plotDataContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    padding: "10px",
    border: "1px solid rgba(0, 0, 0, 0.04)",
    transition: "border 1s",
  },
  selectedPlotStyle: {
    border: "3px solid #4A4B54",
  },
  gridTitle: {
    fontWeight: "bold",
    fontSize: "18px",
    color: "#4A4B54",
    padding: "36px 12px 24px 12px",
  },
  cropName: {
    fontWeight: "500",
    fontSize: "16px",
    color: theme.palette.text.white,
    lineHeight: "19px",
    width: "100px",
    textAlign: "center",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  plotName: {
    fontWeight: "bold",
    fontSize: "14px",
    color: theme.palette.text.white,
    lineHeight: "100%",
    opacity: 0.7,
    width: "100px",
    textAlign: "center",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  wp: {
    fontWeight: "bold",
    fontSize: "18px",
    color: theme.palette.text.white,
    marginTop: "7px",
    direction: "ltr",
  },
  btn: {
    textTransform: "initial",
    width: "78px",
    height: "40px",
    color: "white",
    backgroundColor: theme.palette.primary.main,
    border: "1px solid #A8D8F4",
    cursor: "pointer",
    fontWeight: "400",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      opacity: 0.7,
    },
  },
  btnSelected: {
    backgroundColor: "white",
    color: "#4A4B54",
    textTransform: "initial",
    width: "78px",
    height: "40px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "white",
      opacity: 0.7,
    },
  },
  header: {
    paddingTop: "32px",
    padding: "32px 18px 0px 18px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  filterBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.white,
    textTransform: "initial",
    width: "78px",
    height: "40px",
    cursor: "pointer",
    fontWeight: "400",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      opacity: 0.7,
    },
  },
  filterContent: {
    width: "252px",
    boxShadow: "0px 5px 30px rgba(0, 0, 0, 0.1)",
    borderRadius: "2px",
    padding: "24px",
  },
  rowSpaceBetween: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  resetBtn: {
    color: theme.palette.primary.main,
    textTransform: "initial",
    cursor: "pointer",
    fontSize: "16px",
    fontWeight: "normal",
    "&>svg": {
      fill: theme.palette.primary.main,
    },
  },
  issuesFilter: {
    fontWeight: "bold",
    fontSize: "12px",
    lineHeight: "14px",
    textTransform: "uppercase",
    marginTop: "26px",
  },
  timeBtn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "14px",
    height: "28px",
    fontWeight: "normal",
    cursor: "pointer",
    borderRadius: "3px",
    marginTop: "16px",
    border: "1px solid transparent",
    "& p": {
      fontSize: "14px",
    },
    "&:hover": {
      border: "1px solid #A8D8F4",
    },
  },
  timeBtnSelected: {
    border: "1px solid #A8D8F4",
    background: "#F7F8FC",
    "& p": {
      fontWeight: "bold",
    },
  },
  applyBtn: {
    height: "40px",
    width: "100%",
    marginTop: "30px",
  },
  searchBtn: {
    width: "40px",
    height: "40px",
    border: "1px solid #EBEDF3",
    borderRadius: "2px",
    padding: "0",
    minWidth: "40px",
    color: "#828B93",
    "&> svg": {
      fontSize: "21px",
    },
  },
  icon: {
    visibility: "hidden",
    marginInlineStart: "auto",
  },
  irrigationIcon: {
    marginInlineStart: "auto",
    animation: `$blink normal 2s infinite ease-in-out`,
  },
  visibilityVisible: {
    visibility: "visible",
  },
  checkboxLabel: {
    width: "100%",
    "& span": {
      fontSize: "14px",
    },
  },
  checkboxLabelChecked: {
    "& span": {
      fontWeight: "bold",
    },
  },
  cropBtn: {
    width: "60px",
  },
  regionBtn: {
    width: "70px",
  },
  goToAccountsBtn: {
    textTransform: "none",
    borderRadius: "28px",
    padding: "6px 16px",
    border: "1px solid #EBEDF3",
    color: "#FFFFFF",
    backgroundColor: theme.palette.primary.main,
    marginTop: "16px",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      opacity: 0.7,
    },
  },
}));
