import { put, takeEvery, all, call } from "redux-saga/effects";
import { getCartProducts } from "api";
/* *
 * Constants
 * */
export const moduleName = "productsCartList";
const prefix = `${process.env.REACT_APP_NAME}/${moduleName}`;

export const INIT = `${prefix}/INIT`;
export const SUCCESS = `${prefix}/SUCCESS`;
export const REJECT = `${prefix}/REJECT`;
export const CLEAR = `${prefix}/CLEAR`;

/* *
 * Reducer
 * */
const initialState = {
  productsCartList: null,
  loading: false,
  error: "",
  success: false,
};

export default function reducer(
  state = initialState,
  { type, payload, meta, ...rest }
) {
  switch (type) {
    case INIT:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        productsCartList: payload,
        error: "",
      };
    case REJECT:
      return {
        ...state,
        loading: false,
        success: false,
        error: payload.error,
      };
    case CLEAR:
      return initialState;
    default:
      return state;
  }
}

/* *
 * Selectors
 * */
export const moduleSelector = (state) => state[moduleName];

export const productsCartListSelector = (state) => moduleSelector(state).productsCartList;

export const isLoadingSelector = (state) => moduleSelector(state).loading;

export const isSuccessSelector = (state) => moduleSelector(state).success;

/* *
 * Action Creators
 * */
export const getProductsCartListRequest = (pagination, sort) => ({
  type: INIT,
  pagination,
  sort,
});

export const successAction = (payload) => ({
  type: SUCCESS,
  payload,
});

export const errorAction = (error) => ({
  type: REJECT,
  payload: { error },
});

export const clearProductsListData = () => ({
  type: CLEAR
});

/* *
 * Sagas
 * */
export function* getProductsCartListSaga({ pagination, sort }) {
  try {
    let accountId = null;
    let res = null;
    const accountFromStorage = localStorage.getItem("accountData");
    accountId = JSON.parse(accountFromStorage).id;
    res = yield call(getCartProducts, accountId, pagination, sort);
    yield put(successAction(res));
  } catch (error) {
    yield put(errorAction(error.message));
  }
}

export function* saga() {
  yield all([takeEvery(INIT, getProductsCartListSaga)]);
}
