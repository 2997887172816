import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box, IconButton, Paper } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import {
  plotSettingsSelector,
  getPlotSettingsRequest,
  clearPlotSettingsAction,
} from "redux/plotSettings";
import { PlotGridView, AppLoader } from "components/commons";
import { editPlotSettingsRequest } from "redux/editPlotSettings";
import history from "history.js";
import MapTab from "./mapTab";
import GeneralTab from "./generalTab";
import IrrigationTab from "./irrigationTab";
import useStyles from "./styles";
import _ from "lodash";
import PermissionsService from "services/PermissionsService";

const tabs = {
  Map: 0,
  General: 1,
  Irrigation: 2,
};

const PlotSettings = ({
  plotSettings,
  getPlotSettings,
  match,
  editPlotSettings,
  clearPlotSettings,
  location,
}) => {
  const { t } = useTranslation();
  const [plotsData, setPlotsData] = useState();
  const {
    content,
    container,
    backBtn,
    backRow,
    backTitle,
    leftSideMenu,
    tabsContainer,
    tabBtn,
  } = useStyles();
  const [tabIndex, setTabIndex] = useState(tabs["General"]);
  const [selectedPlotId, setPlotId] = useState();
  const [selectedPlot, selectPlot] = useState();
  useEffect(() => {
    return () => {
      clearPlotSettings();
    };
  }, []);
  useEffect(() => {
    const tabName = new URLSearchParams(location.search).get("tab");
    if (tabName) {
      setTabIndex(tabs[tabName]);
    }
  }, [location]);
  useEffect(() => {
    const { plotId } = match.params;
    if (selectedPlotId !== plotId) {
      setPlotId(plotId);
    }
  }, [match]);
  useEffect(() => {
    if (plotsData) {
      const { plotId } = match.params;
      const plotData = plotsData.find((plot) => +plot.plotId === +plotId);
      selectPlot(plotData);
    }
  }, [plotsData]);
  useEffect(() => {
    if (selectedPlotId) {
      getPlotSettings(selectedPlotId);
    }
  }, [selectedPlotId]);

  const handleChangeTab = (tab) => {
    setTabIndex(tab);
  };
  const handleSavePlotSetttings = (plotData) => {
    editPlotSettings(selectedPlotId, plotData);
  };
  const handleSelectPlot = (plotData) => {
    if (!_.isEqual(plotData, selectedPlot)) {
      selectPlot(plotData);
      setPlotId(plotData.plotId);
      localStorage.setItem("selectedPlot", plotData.plotId);
      const tab = tabIndex;
      setTabIndex(null);
      setTimeout(() => {
        setTabIndex(tab);
      }, 500);
    }
  };
  return (
    <Box className={container}>
      <Box className={backRow}>
        <IconButton
          onClick={() => history.push(`/dashboard`)}
          className={backBtn}
          id="arrowBack"
          aria-label="back"
        >
          <ArrowBackIcon />
        </IconButton>
        <Box className={backTitle}>{t("backToDashboard")}</Box>
      </Box>
      <Box
        style={{
          display: "flex",
          width: "100%",
          height: "100%",
          // height: "calc(100vh - 200px)"
        }}
      >
        <Paper className={leftSideMenu}>
          <PlotGridView
            selectedPlot={selectedPlot}
            selectPlot={handleSelectPlot}
            setPlotsData={setPlotsData}
            hideHeader={true}
          />
        </Paper>
        <Box style={{ width: "100%", overflow: "auto" }}>
          <Box className={tabsContainer}>
            <Box
              className={tabBtn}
              style={{
                backgroundColor: tabIndex === 0 ? "#FFFFFF" : "transparent",
                borderTop:
                  tabIndex === 0 ? "3px solid #70B7E1" : "1px solid #EBEDF3",
                color: tabIndex === 0 ? "#4A4B54" : "#828B93",
              }}
              onClick={() => handleChangeTab(0)}
            >
              {t("map")}
            </Box>
            <Box
              className={tabBtn}
              style={{
                backgroundColor: tabIndex === 1 ? "#FFFFFF" : "transparent",
                borderTop:
                  tabIndex === 1 ? "3px solid #70B7E1" : "1px solid #EBEDF3",
                color: tabIndex === 1 ? "#4A4B54" : "#828B93",
              }}
              onClick={() => handleChangeTab(1)}
            >
              {t("general")}
            </Box>
            {PermissionsService.isAllowed("AUTOPILOT_CHANGE_SETTINGS") && (
              <Box
                className={tabBtn}
                style={{
                  backgroundColor: tabIndex === 2 ? "#FFFFFF" : "transparent",
                  borderTop:
                    tabIndex === 2 ? "3px solid #70B7E1" : "1px solid #EBEDF3",
                  color: tabIndex === 2 ? "#4A4B54" : "#828B93",
                }}
                onClick={() => handleChangeTab(2)}
              >
                {t("irrigation")}
              </Box>
            )}
          </Box>
          <Box className={content}>
            {tabIndex === 0 && (
              <Box style={{ height: "100%", width: "100%" }} value={tabIndex}>
                <MapTab
                  plotName={plotSettings && plotSettings.name}
                  devices={plotSettings && plotSettings.devices}
                  coordinates={plotSettings && plotSettings.coordinates}
                  handleSavePlotSetttings={handleSavePlotSetttings}
                />
              </Box>
            )}
            {tabIndex === 1 && (
              <Box style={{ height: "100%", width: "100%" }} value={tabIndex}>
                <GeneralTab
                  plotSettings={plotSettings}
                  handleSavePlotSetttings={handleSavePlotSetttings}
                />
              </Box>
            )}
            {PermissionsService.isAllowed("AUTOPILOT_CHANGE_SETTINGS") && tabIndex === 2 && (
              <Box style={{ height: "100%", width: "100%" }} value={tabIndex}>
                <IrrigationTab selectedPlot={selectedPlot} />
              </Box>
            )}
            {tabIndex === null && <AppLoader />}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  plotSettings: plotSettingsSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  getPlotSettings: bindActionCreators(getPlotSettingsRequest, dispatch),
  editPlotSettings: bindActionCreators(editPlotSettingsRequest, dispatch),
  clearPlotSettings: bindActionCreators(clearPlotSettingsAction, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlotSettings);
