import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  container: {
    padding: theme.spacing(3.8, 3.8),
    paddingBottom: "130px",
  },
  tableColumn: {
    flex: 2,
    padding: "31px 25px",
  },
  totalColumn: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  content: {
    display: "flex",
    overflow: "auto",
    flexWrap: "wrap"
  },
  tableTitle: {
    fontWeight: "bold",
    fontSize: "24px",
    lineHeight: "28px",
    color: "#4A4B54",
    marginBottom: "24px",
  },
  selectLabel: {
    fontWeight: "bold",
    fontSize: "12px",
    lineHeight: "14px",
    color: "#828B93",
  },
  summeryTitle: {
    fontWeight: "bold",
    fontSize: "24px",
    lineHeight: "28px",
    color: "#4A4B54",
    padding: "32px",
  },
  select: {
    height: "30px",
    margin: "12px 0 24px 0",
    "& > div": {
      height: "30px",
      background: "#F7F8FC",
    },
  },
  divider: {
    height: "1px",
    margin: "0px 2vw",
  },
  licenceRenewal: {
    fontWeight: "bold",
    fontSize: "12px",
    lineHeight: "14px",
    color: "#828B93",
    paddingTop: "24px",
  },
  amount: {
    fontWeight: "bold",
    fontSize: "18px",
    lineHeight: "21px",
    color: "#4A4B54",
    margin: "12px 0px",
  },
  payBtn: {
    background: "#91B54D",
    borderRadius: "40px",
    color: "#FFFFFF",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "16px",
    height: "40px",
    width: "calc(100% - 4vw)",
    textTransform: "initial",
    marginTop: "32px",
    marginBottom: "32px",
    "&:hover": {
      background: "#91B54D",
      opacity: 0.7,
    },
  },
  payOfflineBtn: {
    background: "#EBEDF3",
    borderRadius: "40px",
    color: "#828B93",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "16px",
    height: "40px",
    width: "calc(100% - 4vw)",
    textTransform: "initial",
    marginBottom: "32px",
    "&:hover": {
      background: "#EBEDF3",
      opacity: 0.7,
    },
  },
  backBtn: {
    width: "40px",
    height: "40px",
    borderRadius: "4px",
    backgroundColor: "#FFFFFF",
  },
  backRow: {
    marginBottom: "18px",
    display: "flex",
    alignItems: "center",
  },
  backTitle: {
    fontSize: "24px",
    lineHeight: "24px",
    color: "#4A4B54",
    paddingInlineStart: "16px",
  },
  privacyPolicyStyles: {
    color: "#828B93",
    fontSize: "12px",
    lineHeight: "14px",
    textAlign: "center",
    marginBottom: "32px",
    "& a": {
      color: theme.palette.primary.main
    }
  },
  payIcon: {
    marginInlineEnd: "10px"
  },
  checkboxContainer: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer"
  },
  checkboxLabel: {
    fontWeight: "bold",
    fontSize: "12px",
    lineHeight: "120%",
    color: "#4A4B54",
  }
}));
