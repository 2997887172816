import { makeStyles } from "@material-ui/core";
import AccountBackground from "media/accountBackground.svg";

export default makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  tableStyle: {
    minWidth: 750,
  },
  selectedRow: {
    backgroundColor: `rgba(112, 183, 225, 0.1) !important`,
  },
  headerLabel: {
    fontWeight: "bold",
    color: theme.palette.text.lightGray,
  },
  icon: {
    width: "30px",
    height: "30px",
    "&:hover": {
      opacity: 0.6,
    },
  },
  iconMenu: {
    width: "30px",
    height: "4px",
    "&:hover": {
      opacity: 0.6,
    },
  },
  headerBtns: {
    display: "flex",
    justifyContent: "space-between",
    padding: "12.5px 16px",
    borderBottom: "1px solid #D7D9DF",
  },
  exportBtn: {
    textTransform: "none",
    borderRadius: "28px",
    padding: "6px 16px",
    border: "1px solid #EBEDF3",
    color: theme.palette.primary.main,
    marginInlineStart: "15px",
    "&:> svg": {
      color: theme.palette.primary.main,
    },
  },
  deleteBtn: {
    textTransform: "none",
    borderRadius: "28px",
    padding: "6px 16px",
    border: "1px solid #EBEDF3",
    color: "#DA5062",
    marginInlineStart: "15px",
    "&:> svg": {
      color: "#DA5062",
    },
  },
  addBtn: {
    textTransform: "none",
    borderRadius: "28px",
    padding: "6px 16px",
    border: "1px solid #EBEDF3",
    color: "#FFFFFF",
    marginInlineStart: "15px",
    backgroundColor: theme.palette.primary.main,
    "&:> svg": {
      color: "#FFFFFF",
      backgroundColor: theme.palette.primary.main,
    },
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      opacity: 0.7,
    },
  },
  headerColumn: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  accountNameBtn: {
    cursor: "pointer",
    fontWeight: "bold",
    "&:hover": {
      opacity: 0.7,
    },
  },
  actionBtn: {
    minWidth: "auto",
    padding: 0,
    height: "30px",
  },
  rowReverse: {
    flexDirection: "row-reverse",
  },
  search: {
    height: "40px",
    width: "220px",
    backgroundColor: "#F7F8FC",
    "&:>fieldset": {
      border: "1px solid #EBEDF3",
    },
  },
  searchIcon: {
    color: theme.palette.text.lightGray,
  },
  menuItem: {
    display: "flex",
    width: "162px",
    height: "46px",
    flexDirection: "row",
    alignItems: "center",
    padding: "0 19px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#F7F8FC",
    },
  },
  menuItemLabel: {
    fontSize: "14px",
    color: theme.palette.text.lightGray,
    marginInlineStart: "15px",
  },
  borderStart: {
    borderInlineStart: "1px solid #EBEDF3",
  },
  noAccountBox: {
    height: "440px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  noAccContent: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
  },
  noAccountMsg: {
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "16px",
    textAlign: "center",
    color: "#828B93",
    padding: "16px 0",
  },
  noAccessBox: {
    height: "360px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    backgroundImage: `url(${AccountBackground})`,
    border: "1px solid #EBEDF3",
    borderRadius: "10px",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPositionY: "bottom",
    backgroundPositionX: "right"
  },
  noAccessContent: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
  },
  accountName: {
    fontWeight: "bold",
    fontSize: "28px",
    lineHeight: "36px",
    color: "#4A4B54",
    marginTop: "10px",
  },
  noAccessMsg: {
    fontWeight: "bold",
    fontSize: "18px",
    lineHeight: "150%",
    color: "#828B93",
    marginTop: "16px",
  },
  goToDashboardBtn: {
    textTransform: "none",
    borderRadius: "28px",
    padding: "6px 16px",
    border: "1px solid #EBEDF3",
    color: "#FFFFFF",
    marginInlineStart: "15px",
    backgroundColor: theme.palette.primary.main,
    marginTop: "16px",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      opacity: 0.7,
    },
  },
}));
