import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  cssFocused: {
    "&:after": {
      borderColor: theme.palette.primary.main,
    },
  },
  cssFocusedLabel: {
    color: `${theme.palette.primary.main} !important`,
  },
  fieldContainer: {
    display: "flex",
    flexDirection: "column",
  },
  errorStyles: {
    fontSize: "16px",
    lineHeight: "16px",
  },
}));
