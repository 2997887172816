import React from "react";
import useStyles from "./styles";
import { useTranslation } from "react-i18next";
import { Box, IconButton, Typography } from "@material-ui/core";
import Speaker from "media/speaker.svg";
import Fermer from "media/fermer.svg";
import Intercom from "media/intercom.svg";
import clsx from "clsx";

const HelpFooter = ({ footerStyle }) => {
  const { t } = useTranslation();
  const {
    container,
    content,
    contentBox,
    contentTextBox,
    title,
    msg,
    intercomBtn,
    link,
  } = useStyles();
  const openIntercom = () => {
    window.Intercom("show");
  };
  return (
    <Box className={clsx(container, footerStyle)}>
      <Box className={content}>
        <Box className={contentBox}>
          <img src={Speaker} alt="Speaker" />
          <Box className={contentTextBox}>
            <Typography className={title}>{t("questions")}</Typography>
            <Typography className={msg}>
              {t("questionsHelpMsg")}{" "}
              <a href="*" onClick={(e) => e.preventDefault()} className={link}>
                {t("knowledgeBase")}
              </a>
              .
            </Typography>
          </Box>
        </Box>
        <Box className={contentBox}>
          <img src={Fermer} alt="Fermer" />
          <Box className={contentTextBox}>
            <Typography className={title}>{t("contactSupport")}</Typography>
            <Typography className={msg}>
              {t("contactSupportHelpMsg")}{" "}
              <a
                href="*"
                onClick={(e) => {
                  e.preventDefault();
                  openIntercom();
                }}
                className={link}
              >
                {t("contactSupportLink")}
              </a>
            </Typography>
          </Box>
        </Box>
        <Box className={contentBox}>
          <IconButton onClick={openIntercom} className={intercomBtn}>
            <img src={Intercom} alt="Intercom" />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

export default HelpFooter;
