import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Popover,
  Box,
  Checkbox,
  OutlinedInput,
  InputAdornment,
  Button,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import CloseIcon from "@material-ui/icons/Close";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  popover: {
    overflow: "hidden",
  },
  popoverContent: {
    width: "418px",
    maxHeight: "300px",
    overflow: "auto",
  },
  typography: {
    padding: theme.spacing(2),
  },
  filterIcon: {
    width: "16px",
    height: "16px",
    cursor: "pointer",
    "&:hover": {
      opacity: 0.6,
    },
  },
  itemRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "11px 0",
  },
  search: {
    backgroundColor: "white",
    width: "100%",
    "&>fieldset": {
      border: "none",
    },
  },
  searchIcon: {
    color: theme.palette.text.lightGray,
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid #D7D9DF",
  },
  btnIcon: {
    color: theme.palette.text.lightGray,
  },
  btnLabel: {
    color: theme.palette.text.lightGray,
    textTransform: "none",
    marginInlineEnd: "15px",
  },
  footer: {
    padding: "21px 0",
    display: "flex",
    justifyContent: "flex-end",
  },
  cancelBtn: {
    color: theme.palette.text.lightGray,
    margin: "0 20px",
    fontWeight: "bold",
    textTransform: "none",
    fontSize: "14px",
  },
  selectBtn: {
    color: theme.palette.primary.main,
    margin: "0 20px",
    fontWeight: "bold",
    textTransform: "none",
    fontSize: "14px",
  },
  allLabel: {
    color: theme.palette.text.lightGray,
  },
  selectBox: {
    minWidth: "185px",
    maxWidth: "360px",
    height: "100%",
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    borderInlineEnd: "1px solid #D7D9DF",
    paddingInlineEnd: "26px",
  },
  selectLabel: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  }
}));

const MultiselectAccount = ({ listData, handleFilter }) => {
  const {
    itemRow,
    popoverContent,
    search,
    searchIcon,
    row,
    btnIcon,
    btnLabel,
    popover,
    footer,
    cancelBtn,
    selectBtn,
    allLabel,
    selectBox,
    selectLabel
  } = useStyles();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedItems, setSelected] = useState([]);
  const [chechedAlready, setAlreadyChecked] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [allCheked, setAllChecked] = useState(false);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setSelected(chechedAlready);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelected(chechedAlready);
    setSearchValue("");
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleSelect = (item) => {
    const selectedIndex = selectedItems.findIndex(
      (selectedItem) => selectedItem.id === item.id
    );
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedItems, item);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedItems.slice(1));
    } else if (selectedIndex === item.length - 1) {
      newSelected = newSelected.concat(selectedItems.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedItems.slice(0, selectedIndex),
        selectedItems.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };
  const handleSearch = (e) => {
    setSearchValue(e.target.value);
  };
  const handleClearSelections = () => {
    setSelected([]);
    setAllChecked(false);
  };
  const handleSelectAllClick = () => {
    if (!allCheked) {
      const newSelecteds = listData.map((n) => n);
      setSelected(newSelecteds);
      setAllChecked(!allCheked);
      return;
    }
    setAllChecked(!allCheked);
    setSelected([]);
  };
  const handlePressSelect = () => {
    handleFilter(selectedItems);
    setAlreadyChecked(selectedItems);
    setAnchorEl(null);
    setSearchValue("");
  };
  return (
    <div>
      <Box onClick={handleClick} className={selectBox}>
        <Typography variant="h6" className={selectLabel}>
          {allCheked || !selectedItems.length
            ? t("allAccounts")
            : selectedItems.map((item) => item.name).join(", ")}
        </Typography>
        <ArrowDropDownIcon />
      </Box>
      <Popover
        id={id}
        className={popover}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <OutlinedInput
          id="search"
          placeholder={t("search")}
          className={search}
          onChange={handleSearch}
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon className={searchIcon} edge="end" />
            </InputAdornment>
          }
        />
        <Box className={row}>
          <Box className={itemRow} onClick={() => handleSelectAllClick()}>
            <Checkbox checked={allCheked} color="primary" />
            <Typography className={allLabel} variant="subtitle2">
              {t("all")}
            </Typography>
          </Box>
          <Button
            className={btnLabel}
            startIcon={<CloseIcon className={btnIcon} />}
            onClick={handleClearSelections}
          >
            {t("clear")}
          </Button>
        </Box>
        <Box className={popoverContent}>
          {listData
            .filter((item) =>
              item.name.toLowerCase().includes(searchValue.toLowerCase())
            )
            .map((item) => {
              return (
                <Box
                  className={itemRow}
                  key={item.id}
                  onClick={() => handleSelect(item)}
                >
                  <Checkbox
                    checked={selectedItems.some(
                      (selectedItem) => selectedItem.id === item.id
                    )}
                    color="primary"
                  />
                  <Typography variant="subtitle2">{item.name}</Typography>
                </Box>
              );
            })}
        </Box>
        <Box className={footer}>
          <Button className={cancelBtn} onClick={handleClose}>
            {t("cancel")}
          </Button>
          <Button className={selectBtn} onClick={handlePressSelect}>
            {t("selectBtnLabel")}
          </Button>
        </Box>
      </Popover>
    </div>
  );
};
export default MultiselectAccount;
