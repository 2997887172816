import React, { useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Typography, Box } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Formik } from "formik";
import {
  AppSelect,
  AppBtnFab,
  Datepicker,
  AppAutocomplete,
} from "components/commons";
import _ from "lodash";
import moment from "moment";
import {
  plotsListWithoutAutopilotLicenceSelector,
  getPlotsListWithoutAutopilotLicenceRequest,
} from "redux/plotsListWithoutAutopilotLicence";

import useStyles from "./styles";

const AddAutopilotLicenceForm = ({
  handleCloseAddAutopilotLicenceForm,
  handleAddLicence,
  getPlotsListWithoutAutopilotLicence,
  plotsListWithoutAutopilotLicence,
}) => {
  const { t } = useTranslation();
  const {
    container,
    header,
    headerTitle,
    closeIcon,
    userForm,
    btnContainer,
    cancelBtn,
    saveBtn,
    startDateInputMargin,
    marginBottomInput,
  } = useStyles();
  useEffect(() => {
    getPlotsListWithoutAutopilotLicence();
  }, []);
  return (
    <Box className={container}>
      <Box className={header}>
        <Typography className={headerTitle} variant="h6">
          {t("addAutopilotLicenceFormTitle")}
        </Typography>
        <CloseIcon
          onClick={handleCloseAddAutopilotLicenceForm}
          className={closeIcon}
        />
      </Box>
      {plotsListWithoutAutopilotLicence && (
        <Formik
          initialValues={{
            licenceType: "mottechAutopilot",
            startDate: moment().valueOf(),
            endDate: moment()
              .add(4, "year")
              .valueOf(),
            plot: {
              name: t("selectPlot"),
              id: null,
            },
            enabled: true,
          }}
          validate={(values) => {
            const errors = {};
            if (!values.licenceType) {
              errors.licenceType = "Required";
            }
            if (!values.startDate) {
              errors.startDate = "Required";
            }
            if (!values.endDate) {
              errors.endDate = "Required";
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            handleAddLicence({
              startDate: moment(values.startDate).format('YYYY-MM-DD'),
              endDate: moment(values.endDate).format('YYYY-MM-DD'),
              activated: values.enabled,
              plotId: values.plot.id
            });
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <form className={userForm} onSubmit={handleSubmit}>
              <AppSelect
                label={t("licenceType")}
                name="licenceType"
                items={[
                  { value: "mottechAutopilot", label: t("mottechAutopilot") },
                ]}
                value={values.licenceType}
                touched={touched.licenceType}
                error={errors.licenceType}
                className={marginBottomInput}
                handleChange={handleChange}
                handleBlur={handleBlur}
              />
              <Box display="flex" className={marginBottomInput}>
                <Datepicker
                  label={t("startDate")}
                  name="startDate"
                  value={values.startDate}
                  touched={touched.startDate}
                  error={errors.startDate}
                  maxDate={values.endDate}
                  className={startDateInputMargin}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />
                <Datepicker
                  label={t("endDate")}
                  name="endDate"
                  value={values.endDate}
                  touched={touched.endDate}
                  error={errors.endDate}
                  minDate={values.startDate}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />
              </Box>
              <AppAutocomplete
                label={t("attachLicenceToPlot")}
                name="plot"
                value={values.plot}
                touched={touched.plot}
                error={errors.plot}
                className={marginBottomInput}
                handleChange={handleChange}
                handleBlur={handleBlur}
                optionLabelKey="name"
                options={[
                  { name: t("selectPlot"), id: null, disabled: true },
                  { name: t("none"), id: null, disabled: false },
                  ...plotsListWithoutAutopilotLicence
                ]}
              />
              <AppSelect
                label={t("enabled")}
                name="enabled"
                items={[
                  {
                    label: t("yes"),
                    value: true,
                  },
                  {
                    label: t("no"),
                    value: false,
                  },
                ]}
                value={values.enabled}
                touched={touched.enabled}
                error={errors.enabled}
                className={marginBottomInput}
                handleChange={handleChange}
                handleBlur={handleBlur}
              />
              <Box className={btnContainer}>
                <AppBtnFab
                  className={cancelBtn}
                  btnType="light"
                  label={t("cancel")}
                  onClick={handleCloseAddAutopilotLicenceForm}
                />
                <AppBtnFab
                  className={saveBtn}
                  type="submit"
                  label={t("addBtn")}
                  disabled={!_.isEmpty(errors)}
                />
              </Box>
            </form>
          )}
        </Formik>
      )}
    </Box>
  );
};

const mapStateToProps = (state) => ({
  plotsListWithoutAutopilotLicence: plotsListWithoutAutopilotLicenceSelector(
    state
  ),
});

const mapDispatchToProps = (dispatch) => ({
  getPlotsListWithoutAutopilotLicence: bindActionCreators(
    getPlotsListWithoutAutopilotLicenceRequest,
    dispatch
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddAutopilotLicenceForm);
