import React from "react";
import { InputLabel, MenuItem, FormControl, Select } from "@material-ui/core";

const AppSelect = ({
  label,
  name,
  value,
  handleChange,
  handleBlur,
  items,
  touched,
  error,
  defaultItem,
  disabled,
  className = "",
  formControlStyles = {},
  links = [],
}) => {
  const handleChangeValue = (event) => {
    if (event.target.value === "link") {
      const data = {
        ...event,
        target: {
          ...event.target,
          value: "",
        },
      };
      handleChange(data);
    } else {
      handleChange(event);
    }
  };
  return (
    <FormControl
      style={{
        flex: 1,
        display: "flex",
        marginTop: "16px",
        ...formControlStyles,
      }}
      error={!!(touched && error)}
    >
      <InputLabel shrink id="selectLabel">
        {label}
      </InputLabel>
      <Select
        name={name}
        labelId="selectLabel"
        value={value}
        onChange={handleChangeValue}
        onBlur={handleBlur}
        displayEmpty
        disabled={disabled}
        className={className}
      >
        {defaultItem && (
          <MenuItem disabled value="">
            {defaultItem}
          </MenuItem>
        )}
        {items.map((item) => (
          <MenuItem
            disabled={item.disabled}
            key={Math.random()}
            value={item.value}
          >
            {item.icon && (
              <img
                style={{ height: "13px", margin: "0px 5px" }}
                src={item.icon}
                alt="Icon"
              />
            )}
            {item.label}
          </MenuItem>
        ))}
        {links.map((item) => (
          <MenuItem
            disabled={item.disabled}
            key={Math.random()}
            value="link"
            style={{
              color: "#70B7E1",
              fontWeight: "400",
              fontSize: "14px",
              borderTop: "1px solid rgba(74, 75, 84, 0.2)",
            }}
            onClick={(e) => {
              window.open(item.link, item.target);
            }}
          >
            {item.icon && (
              <img
                style={{ height: "13px", margin: "0px 5px" }}
                src={item.icon}
                alt="Icon"
              />
            )}
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default AppSelect;
