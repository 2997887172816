import React, { useState } from "react";
import { MenuItem, Menu, Box } from "@material-ui/core";
import useStyles from "./styles";
import DropdownIcon from "media/dropdownIcon.svg";
import clsx from "clsx";

const DropdownMenu = ({
  label,
  name,
  value,
  handleChange,
  handleBlur,
  items,
  touched,
  error,
  defaultItem,
  disabled,
  className = "",
  buttons = [],
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const {
    labelStyles,
    inputStyles,
    inputValue,
    container,
    disabledInput,
    selectedItem,
    menuItem,
    itemStyles,
    btnLabel,
    actionStyles,
    whiteText,
  } = useStyles();
  const handleChangeValue = (value) => {
    const data = {
      target: {
        name,
        value,
      },
    };
    handleChange(data);
  };
  const getValue = (value) => {
    if (!value) {
      return defaultItem;
    } else {
      const selectedItem = items.find((item) => item.value === value);
      return selectedItem ? selectedItem.label : defaultItem;
    }
  };
  const getIcon = (value) => {
    if (!value) {
      return null;
    } else {
      const selectedItem = items.find((item) => item.value === value);
      return selectedItem ? selectedItem.icon : defaultItem;
    }
  };
  const handleOpenSelectMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseSelectMenu = () => {
    setAnchorEl(null);
  };
  return (
    <Box className={clsx(className, container)}>
      <Box className={labelStyles}>{label}</Box>
      <Box
        className={clsx(inputStyles, { [disabledInput]: disabled })}
        onClick={!disabled && handleOpenSelectMenu}
      >
        {getIcon(value) && (
          <Box
            style={{
              height: "100%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              style={{ marginInlineEnd: "5px" }}
              src={getIcon(value)}
              alt="icon"
            />
          </Box>
        )}
        <Box className={inputValue}>{getValue(value)}</Box>
        <img src={DropdownIcon} alt="DropdownIcon" />
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseSelectMenu}
        onClick={handleCloseSelectMenu}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        autoFocus={false}
      >
        {items.map((item) => (
          <MenuItem
            key={Math.random()}
            className={clsx(menuItem, { [selectedItem]: item.value === value })}
          >
            <Box className={itemStyles}>
              <Box style={{ display: "flex", alignItems: "center" }}>
                {item.icon && (
                  <Box
                    style={{
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                    onClick={() => handleChangeValue(item.value)}
                  >
                    <img
                      style={{ marginInlineEnd: "5px" }}
                      src={item.icon}
                      alt="icon"
                    />
                  </Box>
                )}
                <Box
                  style={{
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                  }}
                  onClick={() => handleChangeValue(item.value)}
                >
                  {item.label}
                </Box>
              </Box>
              {item.actions && (
                <Box style={{ paddingInlineStart: "40px", display: "flex" }}>
                  {item.actions.map((action) => (
                    <Box
                      key={Math.random()}
                      onClick={() => action.onClick(item.value)}
                      className={clsx(actionStyles, {
                        [whiteText]: item.value === value,
                      })}
                    >
                      {action.label}
                    </Box>
                  ))}
                </Box>
              )}
            </Box>
          </MenuItem>
        ))}
        {buttons.map((button) => (
          <MenuItem
            key={Math.random()}
            className={menuItem}
            onClick={() => button.onClick()}
          >
            <img src={button.icon} alt="icon" />
            <Box className={btnLabel}>{button.label}</Box>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default DropdownMenu;
