import React, { useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  PlotGridView,
  DashboardHeader,
  AddNewWidget,
  AppLoader,
} from "components/commons";
import {
  InsightsWidget,
  AirTemperatureHistory,
  RainHistory,
  DeviceWp,
  AirHumidityHistory,
  DevicesCellstateHistory,
  WPLastValue,
  AirTempLastData,
  AirHumidityLastData,
  WeatherForecast,
  DevicesCellstateLastData,
  DeviceDepths,
  MapWidget,
  SoilTemp,
  PipePressure,
  PowerVoltage,
  AirTemperatureSensor,
  AirHumiditySensor,
  AutopilotWidget,
  VirtualDeviceWp,
} from "components/widgets";
import { Box, Drawer, Paper, Typography } from "@material-ui/core";
import useStyles from "./styles";
import moment from "moment";
import { Responsive, WidthProvider } from "react-grid-layout";
import { clearStatisticsByPlots } from "redux/statisticsByPlots";
import { clearPlotsCoordinatesData } from "redux/plotsCoordinates";
import {
  devicesByPlotSelector,
  getDevicesByPlotRequest,
  isLoadingSelector as devicesByPlotLoading,
} from "redux/devicesByPlot";
import {
  getSettingsRequest,
  settingsSelector,
  clearSettingsStore,
  isLoadingSelector,
} from "redux/dashboardSettings";
import { clearInsightsList } from "redux/insightsList";
import { clearWPData } from "redux/waterPotential";
import { clearGraphEvents } from "redux/graphEvents";
import { clearHeatMapData } from "redux/heatMap";
import { clearFieldWeather } from "redux/fieldWeatherHistory";
import { clearAirHumidity } from "redux/airHumidity";
import { clearDeviceBatteryData } from "redux/devicesCellstate";
import { clearDevicesCellstateStatus } from "redux/devicesCellstateLastData";
import { clearAirTempLastData } from "redux/airTempLastData";
import { clearAirHumidityLastData } from "redux/airHumidityLastData";
import { clearWpLastData } from "redux/waterPotentialLast";
import { saveDashboardSettingsRequest } from "redux/saveDashboardSettings";
import { clearRainAndIrrigation } from "redux/irrigationHistory";
import DefaultDashboardSettings from "constants/defaultDashboardSettings";
import _ from "lodash";
import PermissionsService from "services/PermissionsService";

const deviceColors = [
  "rgb(112, 183, 225)",
  "rgb(112, 127, 225)",
  "rgb(244, 166, 35)",
  "rgb(145, 181, 77)",
];

const randomRgb = () => {
  var o = Math.round,
    r = Math.random,
    s = 255;
  return "rgba(" + o(r() * s) + "," + o(r() * s) + "," + o(r() * s) + ")";
};

const ResponsiveGridLayout = WidthProvider(Responsive);

const generateGridLayout = (settings, devices) => {
  const layout = [];
  settings.forEach((setting) => {
    if (
      /\d/.test(setting.key) &&
      !devices.some((device) => device.id === +setting.key.replace(/\D/g, ""))
    ) {
      return;
    }
    if (setting.enabled && setting.options.gridConfig) {
      layout.push({
        i: setting.key,
        ...setting.options.gridConfig,
      });
    } else if (setting.enabled) {
      layout.push({
        i: setting.key,
        ...setting.options.defaultGridConfig,
      });
    }
  });
  return { lg: layout, md: layout, sm: layout, xs: layout, xxs: layout };
};

const generateDashboardSettingsFromLayout = (settings, layout) => {
  let newSettings = settings;
  layout.forEach((layout) => {
    const index = settings.findIndex((setting) => setting.key === layout.i);
    const { i, ...configs } = layout;
    newSettings[index] = {
      ...newSettings[index],
      options: {
        ...newSettings[index].options,
        gridConfig: configs,
      },
    };
  });
  return newSettings;
};

const drawWidget = (
  widgetKey,
  plotId,
  fieldId,
  plotName,
  daterange,
  devices,
  widgetContainer,
  selectedPlot,
  handleSelectPlotFromMap,
  plotsData
) => {
  if (
    widgetKey === "autopilotWidget" &&
    PermissionsService.isAllowed("AUTOPILOT_WIDGETS_VIEW")
  ) {
    return (
      <Box overflow="auto" key={widgetKey} className={widgetContainer}>
        <AutopilotWidget plotId={plotId} daterange={daterange} />
      </Box>
    );
  } else if (
    widgetKey === "virtualDevice" &&
    PermissionsService.isAllowed("AUTOPILOT_WIDGETS_VIEW")
  ) {
    return (
      <Box key={widgetKey} className={widgetContainer}>
        <VirtualDeviceWp
          fieldId={fieldId}
          daterange={daterange}
          plotId={plotId}
        />
      </Box>
    );
  } else if (widgetKey === "insightsWidget") {
    return (
      <Box overflow="auto" key={widgetKey} className={widgetContainer}>
        <InsightsWidget
          selectedDateRange={daterange}
          plotId={plotId}
          plotName={plotName}
          devices={devices}
        />
      </Box>
    );
  } else if (widgetKey === "mapWidget") {
    return (
      <Box key={widgetKey} className={widgetContainer}>
        <MapWidget
          fieldId={fieldId}
          selectedPlot={selectedPlot}
          handleSelectPlot={handleSelectPlotFromMap}
          plotsData={plotsData}
          devices={devices}
          plotId={plotId}
        />
      </Box>
    );
  } else if (widgetKey === "forecastWidget") {
    return (
      <Box key={widgetKey} className={widgetContainer}>
        <WeatherForecast fieldId={fieldId} plotId={plotId} />
      </Box>
    );
  } else if (widgetKey === "rainWidget") {
    return (
      <Box key={widgetKey} className={widgetContainer}>
        <RainHistory plotId={plotId} daterange={daterange} />
      </Box>
    );
  } else if (widgetKey === "chargingHisory") {
    return (
      <Box key={widgetKey} className={widgetContainer}>
        <DevicesCellstateHistory
          devices={devices}
          daterange={daterange}
          plotId={plotId}
        />
      </Box>
    );
  } else if (widgetKey === "batteryStatus") {
    return (
      <Box key={widgetKey} className={widgetContainer}>
        <DevicesCellstateLastData devices={devices} plotId={plotId} />
      </Box>
    );
  } else if (widgetKey === "realTimeAirTemp") {
    return (
      <Box key={widgetKey} className={widgetContainer}>
        <AirTempLastData fieldId={fieldId} plotId={plotId} />
      </Box>
    );
  } else if (widgetKey === "realTimeHumidity") {
    return (
      <Box key={widgetKey} className={widgetContainer}>
        <AirHumidityLastData fieldId={fieldId} plotId={plotId} />
      </Box>
    );
  } else if (widgetKey === "airTemperatureWidget") {
    return (
      <Box key={widgetKey} className={widgetContainer}>
        <AirTemperatureHistory
          fieldId={fieldId}
          daterange={daterange}
          plotId={plotId}
        />
      </Box>
    );
  } else if (widgetKey === "airHumidityWidget") {
    return (
      <Box key={widgetKey} className={widgetContainer}>
        <AirHumidityHistory
          fieldId={fieldId}
          daterange={daterange}
          plotId={plotId}
        />
      </Box>
    );
  } else if (
    widgetKey.includes("waterPotential") &&
    devices.some((device) => device.id === +widgetKey.replace(/\D/g, ""))
  ) {
    const deviceData = devices.find(
      (device) => device.id === +widgetKey.replace(/\D/g, "")
    );
    return (
      <Box key={widgetKey} className={widgetContainer}>
        <DeviceWp
          plotId={plotId}
          device={deviceData}
          deviceId={widgetKey.replace(/\D/g, "")}
          daterange={daterange}
        />
      </Box>
    );
  } else if (
    widgetKey.includes("soilTemp") &&
    devices.some((device) => device.id === +widgetKey.replace(/\D/g, ""))
  ) {
    const deviceData = devices.find(
      (device) => device.id === +widgetKey.replace(/\D/g, "")
    );
    return (
      <Box key={widgetKey} className={widgetContainer}>
        <SoilTemp
          device={deviceData}
          deviceId={widgetKey.replace(/\D/g, "")}
          daterange={daterange}
          plotId={plotId}
        />
      </Box>
    );
  } else if (
    widgetKey.includes("airTemperatureSensorWidget") &&
    devices.some((device) => device.id === +widgetKey.replace(/\D/g, ""))
  ) {
    const deviceData = devices.find(
      (device) => device.id === +widgetKey.replace(/\D/g, "")
    );
    return (
      <Box key={widgetKey} className={widgetContainer}>
        <AirTemperatureSensor
          device={deviceData}
          deviceId={widgetKey.replace(/\D/g, "")}
          daterange={daterange}
          plotId={plotId}
        />
      </Box>
    );
  } else if (
    widgetKey.includes("airHumiditySensorWidget") &&
    devices.some((device) => device.id === +widgetKey.replace(/\D/g, ""))
  ) {
    const deviceData = devices.find(
      (device) => device.id === +widgetKey.replace(/\D/g, "")
    );
    return (
      <Box key={widgetKey} className={widgetContainer}>
        <AirHumiditySensor
          device={deviceData}
          deviceId={widgetKey.replace(/\D/g, "")}
          daterange={daterange}
          plotId={plotId}
        />
      </Box>
    );
  } else if (
    widgetKey.includes("depths") &&
    devices.some((device) => device.id === +widgetKey.replace(/\D/g, ""))
  ) {
    const deviceData = devices.find(
      (device) => device.id === +widgetKey.replace(/\D/g, "")
    );
    return (
      <Box key={widgetKey} className={widgetContainer}>
        <DeviceDepths
          device={deviceData}
          deviceId={widgetKey.replace(/\D/g, "")}
          daterange={daterange}
          plotId={plotId}
        />
      </Box>
    );
  } else if (
    widgetKey.includes("realTimeWp") &&
    devices.some((device) => device.id === +widgetKey.replace(/\D/g, ""))
  ) {
    const deviceData = devices.find(
      (device) => device.id === +widgetKey.replace(/\D/g, "")
    );
    return (
      <Box key={widgetKey} className={widgetContainer}>
        <WPLastValue
          device={deviceData}
          deviceId={widgetKey.replace(/\D/g, "")}
          plotId={plotId}
        />
      </Box>
    );
  } else if (
    widgetKey.includes("pipePressure") &&
    devices.some((device) => device.id === +widgetKey.replace(/\D/g, ""))
  ) {
    const deviceData = devices.find(
      (device) => device.id === +widgetKey.replace(/\D/g, "")
    );
    return (
      <Box key={widgetKey} className={widgetContainer}>
        <PipePressure
          device={deviceData}
          deviceId={widgetKey.replace(/\D/g, "")}
          daterange={daterange}
          plotId={plotId}
        />
      </Box>
    );
  } else if (
    widgetKey.includes("powerVoltage") &&
    devices.some((device) => device.id === +widgetKey.replace(/\D/g, ""))
  ) {
    const deviceData = devices.find(
      (device) => device.id === +widgetKey.replace(/\D/g, "")
    );
    return (
      <Box key={widgetKey} className={widgetContainer}>
        <PowerVoltage
          device={deviceData}
          deviceId={widgetKey.replace(/\D/g, "")}
          daterange={daterange}
          plotId={plotId}
        />
      </Box>
    );
  } else {
    return <Box key={widgetKey} />;
  }
};

const Dashboard = ({
  clearStatistics,
  getDashboardSettings,
  dashboardSettings,
  saveDashboardSettings,
  clearSettings,
  clearPlotsCoordinates,
  match,
  isLoadingDashboardSettings,
  clearInsightsData,
  clearWP,
  clearGraphEventsData,
  clearHeatMap,
  clearFieldWeatherData,
  clearAirHumidityData,
  clearDeviceBattery,
  clearDevicesCellstate,
  clearAirTempLast,
  clearAirHumidityLast,
  clearWpLast,
  getDevicesByPlot,
  devicesByPlot,
  clearRainAndIrrigation,
}) => {
  const {
    container,
    leftSideMenu,
    content,
    selectPlotMsgContainer,
    selectPlotMsg,
    widgetContainer,
    gridContainer,
  } = useStyles();
  const [addWidgetIsOpen, toggleAddWidgetDrawer] = useState(false);
  const [plotsData, setPlotsData] = useState();
  const { t } = useTranslation();
  const [selectedPlot, selectPlot] = useState();
  const [selectedDateRange, selectDateRange] = useState([
    moment().subtract(7, "days"),
    moment(),
  ]);
  const [settingForDashboard, setSettingForDashboard] = useState();
  useEffect(() => {
    return () => {
      clearSettings();
      clearStatistics();
      clearRainAndIrrigation();
    };
  }, []);
  useEffect(() => {
    if (devicesByPlot) {
      devicesByPlot.forEach((device, i) => {
        if (deviceColors[i]) {
          device.color = deviceColors[i];
        } else {
          device.color = randomRgb();
        }
      });
    }
  }, [devicesByPlot]);
  useEffect(() => {
    const selectedPlotId = localStorage.getItem("selectedPlot");
    if (selectedPlotId && plotsData) {
      const plot = plotsData.find((item) => +item.plotId === +selectedPlotId);
      if (plot) {
        selectPlot(plot);
      } else if (plotsData[0]) {
        selectPlot(plotsData[0]);
      }
    } else if (plotsData && !selectedPlotId) {
      if (plotsData[0]) selectPlot(plotsData[0]);
    }
  }, [plotsData]);

  useEffect(() => {
    if (selectedPlot) {
      getDashboardSettings(selectedPlot.plotId);
      getDevicesByPlot(selectedPlot.plotId);
    }
  }, [selectedPlot]);
  useEffect(() => {
    if (dashboardSettings) {
      let settings = dashboardSettings;
      selectedPlot.devices.forEach((device, i) => {
        DefaultDashboardSettings.defaultSettingForDevice.forEach((setting) => {
          if (deviceColors[i]) {
            device.color = deviceColors[i];
          } else {
            device.color = randomRgb();
          }
          if (
            !dashboardSettings.some(
              (set) => set.key === `${setting.key}-${device.id}`
            )
          ) {
            settings.push({
              ...setting,
              key: `${setting.key}-${device.id}`,
            });
          }
        });
      });
      setSettingForDashboard(settings);
      generateGridLayout(settings, selectedPlot.devices);
    }
  }, [dashboardSettings]);

  const onLayoutChange = (layout) => {
    const newSettigns = generateDashboardSettingsFromLayout(
      settingForDashboard,
      layout
    );
    saveDashboardSettings({
      plotId: selectedPlot.plotId,
      settings: newSettigns,
    });
  };

  const handleSelectPlot = (plotData) => {
    if (!_.isEqual(plotData, selectedPlot)) {
      setSettingForDashboard(null);
      clearPlotsCoordinates();
      clearSettings();
      clearInsightsData();
      clearWP();
      clearGraphEventsData();
      clearHeatMap();
      clearFieldWeatherData();
      clearAirHumidityData();
      clearDeviceBattery();
      clearDevicesCellstate();
      clearAirTempLast();
      clearAirHumidityLast();
      clearWpLast();
      clearRainAndIrrigation();
      selectPlot(plotData);
      localStorage.setItem("selectedPlot", plotData.plotId);
    }
  };

  const handleSelectPlotFromMap = (plotData) => {
    if (!_.isEqual(plotData, selectedPlot)) {
      setSettingForDashboard(null);
      clearPlotsCoordinates();
      clearSettings();
      clearInsightsData();
      clearWP();
      clearGraphEventsData();
      clearHeatMap();
      clearFieldWeatherData();
      clearAirHumidityData();
      clearDeviceBattery();
      clearDevicesCellstate();
      clearAirTempLast();
      clearAirHumidityLast();
      clearWpLast();
      clearRainAndIrrigation();
      selectPlot(plotData);
      localStorage.setItem("selectedPlot", plotData.plotId);
    }
  };
  const refresh = () => {
    setSettingForDashboard(null);
    clearPlotsCoordinates();
    clearSettings();
    clearInsightsData();
    clearWP();
    clearGraphEventsData();
    clearHeatMap();
    clearFieldWeatherData();
    clearAirHumidityData();
    clearDeviceBattery();
    clearDevicesCellstate();
    clearAirTempLast();
    clearAirHumidityLast();
    clearWpLast();
    clearRainAndIrrigation();
    getDashboardSettings(selectedPlot.plotId);
    selectDateRange([moment().subtract(7, "days"), moment()]);
  };
  const debouncedOnLayoutChange = _.debounce(onLayoutChange, 2000);
  return (
    <Box className={container}>
      <Paper className={leftSideMenu}>
        <PlotGridView
          selectedPlot={selectedPlot}
          selectPlot={handleSelectPlot}
          setPlotsData={setPlotsData}
        />
      </Paper>
      {selectedPlot ? (
        <Box className={content}>
          <DashboardHeader
            selectedDateRange={selectedDateRange}
            selectDate={selectDateRange}
            plotName={selectedPlot.plot}
            crops={selectedPlot.crops}
            toggleAddWidgetDrawer={toggleAddWidgetDrawer}
            plotId={selectedPlot.plotId}
            refresh={refresh}
          />
          {settingForDashboard ? (
            <Box className={gridContainer}>
              <ResponsiveGridLayout
                compactType="vertical"
                cols={{ lg: 12, md: 12, sm: 12, xs: 12, xxs: 12 }}
                className="layout"
                layouts={generateGridLayout(settingForDashboard, devicesByPlot)}
                style={{ paddingBottom: "100px" }}
                rowHeight={50}
                onDragStop={debouncedOnLayoutChange}
                onResizeStop={debouncedOnLayoutChange}
                margin={[15, 15]}
                draggableCancel=".no-draggable"
              >
                {generateGridLayout(settingForDashboard, devicesByPlot).lg.map(
                  (widget) =>
                    drawWidget(
                      widget.i,
                      selectedPlot.plotId,
                      selectedPlot.fieldId,
                      selectedPlot.plot,
                      selectedDateRange,
                      devicesByPlot,
                      widgetContainer,
                      selectedPlot,
                      handleSelectPlotFromMap,
                      plotsData
                    )
                )}
              </ResponsiveGridLayout>
            </Box>
          ) : (
            <AppLoader />
          )}
        </Box>
      ) : (
        isLoadingDashboardSettings &&
        !settingForDashboard && (
          <Box className={selectPlotMsgContainer}>
            <Typography className={selectPlotMsg}>
              {t("selectPlotMsg")}
            </Typography>
          </Box>
        )
      )}
      <Drawer
        anchor="right"
        open={addWidgetIsOpen}
        onClose={() => toggleAddWidgetDrawer(false)}
      >
        <AddNewWidget
          toggleAddWidgetDrawer={toggleAddWidgetDrawer}
          selectedPlot={selectedPlot}
          devices={devicesByPlot}
          dashboardSettings={settingForDashboard}
          saveDashboardSettings={saveDashboardSettings}
        />
      </Drawer>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  dashboardSettings: settingsSelector(state),
  isLoadingDashboardSettings: isLoadingSelector(state),
  devicesByPlot: devicesByPlotSelector(state),
  devicesByPlotLoading: devicesByPlotLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
  clearStatistics: bindActionCreators(clearStatisticsByPlots, dispatch),
  getDashboardSettings: bindActionCreators(getSettingsRequest, dispatch),
  saveDashboardSettings: bindActionCreators(
    saveDashboardSettingsRequest,
    dispatch
  ),
  clearSettings: bindActionCreators(clearSettingsStore, dispatch),
  clearPlotsCoordinates: bindActionCreators(
    clearPlotsCoordinatesData,
    dispatch
  ),
  clearInsightsData: bindActionCreators(clearInsightsList, dispatch),
  clearWP: bindActionCreators(clearWPData, dispatch),
  clearGraphEventsData: bindActionCreators(clearGraphEvents, dispatch),
  clearHeatMap: bindActionCreators(clearHeatMapData, dispatch),
  clearFieldWeatherData: bindActionCreators(clearFieldWeather, dispatch),
  clearAirHumidityData: bindActionCreators(clearAirHumidity, dispatch),
  clearDeviceBattery: bindActionCreators(clearDeviceBatteryData, dispatch),
  clearDevicesCellstate: bindActionCreators(
    clearDevicesCellstateStatus,
    dispatch
  ),
  clearAirTempLast: bindActionCreators(clearAirTempLastData, dispatch),
  clearAirHumidityLast: bindActionCreators(clearAirHumidityLastData, dispatch),
  clearWpLast: bindActionCreators(clearWpLastData, dispatch),
  getDevicesByPlot: bindActionCreators(getDevicesByPlotRequest, dispatch),
  clearRainAndIrrigation: bindActionCreators(clearRainAndIrrigation, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
