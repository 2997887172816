import React from "react";
import {
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Checkbox,
  ListItemText,
} from "@material-ui/core";

const replaceValueWithLabel = (value, items) => {
  const selectedItems = items.filter((item) => value.indexOf(item.value) > -1);
  return selectedItems.map((item) => item.label);
};

const AppMultipleSelect = ({
  label,
  name,
  value,
  handleChange,
  handleBlur,
  items,
  touched,
  error,
  defaultItem,
  disabled,
  className = "",
  formControlStyles = {},
}) => {
  return (
    <FormControl
      style={{
        flex: 1,
        display: "flex",
        marginTop: "16px",
        ...formControlStyles,
      }}
      error={!!(touched && error)}
    >
      <InputLabel shrink id="selectLabel">
        {label}
      </InputLabel>
      <Select
        name={name}
        labelId="selectLabel"
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        displayEmpty
        multiple
        disabled={disabled}
        className={className}
        renderValue={(selected) => replaceValueWithLabel(selected, items).join(", ")}
      >
        {defaultItem && (
          <MenuItem disabled value="">
            {defaultItem}
          </MenuItem>
        )}
        {items.map((item) => (
          <MenuItem
            disabled={item.disabled}
            key={item.value}
            value={item.value}
          >
            <Checkbox color="primary" checked={value.indexOf(item.value) > -1} />
            <ListItemText>{item.label}</ListItemText>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default AppMultipleSelect;
