import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  container: {
    height: "40px",
    background: "#4A4B54",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  content: {
    display: "flex",
    alignItems: "center",
  },
  msg: {
    fontSize: "16px",
    color: "#C8D1D9",
  },
  btn: {
    background: "#91B54D",
    borderRadius: "28px",
    height: "32px",
    color: "#FFFFFF",
    fontWeight: "bold",
    fontSize: "14px",
    margin: "0px 24px",
    textTransform: "none",
    padding: "6px 26px",
    "&:hover": {
      background: "#91B54D",
      opacity: 0.9,
    },
  },
  closeIcon: {
    color: "#ffffff",
    cursor: "pointer",
    margin: "0px 23px",
    "&:hover": {
      opacity: 0.7,
    },
  }
}));
