const defaultPlotSetings = [
  {
    key: "autopilotWidget",
    enabled: false,
    options: {
      gridConfig: null,
      defaultGridConfig: {
        x: 0,
        y: 0,
        h: 6,
        w: 12,
        minH: 6,
        minW: 5,
      },
    },
  },
  {
    key: "virtualDevice",
    enabled: false,
    options: {
      gridConfig: null,
      defaultGridConfig: {
        x: 0,
        y: 1,
        h: 6,
        w: 12,
        minH: 4,
        minW: 5,
      },
    },
  },
  {
    key: "mapWidget",
    enabled: true,
    options: {
      gridConfig: null,
      defaultGridConfig: {
        h: 8,
        w: 12,
        x: 0,
        y: 2,
        minH: 5,
        minW: 5,
      },
    },
  },
  {
    key: "insightsWidget",
    enabled: true,
    options: {
      gridConfig: null,
      defaultGridConfig: { h: 5, w: 12, x: 0, y: 1, minH: 4, minW: 5 },
    },
  },
  {
    key: "forecastWidget",
    enabled: true,
    options: {
      gridConfig: null,
      defaultGridConfig: { h: 4, w: 12, x: 0, y: 0, minH: 3, minW: 5 },
    },
  },
  {
    key: "rainWidget",
    enabled: false,
    options: {
      gridConfig: null,
      defaultGridConfig: { x: 0, y: 0, w: 12, h: 4, minH: 4, minW: 5 },
    },
  },
  {
    key: "airTemperatureWidget",
    enabled: false,
    options: {
      gridConfig: null,
      defaultGridConfig: { h: 4, w: 12, x: 0, y: 0, minH: 4, minW: 5 },
    },
  },
  {
    key: "chargingHisory",
    enabled: false,
    options: {
      gridConfig: null,
      defaultGridConfig: { x: 0, y: 0, w: 12, h: 4, minH: 4, minW: 5 },
    },
  },
  {
    key: "batteryStatus",
    enabled: true,
    options: {
      gridConfig: null,
      defaultGridConfig: { h: 4, w: 12, x: 0, y: 3, minH: 4, minW: 3 },
    },
  },
  {
    key: "realTimeAirTemp",
    enabled: false,
    options: {
      gridConfig: null,
      defaultGridConfig: {
        x: 0,
        y: 0,
        w: 12,
        h: 4,
        minH: 4,
        minW: 3,
      },
    },
  },
  {
    key: "realTimeHumidity",
    enabled: false,
    options: {
      gridConfig: null,
      defaultGridConfig: {
        x: 0,
        y: 0,
        w: 12,
        h: 4,
        minH: 4,
        minW: 3,
      },
    },
  },
  {
    key: "airHumidityWidget",
    enabled: false,
    options: {
      gridConfig: null,
      defaultGridConfig: {
        x: 0,
        y: 0,
        w: 12,
        h: 4,
        minH: 4,
        minW: 3,
      },
    },
  },
];

const defaultSettingForDevice = [
  {
    key: "waterPotential",
    enabled: true,
    options: {
      gridConfig: null,
      defaultGridConfig: {
        x: 0,
        y: 0,
        h: 6,
        w: 12,
        minH: 4,
        minW: 5,
      },
    },
  },
  {
    key: "depths",
    enabled: false,
    options: {
      gridConfig: null,
      defaultGridConfig: {
        x: 0,
        y: 0,
        h: 5,
        w: 12,
        minH: 5,
        minW: 5,
      },
    },
  },
  {
    key: "soilTemp",
    enabled: false,
    options: {
      gridConfig: null,
      defaultGridConfig: {
        x: 0,
        y: 0,
        h: 6,
        w: 12,
        minH: 4,
        minW: 5,
      },
    },
  },
  {
    key: "realTimeWp",
    enabled: false,
    options: {
      gridConfig: null,
      defaultGridConfig: {
        x: 0,
        y: 0,
        w: 12,
        h: 4,
        minH: 4,
        minW: 3,
      },
    },
  },
  {
    key: "airTemperatureSensorWidget",
    enabled: false,
    options: {
      gridConfig: null,
      defaultGridConfig: {
        x: 0,
        y: 0,
        h: 6,
        w: 12,
        minH: 4,
        minW: 5,
      },
    },
  },
  {
    key: "airHumiditySensorWidget",
    enabled: false,
    options: {
      gridConfig: null,
      defaultGridConfig: {
        x: 0,
        y: 0,
        h: 6,
        w: 12,
        minH: 4,
        minW: 5,
      },
    },
  },
  {
    key: "pipePressure",
    enabled: false,
    options: {
      gridConfig: null,
      defaultGridConfig: {
        x: 0,
        y: 0,
        h: 6,
        w: 12,
        minH: 4,
        minW: 5,
      },
    },
  },
  {
    key: "powerVoltage",
    enabled: false,
    options: {
      gridConfig: null,
      defaultGridConfig: {
        x: 0,
        y: 0,
        h: 6,
        w: 12,
        minH: 4,
        minW: 5,
      },
    },
  },
];

export default {
  defaultPlotSetings,
  defaultSettingForDevice,
};
