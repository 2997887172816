import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  insightsListSelector,
  getInsightsListRequest,
  isLoadingSelector,
} from "redux/insightsList";
import { deleteInsightRequest } from "redux/deleteInsight";
import { markAsDoneRequest } from "redux/markAsDoneInsight";
import { remindMeRequest } from "redux/remindMe";
import { turnOffAllNotificationOfTypeRequest } from "redux/turnOffNotification";
import {
  WPMiniChart,
  AlertInfo,
  HeatMapMini,
  AppLoader,
} from "components/commons";
import { addCommentForInsight } from "api";
import { useTranslation } from "react-i18next";
import {
  Box,
  IconButton,
  Typography,
  Popover,
  Button,
  Paper,
  Dialog,
  TextField,
  Drawer,
  Menu,
  MenuItem,
} from "@material-ui/core";
import moment from "moment";
import _ from "lodash";
import clsx from "clsx";

import WeatherIcon from "media/weather.png";
import WaterIcon from "media/water.png";
import Tech from "media/tech.png";
import Irrigation from "media/irrigation.svg";
import RemindMe from "media/remindMe.svg";
import Comment from "media/comment.svg";
import TurnOffNotifications from "media/turnOffNotifications.svg";
import Settings from "media/settings.svg";
import ReportIssue from "media/reportIssue.svg";
import InsightsOk from "media/insightsOk.svg";
import InsightsIcon from "media/insightsIcon.svg";

import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";

import useStyles from "./styles";
import { settingsSelector } from "redux/dashboardSettings";
import { saveDashboardSettingsRequest } from "redux/saveDashboardSettings";
import DeleteIcon from "media/deleteIcon.svg";
import MoreVertIcon from "@material-ui/icons/MoreVert";

const ALERT_LEVEL_COLOR = {
  0: "#62c87a",
  1: "#62c87a",
  2: "#ecc545",
  3: "#ecc545",
  4: "#d85746",
  5: "#d85746",
};

const ALERT_ICON_TYPE = {
  BATTERY: Tech,
  WATER_POTENTIAL: WaterIcon,
  WEATHER: WeatherIcon,
  SIGNAL_LEVEL: Tech,
  DEVICE_ACTIVITY: Tech,
  TECHNICAL_ISSUE_ALERT: Tech,
};

const DeleteModal = ({ open, handleClose, handleDeleteInsight }) => {
  const { t } = useTranslation();
  const {
    deleteModal,
    modalTitle,
    modalSubtitle,
    cancelBtn,
    modalApproveBtn,
    btnModalRow,
  } = useStyles();
  return (
    <Dialog open={open} onClose={handleClose} className="no-draggable">
      <Paper className={deleteModal}>
        <Typography className={modalTitle}>{t("deleteInsight")}</Typography>
        <Typography className={modalSubtitle}>
          {t("deleteInsightMsg")}
        </Typography>
        <Box className={btnModalRow}>
          <Button className={cancelBtn} onClick={handleClose}>
            {t("cancel")}
          </Button>
          <Button onClick={handleDeleteInsight} className={modalApproveBtn}>
            {t("delete")}
          </Button>
        </Box>
      </Paper>
    </Dialog>
  );
};

const TurnOffNotificationsModal = ({
  open,
  handleClose,
  handleTurnOffAllNotificationOfType,
}) => {
  const { t } = useTranslation();
  const {
    turnOffNotificationsModal,
    modalTitle,
    modalSubtitle,
    cancelBtn,
    modalApproveBtn,
    btnModalRow,
  } = useStyles();
  return (
    <Dialog open={open} onClose={handleClose} className="no-draggable">
      <Paper className={turnOffNotificationsModal}>
        <Typography className={modalTitle}>
          {t("turnOffNotifications")}
        </Typography>
        <Typography className={modalSubtitle}>
          {t("turnOffNotificationsMsg")}
        </Typography>
        <Box className={btnModalRow}>
          <Button className={cancelBtn} onClick={handleClose}>
            {t("cancel")}
          </Button>
          <Button
            onClick={handleTurnOffAllNotificationOfType}
            className={modalApproveBtn}
          >
            {t("turnOff")}
          </Button>
        </Box>
      </Paper>
    </Dialog>
  );
};

const CommentModal = ({ open, handleClose, handleAddComment }) => {
  const { t } = useTranslation();
  const [comment, handleChangeComment] = useState();
  const {
    commentModal,
    modalTitle,
    cancelBtn,
    modalApproveBtnBlue,
    btnModalRow,
    commentInput,
  } = useStyles();
  const handleCommentInput = (e) => {
    handleChangeComment(e.target.value);
  };
  return (
    <Dialog open={open} onClose={handleClose} className="no-draggable">
      <Paper className={commentModal}>
        <Typography className={modalTitle}>{t("leaveYourComment")}</Typography>
        <TextField
          label="Comment"
          className={commentInput}
          multiline
          onChange={handleCommentInput}
          rows={6}
        />
        <Box className={btnModalRow}>
          <Button className={cancelBtn} onClick={handleClose}>
            {t("cancel")}
          </Button>
          <Button
            onClick={() => handleAddComment(comment)}
            className={modalApproveBtnBlue}
          >
            {t("submit")}
          </Button>
        </Box>
      </Paper>
    </Dialog>
  );
};

const TurnOnIrrigationModal = ({ open, handleClose, plotName }) => {
  const { t } = useTranslation();
  const {
    turnOnIrrigationModal,
    modalTitle,
    cancelBtn,
    modalApproveBtnBlue,
    btnModalRow,
    modalSubtitle,
  } = useStyles();
  return (
    <Dialog open={open} onClose={handleClose} className="no-draggable">
      <Paper className={turnOnIrrigationModal}>
        <Typography className={modalTitle}>{t("turnOnIrrigation")}</Typography>
        <Typography className={modalSubtitle}>
          {t("turnOnIrrigationMsg")} {plotName}
        </Typography>
        <Box className={btnModalRow}>
          <Button className={cancelBtn} onClick={handleClose}>
            {t("cancel")}
          </Button>
          <Button className={modalApproveBtnBlue}>{t("turnON")}</Button>
        </Box>
      </Paper>
    </Dialog>
  );
};

const RemindMeModal = ({ open, handleClose, handleRemindMe }) => {
  const { t } = useTranslation();
  const [selectedTime, selectTime] = useState({ amount: 1, unit: "days" });
  const {
    remindMe,
    modalTitle,
    cancelBtn,
    modalApproveBtnBlue,
    btnModalRow,
    timeBtn,
    timeBtnSelected,
  } = useStyles();
  return (
    <Dialog open={open} onClose={handleClose} className="no-draggable">
      <Paper className={remindMe}>
        <Typography className={modalTitle}>{t("remindMe")}</Typography>
        <Box
          className={clsx(timeBtn, {
            [timeBtnSelected]: _.isEqual(selectedTime, {
              amount: 1,
              unit: "days",
            }),
          })}
          onClick={() =>
            selectTime({
              amount: 1,
              unit: "days",
            })
          }
        >
          <Typography>{t("tommorow")}</Typography>
        </Box>
        <Box
          className={clsx(timeBtn, {
            [timeBtnSelected]: _.isEqual(selectedTime, {
              amount: 1,
              unit: "week",
            }),
          })}
          onClick={() =>
            selectTime({
              amount: 1,
              unit: "week",
            })
          }
        >
          <Typography>{t("inWeek")}</Typography>
        </Box>
        <Box
          className={clsx(timeBtn, {
            [timeBtnSelected]: _.isEqual(selectedTime, {
              amount: 1,
              unit: "month",
            }),
          })}
          onClick={() =>
            selectTime({
              amount: 1,
              unit: "month",
            })
          }
        >
          <Typography>{t("inMonth")}</Typography>
        </Box>
        <Box className={btnModalRow}>
          <Button className={cancelBtn} onClick={handleClose}>
            {t("cancel")}
          </Button>
          <Button
            onClick={() => handleRemindMe(selectedTime)}
            className={modalApproveBtnBlue}
          >
            {t("setReminder")}
          </Button>
        </Box>
      </Paper>
    </Dialog>
  );
};

const generateDateRange = (alertDate) => {
  let to = moment().subtract(1, "minutes");
  if (
    moment(alertDate)
      .add(1, "days")
      .unix() <
    moment()
      .subtract(1, "minutes")
      .unix()
  ) {
    to = moment(alertDate).add(1, "days");
  }
  return [moment(alertDate).subtract(1, "days"), to];
};

const getDeviceDepths = (devices, insight) => {
  const device = devices.find(
    (device) => device.id === insight.insightsGroup.devices[0].id
  );
  return device && device.depths;
};

const Insights = ({
  plotId,
  plotName,
  insightsList,
  getInsightsList,
  deleteInsight,
  markAsDone,
  remindMe,
  turnOffAllNotificationOfType,
  devices,
  isLoadingInsightsList,
  saveDashboardSettings,
  dashboardSettings,
}) => {
  const { t } = useTranslation();
  const {
    container,
    title,
    headerRow,
    insightsColumnTitle,
    dateColumnTitle,
    graphColumnTitle,
    actionsColumnTitle,
    insightRow,
    insightData,
    flexColumn,
    insightTitle,
    insightInstr,
    dateColumn,
    graphColumn,
    actionsColumn,
    insightIconContainer,
    icon,
    moreBtn,
    approveBtn,
    deleteBtn,
    moreBoxContainer,
    turnOnIrrigation,
    turnOnIrrigationIcon,
    turnOnIrrigationLabel,
    btnRow,
    btnRowIcon,
    btnRowLabel,
    allGoodBox,
    allGoodMsg,
    deviceNameColumnTitle,
    deviceNameColumn,
    menuItemText,
    header,
  } = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [moreAnchorEl, setMoreAnchorEl] = useState();
  const [deleteModalOpen, deleteModalChangeState] = useState(false);
  const [commentModalOpen, commentModalChangeState] = useState(false);
  const [irrigationModalOpen, irrigationModalChangeState] = useState(false);
  const [tONModalOpen, tONModalChangeState] = useState(false);
  const [selectedInsight, selectInsight] = useState();
  const [remindMeModalOpen, remindMeModalChangeState] = useState(false);
  const [alertInfoOpen, toggleAlertInfoDrawer] = useState(false);

  useEffect(() => {
    getInsightsList(plotId);
  }, [plotId]);

  const handleCloseMorePopover = () => {
    setMoreAnchorEl(null);
  };
  const handleOpenMorePopover = (event, insight) => {
    selectInsight(insight);
    setMoreAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleOpenDeleteModal = (insight) => {
    setMoreAnchorEl(null);
    selectInsight(insight);
    deleteModalChangeState(true);
  };

  const handleCloseDeleteModal = () => {
    selectInsight(null);
    deleteModalChangeState(false);
  };

  const handleOpenToNModal = () => {
    setMoreAnchorEl(null);
    tONModalChangeState(true);
  };

  const handleCloseToNModal = () => {
    tONModalChangeState(false);
  };

  const handleOpenCommentModal = () => {
    setMoreAnchorEl(null);
    commentModalChangeState(true);
  };

  const handleCloseCommentModal = () => {
    commentModalChangeState(false);
  };

  const handleOpenIrrigationModal = () => {
    setMoreAnchorEl(null);
    irrigationModalChangeState(true);
  };

  const handleCloseIrrigationModal = () => {
    irrigationModalChangeState(false);
  };

  const handleOpenRemindMeModal = () => {
    setMoreAnchorEl(null);
    remindMeModalChangeState(true);
  };

  const handleCloseRemindMeModal = () => {
    remindMeModalChangeState(false);
  };

  const handleAddComment = (comment) => {
    addCommentForInsight(selectedInsight.id, comment);
    commentModalChangeState(false);
  };

  const handleDeleteInsight = () => {
    deleteInsight(selectedInsight.id, plotId);
    deleteModalChangeState(false);
    toggleAlertInfoDrawer(false);
  };

  const handleMarkAsDone = (alert) => {
    markAsDone(alert.id, plotId);
    toggleAlertInfoDrawer(false);
  };

  const handleMarkAsDoneDrawer = () => {
    markAsDone(selectedInsight.id, plotId);
    toggleAlertInfoDrawer(false);
  };

  const handleRemindMe = (remindIn) => {
    setMoreAnchorEl(null);
    remindMe(selectedInsight.id, remindIn, plotId);
    remindMeModalChangeState(false);
  };

  const handleTurnOffAllNotificationOfType = () => {
    turnOffAllNotificationOfType(selectedInsight.id);
    tONModalChangeState(false);
  };

  const handlePressAlert = (alert) => {
    selectInsight(alert);
    toggleAlertInfoDrawer(true);
  };
  const deleteWidget = () => {
    const index = dashboardSettings.findIndex(
      (item) => item.key === "insightsWidget"
    );
    dashboardSettings[index].enabled = false;
    saveDashboardSettings({
      plotId: plotId,
      settings: dashboardSettings,
      updateData: true,
    });
  };
  const open = Boolean(anchorEl);

  return (
    <Box className={container}>
      <Box className={clsx("widgetHeader", header)}>
        <Box style={{ display: "flex", alignItems: "center" }}>
          <img
            width="32px"
            height="32px"
            style={{ marginInlineEnd: "10px" }}
            src={InsightsIcon}
            alt="icon"
          />
          <Typography className={title}>{t("insights")}</Typography>
        </Box>
        <IconButton onClick={handleClick} size="small">
          <MoreVertIcon />
        </IconButton>
      </Box>
      <Box className={headerRow}>
        <Box className={insightsColumnTitle}>{t("issue")}</Box>
        <Box className={deviceNameColumnTitle}>{t("deviceName")}</Box>
        <Box className={dateColumnTitle}>{t("date")}</Box>
        <Box className={graphColumnTitle}>{t("graph")}</Box>
        <Box className={actionsColumnTitle}>{t("actions")}</Box>
      </Box>
      <div
        className="no-draggable"
        style={{
          height: "70%",
          flex: 1,
          display: "flex",
          flexDirection: "column",
          overflow: "auto",
        }}
      >
        {isLoadingInsightsList && !insightsList && <AppLoader />}
        {insightsList && insightsList.length === 0 && (
          <Box className={allGoodBox}>
            <img src={InsightsOk} alt="InsightsOk" />
            <Box className={allGoodMsg}>{t("insightsGoodMsg")}</Box>
          </Box>
        )}
        {insightsList &&
          insightsList.map((insight) => (
            <Box key={insight.id} className={insightRow}>
              <Box className={insightData}>
                <Box
                  className={insightIconContainer}
                  style={{ backgroundColor: ALERT_LEVEL_COLOR[insight.level] }}
                  onClick={() => handlePressAlert(insight)}
                >
                  <img
                    className={icon}
                    src={ALERT_ICON_TYPE[insight.insight.checkerName]}
                    alt={insight.insight.checkerName}
                  />
                </Box>
                <Box
                  className={flexColumn}
                  onClick={() => handlePressAlert(insight)}
                >
                  <Box className={insightTitle}>{t(insight.status)}</Box>
                  <Box className={insightInstr}>
                    {t(`${insight.status}_INSTR`)}
                  </Box>
                </Box>
              </Box>
              <Box className={deviceNameColumn}>
                {insight.insightsGroup.devices[0].name}
              </Box>
              <Box className={dateColumn}>
                {moment(insight.createdAt).fromNow()}
              </Box>
              <Box className={graphColumn}>
                {insight.status.includes("TIME_STRESS") && (
                  <WPMiniChart
                    daterange={generateDateRange(insight.createdAt)}
                    deviceId={insight.insightsGroup.devices[0].id}
                  />
                )}
                {insight.status.includes("IRRIGATION") && (
                  <HeatMapMini
                    daterange={generateDateRange(insight.createdAt)}
                    deviceId={insight.insightsGroup.devices[0].id}
                    deviceDepths={getDeviceDepths(devices, insight)}
                  />
                )}
              </Box>
              <Box className={actionsColumn}>
                <IconButton
                  onClick={() => handleOpenDeleteModal(insight)}
                  className={deleteBtn}
                >
                  <CloseIcon />
                </IconButton>
                <IconButton
                  onClick={() => handleMarkAsDone(insight)}
                  className={approveBtn}
                >
                  <DoneIcon />
                </IconButton>
                <IconButton
                  onClick={(e) => handleOpenMorePopover(e, insight)}
                  className={moreBtn}
                >
                  <MoreHorizIcon />
                </IconButton>
              </Box>
            </Box>
          ))}
      </div>
      <Popover
        open={Boolean(moreAnchorEl)}
        anchorEl={moreAnchorEl}
        onClose={handleCloseMorePopover}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box className={moreBoxContainer}>
          <Box className={turnOnIrrigation} onClick={handleOpenIrrigationModal}>
            <img
              className={turnOnIrrigationIcon}
              src={Irrigation}
              alt="Irrigation"
            />
            <Typography className={turnOnIrrigationLabel}>
              {t("turnOnIrrigation")}
            </Typography>
          </Box>
          <Box className={btnRow} onClick={handleOpenRemindMeModal}>
            <img className={btnRowIcon} src={RemindMe} alt="RemindMe" />
            <Typography className={btnRowLabel}>{t("remindMe")}</Typography>
          </Box>
          <Box className={btnRow} onClick={handleOpenCommentModal}>
            <img className={btnRowIcon} src={Comment} alt="Comment" />
            <Typography className={btnRowLabel}>{t("leaveComment")}</Typography>
          </Box>
          <Box className={btnRow} onClick={handleOpenToNModal}>
            <img
              className={btnRowIcon}
              src={TurnOffNotifications}
              alt="TurnOffNotifications"
            />
            <Typography className={btnRowLabel}>
              {t("turnOffNotifications")}
            </Typography>
          </Box>
          <Box className={btnRow}>
            <img className={btnRowIcon} src={Settings} alt="Settings" />
            <Typography className={btnRowLabel}>
              {t("alertsSettings")}
            </Typography>
          </Box>
          <Box className={btnRow}>
            <img className={btnRowIcon} src={ReportIssue} alt="ReportIssue" />
            <Typography className={btnRowLabel}>{t("reportIssue")}</Typography>
          </Box>
        </Box>
      </Popover>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem
          style={{
            width: "200px",
          }}
          onClick={deleteWidget}
        >
          <img src={DeleteIcon} alt="DeleteIcon" />
          <Box className={menuItemText}>{t("delete")}</Box>
        </MenuItem>
      </Menu>
      <DeleteModal
        open={deleteModalOpen}
        handleClose={handleCloseDeleteModal}
        handleDeleteInsight={handleDeleteInsight}
      />
      <TurnOffNotificationsModal
        open={tONModalOpen}
        handleTurnOffAllNotificationOfType={handleTurnOffAllNotificationOfType}
        handleClose={handleCloseToNModal}
      />
      <TurnOnIrrigationModal
        open={irrigationModalOpen}
        handleClose={handleCloseIrrigationModal}
        plotName={plotName}
      />
      <CommentModal
        open={commentModalOpen}
        handleClose={handleCloseCommentModal}
        handleAddComment={handleAddComment}
      />
      <RemindMeModal
        open={remindMeModalOpen}
        handleClose={handleCloseRemindMeModal}
        handleRemindMe={handleRemindMe}
      />
      <Drawer
        anchor="right"
        open={alertInfoOpen}
        onClose={() => toggleAlertInfoDrawer(false)}
      >
        {selectedInsight && (
          <AlertInfo
            selectedAlert={selectedInsight}
            handleOpenDeleteModal={handleOpenDeleteModal}
            handleOpenCommentModal={handleOpenCommentModal}
            handleOpenRemindMeModal={handleOpenRemindMeModal}
            handleMarkAsDone={handleMarkAsDoneDrawer}
            handleOpenToNModal={handleOpenToNModal}
            daterange={generateDateRange(selectedInsight.createdAt)}
            devices={devices}
          />
        )}
      </Drawer>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  insightsList: insightsListSelector(state),
  isLoadingInsightsList: isLoadingSelector(state),
  dashboardSettings: settingsSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  getInsightsList: bindActionCreators(getInsightsListRequest, dispatch),
  deleteInsight: bindActionCreators(deleteInsightRequest, dispatch),
  markAsDone: bindActionCreators(markAsDoneRequest, dispatch),
  remindMe: bindActionCreators(remindMeRequest, dispatch),
  turnOffAllNotificationOfType: bindActionCreators(
    turnOffAllNotificationOfTypeRequest,
    dispatch
  ),
  saveDashboardSettings: bindActionCreators(
    saveDashboardSettingsRequest,
    dispatch
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Insights);
