import { put, takeEvery, all, call } from "redux-saga/effects";
import { getConnectionPrograms } from "api";
/* *
 * Constants
 * */
export const moduleName = "connectionPrograms";
const prefix = `${process.env.REACT_APP_NAME}/${moduleName}`;

export const INIT = `${prefix}/INIT`;
export const SUCCESS = `${prefix}/SUCCESS`;
export const REJECT = `${prefix}/REJECT`;
export const CLEAR = `${prefix}/CLEAR`;
/* *
 * Reducer
 * */
const initialState = {
  programs: [],
  loading: false,
  error: {},
  success: false,
};

export default function reducer(
  state = initialState,
  { type, payload, meta, ...rest }
) {
  switch (type) {
    case INIT:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        programs: payload,
      };
    case REJECT:
      return {
        ...state,
        loading: false,
        success: false,
        error: payload.error,
      };
    case CLEAR:
      return initialState;
    default:
      return state;
  }
}

/* *
 * Selectors
 * */
export const moduleSelector = (state) => state[moduleName];

export const connectionProgramsSelector = (state) =>
  moduleSelector(state).programs;

export const isLoadingSelector = (state) => moduleSelector(state).loading;

export const isSuccessSelector = (state) => moduleSelector(state).success;

export const errorSelector = (state) => moduleSelector(state).error;

/* *
 * Action Creators
 * */
export const getConnectionProgramsRequest = (
  irrigationProviderConnectionId
) => ({
  type: INIT,
  irrigationProviderConnectionId,
});

export const clearConnectionPrograms = () => ({
  type: CLEAR,
});

export const successAction = (payload) => ({
  type: SUCCESS,
  payload,
});

export const errorAction = (error) => ({
  type: REJECT,
  payload: { error },
});

/* *
 * Sagas
 * */
export function* getConnectionProgramsSaga({ irrigationProviderConnectionId }) {
  try {
    let accountId = null;
    const accountFromStorage = localStorage.getItem("accountData");
    accountId = JSON.parse(accountFromStorage).id;
    const res = yield call(
      getConnectionPrograms,
      accountId,
      irrigationProviderConnectionId
    );
    yield put(successAction(res));
  } catch (error) {
    yield put(errorAction(error.response.data));
  }
}

export function* saga() {
  yield all([takeEvery(INIT, getConnectionProgramsSaga)]);
}
