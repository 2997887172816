import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  search: {
    backgroundColor: "white",
    width: "100%",
    margin: "20px 0",
  },
  searchIcon: {
    color: theme.palette.text.lightGray,
  },
  container: {
    padding: theme.spacing(2, 3.8),
    paddingBottom: "70px",
  },
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "10px",
  },
  modalBox: {
    backgroundColor: "white",
    width: "500px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    padding: "30px",
    border: "1px solid #D7D9DF",
  },
  modalBody: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  modalTitle: {
    fontWeight: "bold",
    padding: "0 20px",
  },
  modalBtnsContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  cancelBtn: {
    color: theme.palette.text.lightGray,
    margin: "0 20px",
    textTransform: "none",
  },
  approveModalBtn: {
    textTransform: "none",
  },
  closeIcon: {
    alignSelf: "flex-end",
    cursor: "pointer",
    "&:hover": {
      opacity: 0.7,
    },
  },
  msgModal: {
    fontWeight: "normal",
    padding: "0 20px",
    color: theme.palette.text.lightGray,
  },
  boxContainer: {
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "#FFFFFF",
    margin: "15px 0",
    padding: "31px 24px",
    borderRadius: "8px",
    border: "1px solid #EBEDF3",
    flexWrap: "wrap"
  },
  boxContent: {
    display: "flex",
    flexWrap: "wrap"
  },
  divider: {
    margin: "0 5vh",
    height: "auto"
  },
  label: {
    fontSize: "12px",
    lineHeight: "140%",
    color: "#828B93",
  },
  info: {
    fontSize: "18px",
    lineHeight: "150%",
    color: "#4A4B54",
  },
  renewBtn: {
    background: "#91B54D",
    color: "#FFFFFF",
    borderRadius: "28px",
    padding: "0 14px",
    height: "40px",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "16px",
    textTransform: "none",
    "&:hover": {
      background: "#91B54D",
      opacity: 0.7,
    },
    "&:disabled": {
      background: "#C8D1D9"
    }
  },
  infoRow: {
    display: "flex",
  },
  firstNumber: {
    borderRadius: "3px 0px 0px 3px",
    padding: "1px 5.5px",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "19px",
    color: "#FFFFFF",
  },
  centerNumber: {
    padding: "1px 5.5px",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "19px",
    color: "#FFFFFF",
  },
  lastNumber: {
    borderRadius: "0px 3px 3px 0px",
    padding: "1px 5.5px",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "19px",
    color: "#FFFFFF",
  },
  singleNumber: {
    borderRadius: "3px",
    padding: "1px 5.5px",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "19px",
    color: "#FFFFFF",
  },
  redNumber: {
    background: "rgba(218, 80, 98, 0.8)",
    border: "1px solid #DA5062",
  },
  grayNumber: {
    background: "rgba(74, 75, 84, 0.8)",
    border: "1px solid #4A4B54",
  },
  attachModalBox: {
    backgroundColor: "white",
    width: "357px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    padding: "61px",
    border: "1px solid #D7D9DF",
  },
  attachModalTitle: {
    fontWeight: "bold",
    fontSize: "24px",
    lineHeight: "28px",
    color: "#4A4B54",
    textAlign: "center"
  },
  attachModalMsg: {
    fontSize: "14px",
    lineHeight: "150%",
    color: "#828B93",
    textAlign: "center",
    margin: "24px 0px"
  },
  btnContainer: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
  },
  attachModalCancelBtn: {
    flex: 1,
    marginInlineEnd: "4px",
  },
  attachBtn: {
    flex: 1,
    marginInlineStart: "4px",
  },
  addBtn: {
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      opacity: 0.7,
    },
  },
  addIcon: {
    color: theme.palette.text.white,
  },
}));
