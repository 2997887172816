import { put, takeEvery, all, call } from "redux-saga/effects";
import { getDashboardSettings } from "api";
import DefaultDashboardSettings from "constants/defaultDashboardSettings";
/* *
 * Constants
 * */
export const moduleName = "dashboardSettings";
const prefix = `${process.env.REACT_APP_NAME}/${moduleName}`;

export const INIT = `${prefix}/INIT`;
export const SUCCESS = `${prefix}/SUCCESS`;
export const REJECT = `${prefix}/REJECT`;
export const CLEAR = `${prefix}/CLEAR`;

/* *
 * Reducer
 * */
const initialState = {
  settings: null,
  loading: false,
  error: {},
  success: false,
};

export default function reducer(
  state = initialState,
  { type, payload, meta, ...rest }
) {
  switch (type) {
    case INIT:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        settings: payload,
      };
    case REJECT:
      return {
        ...state,
        loading: false,
        success: false,
        error: payload.error,
      };
    case CLEAR:
      return initialState;
    default:
      return state;
  }
}

/* *
 * Selectors
 * */
export const moduleSelector = (state) => state[moduleName];

export const settingsSelector = (state) => moduleSelector(state).settings;

export const isLoadingSelector = (state) => moduleSelector(state).loading;

export const isSuccessSelector = (state) => moduleSelector(state).success;

/* *
 * Action Creators
 * */
export const getSettingsRequest = (plotId) => ({
  type: INIT,
  plotId,
});

export const successAction = (payload) => ({
  type: SUCCESS,
  payload,
});

export const errorAction = (error) => ({
  type: REJECT,
  payload: { error },
});

export const clearSettingsStore = () => ({
  type: CLEAR,
});

/* *
 * Sagas
 * */
export function* getDashboardSettingsSaga({ plotId }) {
  try {
    const accountFromStorage = localStorage.getItem("accountData");
    const { config } = yield call(
      getDashboardSettings,
      JSON.parse(accountFromStorage).id,
      plotId
    );
    yield put(successAction(config));
  } catch (error) {
    if (error.message.includes("404")) {
      yield put(successAction(DefaultDashboardSettings.defaultPlotSetings));
    }
    yield put(errorAction(error.message));
  }
}

export function* saga() {
  yield all([takeEvery(INIT, getDashboardSettingsSaga)]);
}
