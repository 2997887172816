import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box, Divider, IconButton, Menu, MenuItem } from "@material-ui/core";
import moment from "moment";
import { AppLoader } from "components/commons";

import SnowHeavy from "media/snow_heavy.svg";
import Snow from "media/snow.svg";
import SnowLight from "media/snow_light.svg";
import Flurries from "media/flurries.svg";
import FreezingDrizzle from "media/freezing_drizzle.svg";
import IcePelletsHeavy from "media/ice_pellets_heavy.svg";
import IcePellets from "media/ice_pellets.svg";
import IcePelletsLight from "media/ice_pellets_light.svg";
import FreezingRainHeavy from "media/freezing_rain_heavy.svg";
import FreezingRain from "media/freezing_rain.svg";
import FreezingRainLight from "media/freezing_rain_light.svg";
import Storm from "media/storm.svg";
import RainHeavy from "media/rain_heavy.svg";
import Rain from "media/rain.svg";
import RainLight from "media/rain_light.svg";
import Drizzle from "media/drizzle.svg";
import FogLight from "media/fog_light.svg";
import Fog from "media/fog.svg";
import Cloudy from "media/cloudy.svg";
import MostlyCloudy from "media/mostly_cloudy.svg";
import PartlyCloudy from "media/partly_cloudy.svg";
import MostlyClear from "media/mostly_clear.svg";
import ClearDay from "media/clear.svg";
import ForecastIcon from "media/forecastIcon.svg";

import clsx from "clsx";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { settingsSelector } from "redux/dashboardSettings";
import { saveDashboardSettingsRequest } from "redux/saveDashboardSettings";
import DeleteIcon from "media/deleteIcon.svg";

import {
  getWeatherForecastRequest,
  weatherForecastSelector,
} from "redux/weatherForecast";
import useStyles from "./styles";
const weatherIcons = {
  snow_heavy: SnowHeavy,
  snow: Snow,
  snow_light: SnowLight,
  flurries: Flurries,
  freezing_drizzle: FreezingDrizzle,
  ice_pellets_heavy: IcePelletsHeavy,
  ice_pellets: IcePellets,
  ice_pellets_light: IcePelletsLight,
  freezing_rain_heavy: FreezingRainHeavy,
  freezing_rain: FreezingRain,
  freezing_rain_light: FreezingRainLight,
  tstorm: Storm,
  rain_heavy: RainHeavy,
  rain: Rain,
  rain_light: RainLight,
  drizzle: Drizzle,
  fog_light: FogLight,
  fog: Fog,
  cloudy: Cloudy,
  mostly_cloudy: MostlyCloudy,
  partly_cloudy: PartlyCloudy,
  mostly_clear: MostlyClear,
  clear: ClearDay,
};
const WeatherForecast = ({
  fieldId,
  getWeatherForecast,
  weatherForecast,
  plotId,
  saveDashboardSettings,
  dashboardSettings,
}) => {
  const { t } = useTranslation();
  const today = moment()
    .startOf("day")
    .valueOf();
  const {
    title,
    container,
    content,
    weatherIcon,
    tempDataRow,
    maxTemp,
    minTemp,
    day,
    forecastData,
    humidity,
    weekForecast,
    weekForecastRow,
    thisWeek,
    weatherToday,
    weatherTodayIcon,
    maxTempToday,
    minTempToday,
    menuItem,
    header,
  } = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const deleteWidget = () => {
    const index = dashboardSettings.findIndex(
      (item) => item.key === "forecastWidget"
    );
    dashboardSettings[index].enabled = false;
    saveDashboardSettings({
      plotId: plotId,
      settings: dashboardSettings,
      updateData: true,
    });
  };
  useEffect(() => {
    if (fieldId) {
      getWeatherForecast(fieldId);
    }
  }, [fieldId]);
  const open = Boolean(anchorEl);
  return (
    <Box className={clsx(container, "widgetHeader")}>
      <Box className={header}>
        <Box style={{ display: "flex", alignItems: "center" }}>
          <img
            width="32px"
            height="32px"
            style={{ marginInlineEnd: "10px" }}
            src={ForecastIcon}
            alt="icon"
          />
          <Box className={title}>{t("forecast")}</Box>
        </Box>
        <IconButton onClick={handleClick} size="small">
          <MoreVertIcon />
        </IconButton>
      </Box>
      {weatherForecast && Object.keys(weatherForecast).length ? (
        <Box className={content}>
          {weatherForecast[today] && (
            <Box flex={1}>
              <Box className={weatherToday}>{t("weatherToday")}</Box>
              <Box className={forecastData}>
                <Box className={tempDataRow}>
                  <img
                    className={weatherTodayIcon}
                    src={weatherIcons[weatherForecast[today].weatherCode]}
                    alt={weatherForecast[today].weatherCode}
                  />
                  <Box className={maxTempToday}>
                    {weatherForecast[today].max.toFixed()}˚
                  </Box>
                  <Box className={minTempToday}>
                    {weatherForecast[today].min.toFixed()}˚
                  </Box>
                </Box>
                <Box className={humidity}>
                  {weatherForecast[today].humidity}% ‧{" "}
                  {weatherForecast[today].windSpeed}km/h
                </Box>
              </Box>
            </Box>
          )}
          {weatherForecast[today] && (
            <Divider
              orientation="vertical"
              style={{ margin: "0 20px" }}
              flexItem
            />
          )}
          <Box flex={5} className={weekForecast}>
            <Box className={thisWeek}>{t("thisWeek")}</Box>
            <Box className={weekForecastRow}>
              {Object.keys(weatherForecast)
                .filter((date) => date)
                .map(
                  (date) =>
                    +date !==
                      moment()
                        .startOf("day")
                        .valueOf() && (
                      <Box key={Math.random()} className={forecastData}>
                        <Box className={day}>{moment(+date).format("ddd")}</Box>
                        <Box className={tempDataRow}>
                          <img
                            className={weatherIcon}
                            src={
                              weatherIcons[weatherForecast[date].weatherCode]
                            }
                            alt={weatherForecast[date].weatherCode}
                          />
                          <Box className={maxTemp}>
                            {weatherForecast[date].max.toFixed()}˚
                          </Box>
                          <Box className={minTemp}>
                            {weatherForecast[date].min.toFixed()}˚
                          </Box>
                        </Box>
                        <Box className={humidity}>
                          {weatherForecast[date].humidity}%
                        </Box>
                      </Box>
                    )
                )}
            </Box>
          </Box>
        </Box>
      ) : (
        <Box height="60%">
          <AppLoader />
        </Box>
      )}
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem
          style={{
            width: "200px",
          }}
          onClick={deleteWidget}
        >
          <img src={DeleteIcon} alt="DeleteIcon" />
          <Box className={menuItem}>{t("delete")}</Box>
        </MenuItem>
      </Menu>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  weatherForecast: weatherForecastSelector(state),
  dashboardSettings: settingsSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  getWeatherForecast: bindActionCreators(getWeatherForecastRequest, dispatch),
  saveDashboardSettings: bindActionCreators(
    saveDashboardSettingsRequest,
    dispatch
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WeatherForecast);
