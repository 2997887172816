import React, { useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { Box, Typography, Backdrop, CircularProgress } from "@material-ui/core";
import validator from "validator";
import {
  StyledTextField,
  AppBtnFab,
  CountrySelect,
  CodeField,
} from "components/commons";
import {
  getOtpRequest,
  isLoadingSelector as getOtpLoading,
  isSuccessSelector,
} from "redux/otp";
import { useGA4React } from "ga-4-react";
import {
  isLoadingSelector as loginLoading,
  loginRequest,
  isSuccessLoginSelector,
} from "redux/login";
import FarmerImg from "media/farmer.svg";
import useStyles from "./styles";
import _ from "lodash";

const SignIn = ({
  getOtp,
  loading,
  getOtpSuccess,
  loginRequest,
  countryData,
  countryDataLoading,
  location,
  loginSuccess,
}) => {
  const { t } = useTranslation();
  const ga = useGA4React();
  const {
    loginWindow,
    container,
    loginForm,
    title,
    btnContainer,
    signUpMsg,
    signUpBtn,
    backdrop,
    msg,
    farmerIcon,
  } = useStyles();
  useEffect(() => {
    if (loginSuccess) {
      ga.gtag("event", "login", { method: "phoneNumber" });
    }
  }, [loginSuccess]);
  return (
    <Box className={container}>
      <Backdrop className={backdrop} open={loading}>
        <CircularProgress color="primary" />
      </Backdrop>
      <Box className={loginWindow}>
        <img src={FarmerImg} className={farmerIcon} alt="farmer" />
        <Typography variant="h4" className={title}>
          {!getOtpSuccess ? t("login") : t("enterCodeTitle")}
        </Typography>
        {getOtpSuccess && (
          <Typography variant="caption" className={msg}>
            {t("enterCodeMsg")}
          </Typography>
        )}
        {!getOtpSuccess && !countryDataLoading && (
          <Formik
            initialValues={{
              callingCode:
                countryData && countryData.calling_code
                  ? countryData.calling_code
                  : "1",
              phoneNumber: "",
            }}
            validate={(values) => {
              const errors = {};
              if (!values.phoneNumber) {
                errors.phoneNumber = "Required";
              } else if (
                `${values.phoneNumber}`[0] === "0"
                  ? !validator.isMobilePhone(
                      `${values.phoneNumber}`.substring(1)
                    )
                  : !validator.isMobilePhone(`${values.phoneNumber}`)
              ) {
                errors.phoneNumber = "Enter a valid Phone Number";
              }
              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              let phone = values.callingCode + values.phoneNumber;
              if (values.phoneNumber[0] === "0") {
                phone = values.callingCode + values.phoneNumber.substring(1);
              }
              localStorage.setItem("userPhone", phone);
              getOtp(phone);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit} className={loginForm}>
                <CountrySelect
                  callingCodePicker={true}
                  label={t("location")}
                  name="callingCode"
                  value={values.callingCode}
                  touched={touched.callingCode}
                  error={errors.callingCode}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />
                <StyledTextField
                  label={t("phoneNumberPlaceholder")}
                  name="phoneNumber"
                  type="number"
                  value={values.phoneNumber}
                  touched={touched.phoneNumber}
                  error={errors.phoneNumber}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />
                <Box className={btnContainer}>
                  <AppBtnFab
                    type="submit"
                    label={t("login")}
                    disabled={!_.isEmpty(errors) || !values.phoneNumber}
                  />
                </Box>
                <Typography className={signUpMsg}>
                  {t("dontHaveAccount")}{" "}
                  <a
                    href="https://www.viridix.com/contact-us-monday/"
                    className={signUpBtn}
                  >
                    {t("signUp")}
                  </a>
                </Typography>
              </form>
            )}
          </Formik>
        )}
        {getOtpSuccess && (
          <Formik
            initialValues={{ code: "" }}
            validate={(values) => {
              const errors = {};
              if (!values.code) {
                errors.code = "Required";
              } else if (!validator.isLength(values.code, { min: 4, max: 4 })) {
                errors.code = "Please enter code";
              }
              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              const phone = localStorage.getItem("userPhone");
              var urlParams = new URLSearchParams(location.search);
              const isGetSubscription = urlParams.get("isGetSubscription");
              const isFromPaymentEmail = urlParams.get("isFromPaymentEmail");
              const userOrganization = urlParams.get("userOrganization");
              const isFromMobile = urlParams.get("isFromMobile");
              const organizationId = urlParams.get("organizationId");
              const name = urlParams.get("name");
              const parentId = urlParams.get("parentId");
              const licenceIds = urlParams.get("licenceIds");
              if (isGetSubscription === "true") {
                loginRequest(
                  {
                    phoneNumber: phone,
                    otp: values.code,
                    deviceType: "web",
                  },
                  `/trial/${userOrganization}?isFromMobile=${isFromMobile}`
                );
              } else if (isFromPaymentEmail === "true") {
                loginRequest(
                  {
                    phoneNumber: phone,
                    otp: values.code,
                    deviceType: "web",
                  },
                  licenceIds
                    ? `/account-login?organizationId=${organizationId}&name=${name}&parentId=${parentId}&redirectUrl=/my-products&fromEmail=true&licenceIds=${licenceIds}`
                    : `/account-login?organizationId=${organizationId}&name=${name}&parentId=${parentId}&redirectUrl=/my-products&fromEmail=true`
                );
              } else {
                loginRequest({
                  phoneNumber: phone,
                  otp: values.code,
                  deviceType: "web",
                });
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit} className={loginForm}>
                <CodeField
                  name="code"
                  value={values.code}
                  touched={touched.code}
                  error={errors.code}
                  fields={4}
                  type="number"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />
                <Box className={btnContainer}>
                  <AppBtnFab
                    type="submit"
                    label={t("login")}
                    disabled={!_.isEmpty(errors) || !values.code}
                  />
                </Box>
                <Typography className={signUpMsg}>
                  {t("dontHaveAccount")}{" "}
                  <a
                    href="https://www.viridix.com/contact-us-monday/"
                    className={signUpBtn}
                  >
                    {t("signUp")}
                  </a>
                </Typography>
              </form>
            )}
          </Formik>
        )}
      </Box>
    </Box>
  );
};
const mapStateToProps = (state) => ({
  loading: getOtpLoading(state) || loginLoading(state),
  getOtpSuccess: isSuccessSelector(state),
  loginSuccess: isSuccessLoginSelector(state),
  countryData: state.countryData && state.countryData.countryData,
  countryDataLoading: state.countryData && state.countryData.loading,
});

const mapDispatchToProps = (dispatch) => ({
  getOtp: bindActionCreators(getOtpRequest, dispatch),
  loginRequest: bindActionCreators(loginRequest, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignIn);
