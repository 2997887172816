import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@material-ui/core";
import { AppBtnFab } from "components/commons";
import FarmerImg from "media/farmer.svg";
import useStyles from "./styles";

const NoMember = () => {
  const { t } = useTranslation();
  const {
    loginWindow,
    container,
    title,
    msg,
    btnContainer,
    loginMsg,
    loginBtn,
  } = useStyles();
  return (
    <Box className={container}>
      <Box className={loginWindow}>
        <img src={FarmerImg} alt="farmer" style={{ height: "215px" }} />
        <Typography variant="h3" className={title}>
          {t("notAMemberTitle")}
        </Typography>
        <Typography variant="caption" className={msg}>
          {t("notAMemberMsg")}
        </Typography>
        <Box className={btnContainer}>
          <AppBtnFab
            onClick={() => window.location.replace("https://viridix.com")}
            label={t("signUp")}
          />
        </Box>
        <Typography className={loginMsg}>
          {t("haveAccount")}{" "}
          <a href="/login" className={loginBtn}>
            {t("login")}
          </a>
        </Typography>
      </Box>
    </Box>
  );
};

export default NoMember;
