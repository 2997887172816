import React, { useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  Box,
  Typography,
  IconButton,
  Modal,
  Button,
  Tooltip,
} from "@material-ui/core";
import { Add, Close } from "@material-ui/icons";
import { getUserAccountsRequest, userAccountsSelector, errorSelector } from "redux/accounts";
import {
  isLoadingSelector,
  accountsListSelector,
  getAccountsListRequest,
} from "redux/accountsList";
import { disableAccountsRequest } from "redux/disableAccounts";
import { enableAccountsRequest } from "redux/enableAccounts";
import { deleteAccountsRequest } from "redux/deleteAccounts";
import { exportAccounts } from "api";
import { userIdSelector } from "redux/me";
import { default as Table } from "./table";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ACCOUNTS_COLUMNS } from "constants/accountsTableColumns";
import FarmerCircle from "media/farmer-circle.svg";
import PermissionsService from "services/PermissionsService";

import history from "history.js";
import useStyles from "./styles";
import _ from "lodash";

const setInitialPage = () => {
  const page = sessionStorage.getItem("lastSelectedPageAccounts");
  sessionStorage.removeItem("lastSelectedPageAccounts");
  if(page) {
    return +page;
  } else {
    return 0;
  }
}

const Accounts = ({
  accounts,
  getUserAccounts,
  userId,
  disableAccounts,
  deleteAccounts,
  enableAccounts,
  getAccountsList,
  accountsList,
  loadingAccountsList,
  errorGetAccounts
}) => {
  const { t } = useTranslation();
  const {
    container,
    addBtn,
    headerContainer,
    addIcon,
    modalBox,
    modalBody,
    modalTitle,
    modalBtnsContainer,
    cancelBtn,
    approveModalBtn,
    closeIcon,
    msgModal,
  } = useStyles();
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("createdAt");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(setInitialPage());
  const [rowsPerPage, setRowsPerPage] = useState(+localStorage.getItem('accountsTableRows') || 10);
  const [searchValue, setSearchValue] = useState("");
  const [deleteModalIsOpen, openDeleteModal] = useState(false);
  const [disableModalIsOpen, openDisableModal] = useState(false);
  const [enableModalIsOpen, openEnableModal] = useState(false);
  const [singleAction, setSingleAction] = useState(false);
  const [selectedAccount, selectAccount] = useState(null);
  const [selectedFilterParent, setSelectedFilterParent] = useState([]);
  useEffect(() => {
    if (userId) {
      getUserAccounts(
        searchValue,
        {
          limit: rowsPerPage,
          page: page + 1,
        },
        [`${orderBy},${order.toUpperCase()}`],
        selectedFilterParent
      );
    }
  }, [
    userId,
    page,
    rowsPerPage,
    searchValue,
    orderBy,
    order,
    selectedFilterParent,
  ]);
  useEffect(() => {
    if (userId) {
      getAccountsList();
    }
  }, [userId]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = accounts.data.map((n) => n);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleSelect = (event, account) => {
    const selectedIndex = selected.findIndex(
      (selectedItem) => selectedItem.id === account.id
    );

    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, account);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    localStorage.setItem('accountsTableRows', event.target.value)
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.some((item) => item.id === id);

  const debouncedSearch = _.debounce(setSearchValue, 500);

  const handleSearch = (e) => {
    setPage(0);
    debouncedSearch(e.target.value);
  };

  const handleGoDevices = (accountData) => {
    history.push(
      `/account-login?organizationId=${accountData.id}&name=${
        accountData.name
      }&parentId=${accountData.parentOrganization}&redirectUrl=${"/devices"}`
    );
  };
  const handleGoUsers = (accountData) => {
    history.push(
      `/account-login?organizationId=${accountData.id}&name=${
        accountData.name
      }&parentId=${accountData.parentOrganization}&redirectUrl=${"/users"}`
    );
  };
  const handleCreateAccount = () => {
    history.push(`/create-account`);
  };
  const handleEditAccount = (accountId) => {
    sessionStorage.setItem("lastSelectedPageAccounts", page)
    history.push(`/edit-account/${accountId}`);
  };
  const handleCloseDeleteModal = () => {
    openDeleteModal(false);
    setSingleAction(false);
    selectAccount(null);
  };
  const handleOpenDeleteModal = (account) => {
    selectAccount(account);
    setSingleAction(true);
    openDeleteModal(true);
  };
  const handleCloseDisableModal = () => {
    openDisableModal(false);
    setSingleAction(false);
    selectAccount(null);
  };
  const handleCloseEnableModal = () => {
    openEnableModal(false);
    setSingleAction(false);
    selectAccount(null);
  };
  const handleOpenDisableModal = (account) => {
    selectAccount(account);
    setSingleAction(true);
    openDisableModal(true);
  };
  const handleOpenEnableModal = (account) => {
    selectAccount(account);
    setSingleAction(true);
    openEnableModal(true);
  };
  const handleDisableAccount = () => {
    disableAccounts([selectedAccount.id], {
      search: searchValue,
      pagination: {
        limit: rowsPerPage,
        page: page + 1,
      },
      sort: [`${orderBy},${order.toUpperCase()}`],
      parentFilter: selectedFilterParent,
    });
    openDisableModal(false);
    setSingleAction(false);
    selectAccount(null);
  };
  const handleEnableAccount = () => {
    enableAccounts([selectedAccount.id], {
      search: searchValue,
      pagination: {
        limit: rowsPerPage,
        page: page + 1,
      },
      sort: [`${orderBy},${order.toUpperCase()}`],
      parentFilter: selectedFilterParent,
    });
    openEnableModal(false);
    setSingleAction(false);
    selectAccount(null);
  };
  const handleDeleteAccounts = () => {
    if (singleAction) {
      deleteAccounts([selectedAccount.id], {
        search: searchValue,
        pagination: {
          limit: rowsPerPage,
          page: page + 1,
        },
        sort: [`${orderBy},${order.toUpperCase()}`],
        parentFilter: selectedFilterParent,
      });
      openDeleteModal(false);
      setSingleAction(false);
      const selectedIndex = selected.findIndex(
        (selectedItem) => selectedItem.id === selectedAccount.id
      );
      let newSelected = [];
      if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      }
      setSelected(newSelected);
      selectAccount(null);
    } else {
      deleteAccounts(selected.map((selectedAcc) => selectedAcc.id), {
        search: searchValue,
        pagination: {
          limit: rowsPerPage,
          page: page + 1,
        },
        sort: [`${orderBy},${order.toUpperCase()}`],
        parentFilter: selectedFilterParent,
      });
      openDeleteModal(false);
    }
  };
  const handleExportAccounts = () => {
    exportAccounts(
      userId,
      selected.map((selectedAccount) => selectedAccount.id)
    );
  };
  const handleParentFilter = (accounts) => {
    setSelectedFilterParent(accounts.map((account) => account.id));
  };
  const handleChangeAccount = (accountData) => {
    history.push(
      `/account-login?organizationId=${accountData.id}&name=${
        accountData.name
      }&parentId=${accountData.parentOrganization}&redirectUrl=${"/accounts"}`
    );
  };
  return (
    <Box className={container}>
      <Box className={headerContainer}>
        <Typography variant="h5">{t("accounts")}</Typography>
        {PermissionsService.isAllowed("ACCOUNT_CREATE") && (
          <Tooltip title={t("createAccount")}>
            <IconButton className={addBtn} onClick={handleCreateAccount}>
              <Add className={addIcon} />
            </IconButton>
          </Tooltip>
        )}
      </Box>
      <Table
        cells={ACCOUNTS_COLUMNS}
        data={accounts.data || []}
        order={order}
        orderBy={orderBy}
        selected={selected}
        page={page}
        rowsPerPage={rowsPerPage}
        handleRequestSort={handleRequestSort}
        handleSelectAllClick={handleSelectAllClick}
        handleSelect={handleSelect}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        isSelected={isSelected}
        pageCount={accounts.total || 0}
        handleGoDevices={handleGoDevices}
        handleGoUsers={handleGoUsers}
        handleEditAccount={handleEditAccount}
        handleOpenDeleteModal={handleOpenDeleteModal}
        openDeleteModal={openDeleteModal}
        handleOpenDisableModal={handleOpenDisableModal}
        handleOpenEnableModal={handleOpenEnableModal}
        exportAccounts={handleExportAccounts}
        accountsList={accountsList}
        handleParentFilter={handleParentFilter}
        handleChangeAccount={handleChangeAccount}
        handleSearch={handleSearch}
        handleCreateAccount={handleCreateAccount}
        noAccess={errorGetAccounts.includes("403")}
        loadingAccountsList={loadingAccountsList}
      />
      <Modal
        disableAutoFocus={true}
        disableEnforceFocus={true}
        open={deleteModalIsOpen}
        onClose={handleCloseDeleteModal}
      >
        <Box className={modalBox}>
          <Box style={{ display: "flex" }}>
            <img src={FarmerCircle} alt="logo" />
            <Box className={modalBody}>
              <Close className={closeIcon} onClick={handleCloseDeleteModal} />
              <Typography className={modalTitle} variant="h6">
                {t("deleteAccountModalTitle")}
              </Typography>
              {selectedAccount && singleAction && (
                <ol>
                  <li>{selectedAccount.name}</li>
                </ol>
              )}
              {selected.length > 0 && !singleAction && (
                <ol>
                  {selected.map((selectedAcc, i) => (
                    <li key={i}>{selectedAcc.name}</li>
                  ))}
                </ol>
              )}
            </Box>
          </Box>
          <Box className={modalBtnsContainer}>
            <Button className={cancelBtn} onClick={handleCloseDeleteModal}>
              {t("cancel")}
            </Button>
            <Button
              className={approveModalBtn}
              color="primary"
              onClick={handleDeleteAccounts}
            >
              {t("delete")}
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal
        disableAutoFocus={true}
        disableEnforceFocus={true}
        open={disableModalIsOpen}
        onClose={handleCloseDisableModal}
      >
        <Box className={modalBox}>
          <Box style={{ display: "flex" }}>
            <img src={FarmerCircle} alt="logo" />
            <Box className={modalBody}>
              <Close className={closeIcon} onClick={handleCloseDisableModal} />
              <Typography className={modalTitle} variant="h6">
                {t("disableAccountModalTitle")}
              </Typography>
              {selectedAccount && (
                <Typography variant="subtitle2" className={msgModal}>
                  {t("disableAccountModalMsg", {
                    selectedAccount: selectedAccount.name,
                  })}
                </Typography>
              )}
            </Box>
          </Box>
          <Box className={modalBtnsContainer}>
            <Button className={cancelBtn} onClick={handleCloseDisableModal}>
              {t("cancel")}
            </Button>
            <Button
              className={approveModalBtn}
              color="primary"
              onClick={handleDisableAccount}
            >
              {t("disable")}
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal
        disableAutoFocus={true}
        disableEnforceFocus={true}
        open={enableModalIsOpen}
        onClose={handleCloseEnableModal}
      >
        <Box className={modalBox}>
          <Box style={{ display: "flex" }}>
            <img src={FarmerCircle} alt="logo" />
            <Box className={modalBody}>
              <Close className={closeIcon} onClick={handleCloseEnableModal} />
              <Typography className={modalTitle} variant="h6">
                {t("enableAccountModalTitle")}
              </Typography>
              {selectedAccount && (
                <Typography variant="subtitle2" className={msgModal}>
                  {t("enableAccountModalMsg", {
                    selectedAccount: selectedAccount.name,
                  })}
                </Typography>
              )}
            </Box>
          </Box>
          <Box className={modalBtnsContainer}>
            <Button className={cancelBtn} onClick={handleCloseEnableModal}>
              {t("cancel")}
            </Button>
            <Button
              className={approveModalBtn}
              color="primary"
              onClick={handleEnableAccount}
            >
              {t("enable")}
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  accounts: userAccountsSelector(state),
  userId: userIdSelector(state),
  loadingAccountsList: isLoadingSelector(state),
  accountsList: accountsListSelector(state),
  errorGetAccounts: errorSelector(state)
});

const mapDispatchToProps = (dispatch) => ({
  getUserAccounts: bindActionCreators(getUserAccountsRequest, dispatch),
  disableAccounts: bindActionCreators(disableAccountsRequest, dispatch),
  deleteAccounts: bindActionCreators(deleteAccountsRequest, dispatch),
  enableAccounts: bindActionCreators(enableAccountsRequest, dispatch),
  getAccountsList: bindActionCreators(getAccountsListRequest, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Accounts);
