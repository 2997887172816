import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  hideScroll: {
    display: "flex",
    flexDirection: "column",
    overflowY: "scroll",
    overflowX: "scroll",
    msOverflowStyle: "none",
    overflow: "-moz-scrollbars-none",
    scrollbarWidth: "none",
    borderInlineEnd: "1px solid #EBEDF3",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    flex: 1,
  },
  logoContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: "56px",
    padding: ({ isOpen }) => (isOpen ? "0 24px" : "0 12px"),
  },
  accountNameText: {
    fontSize: 18,
    fontWeight: 500,
    lineHeight: "21px",
    color: theme.palette.primary.main,
    textOverflow: "ellipsis",
    overflow: "hidden",
    // padding: "32px 24px",
  },
  accountNameCollapsed: {
    fontSize: 18,
    fontWeight: 500,
    lineHeight: "21px",
    color: theme.palette.primary.main,
    textOverflow: "ellipsis",
    overflow: "hidden",
    margin: "21px",
    width: "42px",
    height: "42px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "10px",
    background: "#F7F8FC",
  },
  routeRow: {
    paddingInlineStart: ({ isOpen }) => (isOpen ? "35px" : "initial"),
    justifyContent: ({ isOpen }) => (isOpen ? "space-between" : "center"),
    paddingInlineEnd: ({ isOpen }) => (isOpen ? "30px" : "initial"),
    display: "flex",
    alignItems: "center",
    height: "50px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#F7F8FC",
    },
  },
  routeName: {
    color: theme.palette.text.lightGray,
    fontWeight: "bold",
  },
  icon: {
    minWidth: "35px",
    justifyContent: ({ isOpen }) => (isOpen ? "flex-start" : "center"),
  },
  activeRoute: {
    backgroundColor: "#F7F8FC",
    borderInlineEnd: `3px solid ${theme.palette.primary.main}`,
  },
  activeRouteName: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
  },
  menuToggle: {
    height: "30px",
    width: "26px",
    cursor: "pointer",
    "&:hover": {
      opacity: 0.7,
    },
  },
  disabledRoute: {
    opacity: 0.5,
    cursor: "not-allowed",
    "&:hover": {
      backgroundColor: "initial",
    },
  },
  addBtn: {
    width: "20px",
    height: "20px",
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      opacity: 0.7,
    },
  },
  addIcon: {
    color: theme.palette.text.white,
  },
  accountBox: {
    display: "flex",
    flexDirection: ({ isOpen }) => (isOpen ? "row" : "column"),
    alignItems: "center",
    justifyContent: "space-between",
    padding: "32px 24px",
  },
  routeLabelContainer: {
    display: "flex"
  },
  badge: {
    background: "rgba(218, 80, 98, 0.1)",
    border: "1px solid rgba(218, 80, 98, 0.15)",
    borderRadius: "40px",
    width: "18px",
    height: "18px",
    fontWeight: "bold",
    fontSize: "12px",
    color: "#DA5062",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }
}));
