import React, { useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box, IconButton, Menu, MenuItem } from "@material-ui/core";
import clsx from "clsx";

import { HeatMap } from "components/commons";
import useStyles from "./styles";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { settingsSelector } from "redux/dashboardSettings";
import { saveDashboardSettingsRequest } from "redux/saveDashboardSettings";
import DeleteIcon from "media/deleteIcon.svg";
import HeatmapIcon from "media/heatmapIcon.svg";

const DeviceDepths = ({
  plotId,
  deviceId,
  daterange,
  device,
  saveDashboardSettings,
  dashboardSettings,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { t } = useTranslation();
  const { title, container, subtitle, header, menuItemText } = useStyles();
  const generateDepth = (device) => {
    const rootenseSensors = device.sensors.filter(
      (sensor) =>
        sensor && sensor.sensorType && sensor.sensorType.type === "rootense"
    );
    const depths = {};
    rootenseSensors.forEach((sensor) => {
      if (sensor) {
        depths[`depth${sensor.port}`] = sensor.settings.depth;
      }
    });
    return depths;
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const deleteWidget = () => {
    const index = dashboardSettings.findIndex(
      (item) => item.key === `depths-${deviceId}`
    );
    dashboardSettings[index].enabled = false;
    saveDashboardSettings({
      plotId: plotId,
      settings: dashboardSettings,
      updateData: true,
    });
  };
  const open = Boolean(anchorEl);
  return (
    <Box className={container}>
      <Box className={clsx("widgetHeader", header)}>
        <Box display="flex" alignItems="center">
          <img width="32px" height="32px" src={HeatmapIcon} alt="icon" />
          <Box padding="0 15px">
            <Box className={title}>
              {device.name} ({device.externalName || "-"})
            </Box>
            <Box className={subtitle}>{t("depth")}</Box>
          </Box>
        </Box>
        <IconButton onClick={handleClick} size="small">
          <MoreVertIcon />
        </IconButton>
      </Box>
      <div className="no-draggable" style={{ height: "80%" }}>
        <HeatMap
          deviceDepths={generateDepth(device)}
          deviceId={deviceId}
          daterange={daterange}
        />
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <MenuItem
            style={{
              width: "200px",
            }}
            onClick={deleteWidget}
          >
            <img src={DeleteIcon} alt="DeleteIcon" />
            <Box className={menuItemText}>{t("delete")}</Box>
          </MenuItem>
        </Menu>
      </div>
    </Box>
  );
};
const mapStateToProps = (state) => ({
  dashboardSettings: settingsSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  saveDashboardSettings: bindActionCreators(
    saveDashboardSettingsRequest,
    dispatch
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeviceDepths);
