import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
  SvgIcon,
  Button,
  Box,
  Tooltip,
} from "@material-ui/core";
import useStyles from "./styles";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

import moment from "moment";

const DeleteIcon = ({ className, ...rest }) => (
  <SvgIcon {...rest} viewBox="0 0 14 14" className={className}>
    <path
      d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
      fill="#828B93"
    />
  </SvgIcon>
);

const AppTable = ({
  cells,
  data,
  order,
  orderBy,
  page,
  rowsPerPage,
  handleRequestSort,
  handleChangePage,
  handleChangeRowsPerPage,
  pageCount,
  handleRemoveProduct,
  noAccess,
  period,
}) => {
  const {
    root,
    tableStyle,
    headerLabel,
    icon,
    headerColumn,
    actionBtn,
    borderStart,
    tableColumn,
    expiredLicence,
    endingLicence,
    activeLicence,
    productColumn,
  } = useStyles();
  const { t } = useTranslation();
  const emptyRows = rowsPerPage - data.length;
  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };
  return (
    <div className={root}>
      <TableContainer>
        <Table className={tableStyle} size="medium">
          <TableHead>
            <TableRow>
              {cells.map((headCell) => (
                <TableCell
                  key={headCell.id}
                  align={headCell.numeric ? "right" : "left"}
                  style={{
                    minWidth: headCell.minWidth,
                    maxWidth: headCell.maxWidth,
                    width: headCell.width,
                  }}
                  className={clsx({
                    [borderStart]: headCell.borderStart,
                  })}
                  padding={headCell.disablePadding ? "none" : "default"}
                  sortDirection={orderBy === headCell.id ? order : false}
                >
                  <Box className={headerColumn}>
                    {headCell.sortable ? (
                      <TableSortLabel
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : "asc"}
                        onClick={createSortHandler(headCell.id)}
                      >
                        <Typography className={headerLabel} variant="body2">
                          {t(headCell.label)}
                        </Typography>
                      </TableSortLabel>
                    ) : (
                      <Typography className={headerLabel} variant="body2">
                        {t(headCell.label)}
                      </Typography>
                    )}
                  </Box>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {!noAccess &&
              data.map((row, index) => {
                return (
                  <TableRow role="checkbox" tabIndex={-1} key={Math.random()}>
                    <TableCell align="left">
                      <Typography className={productColumn}>
                        {row.product.name}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography
                        className={clsx(tableColumn, {
                          [expiredLicence]: row.status === "expired",
                          [endingLicence]: row.status === "ending",
                          [activeLicence]: row.status === "active",
                        })}
                      >
                        {moment(row.endDate).format("MMM D, YYYY")}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography className={tableColumn}>
                        {moment(row.endDate)
                          .add(period, "years")
                          .format("MMM D, YYYY")}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography className={tableColumn}>
                        {
                          row.product.prices.length
                          ? new Intl.NumberFormat(row.product.prices[0].countryCode, {
                              style: "currency",
                              currency: row.product.prices[0].currency,
                            }).format(row.product.prices[0].price)
                          : new Intl.NumberFormat(row.product.prices[0].countryCode, {
                              style: "currency",
                              currency: row.product.prices[0].currency,
                            }).format(0)
                        }
                      </Typography>
                    </TableCell>
                    <TableCell className={borderStart} align="center">
                      {!row.isDisabled && (
                        <Tooltip title={t("removeProductFromCart")}>
                          <Button
                            onClick={() => handleRemoveProduct(row.id)}
                            className={actionBtn}
                          >
                            <DeleteIcon className={icon} />
                          </Button>
                        </Tooltip>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            {emptyRows > 0 && !noAccess && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={9} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {!noAccess && (
        <TablePagination
          labelDisplayedRows={({ from, to, count }) => {
            return t("itemCountOnPageInTable", { from, to, count });
          }}
          labelRowsPerPage={t("labelRowsPerPage")}
          nextIconButtonText={t("nextIconButtonText")}
          backIconButtonText={t("backIconButtonText")}
          rowsPerPageOptions={[3, 5, 10, 25]}
          component="div"
          count={pageCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}
    </div>
  );
};

export default AppTable;
