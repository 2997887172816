import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  container: {
    width: "483px",
  },
  header: {
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "30px",
  },
  title: {
    fontWeight: "bold",
    fontSize: "24px",
    lineHeight: "28px",
    color: "white",
  },
  closeBtn: {
    color: "white",
    cursor: "pointer",
    "&:hover": {
      opacity: 0.7,
    },
  },
  tabLabel: {
    textTransform: "none",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "19px",
    color: "#4A4B54",
    minWidth: "initial",
    opacity: 1,
    letterSpacing: "0px",
  },
  content: {
    padding: "30px",
  },
  category: {
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "19px",
    color: "#828B93",
    marginBottom: "16px",
    marginTop: "40px",
  },
  categoryItems: {
    display: "flex",
    flexWrap: "wrap",
  },
  categoryItem: {
    width: "135px",
    height: "104px",
    border: "1px solid #EBEDF3",
    borderRadius: "8px",
    marginInlineEnd: "4px",
    marginBottom: "5px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    position: "relative",
    "&:hover": {
      opacity: 0.7,
    },
  },
  categoryItemName: {
    color: "#4A4B54",
    fontSize: "14px",
    lineHeight: "150%",
    textAlign: "center",
  },
  categoryItemValue: {
    marginBottom: "14px",
    fontWeight: "bold",
    fontSize: "18px",
    lineHeight: "150%",
    color: "#4A4B54",
  },
  checkIcon: {
    position: "absolute",
    top: "8px",
    right: "20px",
  },
  checkedWidget: {
    background: "rgba(168, 216, 244, 0.2)",
  },
  categoryItemNameChecked: {
    fontWeight: "bold",
  },
  autopilotLabel: {
    textShadow: "0px 5.98px 9.2px rgba(237, 251, 255, 0.17)",
    '-webkit-background-clip': "text",
    textFillColor: "transparent",
    background:
      "radial-gradient(122.33% 92.11% at 109.09% -6.03%, rgba(110, 254, 21, 0.034) 0%, rgba(255, 255, 255, 0) 100%), radial-gradient(86.9% 85.38% at 97.99% 97.91%, rgba(254, 21, 231, 0.102) 0%, rgba(255, 255, 255, 0) 100%), radial-gradient(97.33% 73.29% at 4.95% 3.13%, rgba(0, 255, 163, 0.8) 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(0deg, #26AEFF, #26AEFF)",
  },
  noMargin: {
    marginBottom: 0,
    marginTop: 0,
  }
}));
