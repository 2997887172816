import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box, IconButton, Menu, MenuItem } from "@material-ui/core";
import moment from "moment";
import {
  getFieldWeatherHistoryRequest,
  weatherHistorySelector,
} from "redux/fieldWeatherHistory";
import { AirTempGraph } from "components/commons";
import useStyles from "./styles";
import ExportIcon from "media/exportIcon.svg";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import clsx from "clsx";
import { settingsSelector } from "redux/dashboardSettings";
import { saveDashboardSettingsRequest } from "redux/saveDashboardSettings";
import DeleteIcon from "media/deleteIcon.svg";
import AirTempIcon from "media/airTemp.svg";

const AirTemperatureHistory = ({
  plotId,
  fieldId,
  daterange,
  getFieldWeatherHistory,
  weatherHistory,
  saveDashboardSettings,
  dashboardSettings,
}) => {
  const { t } = useTranslation();
  const { title, container, header, exportText } = useStyles();
  useEffect(() => {
    if (fieldId) {
      getFieldWeatherHistory(
        fieldId,
        moment(daterange[0]).valueOf(),
        moment(daterange[1]).valueOf()
      );
    }
  }, [fieldId, daterange]);
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const exportToCsv = () => {
    const data = [
      [t("time"), t("virtualAirTemperature")].join(","),
      ...weatherHistory.weather.map((item) =>
        [moment(item.time).format("DD/MM/YYYY HH:mm"), item.temperature].join(
          ","
        )
      ),
    ];
    const csv = data.join("\r\n");
    let link = document.createElement("a");
    link.setAttribute(
      "href",
      "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURIComponent(csv)
    );
    link.setAttribute("download", `${t("virtualAirTemperature")}`);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const deleteWidget = () => {
    const index = dashboardSettings.findIndex(
      (item) => item.key === "airTemperatureWidget"
    );
    dashboardSettings[index].enabled = false;
    saveDashboardSettings({
      plotId: plotId,
      settings: dashboardSettings,
      updateData: true,
    });
  };
  const open = Boolean(anchorEl);
  return (
    <Box className={container}>
      <Box className={clsx("widgetHeader", header)}>
        <Box style={{ display: "flex", alignItems: "center" }}>
          <img
            width="32px"
            height="32px"
            style={{ marginInlineEnd: "10px" }}
            src={AirTempIcon}
            alt="icon"
          />
          <Box className={title}>{t("virtualAirTemperature")}</Box>
        </Box>
        <IconButton onClick={handleClick} size="small">
          <MoreVertIcon />
        </IconButton>
      </Box>
      <div className="no-draggable" style={{ height: "80%" }}>
        <AirTempGraph daterange={daterange} airTempData={weatherHistory} />
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <MenuItem
            style={{
              width: "200px",
            }}
            onClick={exportToCsv}
          >
            <img src={ExportIcon} alt="Export" />
            <Box className={exportText}>{t("export")}</Box>
          </MenuItem>
          <MenuItem
            style={{
              width: "200px",
            }}
            onClick={deleteWidget}
          >
            <img src={DeleteIcon} alt="DeleteIcon" />
            <Box className={exportText}>{t("delete")}</Box>
          </MenuItem>
        </Menu>
      </div>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  weatherHistory: weatherHistorySelector(state),
  dashboardSettings: settingsSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  getFieldWeatherHistory: bindActionCreators(
    getFieldWeatherHistoryRequest,
    dispatch
  ),
  saveDashboardSettings: bindActionCreators(
    saveDashboardSettingsRequest,
    dispatch
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AirTemperatureHistory);
