import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.background.default,
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center",
    width: "100%",
    justifyContent: "space-between",
    height: "56px",
  },
  containerAuthenticated: {
    backgroundColor: theme.palette.primary.main,
    height: theme.headerHeight,
  },
  userNameStyle: {
    fontWeight: "bold",
    lineHeight: "16px",
    textAlign: "right",
    color: "#FFFFFF",
    marginInlineEnd: "20px",
    cursor: "pointer",
    "&:hover": {
      opacity: 0.7,
    },
  },
  divider: {
    margin: "0 4vw",
    backgroundColor: "#FFFFFF",
  },
  link: {
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "16px",
    color: "#A8D8F4",
  },
  mainAccount: {
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "16px",
    color: "#FFFFFF",
  },
  separatorStyle: {
    color: "#A8D8F4",
  },
  cartContainer: {
    display: "flex",
    alignItems: "center",
    marginInlineStart: "40px",
    cursor: "pointer",
    "&:hover": {
      opacity: 0.7,
    },
  },
  cartNumber: {
    background: "rgba(255, 255, 255, 0.1)",
    border: "1px solid rgba(255, 255, 255, 0.15)",
    borderRadius: "40px",
    padding: "3px 9px",
    color: "#FFFFFF",
    fontWeight: "bold",
    fontSize: "12px",
    lineHeight: "14px",
  },
  logoutBtn: {
    fontSize: "14px",
    lineHeight: "150%",
    alignItems: "center",
    color: "#828B93",
    marginInlineStart: "16px"
  },
}));
