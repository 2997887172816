import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  container: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    padding: theme.spacing(3, 0),
  },
  loginWindow: {
    display: "flex",
    flex: 1,
    width: `calc(90vw - ${theme.spacing(4) * 2}px)`,
    maxWidth: "546px",
    background: "#FFFFFF",
    padding: theme.spacing(4, 4),
    flexDirection: "column",
    border: "1px solid #D7D9DF",
    borderRadius: "8px",
  },
  loginForm: {
    flex: 1,
  },
  title: {
    color: "#363740",
    textAlign: "center",
    paddingTop: theme.spacing(3),
  },
  btnContainer: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    padding: "31px 25% 0 25%",
  },
  signUpMsg: {
    fontWeight: "bold",
    fontSize: "12px",
    lineHeight: "14px",
    textAlign: "center",
    color: "rgba(0, 0, 0, 0.6)",
    paddingTop: theme.spacing(2),
  },
  signUpBtn: {
    color: theme.palette.primary.main,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  msg: {
    color: "#828B93",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "19px",
    textAlign: "center",
    padding: theme.spacing(2)
  },
  farmerIcon: {
    height: "215px"
  }
}));
