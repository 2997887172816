import { makeStyles } from "@material-ui/core";
import BackgroundImage from "./../../media/login-background.png";

export default makeStyles((theme) => ({
  root: {
    flex: 1,
    display: "flex",
    height: "100vh",
    // overflow: "auto"
  },
  content: {
    display: "flex",
    flex: 1,
    padding: theme.spacing(0, 2.5),
    flexDirection: "column",
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: "cover",
    paddingTop: '60px',
    overflow: "auto"
  },
  appBar: {
    backgroundColor: "white"
  }
}));
