import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  container: {
    padding: "24px",
    height: "calc(100% - 48px)",
    display: "flex",
    flexDirection: "column"
  },
  title: {
    color: "#4A4B54",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "16px",
  },
  circleProgress: {
    width: "65px",
  },
  content: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    flex: 1
  },
  percents: {
    fontWeight: "bold",
    fontSize: "18px",
    lineHeight: "150%",
    color: "#4A4B54",
    padding: "8px 0"
  },
  deviceName: {
    fontSize: "14px",
    lineHeight: "150%",
    color: "#828B93"
  },
  deviceInfo: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "27px",
  },
  menuItemText: {
    color: "#828B93",
    fontSize: "14px",
    padding: "0 10px",
  }
}));
