export const MY_PRODUCTS_COLUMNS = [
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "licence",
    sortable: false,
    filterable: false,
    minWidth: null,
    maxWidth: null,
    width: null
  },
  {
    id: "product",
    numeric: false,
    disablePadding: false,
    label: "prooductId",
    sortable: true,
    filterable: false,
    minWidth: null,
    maxWidth: null,
    width: null
  },
  {
    id: "device",
    numeric: false,
    disablePadding: false,
    label: "deviceName",
    sortable: true,
    filterable: false,
    minWidth: null,
    maxWidth: null,
    width: null
  },
  {
    id: "account",
    numeric: false,
    disablePadding: false,
    label: "account",
    sortable: true,
    filterable: false,
    minWidth: null,
    maxWidth: null,
    width: null
  },
  {
    id: "licenceEnd",
    numeric: false,
    disablePadding: false,
    label: "licenceEnd",
    sortable: true,
    filterable: false,
    minWidth: null,
    maxWidth: null,
    width: null
  },
  {
    id: "region",
    numeric: false,
    disablePadding: false,
    label: "regionAndPlot",
    sortable: false,
    filterable: false,
    minWidth: null,
    maxWidth: null,
    width: null
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: false,
    label: "actions",
    sortable: false,
    filterable: false,
    minWidth: null,
    maxWidth: null,
    borderStart: true,
    width: null
  },
];
