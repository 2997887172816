import React, { useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  getAlertInfoRequest,
  alertInfoSelector,
  clearAlertInfoData,
} from "redux/alertInfo";
import { WPChart, HeatMap, BatteryGraph } from "components/commons";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Box, Button, IconButton } from "@material-ui/core";
import WeatherIcon from "media/weather.png";
import WaterIcon from "media/water.png";
import Tech from "media/tech.png";
import RemindMe from "media/remindMe.svg";
import Comment from "media/comment.svg";
import TurnOffNotifications from "media/turnOffNotifications.svg";
import clsx from "clsx";

import useStyles from "./styles";

const ALERT_LEVEL_COLOR = {
  0: "#62c87a",
  1: "#62c87a",
  2: "#ecc545",
  3: "#ecc545",
  4: "#d85746",
  5: "#d85746",
};

const ALERT_ICON_TYPE = {
  BATTERY: Tech,
  WATER_POTENTIAL: WaterIcon,
  WEATHER: WeatherIcon,
  SIGNAL_LEVEL: Tech,
  DEVICE_ACTIVITY: Tech,
  TECHNICAL_ISSUE_ALERT: Tech,
};

const AlertInfo = ({
  selectedAlert,
  getAlertInfo,
  alertInfo,
  handleOpenDeleteModal,
  handleOpenCommentModal,
  handleOpenRemindMeModal,
  handleMarkAsDone,
  handleOpenToNModal,
  daterange,
  devices,
  clearAlertInfo,
}) => {
  const { t } = useTranslation();
  const {
    container,
    header,
    headerTitle,
    btnContainer,
    deleteBtn,
    markAsDone,
    content,
    alert,
    insightIconContainer,
    icon,
    iconContainer,
    alertData,
    alertTitle,
    alertInstr,
    fieldPlotInfo,
    btnsRow,
    actionBtn,
    comments,
    comment,
    commentText,
    commentInfo,
    author,
    date,
    commentsContainer,
    link,
    recommendations,
    recommendation,
    helpMsg,
    supportLink,
    chartTitle,
    deviceName,
  } = useStyles();
  useEffect(() => {
    if (selectedAlert) {
      getAlertInfo(selectedAlert.id);
    }
  }, [selectedAlert]);
  useEffect(() => {
    return () => {
      clearAlertInfo();
    };
  }, []);
  const handlePressSuport = () => {
    window.Intercom("show");
  };
  const generateDepth = (devices) => {
    const device = devices.find(
      (device) => device.id === alertInfo.alert.insightsGroup.devices[0].id
    );
    const rootenseSensors = device.sensors.filter(
      (sensor) =>
        sensor && sensor.sensorType && sensor.sensorType.type === "rootense"
    );
    const depths = {};
    rootenseSensors.forEach((sensor) => {
      if (sensor) {
        depths[`depth${sensor.port}`] = sensor.settings.depth;
      }
    });
    return depths;
  };
  return (
    <Box className={clsx(container, "no-draggable")}>
      <Box className={header}>
        <Box className={headerTitle}>{t("insight")}</Box>
        <Box className={btnContainer}>
          <Button
            className={deleteBtn}
            onClick={() => handleOpenDeleteModal(selectedAlert)}
          >
            {t("delete")}
          </Button>
          <Button
            className={markAsDone}
            onClick={() => handleMarkAsDone(selectedAlert)}
          >
            {t("markAsDone")}
          </Button>
        </Box>
      </Box>
      {alertInfo && (
        <Box className={content}>
          <Box className={alert}>
            <Box className={iconContainer}>
              <Box
                className={insightIconContainer}
                style={{ backgroundColor: ALERT_LEVEL_COLOR[alertInfo.level] }}
              >
                <img
                  className={icon}
                  src={ALERT_ICON_TYPE[alertInfo.alert.insight.checkerName]}
                  alt={alertInfo.alert.insight.checkerName}
                />
              </Box>
            </Box>
            <Box className={alertData}>
              <Box className={alertTitle}>{t(alertInfo.status)}</Box>
              <Box className={deviceName}>
                {alertInfo.alert.insightsGroup.devices[0].name} -{" "}
                {alertInfo.alert.insightsGroup.devices[0].externalName}
              </Box>
              <Box className={alertInstr}>{t(`${alertInfo.status}_INSTR`)}</Box>
              <Box className={fieldPlotInfo}>
                {moment(alertInfo.createdAt).fromNow()} •{" "}
                <p className={link}>
                  {alertInfo.alert.insightsGroup.devices[0].field.name}
                </p>{" "}
                •{" "}
                <p className={link}>
                  {alertInfo.alert.insightsGroup.devices[0].plot.name}
                </p>
              </Box>
              <Box className={btnsRow}>
                <IconButton
                  className={actionBtn}
                  onClick={handleOpenCommentModal}
                >
                  <img src={Comment} alt="Comment" />
                </IconButton>
                <IconButton
                  className={actionBtn}
                  onClick={handleOpenRemindMeModal}
                >
                  <img src={RemindMe} alt="RemindMe" />
                </IconButton>
                <IconButton className={actionBtn} onClick={handleOpenToNModal}>
                  <img src={TurnOffNotifications} alt="TurnOffNotifications" />
                </IconButton>
              </Box>
            </Box>
          </Box>
          <Box className={commentsContainer}>
            <Box className={comments}>{t("comments")}</Box>
            {alertInfo.comments.map((data) => (
              <Box className={comment} key={data.id}>
                <Box className={commentText}>{data.text}</Box>
                <Box className={commentInfo}>
                  <Box className={author}>{data.user.name}</Box>
                  <Box className={date}>{moment(data.createdAt).fromNow()}</Box>
                </Box>
              </Box>
            ))}
            {!alertInfo.comments.length && (
              <Box className={commentText}>{t("noComments")}</Box>
            )}
          </Box>
          {alertInfo.status.includes("TIME_STRESS") && (
            <Box style={{ height: "300px" }}>
              <Box className={chartTitle}>{t("waterPotential")}</Box>
              <WPChart
                daterange={daterange}
                deviceId={alertInfo.alert.insightsGroup.devices[0].id}
                depths={generateDepth(devices)}
                isForInsight={true}
              />
            </Box>
          )}
          {alertInfo.status.includes("BATTERY") && (
            <Box style={{ height: "300px" }}>
              <Box className={chartTitle}>{t("batteryLevel")}</Box>
              <BatteryGraph
                daterange={daterange}
                deviceId={alertInfo.alert.insightsGroup.devices[0].id}
                isForInsight={true}
              />
            </Box>
          )}
          {alertInfo.status.includes("IRRIGATION") && (
            <Box style={{ height: "300px" }}>
              <Box className={chartTitle}>{t("depth")}</Box>
              <HeatMap
                daterange={daterange}
                deviceId={alertInfo.alert.insightsGroup.devices[0].id}
                deviceDepths={generateDepth(devices)}
                isForInsight={true}
              />
            </Box>
          )}
          <Box>
            <Box className={recommendations}>{t("recommendations")}</Box>
            <Box className={recommendation}>{t(alertInfo.recommendation)}</Box>
          </Box>
          <Box className={helpMsg}>
            {t("alertInfoHelpMsg")}{" "}
            <p className={supportLink} onClick={handlePressSuport}>
              {t("support")}
            </p>
          </Box>
        </Box>
      )}
    </Box>
  );
};

const mapStateToProps = (state) => ({
  alertInfo: alertInfoSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  getAlertInfo: bindActionCreators(getAlertInfoRequest, dispatch),
  clearAlertInfo: bindActionCreators(clearAlertInfoData, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AlertInfo);
