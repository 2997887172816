import { put, takeEvery, all, call } from "redux-saga/effects";
import { login } from "api";
import { getMeRequest } from "./me";
import history from "history.js";
/* *
 * Constants
 * */
export const moduleName = "login";
const prefix = `${process.env.REACT_APP_NAME}/${moduleName}`;

export const INIT = `${prefix}/INIT`;
export const SUCCESS = `${prefix}/SUCCESS`;
export const REJECT = `${prefix}/REJECT`;

/* *
 * Reducer
 * */
const initialState = {
  data: {},
  loading: false,
  error: {},
  success: false,
};

export default function reducer(
  state = initialState,
  { type, payload, meta, ...rest }
) {
  switch (type) {
    case INIT:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        data: payload,
      };
    case REJECT:
      return {
        ...state,
        loading: false,
        success: false,
        error: payload.error,
      };
    default:
      return state;
  }
}

/* *
 * Selectors
 * */
export const moduleSelector = (state) => state[moduleName];

export const isLoadingSelector = (state) => moduleSelector(state).loading;

export const isSuccessLoginSelector = (state) => moduleSelector(state).success;

/* *
 * Action Creators
 * */
export const loginRequest = (data, redirectUrl) => ({
  type: INIT,
  payload: { data, redirectUrl },
});

export const successAction = (payload) => ({
  type: SUCCESS,
  payload,
});

export const errorAction = (error) => ({
  type: REJECT,
  payload: { error },
});

/* *
 * Sagas
 * */
export function* loginSaga({ payload: { data, redirectUrl } }) {
  try {
    const result = yield call(login, data);
    yield put(successAction(result));
    yield put(getMeRequest());
    if(redirectUrl) {
      history.push(redirectUrl);
    } else {
      history.push("/accounts");
    }
  } catch (error) {
    yield put(errorAction(error.message));
  }
}

export function* saga() {
  yield all([takeEvery(INIT, loginSaga)]);
}
