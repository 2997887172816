import React from "react";
import { Box } from "@material-ui/core";
import LoadingGit from "media/loading.gif";

const AppLoader = () => {
  return (
    <Box style={{
      height: "100%",
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    }}>
      <img style={{height: "50%"}} src={LoadingGit} alt="loading" />
    </Box>
  );
};

export default AppLoader;
