import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import {
  AppSelect,
  AppBtnFab,
  MottechLoginModal,
  AppLoader,
  DropdownMenu,
  MottechServerEditModal,
  AppMultipleSelect,
  TalgilLoginModal,
  TalgilServerEditModal,
} from "components/commons";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box, Dialog, FormControlLabel, Checkbox } from "@material-ui/core";
import useStyles from "../styles";
import { useFormik } from "formik";
import MottechLogo from "media/mottechLogo.svg";
import TalgilIconSmall from "media/talgilIconSmall.svg";
import StartIrrigationIcon from "media/startIrrigation.svg";
import StopIrrigationIcon from "media/stopIrrigation.svg";
import ServerOk from "media/serverOk.svg";
import ServerNoSignal from "media/serverNoSignal.svg";
import ServerProblem from "media/serverProblem.svg";
import AddServerIcon from "media/addServerIcon.svg";
import {
  getIrrigationProviderConnectionRequest,
  irrigationProviderConnectionSelector,
  isLoadingSelector as irrigationConnectionProviderLoading,
} from "redux/irrigationProviderConnection";
import {
  getConnectionProgramsRequest,
  connectionProgramsSelector,
  clearConnectionPrograms,
} from "redux/connectionPrograms";
import {
  irrigationConnectRequest,
  isLoadingSelector as irrigationConnectLoading,
} from "redux/irrigationConnect";
import {
  editIrrigationConnectionRequest,
  isLoadingSelector as editIrrigationConnectionLoading,
} from "redux/editIrrigationConnection";
import {
  getPlotIrrigationConnectionRequest,
  plotIrrigationConnectionSelector,
} from "redux/plotIrrigationConnection";
import { irrigationDisconnectRequest } from "redux/irrigationDisconnect";
import {
  plotSensorListSelector,
  getPlotSensorListRequest,
} from "redux/plotSensorList";
import { updateIrrigationSettingsRequest } from "redux/updateIrrigationSettings";
import {
  availableAutopilotLicenceSelector,
  getAvailableAutopilotLicenceRequest,
} from "redux/availableAutopilotLicence";
import {
  plotAutopilotLicenceSelector,
  getPlotAutopilotLicenceRequest,
} from "redux/plotAutopilotLicence";
import AddLicenceIcon from "media/addLicenceIcon.svg";
import WarningIcon from "media/warningIcon.svg";
import PermissionsService from "services/PermissionsService";
import getAppDirection from "utils/appDirection";

import clsx from "clsx";

const AutopilotProviderForm = ({ handleOpenLoginModal }) => {
  const allowedConnectProvider = PermissionsService.isAllowed(
    "AUTOPILOT_CONNECT_PROVIDER"
  );
  const { t } = useTranslation();
  const { providerField, applyBtn } = useStyles();
  const [provider, setProvider] = useState("mottech");
  const handleSelectProvider = (element) => {
    setProvider(element.target.value);
  };
  return (
    <Box style={{ display: "flex", alignItems: "flex-end", marginTop: "47px" }}>
      <AppSelect
        formControlStyles={{
          display: "block",
          flex: "initial",
        }}
        className={providerField}
        label={t("irrigationController")}
        name="provider"
        items={[
          { label: "Mottech", value: "mottech", icon: MottechLogo },
          {
            label: "Talgil",
            value: "talgil",
            icon: TalgilIconSmall,
          },
        ]}
        value={provider}
        disabled={!allowedConnectProvider}
        handleChange={handleSelectProvider}
      />
      <AppBtnFab
        onClick={() => handleOpenLoginModal(provider)}
        className={applyBtn}
        disabled={!allowedConnectProvider}
        label={t("login")}
      />
    </Box>
  );
};
const ConnectControllerForm = ({
  handleConnectController,
  controllerConnected,
  irrigationProviderConnection,
  getConnectionPrograms,
  connectionPrograms,
  irrigationConnect,
  irrigationConnectLoading,
  plotId,
  plotIrrigationConnection,
  irrigationDisconnect,
  getAvailableAutopilotLicence,
  availableAutopilotLicence,
  plotAutopilotLicence,
  getPlotAutopilotLicence,
  handleOpenLoginModal,
  editIrrigationConnection,
  editIrrigationConnectionLoading,
  handleOpenEditServerModal,
  handleOpenReconnectModal,
  clearConnectionPrograms,
}) => {
  const allowedConnectProvider = PermissionsService.isAllowed(
    "AUTOPILOT_CONNECT_PROVIDER"
  );
  const allowedAddAutopilotLicence = PermissionsService.isAllowed(
    "AUTOPILOT_CREATE_LICENCE"
  );
  const { t } = useTranslation();
  const {
    field,
    applyBtn,
    disconnectBtn,
    licenceInput,
    dropdownField,
    saveChangesBtn,
  } = useStyles();
  useEffect(() => {
    getAvailableAutopilotLicence(plotId);
    getPlotAutopilotLicence(plotId);
  }, [plotId]);
  useEffect(() => {
    if (plotAutopilotLicence) {
      setFieldValue("licence", plotAutopilotLicence.id);
    }
  }, [plotAutopilotLicence]);
  useEffect(() => {
    if (plotIrrigationConnection) {
      setFieldValue("controller", plotIrrigationConnection.provider);
      setFieldValue("server", plotIrrigationConnection.providerConnectionId);
      setFieldValue(
        "irrigationProgram",
        plotIrrigationConnection.settings.program.id
      );
      getConnectionPrograms(plotIrrigationConnection.providerConnectionId);
    }
  }, [plotIrrigationConnection]);
  const {
    setFieldValue,
    handleSubmit,
    errors,
    touched,
    values,
    handleChange,
    handleBlur,
    isValid,
  } = useFormik({
    initialValues: {
      controller: "mottech",
      server: "",
      irrigationProgram: "",
      licence: "",
    },
    isInitialValid: false,
    validate: (values) => {
      const errors = {};
      if (!`${values.controller}`.length) {
        errors.controller = "Required";
      }
      if (!`${values.server}`.length) {
        errors.server = "Required";
      }
      if (!`${values.irrigationProgram}`.length) {
        errors.irrigationProgram = "Required";
      }
      if (!`${values.licence}`.length) {
        errors.licence = "Required";
      }
      return errors;
    },
    onSubmit: (values, { setSubmitting }) => {
      const programData = connectionPrograms.find(
        (item) => item.id === values.irrigationProgram
      );
      irrigationConnect({
        plotId,
        providerConnectionId: values.server,
        program: programData,
        licenceId: values.licence,
      });
    },
  });
  const generateServersItems = (controllers) => {
    const items = controllers.map((item) => ({
      label: item.metadata.serverUrl || item.metadata.controllerId,
      value: item.id,
      provider: item.provider,
      actions: [
        {
          label: t("edit"),
          onClick: () => handleOpenEditServerModal(item),
        },
      ],
      icon:
        item.status === "active"
          ? ServerOk
          : item.status === "failed"
          ? ServerNoSignal
          : item.status === "notFound" ||
            (item.status === "pending" && ServerProblem),
    }));
    if (values.controller === "mottech") {
      return items.filter((item) => item.provider === "mottech");
    } else if (values.controller === "talgil") {
      return items.filter((item) => item.provider === "talgil");
    } else {
      return [];
    }
  };
  const generateProgramsList = (programs) => {
    return programs
      .map((item) => ({
        label: item.name,
        value: item.id,
      }))
      .sort((a, b) => {
        if (a.label > b.label) {
          return 1;
        }
        if (a.label < b.label) {
          return -1;
        }
        return 0;
      });
  };
  const generateLicenceList = () => {
    return availableAutopilotLicence.map((item) => ({
      label: `${t("autopilot")}(${item.startDate} - ${item.endDate})`,
      value: item.id,
    }));
  };
  const handleSelectServer = (element) => {
    getConnectionPrograms(element.target.value);
    handleChange(element);
    setFieldValue("irrigationProgram", "");
  };
  const handleSelectProvider = (element) => {
    handleChange(element);
    clearConnectionPrograms();
    setFieldValue("irrigationProgram", "");
    setFieldValue("server", "");
  };
  const handleDisconnectController = () => {
    irrigationDisconnect({ plotId });
  };
  const handleEditController = () => {
    const programData = connectionPrograms.find(
      (item) => item.id === values.irrigationProgram
    );
    editIrrigationConnection({
      plotId,
      providerConnectionId: values.server,
      program: programData,
      licenceId: values.licence,
    });
  };
  const getConnectionError = (serverId) => {
    let error = "";
    const selectedServer = irrigationProviderConnection.find(
      (connection) => connection.id === serverId
    );
    if (
      selectedServer &&
      (selectedServer.status === "failed" ||
        selectedServer.status === "notFound")
    ) {
      error = t("failedConnectionErr");
    } else if (selectedServer && selectedServer.status === "pending") {
      error = t("connectionPending");
    }
    return error;
  };
  const connectionIsOk = (serverId) => {
    let status = true;
    const selectedServer = irrigationProviderConnection.find(
      (connection) => connection.id === serverId
    );
    if (
      selectedServer &&
      (selectedServer.status === "failed" ||
        selectedServer.status === "notFound")
    ) {
      status = false;
    }
    return status;
  };
  const handleRecconect = (serverId) => {
    const selectedServer = irrigationProviderConnection.find(
      (connection) => connection.id === serverId
    );
    if (selectedServer) {
      handleOpenReconnectModal(selectedServer);
    }
  };
  return (
    <form
      style={{ display: "flex", alignItems: "flex-end", marginTop: "47px" }}
      onSubmit={handleSubmit}
    >
      <AppSelect
        className={field}
        label={t("irrigationController")}
        name="controller"
        items={[
          {
            label: "Mottech",
            value: "mottech",
            icon: MottechLogo,
          },
          {
            label: "Talgil",
            value: "talgil",
            icon: TalgilIconSmall,
          },
        ]}
        defaultItem={t("selectController")}
        value={values.controller}
        touched={touched.controller}
        error={errors.controller}
        handleChange={handleSelectProvider}
        handleBlur={handleBlur}
        disabled={!allowedConnectProvider}
      />
      <div className={licenceInput}>
        <DropdownMenu
          className={dropdownField}
          label={t("server")}
          name="server"
          items={generateServersItems(irrigationProviderConnection)}
          defaultItem={t("selectServer")}
          value={values.server}
          touched={touched.server}
          error={errors.server}
          handleChange={handleSelectServer}
          handleBlur={handleBlur}
          disabled={!allowedConnectProvider}
          buttons={[
            {
              icon: AddServerIcon,
              label: t("addServer"),
              onClick: () => {
                handleOpenLoginModal(values.controller);
              },
            },
          ]}
        />
        {getConnectionError(values.server).length > 0 && (
          <div
            style={{
              position: "absolute",
              color: "#EF5350",
              fontWeight: 400,
              fontSize: "12px",
              lineHeight: "140%",
              width: "154px",
              top: "66px",
            }}
          >
            {getConnectionError(values.server)}
          </div>
        )}
      </div>
      <AppSelect
        className={field}
        label={t("irrigationProgram")}
        name="irrigationProgram"
        items={generateProgramsList(connectionPrograms)}
        defaultItem={t("selectProgram")}
        value={values.irrigationProgram}
        touched={touched.irrigationProgram}
        error={errors.irrigationProgram}
        handleChange={handleChange}
        handleBlur={handleBlur}
        disabled={connectionPrograms.length === 0 || !allowedConnectProvider}
      />
      <div className={licenceInput}>
        <AppSelect
          className={field}
          label={t("licence")}
          name="licence"
          defaultItem={
            generateLicenceList().length > 0
              ? t("selectLicence")
              : t("noAvailable")
          }
          items={generateLicenceList()}
          value={values.licence}
          touched={touched.licence}
          error={errors.licence}
          handleChange={handleChange}
          handleBlur={handleBlur}
          disabled={!allowedConnectProvider}
          links={
            generateLicenceList().length === 0
              ? [
                  {
                    label: t("getLicence"),
                    link: allowedAddAutopilotLicence
                      ? "/my-products?addAutopilotLicence=true"
                      : "https://www.viridix.com/contact-us-monday/",
                    icon: AddLicenceIcon,
                    target: "_self",
                  },
                ]
              : []
          }
        />
        {generateLicenceList().length === 0 && (
          <div
            style={{
              position: "absolute",
              color: "#E0920F",
              fontWeight: 400,
              fontSize: "12px",
              lineHeight: "140%",
              width: "154px",
              bottom: "-54px",
            }}
          >
            {t("dontHaveLicenceMsg")}
            <br />
            {t("subscribeToGetLicence")}
          </div>
        )}
      </div>
      {!connectionIsOk(values.server) && (
        <AppBtnFab
          type="button"
          onClick={() => handleRecconect(values.server)}
          btnType="light"
          disabled={!allowedConnectProvider}
          className={disconnectBtn}
          label={t("reconnect")}
        />
      )}
      {!controllerConnected && (
        <AppBtnFab
          type="submit"
          className={applyBtn}
          label={t("connect")}
          disabled={
            irrigationConnectLoading ||
            editIrrigationConnectionLoading ||
            !isValid ||
            !allowedConnectProvider
          }
        />
      )}
      {connectionIsOk(values.server) && controllerConnected && (
        <div>
          <AppBtnFab
            type="button"
            className={saveChangesBtn}
            label={t("updateConnection")}
            onClick={handleEditController}
            disabled={
              irrigationConnectLoading ||
              editIrrigationConnectionLoading ||
              !isValid ||
              !allowedConnectProvider
            }
          />
          <AppBtnFab
            type="button"
            onClick={handleDisconnectController}
            btnType="light"
            disabled={!allowedConnectProvider}
            className={disconnectBtn}
            label={t("disconnect")}
          />
        </div>
      )}
    </form>
  );
};
const IrrigationSettignsForm = ({
  plotSensorList,
  getPlotSensorList,
  plotId,
  updateIrrigationSettings,
  plotIrrigationConnection,
}) => {
  const allowedChangeSettings = PermissionsService.isAllowed(
    "AUTOPILOT_CHANGE_SETTINGS"
  );
  const choosesThreshold = Array.from(Array(250), (x, i) => ({
    value: -(i + 1),
    label: `-${i + 1}`,
  }));
  const { t } = useTranslation();
  const {
    irrigationTypeSelect,
    startIrrigationLabel,
    field,
    divider,
    formBox,
    formBtn,
    irrigationTypeSelectText,
    irrigationSubtitle,
    checkboxLabel,
  } = useStyles();
  useEffect(() => {
    getPlotSensorList(plotId);
  }, [plotId]);
  useEffect(() => {
    if (
      plotIrrigationConnection &&
      plotIrrigationConnection[plotId] &&
      plotIrrigationConnection[plotId].irrigationRules
    ) {
      const stopIrrigationSensor = plotIrrigationConnection[
        plotId
      ].irrigationRules.find((item) => item.irrigationCommand === "stop");
      const startIrrigationSensor = plotIrrigationConnection[
        plotId
      ].irrigationRules.find((item) => item.irrigationCommand === "start");
      if (startIrrigationSensor) {
        setFieldValue(
          "startIrrigationType",
          startIrrigationSensor.irrigationType
        );
        setFieldValue(
          "startNotification",
          startIrrigationSensor.irrigationNotification
        );
        setFieldValue(
          "startIrrigationSensors",
          startIrrigationSensor.sensors.map((sensor) => sensor.sensorId)
        );
        if (
          startIrrigationSensor.irrigationType === "bySensor" &&
          startIrrigationSensor.settings
        ) {
          setFieldValue(
            "startSensorCondition",
            startIrrigationSensor.settings.sensorCondition
          );
          setFieldValue(
            "startIrrigationThreshold",
            startIrrigationSensor.settings.thresholdRange
          );
          setFieldValue(
            "startIrrigationThresholdPeriod",
            startIrrigationSensor.settings.atLeastTime
          );
          if (startIrrigationSensor.settings.groupedBy) {
            setFieldValue(
              "startSensorGroupedBy",
              startIrrigationSensor.settings.groupedBy
            );
          }
        }
      }
      if (stopIrrigationSensor) {
        setFieldValue(
          "stopIrrigationType",
          stopIrrigationSensor.irrigationType
        );
        setFieldValue(
          "stopNotification",
          stopIrrigationSensor.irrigationNotification
        );
        setFieldValue(
          "stopIrrigationSensors",
          stopIrrigationSensor.sensors.map((sensor) => sensor.sensorId)
        );
        if (
          stopIrrigationSensor.irrigationType === "bySensor" &&
          stopIrrigationSensor.settings
        ) {
          setFieldValue(
            "stopSensorCondition",
            stopIrrigationSensor.settings.sensorCondition
          );
          setFieldValue(
            "stopIrrigationThreshold",
            stopIrrigationSensor.settings.thresholdRange
          );
          setFieldValue(
            "stopIrrigationThresholdPeriod",
            stopIrrigationSensor.settings.atLeastTime
          );
          if (stopIrrigationSensor.settings.groupedBy) {
            setFieldValue(
              "stopSensorGroupedBy",
              stopIrrigationSensor.settings.groupedBy
            );
          }
        }
      }
    }
  }, [plotIrrigationConnection]);
  const {
    setFieldValue,
    handleSubmit,
    errors,
    touched,
    values,
    handleChange,
    handleBlur,
    isValid,
    resetForm,
  } = useFormik({
    initialValues: {
      startIrrigationType: "byProgram",
      startIrrigationSensors: [],
      startSensorGroupedBy: "average",
      startSensorCondition: "lte",
      startIrrigationThreshold: "-50",
      startIrrigationThresholdPeriod: 15,
      stopIrrigationType: "byProgram",
      stopIrrigationSensors: [],
      stopSensorGroupedBy: "average",
      stopSensorCondition: "gte",
      stopIrrigationThreshold: "-10",
      stopIrrigationThresholdPeriod: 15,
      stopNotification: true,
      startNotification: true,
    },
    isInitialValid: false,
    validate: (values) => {
      const errors = {};
      if (!values.startIrrigationSensors.length) {
        errors.startIrrigationSensors = "Required";
      }
      if (!values.stopIrrigationSensors.length) {
        errors.stopIrrigationSensors = "Required";
      }
      return errors;
    },
    onSubmit: (values, { setSubmitting }) => {
      const startSensors = plotSensorList.filter(
        (sensor) => values.startIrrigationSensors.indexOf(sensor.id) > -1
      );
      const stopSensors = plotSensorList.filter(
        (sensor) => values.stopIrrigationSensors.indexOf(sensor.id) > -1
      );

      updateIrrigationSettings({
        plotId,
        irrigationRules: [
          {
            irrigationCommand: "start",
            irrigationType: values.startIrrigationType,
            sensors: startSensors.map((sensor) => ({
              sensorId: sensor.id,
              deviceId: sensor.deviceId,
            })),
            irrigationNotification: values.startNotification,
            settings:
              values.startIrrigationType === "bySensor"
                ? {
                    sensorCondition: values.startSensorCondition,
                    thresholdRange: +values.startIrrigationThreshold,
                    atLeastTime: values.startIrrigationThresholdPeriod,
                    groupedBy:
                      values.startIrrigationSensors.length > 1
                        ? values.startSensorGroupedBy
                        : null,
                  }
                : null,
          },
          {
            irrigationCommand: "stop",
            irrigationType: values.stopIrrigationType,
            sensors: stopSensors.map((sensor) => ({
              sensorId: sensor.id,
              deviceId: sensor.deviceId,
            })),
            irrigationNotification: values.stopNotification,
            settings:
              values.stopIrrigationType === "bySensor"
                ? {
                    sensorCondition: values.stopSensorCondition,
                    thresholdRange: +values.stopIrrigationThreshold,
                    atLeastTime: values.stopIrrigationThresholdPeriod,
                    groupedBy:
                      values.stopIrrigationSensors.length > 1
                        ? values.stopSensorGroupedBy
                        : null,
                  }
                : null,
          },
        ],
      });
    },
  });
  const buildSensorsList = (data) => {
    return data.map((item) => ({
      label: item.sensorName,
      value: item.id,
    }));
  };
  const buildPeriods = () => {
    const start = 5;
    const end = 300;
    const step = 5;
    const arrayLength = Math.floor((end - start) / step) + 1;
    const range = [...Array(arrayLength).keys()].map((x) => ({
      value: x * step + start,
      label: `${x * step + start}${t("minute")}`,
    }));
    return range;
  };

  return (
    <form style={{ marginTop: "47px" }} onSubmit={handleSubmit}>
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          style={{ display: "flex", alignItems: "center", marginTop: "16px" }}
        >
          <img src={StartIrrigationIcon} alt="Start" />
          <Box>
            <Box className={startIrrigationLabel}>{t("startIrrigation")}</Box>
            <Box className={irrigationSubtitle}>
              {t("startIrrigationSybtitle")}
            </Box>
          </Box>
        </Box>
        <Box className={irrigationTypeSelect}>
          <AppSelect
            className={irrigationTypeSelectText}
            name="startIrrigationType"
            items={[
              { label: t("program"), value: "byProgram" },
              { label: t("rootense"), value: "bySensor" },
            ]}
            disabled={!allowedChangeSettings}
            value={values.startIrrigationType}
            touched={touched.startIrrigationType}
            error={errors.startIrrigationType}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />
        </Box>
      </Box>
      {values.startIrrigationType === "byProgram" ? (
        <Box className={formBox}>
          <AppMultipleSelect
            label={t("selectStartSensorByProgram")}
            className={field}
            name="startIrrigationSensors"
            items={buildSensorsList(plotSensorList)}
            disabled={!allowedChangeSettings}
            value={values.startIrrigationSensors}
            touched={touched.startIrrigationSensors}
            error={errors.startIrrigationSensors}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />
        </Box>
      ) : (
        <Box className={formBox}>
          <AppMultipleSelect
            label={t("selectSensorInputLabel")}
            className={field}
            formControlStyles={{ flex: 2 }}
            name="startIrrigationSensors"
            items={buildSensorsList(plotSensorList)}
            disabled={!allowedChangeSettings}
            value={values.startIrrigationSensors}
            touched={touched.startIrrigationSensors}
            error={errors.startIrrigationSensors}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />
          {values.startIrrigationSensors.length > 1 && (
            <AppSelect
              label={t("groupedBy")}
              className={field}
              name="startSensorGroupedBy"
              items={[
                {
                  label: t("average"),
                  value: "average",
                },
                {
                  label: t("median"),
                  value: "median",
                },
              ]}
              disabled={!allowedChangeSettings}
              value={values.startSensorGroupedBy}
              touched={touched.startSensorGroupedBy}
              error={errors.startSensorGroupedBy}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
          )}
          <AppSelect
            label={t("startOperatorInputLabel")}
            className={field}
            name="startSensorCondition"
            items={[
              {
                label: getAppDirection() === "rtl" ? ">=" : "<=",
                value: "lte",
              },
            ]}
            disabled={!allowedChangeSettings}
            value={values.startSensorCondition}
            touched={touched.startSensorCondition}
            error={errors.startSensorCondition}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />
          <AppSelect
            label={t("threshold")}
            className={field}
            name="startIrrigationThreshold"
            items={choosesThreshold}
            disabled={!allowedChangeSettings}
            value={values.startIrrigationThreshold}
            touched={touched.startIrrigationThreshold}
            error={errors.startIrrigationThreshold}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />
          <AppSelect
            label={t("periodInputLabel")}
            className={field}
            name="startIrrigationThresholdPeriod"
            items={buildPeriods()}
            disabled={!allowedChangeSettings}
            value={values.startIrrigationThresholdPeriod}
            touched={touched.startIrrigationThresholdPeriod}
            error={errors.startIrrigationThresholdPeriod}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />
        </Box>
      )}
      <FormControlLabel
        control={
          <Checkbox
            checked={values.startNotification}
            onChange={handleChange}
            name="startNotification"
            color="primary"
          />
        }
        className={checkboxLabel}
        label={t("startIrrigationNotification")}
      />
      <Box className={divider} />
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          style={{ display: "flex", alignItems: "center", marginTop: "16px" }}
        >
          <img src={StopIrrigationIcon} alt="Stop" />
          <Box>
            <Box className={startIrrigationLabel}>{t("stopIrrigation")}</Box>
            <Box className={irrigationSubtitle}>
              {t("stopIrrigationSybtitle")}
            </Box>
          </Box>
        </Box>
        <Box className={irrigationTypeSelect}>
          <AppSelect
            className={irrigationTypeSelectText}
            name="stopIrrigationType"
            items={[
              { label: t("program"), value: "byProgram" },
              { label: t("rootense"), value: "bySensor" },
            ]}
            disabled={!allowedChangeSettings}
            value={values.stopIrrigationType}
            touched={touched.stopIrrigationType}
            error={errors.stopIrrigationType}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />
        </Box>
      </Box>
      {values.stopIrrigationType === "bySensor" ? (
        <Box className={formBox}>
          <AppMultipleSelect
            label={t("selectSensorInputLabel")}
            className={field}
            formControlStyles={{ flex: 2 }}
            name="stopIrrigationSensors"
            items={buildSensorsList(plotSensorList)}
            disabled={!allowedChangeSettings}
            value={values.stopIrrigationSensors}
            touched={touched.stopIrrigationSensors}
            error={errors.stopIrrigationSensors}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />
          {values.stopIrrigationSensors.length > 1 && (
            <AppSelect
              label={t("groupedBy")}
              className={field}
              name="stopSensorGroupedBy"
              items={[
                {
                  label: t("average"),
                  value: "average",
                },
                {
                  label: t("median"),
                  value: "median",
                },
              ]}
              disabled={!allowedChangeSettings}
              value={values.stopSensorGroupedBy}
              touched={touched.stopSensorGroupedBy}
              error={errors.stopSensorGroupedBy}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
          )}
          <AppSelect
            label={t("operatorInputLabel")}
            className={field}
            name="stopSensorCondition"
            items={[
              {
                label: getAppDirection() === "rtl" ? "<=" : ">=",
                value: "gte",
              },
            ]}
            disabled={!allowedChangeSettings}
            value={values.stopSensorCondition}
            touched={touched.stopSensorCondition}
            error={errors.stopSensorCondition}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />
          <AppSelect
            label={t("threshold")}
            className={field}
            name="stopIrrigationThreshold"
            items={choosesThreshold}
            disabled={!allowedChangeSettings}
            value={values.stopIrrigationThreshold}
            touched={touched.stopIrrigationThreshold}
            error={errors.stopIrrigationThreshold}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />
          <AppSelect
            label={t("periodInputLabel")}
            className={field}
            name="stopIrrigationThresholdPeriod"
            items={buildPeriods()}
            disabled={!allowedChangeSettings}
            value={values.stopIrrigationThresholdPeriod}
            touched={touched.stopIrrigationThresholdPeriod}
            error={errors.stopIrrigationThresholdPeriod}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />
        </Box>
      ) : (
        <Box className={formBox}>
          <AppMultipleSelect
            label={t("selectSensorByProgram")}
            className={field}
            name="stopIrrigationSensors"
            items={buildSensorsList(plotSensorList)}
            disabled={!allowedChangeSettings}
            value={values.stopIrrigationSensors}
            touched={touched.stopIrrigationSensors}
            error={errors.stopIrrigationSensors}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />
        </Box>
      )}
      <FormControlLabel
        control={
          <Checkbox
            checked={values.stopNotification}
            onChange={handleChange}
            name="stopNotification"
            color="primary"
          />
        }
        className={checkboxLabel}
        label={t("stopIrrigationNotification")}
      />
      <Box style={{ display: "flex", marginBottom: "50px", marginTop: "29px" }}>
        <AppBtnFab
          className={formBtn}
          disabled={!isValid || !allowedChangeSettings}
          type="submit"
          label={t("save")}
        />
        <AppBtnFab
          disabled={!allowedChangeSettings}
          className={formBtn}
          btnType="light"
          onClick={resetForm}
          label={t("cancel")}
        />
      </Box>
    </form>
  );
};

const IrrigationTab = ({
  selectedPlot,
  irrigationProviderConnection,
  getIrrigationProviderConnection,
  irrigationConnectionProviderLoading,
  getConnectionPrograms,
  connectionPrograms,
  irrigationConnect,
  irrigationConnectLoading,
  getPlotIrrigationConnection,
  plotIrrigationConnection,
  irrigationDisconnect,
  plotSensorList,
  getPlotSensorList,
  updateIrrigationSettings,
  getAvailableAutopilotLicence,
  availableAutopilotLicence,
  plotAutopilotLicence,
  getPlotAutopilotLicence,
  editIrrigationConnectionLoading,
  editIrrigationConnection,
  clearConnectionPrograms,
}) => {
  const { t } = useTranslation();
  const [mottechLoginModalOpen, setMottechLoginModalOpen] = useState(false);
  const [talgilLoginModalOpen, setTalgilLoginModalOpen] = useState(false);
  const [mottechEditServerModalOpen, setMottechServerEditModalOpen] =
    useState(false);
  const [talgilServerEditModalOpen, setTalgilServerEditModalOpen] =
    useState(false);
  const [editServerWarningModalOpen, setServerEditWarningModalOpen] =
    useState(false);
  const [disconnectServerWarningOpen, setDisconnectServerWarningOpen] =
    useState(false);
  const [controllerLoggedIn, setControllerLoggedIn] = useState(false);
  const [controllerConnected, setControllerConnected] = useState(false);
  const [editServerData, setEditServerData] = useState(null);
  const [isReconnect, setIsReconnect] = useState(false);
  const {
    generalTabContainer,
    tabHeaderTitle,
    editServerBtn,
    warningContainer,
    warningTitle,
    warningMsg,
    btnsContainer,
    continueBtn,
  } = useStyles();
  useEffect(() => {
    if (selectedPlot && selectedPlot.plotId) {
      getIrrigationProviderConnection();
    }
  }, [selectedPlot]);
  useEffect(() => {
    if (
      selectedPlot &&
      irrigationProviderConnection &&
      irrigationProviderConnection.length > 0
    ) {
      setControllerLoggedIn(true);
      getPlotIrrigationConnection(selectedPlot.plotId);
    }
  }, [irrigationProviderConnection]);
  useEffect(() => {
    if (
      selectedPlot &&
      selectedPlot.plotId &&
      plotIrrigationConnection &&
      plotIrrigationConnection[selectedPlot.plotId]
    ) {
      setControllerConnected(true);
    } else {
      setControllerConnected(false);
    }
  }, [plotIrrigationConnection]);
  const handleConnectController = () => {
    setControllerConnected(true);
  };
  const handleOpenLoginModal = (provider) => {
    if (provider === "mottech") {
      setMottechLoginModalOpen(true);
    } else if (provider === "talgil") {
      setTalgilLoginModalOpen(true);
    }
  };
  const handleCloseMottechLoginModal = () => {
    setMottechLoginModalOpen(false);
  };
  const handleCloseTalgilLoginModal = () => {
    setTalgilLoginModalOpen(false);
  };
  const handleOpenEditServerModal = (serverData) => {
    setEditServerData(serverData);
    setServerEditWarningModalOpen(true);
  };
  const handleCloseMottechEditServerModal = () => {
    setMottechServerEditModalOpen(false);
  };
  const handleCloseTalgilEditServerModal = () => {
    setTalgilServerEditModalOpen(false);
  };
  const handleCloseEditServerWarning = () => {
    setEditServerData(null);
    setServerEditWarningModalOpen(false);
  };
  const handleCloseDisconnectServerWarning = () => {
    setDisconnectServerWarningOpen(false);
  };
  const handleApproveEditServer = () => {
    if (editServerData.provider === "mottech") {
      setMottechServerEditModalOpen(true);
      setServerEditWarningModalOpen(false);
    } else if (editServerData.provider === "talgil") {
      setTalgilServerEditModalOpen(true);
      setServerEditWarningModalOpen(false);
    }
  };
  const handleOpenReconnectModal = (data) => {
    setEditServerData(data);
    setIsReconnect(true);
    if (data.provider === "mottech") {
      setMottechServerEditModalOpen(true);
    }
  };
  const handleOpenDisconnectModal = () => {
    setDisconnectServerWarningOpen(true);
  };
  const handleApproveDisconnectServer = () => {
    irrigationDisconnect({ plotId: selectedPlot.plotId });
    setDisconnectServerWarningOpen(false);
  };
  if (selectedPlot && !irrigationConnectionProviderLoading) {
    return (
      <Box className={generalTabContainer}>
        <Box className={tabHeaderTitle}>{selectedPlot.plot}</Box>
        {!controllerLoggedIn && (
          <AutopilotProviderForm handleOpenLoginModal={handleOpenLoginModal} />
        )}
        {controllerLoggedIn && (
          <ConnectControllerForm
            handleConnectController={handleConnectController}
            controllerConnected={controllerConnected}
            irrigationProviderConnection={irrigationProviderConnection}
            getConnectionPrograms={getConnectionPrograms}
            connectionPrograms={connectionPrograms}
            irrigationConnect={irrigationConnect}
            irrigationConnectLoading={irrigationConnectLoading}
            editIrrigationConnectionLoading={editIrrigationConnectionLoading}
            editIrrigationConnection={editIrrigationConnection}
            plotIrrigationConnection={
              plotIrrigationConnection[selectedPlot.plotId]
            }
            plotId={selectedPlot.plotId}
            irrigationDisconnect={handleOpenDisconnectModal}
            getAvailableAutopilotLicence={getAvailableAutopilotLicence}
            availableAutopilotLicence={availableAutopilotLicence}
            plotAutopilotLicence={plotAutopilotLicence}
            getPlotAutopilotLicence={getPlotAutopilotLicence}
            handleOpenLoginModal={handleOpenLoginModal}
            handleOpenEditServerModal={handleOpenEditServerModal}
            handleOpenReconnectModal={handleOpenReconnectModal}
            clearConnectionPrograms={clearConnectionPrograms}
          />
        )}
        {controllerConnected && (
          <IrrigationSettignsForm
            plotSensorList={plotSensorList}
            getPlotSensorList={getPlotSensorList}
            plotId={selectedPlot.plotId}
            updateIrrigationSettings={updateIrrigationSettings}
            plotIrrigationConnection={plotIrrigationConnection}
          />
        )}
        <MottechLoginModal
          plotId={selectedPlot.plotId}
          open={mottechLoginModalOpen}
          handleClose={handleCloseMottechLoginModal}
        />
        <TalgilLoginModal
          plotId={selectedPlot.plotId}
          open={talgilLoginModalOpen}
          handleClose={handleCloseTalgilLoginModal}
        />
        <MottechServerEditModal
          editServerData={editServerData}
          open={mottechEditServerModalOpen}
          isReconnect={isReconnect}
          handleClose={handleCloseMottechEditServerModal}
        />
        <TalgilServerEditModal
          editServerData={editServerData}
          open={talgilServerEditModalOpen}
          isReconnect={isReconnect}
          handleClose={handleCloseTalgilEditServerModal}
        />
        <Dialog
          open={editServerWarningModalOpen}
          onClose={handleCloseEditServerWarning}
        >
          <Box className={warningContainer}>
            <img
              src={WarningIcon}
              style={{ width: "50px", marginBottom: "15px" }}
              alt="WarningIcon"
            />
            <Box className={warningTitle}>{t("editServerWarningTitle")}</Box>
            <Box className={warningMsg}>{t("editServerWarningMsg")}</Box>
            <Box className={btnsContainer}>
              <AppBtnFab
                className={editServerBtn}
                label={t("cancel")}
                onClick={handleCloseEditServerWarning}
              />
              <AppBtnFab
                onClick={handleApproveEditServer}
                btnType="light"
                className={clsx(editServerBtn, continueBtn)}
                label={t("continue")}
              />
            </Box>
          </Box>
        </Dialog>
        <Dialog
          open={disconnectServerWarningOpen}
          onClose={handleCloseDisconnectServerWarning}
        >
          <Box className={warningContainer}>
            <Box className={warningTitle}>
              {t("disconnectServerWarningTitle")}
            </Box>
            <Box className={warningMsg}>{t("disconnectServerWarningMsg")}</Box>
            <Box className={btnsContainer}>
              <AppBtnFab
                className={editServerBtn}
                label={t("cancel")}
                onClick={handleCloseDisconnectServerWarning}
              />
              <AppBtnFab
                onClick={handleApproveDisconnectServer}
                btnType="light"
                className={clsx(editServerBtn, continueBtn)}
                label={t("disconnect")}
              />
            </Box>
          </Box>
        </Dialog>
      </Box>
    );
  } else {
    return (
      <Box style={{ width: "100%", height: "300px" }}>
        <AppLoader />
      </Box>
    );
  }
};

const mapStateToProps = (state) => ({
  irrigationProviderConnection: irrigationProviderConnectionSelector(state),
  irrigationConnectionProviderLoading:
    irrigationConnectionProviderLoading(state),
  connectionPrograms: connectionProgramsSelector(state),
  irrigationConnectLoading: irrigationConnectLoading(state),
  plotIrrigationConnection: plotIrrigationConnectionSelector(state),
  availableAutopilotLicence: availableAutopilotLicenceSelector(state),
  plotAutopilotLicence: plotAutopilotLicenceSelector(state),
  editIrrigationConnectionLoading: editIrrigationConnectionLoading(state),
  plotSensorList: plotSensorListSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  getIrrigationProviderConnection: bindActionCreators(
    getIrrigationProviderConnectionRequest,
    dispatch
  ),
  getConnectionPrograms: bindActionCreators(
    getConnectionProgramsRequest,
    dispatch
  ),
  irrigationConnect: bindActionCreators(irrigationConnectRequest, dispatch),
  getPlotIrrigationConnection: bindActionCreators(
    getPlotIrrigationConnectionRequest,
    dispatch
  ),
  irrigationDisconnect: bindActionCreators(
    irrigationDisconnectRequest,
    dispatch
  ),
  getPlotSensorList: bindActionCreators(getPlotSensorListRequest, dispatch),
  updateIrrigationSettings: bindActionCreators(
    updateIrrigationSettingsRequest,
    dispatch
  ),
  getAvailableAutopilotLicence: bindActionCreators(
    getAvailableAutopilotLicenceRequest,
    dispatch
  ),
  getPlotAutopilotLicence: bindActionCreators(
    getPlotAutopilotLicenceRequest,
    dispatch
  ),
  editIrrigationConnection: bindActionCreators(
    editIrrigationConnectionRequest,
    dispatch
  ),
  clearConnectionPrograms: bindActionCreators(
    clearConnectionPrograms,
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(IrrigationTab);
