import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  container: {
    padding: "31px",
  },
  content: {
    overflow: "auto",
    display: "flex",
    backgroundColor: "white",
    borderRadius: "8px",
    height: "calc(100% - 47px)",
    width: "100%",
  },
  bottomContainer: {
    position: "absolute",
    bottom: "20px",
    left: "calc(50% - 200px)",
    width: "338px",
    height: "64px",
    background: "#4A4B54",
    borderRadius: "8px",
    padding: "0 32px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  plotNameStyle: {
    fontSize: "14px",
    lineHeight: "150%",
    color: "#FFF",
    fontWeight: "300",
  },
  plotAreaStyle: {
    fontWeight: "bold",
    fontSize: "18px",
    lineHeight: "150%",
    color: "#FFF",
  },
  editBtn: {
    width: "200px",
    height: "40px",
    fontSize: "14px",
    backgroundColor: "#70B7E1",
    borderRadius: "28px",
    color: "#fff",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#70B7E1",
      opacity: 0.7,
    },
  },
  cancelBtn: {
    width: "82px",
    height: "40px",
    fontSize: "14px",
    backgroundColor: "#fff",
    borderRadius: "28px",
    color: "#70B7E1",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#fff",
      opacity: 0.7,
    },
  },
  saveBtn: {
    width: "82px",
    height: "40px",
    fontSize: "14px",
    backgroundColor: "#91B54D",
    borderRadius: "28px",
    color: "#fff",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#91B54D",
      opacity: 0.7,
    },
  },
  generalTabContainer: {
    padding: "40px 30px",
    borderLeft: "1px solid #EBEDF3",
  },
  tabHeaderTitle: {
    fontWeight: "bold",
    fontSize: "24px",
    lineHeight: "28px",
    color: "#4A4B54",
  },
  inputRow: {
    display: "flex",
    marginTop: "51px",
  },
  inputContainer: {
    flex: 1,
  },
  licenceInput: {
    display: "inline-flex",
    flex: "1 1 0%",
    position: "relative",
    minWidth: 0,
  },
  dropdownField: {
    display: "inline-flex",
    flex: "1 1 0%",
    position: "relative",
    minWidth: 0,
    marginInlineEnd: "10px",
  },
  secondInputContainer: {
    flex: 1,
    paddingInlineStart: "40px",
  },
  btnContainer: {
    width: "89px",
    marginTop: "43px",
    height: "40px",
  },
  submitBtn: {
    width: "100%",
    height: "100%",
  },
  backBtn: {
    width: "40px",
    height: "40px",
    borderRadius: "4px",
    backgroundColor: "#FFFFFF",
  },
  backRow: {
    marginBottom: "18px",
    display: "flex",
    alignItems: "center",
  },
  backTitle: {
    fontSize: "24px",
    lineHeight: "24px",
    color: "#4A4B54",
    paddingInlineStart: "16px",
  },
  leftSideMenu: {
    width: "300px",
    height: "100%",
  },
  tabsContainer: {
    display: "flex",
    flexDirection: "row",
  },
  tabBtn: {
    width: "150px",
    height: "43px",
    border: "1px solid #EBEDF3",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    cursor: "pointer",
  },
  field: {
    marginInlineEnd: "10px",
  },
  applyBtn: {
    width: "134px",
    height: "40px",
  },
  disconnectBtn: {
    width: "134px",
    height: "40px",
    color: "#DA5062",
  },
  irrigationTypeSelect: {
    width: "174px",
    flex: "initial",
    "& > div": {
      marginTop: "0px !important",
    },
  },
  startIrrigationLabel: {
    fontWeight: "bold",
    fontSize: "18px",
    marginInlineStart: "10px",
  },
  irrigationSubtitle: {
    fontSize: "11px",
    marginInlineStart: "10px",
  },
  divider: {
    height: "1px",
    backgroundColor: "#EBEDF3",
    width: "100%",
    marginTop: "30px",
    marginBottom: "20px",
  },
  checkboxLabel: {
    fontSize: "16px",
    color: "#4A4B54",
    marginTop: "29px"
  },
  formBox: {
    padding: "24px",
    backgroundColor: "#F7F8FC",
    width: "calc(100% - 48px)",
    borderRadius: "8px",
    marginTop: "38px",
    display: "flex",
  },
  formBtn: { width: "180px", height: "40px", marginInlineEnd: "16px" },
  providerField: {
    marginInlineEnd: "10px",
    width: "200px",
  },
  controllingByProgramMsg: {
    fontSize: "14px",
    color: "#828B93",
  },
  programLabel: {
    fontWeight: "bold",
    fontSize: "14px",
    color: "#828B93",
    borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
    paddingBottom: "3px",
    lineHeight: "150%",
  },
  irrigationTypeSelectText: {
    fontWeight: "bold",
    fontSize: "14px",
    color: "#70B7E1",
  },
  saveChangesBtn: {
    height: "40px",
    marginInlineEnd: "10px",
  },
  editServerBtn: {
    width: "155px",
    height: "50px",
    margin: "0 10px",
  },
  continueBtn: {
    color: "#DA5062"
  },
  btnsContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
  },
  warningMsg: {
    fontWeight: "400",
    fontSize: "14px",
    color: "#828B93",
    marginBottom: "24px",
    textAlign: "center",
  },
  warningTitle: {
    fontWeight: "700",
    fontSize: "24px",
    color: "#4A4B54",
    marginBottom: "15px",
  },
  warningContainer: {
    padding: "54px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));
