import React, { useEffect, useState } from "react";
import moment from "moment";
import ReactEcharts from "echarts-for-react";
import { useTranslation } from "react-i18next";
import { AppLoader } from "components/commons";
import _ from "lodash";

const SEVERITY_LEVEL_COLOR = {
  0: "rgba(168, 216, 244, 0.1)",
  1: "rgba(168, 216, 244, 0.1)",
  2: "rgba(244, 166, 35, 0.1)",
  3: "rgba(244, 166, 35, 0.1)",
  4: "rgba(218, 80, 98, 0.1)",
  5: "rgba(218, 80, 98, 0.1)",
};
const SEVERITY_LEVEL_COLOR_LABEL = {
  0: "rgba(168, 216, 244, 1)",
  1: "rgba(168, 216, 244, 1)",
  2: "rgba(244, 166, 35, 1)",
  3: "rgba(244, 166, 35, 1)",
  4: "rgba(218, 80, 98, 1)",
  5: "rgba(218, 80, 98, 1)",
};
const SENSOR_TO_DEPTH = {
  "rt1-cent": "depth1",
  "rt2-cent": "depth2",
  "rt3-cent": "depth3",
};

const UNITS_BY_SERIES_INDEX_MOTTECH = {
  0: "cb",
  1: "cb",
  2: "cb",
  4: "mm",
  5: "mm",
};
const UNITS_BY_SERIES_INDEX_TALGIL = {
  0: "cb",
  1: "cb",
  2: "cb",
  4: "mm",
  5: "㎥",
};

const getPrevValue = (rt, index, array) => {
  if (index === 0) {
    return 0;
  }
  if (array[index - 1][rt] === null) {
    return getPrevValue(rt, index - 1, array);
  } else {
    return array[index - 1][rt];
  }
};

const normalizeData = (data) => {
  return data.reduce(
    (acc, [ts, rt1, rt2, rt3, rain, irrigation], index, array) => [
      ...acc,
      [
        ts,
        rt1 === null ? getPrevValue(1, index, array) : rt1,
        rt2 === null ? getPrevValue(2, index, array) : rt2,
        rt3 === null ? getPrevValue(3, index, array) : rt3,
        rain === undefined ? 0 : rain,
        irrigation === undefined ? 0 : irrigation,
      ],
    ],
    []
  );
};

const ThreeRootense = ({
  daterange,
  depths,
  irrigationHistory,
  eventsData,
  wpData,
  sensors,
  isForInsight,
  pairedDate,
}) => {
  const { t } = useTranslation();
  const [chartData, setData] = useState();
  useEffect(() => {
    let data = [];
    let irrigationData = [];
    let rainData = [];
    if (irrigationHistory) {
      const precision = 6 * 60 * 60 * 1000;
      const groupedWeatherBy6Hour = _.groupBy(
        irrigationHistory.weather,
        (item) =>
          moment(Math.floor(item.time / precision) * precision).valueOf()
      );
      rainData = Object.keys(groupedWeatherBy6Hour).map((key) => {
        return [
          +key,
          undefined,
          undefined,
          undefined,
          +groupedWeatherBy6Hour[key]
            .reduce((acc, value) => {
              return acc + value.precipitation;
            }, 0)
            .toFixed(2),
          0,
          undefined,
        ];
      });
      if (irrigationHistory.irrigation) {
        irrigationData = irrigationHistory.irrigation.values.map((item) => [
          item[0],
          undefined,
          undefined,
          undefined,
          undefined,
          Number(item[1]).toFixed(2),
        ]);
      }
    }
    if (wpData) {
      data = [
        ...rainData,
        ...irrigationData,
        ...wpData.data.map((item) => [
          item[0],
          item[1],
          item[2],
          item[3],
          undefined,
          undefined,
        ]),
      ];
    }
    setData(normalizeData(data));
  }, [irrigationHistory, wpData]);
  if (chartData && eventsData && wpData) {
    return (
      <ReactEcharts
        style={{ height: "100%" }}
        option={{
          tooltip: {
            trigger: "axis",
            appendToBody: true,
            formatter: (data) => {
              if (!data.length) {
                data = [data];
              }
              let tooltip = `${moment(data[0].value[data[0].encode.x]).format(
                "DD-MM YYYY HH:mm"
              )} <br/>`;
              data.forEach(
                ({ value, marker, seriesName, seriesIndex, encode: { y } }) => {
                  tooltip += `<div style="display: flex; align-items: center; direction: ltr;">${marker}<div style="padding-inline-end: 5px">${seriesName}:</div><div style="direction: ltr">${
                    value[y] === null || value[y] === undefined ? "-" : value[y]
                  }${
                    irrigationHistory &&
                    irrigationHistory.irrigation &&
                    irrigationHistory.irrigation.columns.includes(
                      "irrigationVolume"
                    )
                      ? UNITS_BY_SERIES_INDEX_TALGIL[seriesIndex]
                      : UNITS_BY_SERIES_INDEX_MOTTECH[seriesIndex]
                  }</div></div>`;
                }
              );
              return tooltip;
            },
          },
          axisLabel: { hideOverlap: true },
          calculable: true,
          color: [
            "#FF3B30",
            "#70b8e1",
            "#F3B701",
            "rgba(232, 235, 189, 1)",
            "rgba(199, 205, 240, 1)",
          ],
          legend: {},
          grid: {
            top: "50",
            left: "50",
            right: "50",
            show: true,
          },
          toolbox: {
            show: true,
            feature: {
              mark: { show: true },
              dataZoom: {
                show: true,
                title: { zoom: t("zoomTooltip"), back: t("back") },
              },
              restore: { show: true, title: t("restore") },
              saveAsImage: { show: true, title: t("saveAsImage") },
            },
          },
          xAxis: {
            type: "time",
            boundaryGap: false,
            min: daterange[0].valueOf(),
            max: daterange[1].valueOf(),
            axisLabel: {
              formatter: function (value) {
                return moment(value).format("DD-MM YYYY");
              },
            },
          },
          yAxis: [
            {
              type: "value",
              position: "right",
              min: (value) => {
                if (
                  wpData.lastValues.threshold &&
                  wpData.lastValues.threshold < value.min
                ) {
                  return wpData.lastValues.threshold - 5;
                }
                return value.min - 5;
              },
              max: 0,
              name: t("waterPotentialYAxisTitle"),
              nameLocation: "center",
              nameTextStyle: {
                color: "#828B93",
                fontSize: "12px",
              },
              nameGap: 30,
            },
            {
              type: "value",
              show: false,
              min: 0.5,
            },
            {
              type: "value",
              show: false,
              min: 0.5,
            },
          ],
          dataset: {
            source: chartData.sort((a, b) => b[0] - a[0]),
            dimensions: [
              "timestamp",
              "rt1-cent",
              "rt2-cent",
              "rt3-cent",
              t("rain"),
              t("irrigation"),
            ],
          },
          series: [
            {
              name: `(1)${
                depths ? depths[SENSOR_TO_DEPTH["rt1-cent"]] : "-"
              }cm`,
              type: "line",
              symbol: "none",
              connectNulls: true,
              sampling: "min",
              encode: {
                x: "timestamp",
                y: "rt1-cent",
              },
              markLine: {
                data: wpData.lastValues.threshold && [
                  {
                    name: t("threshold"),
                    yAxis: wpData.lastValues.threshold,
                    lineStyle: {
                      normal: {
                        color: "#FF3B30",
                      },
                    },
                    label: {
                      position: "insideEndTop",
                    },
                  },
                ],
              },
              markArea: {
                data: [
                  [
                    {
                      name: t("installation"),
                      xAxis: moment(pairedDate).valueOf(),
                      itemStyle: {
                        color: "#91B54D",
                      },
                      label: {
                        show: false,
                        color: "#91B54D",
                      },
                      emphasis: {
                        label: {
                          show: true,
                        },
                      },
                    },
                    {
                      xAxis: moment(pairedDate).add(30, "minutes").valueOf(),
                    },
                  ],
                  ...eventsData.alertEvents.map((alertEvent) => {
                    return [
                      {
                        name: t(alertEvent.status),
                        xAxis: alertEvent.createdAt,
                        itemStyle: {
                          color: SEVERITY_LEVEL_COLOR[alertEvent.level],
                        },
                        label: {
                          show: false,
                          color: SEVERITY_LEVEL_COLOR_LABEL[alertEvent.level],
                        },
                        emphasis: {
                          label: {
                            show: true,
                          },
                        },
                      },
                      {
                        xAxis: alertEvent.resolvedOn,
                      },
                    ];
                  }),
                ],
              },
            },
            {
              name: `(2)${
                depths ? depths[SENSOR_TO_DEPTH["rt2-cent"]] : "-"
              }cm`,
              type: "line",
              symbol: "none",
              connectNulls: true,
              sampling: "min",
              encode: {
                x: "timestamp",
                y: "rt2-cent",
              },
              markLine: {
                data: wpData.lastValues.threshold && [
                  {
                    name: t("threshold"),
                    yAxis: wpData.lastValues.threshold,
                    lineStyle: {
                      normal: {
                        color: "#FF3B30",
                      },
                    },
                    label: {
                      position: "insideEndTop",
                    },
                  },
                ],
              },
              markArea: {
                data: eventsData.alertEvents.map((alertEvent) => {
                  return [
                    {
                      name: t(alertEvent.status),
                      xAxis: alertEvent.createdAt,
                      itemStyle: {
                        color: SEVERITY_LEVEL_COLOR[alertEvent.level],
                      },
                      label: {
                        show: false,
                        color: SEVERITY_LEVEL_COLOR_LABEL[alertEvent.level],
                      },
                      emphasis: {
                        label: {
                          show: true,
                        },
                      },
                    },
                    {
                      xAxis: alertEvent.resolvedOn,
                    },
                  ];
                }),
              },
            },
            {
              name: `(3)${
                depths ? depths[SENSOR_TO_DEPTH["rt3-cent"]] : "-"
              }cm`,
              type: "line",
              symbol: "none",
              connectNulls: true,
              sampling: "min",
              encode: {
                x: "timestamp",
                y: "rt3-cent",
              },
              markLine: {
                data: wpData.lastValues.threshold && [
                  {
                    name: t("threshold"),
                    yAxis: wpData.lastValues.threshold,
                    lineStyle: {
                      normal: {
                        color: "#FF3B30",
                      },
                    },
                    label: {
                      position: "insideEndTop",
                    },
                  },
                ],
              },
              markArea: {
                data: eventsData.alertEvents.map((alertEvent) => {
                  return [
                    {
                      name: t(alertEvent.status),
                      xAxis: alertEvent.createdAt,
                      itemStyle: {
                        color: SEVERITY_LEVEL_COLOR[alertEvent.level],
                      },
                      label: {
                        show: false,
                        color: SEVERITY_LEVEL_COLOR_LABEL[alertEvent.level],
                      },
                      emphasis: {
                        label: {
                          show: true,
                        },
                      },
                    },
                    {
                      xAxis: alertEvent.resolvedOn,
                    },
                  ];
                }),
              },
            },
            {
              name: t("rain"),
              type: "bar",
              barWidth: "6",
              yAxisIndex: 1,
              encode: {
                x: "timestamp",
                y: t("rain"),
              },
              tooltip: {
                trigger: "item",
              },
            },
            {
              name: t("irrigation"),
              type: "bar",
              barWidth: "6",
              yAxisIndex:
                irrigationHistory &&
                irrigationHistory.irrigation &&
                irrigationHistory.irrigation.columns.includes(
                  "irrigationVolume"
                )
                  ? 2
                  : 1,
              encode: {
                x: "timestamp",
                y: t("irrigation"),
              },
              tooltip: {
                trigger: "item",
              },
            },
          ],
        }}
      />
    );
  } else {
    return <AppLoader />;
  }
};

export default ThreeRootense;
