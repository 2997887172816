import React, { useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  Box,
  Typography,
  Divider,
  Button,
  Drawer,
  Modal,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import {
  EditLicenceForm,
  AppBtnFab,
  AddAutopilotLicenceForm,
} from "components/commons";
import {
  getMyProductsRequest,
  myProductsSelector,
  errorSelector,
} from "redux/productsList";
import { editLicenceDataRequest } from "redux/editLicence";
import {
  licenceStatusInfoSelector,
  getLicenceStatusBarRequest,
} from "redux/licenceStatusBar";
import { addProductsToCartRequest } from "redux/addProductsToCart";
import { addAutopilotLicenceRequest } from "redux/addAutopilotLicence";
import { getCartTotalsRequest } from "redux/cartTotals";
import { default as Table } from "./table";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { MY_PRODUCTS_COLUMNS } from "constants/myProductsTableColumns";
import history from "history.js";
import useStyles from "./styles";
import _ from "lodash";
import moment from "moment";
import { useGA4React } from "ga-4-react";
import { Add } from "@material-ui/icons";
import PermissionsService from "services/PermissionsService";

import clsx from "clsx";

const MyProducts = ({
  myProducts,
  getMyProducts,
  errorGetLicense,
  licenceStatusInfo,
  getLicenceStatusBar,
  addProductsToCart,
  getCartTotals,
  editLicenceData,
  addAutopilotLicence,
  location,
}) => {
  const { t } = useTranslation();
  const ga = useGA4React();
  const {
    container,
    headerContainer,
    boxContainer,
    boxContent,
    label,
    info,
    availableLicenceInfo,
    divider,
    renewBtn,
    firstNumber,
    lastNumber,
    centerNumber,
    infoRow,
    singleNumber,
    attachModalBox,
    attachModalTitle,
    attachModalMsg,
    attachBtn,
    attachModalCancelBtn,
    btnContainer,
    redNumber,
    grayNumber,
    addBtn,
    addIcon,
  } = useStyles();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("licenceEnd");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [searchValue, setSearchValue] = useState("");
  const [selectedLicence, selectLicence] = useState(null);
  const [editLicenceFormOpened, triggerEditLicenceFrom] = useState(false);
  const [
    addAutopilotLicenceFormOpened,
    triggerAddAutopilotLicenceFormOpened,
  ] = useState(false);
  const [attachLicenceModalOpen, triggerAttachLicenceModal] = useState(false);
  const [editedDeviceData, setEditedDeviceData] = useState();
  useEffect(() => {
    setTimeout(() => {
      getCartTotals({ period: 1 });
    }, 2000);
  }, []);
  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const addAutopilotLicence = urlParams.get("addAutopilotLicence");
    if (
      addAutopilotLicence === "true" &&
      PermissionsService.isAllowed("AUTOPILOT_CREATE_LICENCE")
    ) {
      triggerAddAutopilotLicenceFormOpened(true);
    }
  }, [location]);
  useEffect(() => {
    getMyProducts(
      searchValue,
      {
        limit: rowsPerPage,
        page: page + 1,
      },
      [`${orderBy},${order.toUpperCase()}`]
    );
  }, [page, rowsPerPage, searchValue, orderBy, order]);

  useEffect(() => {
    getLicenceStatusBar({
      licenceIds: selected.map((item) => item.id),
    });
  }, [selected]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelect = (event, account) => {
    const selectedIndex = selected.findIndex(
      (selectedItem) => selectedItem.id === account.id
    );

    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, account);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.some((item) => item.id === id);

  const debouncedSearch = _.debounce(setSearchValue, 500);

  const handleSearch = (e) => {
    setPage(0);
    debouncedSearch(e.target.value);
  };
  const handleOpenEditDrawer = (id) => {
    selectLicence(id);
    triggerEditLicenceFrom(true);
  };
  const handleAddProductsToCart = () => {
    ga.gtag("event", "add_to_cart", {
      currency: "USD",
      items: selected,
    });
    addProductsToCart({
      productIds: selected.map((item) => item.id),
      callback: () => history.push("/cart"),
    });
  };
  const handleOpenAddAutopilotDrawer = () => {
    triggerAddAutopilotLicenceFormOpened(true);
  };
  const handleCloseEditLicenceForm = () => {
    triggerEditLicenceFrom(false);
    selectLicence(null);
  };
  const handleCloseAddAutopilotLicenceForm = () => {
    triggerAddAutopilotLicenceFormOpened(false);
  };
  const handleAddLicence = (licenceData) => {
    addAutopilotLicence(licenceData, {
      search: searchValue,
      pagination: {
        limit: rowsPerPage,
        page: page + 1,
      },
      sort: [`${orderBy},${order.toUpperCase()}`],
    });
    triggerAddAutopilotLicenceFormOpened(false);
  };
  const handleCloseAttachLicenceModal = () => {
    triggerAttachLicenceModal(false);
  };
  const handleEditLicence = (data, deviceIsChanged = false) => {
    if (deviceIsChanged) {
      setEditedDeviceData(data);
      triggerAttachLicenceModal(true);
    } else {
      const productData = {
        uniqueKey: data.licenceId,
        productId: data.licenceType,
        startDate: data.startDate,
        endDate: data.endDate,
        activated: data.enabled,
        deviceId: data.device.id,
      };
      editLicenceData(selectedLicence, productData, () => {
        triggerEditLicenceFrom(false);
        getMyProducts(
          searchValue,
          {
            limit: rowsPerPage,
            page: page + 1,
          },
          [`${orderBy},${order.toUpperCase()}`]
        );
      });
    }
  };
  const handleEditAutopilotLicence = (data) => {
    const productData = {
      uniqueKey: data.licenceId,
      startDate: data.startDate,
      endDate: data.endDate,
      activated: data.enabled,
      plotId: data.plot.id,
    };
    editLicenceData(selectedLicence, productData, () => {
      triggerEditLicenceFrom(false);
      getMyProducts(
        searchValue,
        {
          limit: rowsPerPage,
          page: page + 1,
        },
        [`${orderBy},${order.toUpperCase()}`]
      );
    });
  };
  const handleAcceptChangeDevice = () => {
    const productData = {
      uniqueKey: editedDeviceData.licenceId,
      productId: editedDeviceData.licenceType,
      startDate: editedDeviceData.startDate,
      endDate: editedDeviceData.endDate,
      activated: editedDeviceData.enabled,
      deviceId: editedDeviceData.device.id,
    };
    editLicenceData(selectedLicence, productData, () => {
      triggerAttachLicenceModal(false);
      triggerEditLicenceFrom(false);
      getMyProducts(
        searchValue,
        {
          limit: rowsPerPage,
          page: page + 1,
        },
        [`${orderBy},${order.toUpperCase()}`]
      );
    });
  };
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = myProducts.data.filter((n) => n.status !== "auto");
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };
  return (
    <Box className={container}>
      <Box className={headerContainer}>
        <Typography variant="h5">{t("myProducts")}</Typography>
        {PermissionsService.isAllowed("AUTOPILOT_CREATE_LICENCE") && (
          <Tooltip title={t("addLicence")}>
            <IconButton
              disableRipple
              className={addBtn}
              onClick={handleOpenAddAutopilotDrawer}
            >
              <Add className={addIcon} />
            </IconButton>
          </Tooltip>
        )}
      </Box>
      <Box className={boxContainer}>
        <Box className={boxContent}>
          <Box className={availableLicenceInfo}>
            <Box className={label}>{t("availableLicence")}</Box>
            <Box className={info}>
              {licenceStatusInfo.availableLicences} /{" "}
              {licenceStatusInfo.totalLicences}
            </Box>
          </Box>
          <Divider orientation="vertical" className={divider} />
          <Box className={availableLicenceInfo}>
            <Box className={label}>{t("nextRenewalDate")}</Box>
            {moment(licenceStatusInfo.nextRenewalDate).format() !==
            "Invalid date" ? (
              <Box className={info}>
                {moment(licenceStatusInfo.nextRenewalDate).format("DD")}{" "}
                &middot;{" "}
                {moment(licenceStatusInfo.nextRenewalDate).format("MM")}{" "}
                &middot;{" "}
                {moment(licenceStatusInfo.nextRenewalDate).format("YYYY")}
              </Box>
            ) : (
              <Box className={info}>-</Box>
            )}
          </Box>
          <Divider orientation="vertical" className={divider} />
          <Box className={availableLicenceInfo}>
            <Box className={label}>{t("renewalAmount")}</Box>
            <Box className={info}>
              {licenceStatusInfo.currency &&
                new Intl.NumberFormat(licenceStatusInfo.countryCode, {
                  style: "currency",
                  currency: licenceStatusInfo.currency,
                }).format(licenceStatusInfo.amount)}
            </Box>
          </Box>
          <Divider orientation="vertical" className={divider} />
          <Box className={availableLicenceInfo}>
            <Box className={label}>{t("daysLeft")}</Box>
            {licenceStatusInfo && licenceStatusInfo.daysLeft && (
              <Box className={infoRow}>
                {`${
                  licenceStatusInfo.daysLeft < 0
                    ? "0"
                    : licenceStatusInfo.daysLeft
                }`
                  .split("")
                  .map((number, i, arr) => {
                    if (arr.length === 1) {
                      return (
                        <Box
                          key={Math.random()}
                          className={clsx(singleNumber, {
                            [redNumber]: licenceStatusInfo.daysLeft < 30,
                            [grayNumber]: licenceStatusInfo.daysLeft >= 30,
                          })}
                        >
                          {number}
                        </Box>
                      );
                    } else if (i === 0) {
                      return (
                        <Box
                          key={Math.random()}
                          className={clsx(firstNumber, {
                            [redNumber]: licenceStatusInfo.daysLeft < 30,
                            [grayNumber]: licenceStatusInfo.daysLeft >= 30,
                          })}
                        >
                          {number}
                        </Box>
                      );
                    } else if (i === arr.length - 1) {
                      return (
                        <Box
                          key={Math.random()}
                          className={clsx(lastNumber, {
                            [redNumber]: licenceStatusInfo.daysLeft < 30,
                            [grayNumber]: licenceStatusInfo.daysLeft >= 30,
                          })}
                        >
                          {number}
                        </Box>
                      );
                    } else {
                      return (
                        <Box
                          key={Math.random()}
                          className={clsx(centerNumber, {
                            [redNumber]: licenceStatusInfo.daysLeft < 30,
                            [grayNumber]: licenceStatusInfo.daysLeft >= 30,
                          })}
                        >
                          {number}
                        </Box>
                      );
                    }
                  })}
              </Box>
            )}
          </Box>
        </Box>
        <Button
          disabled={
            selected.length === 0 &&
            licenceStatusInfo &&
            licenceStatusInfo.isAllActive
          }
          className={renewBtn}
          onClick={handleAddProductsToCart}
        >
          {selected.length === 1
            ? t("renewProduct")
            : selected.length
            ? t("renewProducts", { number: selected.length })
            : t("renewAll")}
        </Button>
      </Box>
      <Table
        cells={MY_PRODUCTS_COLUMNS}
        data={myProducts.data || []}
        order={order}
        orderBy={orderBy}
        selected={selected}
        page={page}
        rowsPerPage={rowsPerPage}
        handleRequestSort={handleRequestSort}
        handleSelect={handleSelect}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        isSelected={isSelected}
        pageCount={myProducts.total || 0}
        handleEditLicense={handleOpenEditDrawer}
        handleSearch={handleSearch}
        noAccess={errorGetLicense.includes("403")}
        handleSelectAllClick={handleSelectAllClick}
      />
      <Drawer
        anchor="right"
        open={editLicenceFormOpened}
        onClose={handleCloseEditLicenceForm}
      >
        <EditLicenceForm
          licenceId={selectedLicence}
          handleCloseEditLicenceForm={handleCloseEditLicenceForm}
          handleEditLicence={handleEditLicence}
          handleEditAutopilotLicence={handleEditAutopilotLicence}
        />
      </Drawer>
      <Drawer
        anchor="right"
        open={addAutopilotLicenceFormOpened}
        onClose={handleCloseAddAutopilotLicenceForm}
      >
        <AddAutopilotLicenceForm
          handleCloseAddAutopilotLicenceForm={
            handleCloseAddAutopilotLicenceForm
          }
          handleAddLicence={handleAddLicence}
        />
      </Drawer>

      <Modal
        open={attachLicenceModalOpen}
        onClose={handleCloseAttachLicenceModal}
      >
        <Box className={attachModalBox}>
          <Box className={attachModalTitle}>
            {t("attachToAnotherDeviceModalTitle", {
              deviceName: editedDeviceData ? editedDeviceData.device.name : "",
            })}
          </Box>
          <Box className={attachModalMsg}>
            {t("attachToAnotherDeviceModalMsg")}
          </Box>
          <Box className={btnContainer}>
            <AppBtnFab
              className={attachModalCancelBtn}
              btnType="light"
              label={t("cancel")}
              onClick={handleCloseAttachLicenceModal}
            />
            <AppBtnFab
              className={attachBtn}
              onClick={handleAcceptChangeDevice}
              label={t("attach")}
            />
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  myProducts: myProductsSelector(state),
  errorGetLicense: errorSelector(state),
  licenceStatusInfo: licenceStatusInfoSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  getMyProducts: bindActionCreators(getMyProductsRequest, dispatch),
  getLicenceStatusBar: bindActionCreators(getLicenceStatusBarRequest, dispatch),
  addProductsToCart: bindActionCreators(addProductsToCartRequest, dispatch),
  getCartTotals: bindActionCreators(getCartTotalsRequest, dispatch),
  editLicenceData: bindActionCreators(editLicenceDataRequest, dispatch),
  addAutopilotLicence: bindActionCreators(addAutopilotLicenceRequest, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MyProducts);
