import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "translations/en";
import he from "translations/he";
import es from "translations/es";
import ro from "translations/ro";
import pt from "translations/pt";
import tr from "translations/tr";
import de from "translations/de";
import it from "translations/it";
import ar from "translations/ar";
const resources = {
  en: {
    translation: {
      ...en,
    },
  },
  he: {
    translation: {
      ...he,
    },
  },
  es: {
    translation: {
      ...es,
    },
  },
  tr: {
    translation: {
      ...tr,
    },
  },
  ro: {
    translation: {
      ...ro,
    },
  },
  pt: {
    translation: {
      ...pt,
    },
  },
  de: {
    translation: {
      ...de,
    },
  },
  it: {
    translation: {
      ...it,
    },
  },
  ar: {
    translation: {
      ...ar,
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
