import React, { useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  Box,
  Typography,
  IconButton,
  Modal,
  Button,
  Drawer,
  Tooltip,
} from "@material-ui/core";
import { AddUserForm, EditUserForm } from "components/commons";
import { Add, Close } from "@material-ui/icons";
import { userIdSelector, defaultAccountSelector } from "redux/me";
import { addUserRequest } from "redux/addUser";
import { editUserRequest } from "redux/editUser";
import {
  accountsListSelector,
  getAccountsListRequest,
} from "redux/accountsList";
import { usersSelector, getUsersRequest } from "redux/users";
import { detachUserRequest } from "redux/detachUser";
import { deleteUsersRequest } from "redux/deleteUsers";
import { default as Table } from "./table";
import { useTranslation } from "react-i18next";
import { USERS_COLUMNS } from "constants/usersTableColumns";
import FarmerCircle from "media/farmer-circle.svg";
import clsx from "clsx";
import PermissionsService from "services/PermissionsService";

import useStyles from "./styles";
import _ from "lodash";
import { exportUsers } from "api";

const Users = ({
  users,
  userId,
  getAccountsList,
  accountsList,
  getUsers,
  detachUser,
  defaultAccount,
  deleteUsers,
  addUser,
  editUser,
}) => {
  const { t } = useTranslation();
  const {
    container,
    addBtn,
    headerContainer,
    addIcon,
    modalBox,
    modalBody,
    closeIcon,
    modalTitle,
    modalBtnsContainer,
    cancelBtn,
    approveModalBtn,
    modalMsg,
    addBtnDisabled,
  } = useStyles();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");
  const [selected, setSelected] = useState([]);
  // eslint-disable-next-line
  const [allAccountsSelected, setAllAccountsSelected] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchValue, setSearchValue] = useState("");
  // const [filterRoles, setFilterRoles] = useState([]);
  const [filterOrganizationsIds, setFilterOrganizationsIds] = useState([]);
  const [detachModalIsOpen, openDetachModal] = useState(false);
  const [deleteModalIsOpen, openDeleteModal] = useState(false);
  const [selectedUser, selectUser] = useState({});
  const [addUserFormOpened, triggerAddUserFrom] = useState(false);
  const [editUserFormOpened, triggerEditUserFrom] = useState(false);
  useEffect(() => {
    if (userId) {
      getUsers(
        searchValue,
        {
          limit: rowsPerPage,
          page: page + 1,
        },
        [`${orderBy},${order.toUpperCase()}`],
        [], // filterRoles,
        filterOrganizationsIds,
        allAccountsSelected
      );
    }
  }, [
    userId,
    page,
    rowsPerPage,
    searchValue,
    orderBy,
    order,
    // filterRoles,
    filterOrganizationsIds,
    allAccountsSelected
  ]);
  useEffect(() => {
    if (userId) {
      getAccountsList();
    }
  }, [userId]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = users.data.map((n) => n);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleSelect = (event, user) => {
    const selectedIndex = selected.findIndex(
      (selectedItem) =>
        selectedItem.userId === user.userId &&
        selectedItem.organizationId === user.organizationId
    );

    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, user);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (user) =>
    selected.some(
      (item) =>
        item.userId === user.userId &&
        item.organizationId === user.organizationId
    );

  const debouncedSearch = _.debounce(setSearchValue, 500);

  const handleSearch = (e) => {
    setPage(0);
    debouncedSearch(e.target.value);
  };
  const handleCreateUser = () => {
    triggerAddUserFrom(true);
  };
  const handleOpenDetachModal = (user) => {
    selectUser(user);
    openDetachModal(true);
  };
  const handleCloseDetachModal = () => {
    openDetachModal(false);
    selectUser({});
  };
  const handleCloseDeleteModal = () => {
    openDeleteModal(false);
  };
  const handleOpenDeleteModal = () => {
    openDeleteModal(true);
  };
  const handleDetachUser = () => {
    detachUser(selectedUser.userId, selectedUser.organizationId, {
      search: searchValue,
      pagination: {
        limit: rowsPerPage,
        page: page + 1,
      },
      sort: [`${orderBy},${order.toUpperCase()}`],
      filterRoles: [], // filterRoles,
      filterOrganizationsIds: filterOrganizationsIds,
    });
    openDetachModal(false);
    selectUser({});
  };
  const handleDeleteUsers = () => {
    deleteUsers(selected.map((user) => user.userId), {
      search: searchValue,
      pagination: {
        limit: rowsPerPage,
        page: page + 1,
      },
      sort: [`${orderBy},${order.toUpperCase()}`],
      filterRoles: [], // filterRoles,
      filterOrganizationsIds: filterOrganizationsIds,
    });
    openDeleteModal(false);
  };
  const handleExportUsers = () => {
    exportUsers(
      selected.map((user) => `${user.userId}, ${user.organizationId}`)
    );
  };
  const handleCloseAddUserForm = () => {
    triggerAddUserFrom(false);
  };
  const handleAddUser = (values) => {
    addUser(values, {
      search: searchValue,
      pagination: {
        limit: rowsPerPage,
        page: page + 1,
      },
      sort: [`${orderBy},${order.toUpperCase()}`],
      filterRoles: [], // filterRoles,
      filterOrganizationsIds: filterOrganizationsIds,
    });
    triggerAddUserFrom(false);
  };
  const handleCloseEditUserForm = () => {
    triggerEditUserFrom(false);
    selectUser({});
  };
  const handleOpenEditUserForm = (user) => {
    selectUser(user);
    triggerEditUserFrom(true);
  };
  const handleEditUser = (values) => {
    editUser(selectedUser.userId, selectedUser.organizationId, values, {
      search: searchValue,
      pagination: {
        limit: rowsPerPage,
        page: page + 1,
      },
      sort: [`${orderBy},${order.toUpperCase()}`],
      filterRoles: [], // filterRoles,
      filterOrganizationsIds: filterOrganizationsIds,
    });
    selectUser({});
    triggerEditUserFrom(false);
  };
  const handleAccountFilter = (accounts) => {
    setFilterOrganizationsIds(accounts.map((account) => account.id));
  };

  const accountFromStorage = localStorage.getItem("accountData");
  return (
    <Box className={container}>
      <Box className={headerContainer}>
        <Typography variant="h5">{t("users")}</Typography>
        {PermissionsService.isAllowed("USER_ATTACH") && (
          <Tooltip
            title={!accountFromStorage ? t("disabledBtnMsg") : t("addUser")}
          >
            <IconButton
              disableRipple
              className={clsx(addBtn, {
                [addBtnDisabled]: !accountFromStorage,
              })}
              onClick={accountFromStorage && handleCreateUser}
            >
              <Add className={addIcon} />
            </IconButton>
          </Tooltip>
        )}
      </Box>
      <Table
        cells={USERS_COLUMNS}
        data={users.data || []}
        order={order}
        orderBy={orderBy}
        selected={selected}
        page={page}
        rowsPerPage={rowsPerPage}
        handleRequestSort={handleRequestSort}
        handleSelectAllClick={handleSelectAllClick}
        handleSelect={handleSelect}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        isSelected={isSelected}
        pageCount={users.total || 0}
        accountsList={accountsList}
        handleOpenDetachModal={handleOpenDetachModal}
        handleExportUsers={handleExportUsers}
        handleOpenDeleteModal={handleOpenDeleteModal}
        handleOpenEditUserForm={handleOpenEditUserForm}
        handleSearch={handleSearch}
        handleAccountFilter={handleAccountFilter}
        currentAccount={accountFromStorage}
      />
      <Drawer
        anchor="right"
        open={addUserFormOpened}
        onClose={handleCloseAddUserForm}
      >
        <AddUserForm
          handleCloseAddUserForm={handleCloseAddUserForm}
          handleAddUser={handleAddUser}
        />
      </Drawer>
      <Drawer
        anchor="right"
        open={editUserFormOpened}
        onClose={handleCloseEditUserForm}
      >
        <EditUserForm
          userData={selectedUser}
          handleCloseEditUserForm={handleCloseEditUserForm}
          handleEditUser={handleEditUser}
        />
      </Drawer>
      <Modal
        disableAutoFocus={true}
        disableEnforceFocus={true}
        open={detachModalIsOpen}
        onClose={handleCloseDetachModal}
      >
        <Box className={modalBox}>
          <Box style={{ display: "flex" }}>
            <img src={FarmerCircle} alt="logo" />
            <Box className={modalBody}>
              <Close className={closeIcon} onClick={handleCloseDetachModal} />
              <Typography className={modalTitle} variant="h6">
                {t("detachUserModalTitle")}
              </Typography>
              <Typography className={modalMsg} variant="body2">
                {t("detachUserModalMsg", {
                  username: selectedUser.username,
                  accountName: selectedUser.company,
                })}
              </Typography>
            </Box>
          </Box>
          <Box className={modalBtnsContainer}>
            <Button className={cancelBtn} onClick={handleCloseDetachModal}>
              {t("cancel")}
            </Button>
            <Button
              className={approveModalBtn}
              color="primary"
              onClick={handleDetachUser}
            >
              {t("detach")}
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal
        disableAutoFocus={true}
        disableEnforceFocus={true}
        open={deleteModalIsOpen}
        onClose={handleCloseDeleteModal}
      >
        <Box className={modalBox}>
          <Box style={{ display: "flex" }}>
            <img src={FarmerCircle} alt="logo" />
            <Box className={modalBody}>
              <Close className={closeIcon} onClick={handleCloseDeleteModal} />
              <Typography className={modalTitle} variant="h6">
                {t("deleteUsersModalTitle")}
              </Typography>
              {selected.length > 0 && (
                <ol>
                  {selected.map((user, i) => (
                    <li key={i}>{user.username}</li>
                  ))}
                </ol>
              )}
            </Box>
          </Box>
          <Box className={modalBtnsContainer}>
            <Button className={cancelBtn} onClick={handleCloseDeleteModal}>
              {t("cancel")}
            </Button>
            <Button
              className={approveModalBtn}
              color="primary"
              onClick={handleDeleteUsers}
            >
              {t("delete")}
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  userId: userIdSelector(state),
  accountsList: accountsListSelector(state),
  users: usersSelector(state),
  defaultAccount: defaultAccountSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  getAccountsList: bindActionCreators(getAccountsListRequest, dispatch),
  getUsers: bindActionCreators(getUsersRequest, dispatch),
  detachUser: bindActionCreators(detachUserRequest, dispatch),
  deleteUsers: bindActionCreators(deleteUsersRequest, dispatch),
  addUser: bindActionCreators(addUserRequest, dispatch),
  editUser: bindActionCreators(editUserRequest, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Users);
