import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  container: {
    flex: 1,
    display: "flex",
    overflowY: "hidden",
  },
  leftSideMenu: {
    width: "300px",
    height: "100%",
  },
  content: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
  },
  selectPlotMsgContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
  },
  selectPlotMsg: {
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "16px",
    textAlign: "center",
    color: theme.palette.text.lightGray,
  },
  widgetContainer: {
    backgroundColor: "white",
    borderRadius: "8px",
    cursor: "move"
  },
  gridContainer: {
    overflow: "auto",
    direction: "ltr",
  },
}));
