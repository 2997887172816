import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  tableStyle: {
    minWidth: 750,
  },
  selectedRow: {
    backgroundColor: `rgba(112, 183, 225, 0.1) !important`,
  },
  headerLabel: {
    fontWeight: "bold",
    color: theme.palette.text.lightGray,
  },
  icon: {
    width: "30px",
    height: "30px",
    cursor: "pointer",
    "&:hover": {
      opacity: 0.6,
    },
  },
  headerBtns: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "0 10px",
    borderBottom: "1px solid #D7D9DF",
  },
  btnIcon: {
    color: theme.palette.text.lightGray,
  },
  btnLabel: {
    color: theme.palette.text.lightGray,
    textTransform: "none",
    marginInlineStart: "15px",
    minWidth: "100px",
  },
  headerColumn: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  actionBtn: {
    minWidth: "auto",
    padding: 0,
  },
  search: {
    backgroundColor: "white",
    width: "100%",
    "&>fieldset": {
      border: "none",
    },
  },
  searchIcon: {
    color: theme.palette.text.lightGray,
  },
  disabledBtn: {
    cursor: "not-allowed",
    "& svg": {
      cursor: "not-allowed",
    },
  },
}));
