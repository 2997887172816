import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.background.default,
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center",
    width: "100%",
    justifyContent: "space-between",
    height: "56px",
    padding: "0 16px",
  },
  containerAuthenticated: {
    backgroundColor: theme.palette.background.default,
    height: theme.headerHeight,
  },
  userNameStyle: {
    fontWeight: "bold",
    lineHeight: "16px",
    textAlign: "right",
    color: theme.palette.text.lightGray,
  },
  divider: {
    margin: "0 4vw",
    backgroundColor: "#EBEDF3",
  },
  link: {
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "16px",
    color: "#A8D8F4",
  },
  mainAccount: {
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "16px",
    color: "#C8D1D9",
  },
  separatorStyle: {
    color: "#A8D8F4",
  },
  menuToggle: {
    height: "30px",
    width: "26px",
    cursor: "pointer",
    "&:hover": {
      opacity: 0.7,
    },
  },
  hierarchyContainer: {
    padding: "16px",
  },
}));
