import { put, takeEvery, all, call } from "redux-saga/effects";
import { markAsDone } from "api";
import { getInsightsListRequest } from "./insightsList";
/* *
 * Constants
 * */
export const moduleName = "markAsDone";
const prefix = `${process.env.REACT_APP_NAME}/${moduleName}`;

export const INIT = `${prefix}/INIT`;
export const SUCCESS = `${prefix}/SUCCESS`;
export const REJECT = `${prefix}/REJECT`;

/* *
 * Action Creators
 * */
export const markAsDoneRequest = (alertId, plotId) => ({
  type: INIT,
  alertId,
  plotId,
});

export const successAction = (payload) => ({
  type: SUCCESS,
  payload,
});

export const errorAction = (error) => ({
  type: REJECT,
  payload: { error },
});

/* *
 * Sagas
 * */
export function* markAsDoneInsightSaga({ alertId, plotId }) {
  try {
    const accountFromStorage = localStorage.getItem("accountData");
    const res = yield call(
      markAsDone,
      alertId,
      JSON.parse(accountFromStorage).id
    );
    yield put(getInsightsListRequest(plotId));
    yield put(successAction(res));
  } catch (error) {
    yield put(errorAction(error.message));
  }
}

export function* saga() {
  yield all([takeEvery(INIT, markAsDoneInsightSaga)]);
}
