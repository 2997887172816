import React, { useEffect, useState } from "react";
import { usersListSelector, getUsersListRequest } from "redux/usersList";
import { getRolesRequest, rolesSelector } from "redux/roles";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Typography, Box, TextField, InputAdornment } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CloseIcon from "@material-ui/icons/Close";
import { useFormik } from "formik";
import { StyledTextField, AppSelect, AppBtnFab } from "components/commons";
import _ from "lodash";
import validator from "validator";
import SearchIcon from "@material-ui/icons/Search";

import { findPhoneNumbersInText } from "libphonenumber-js";
import useStyles from "./styles";

const validatePhoneNumber = (number) => {
  if (number[0] === "+") {
    return findPhoneNumbersInText(number).length === 0;
  } else {
    return findPhoneNumbersInText(`+${number}`).length === 0;
  }
};
let sortingOrder = {
  USER_ROLE: 1,
  OPERATOR_ROLE: 2,
  LOCAL_ADMIN_ROLE: 3,
  RESELLER_ROLE: 4,
};
const sortRoleByPriority = (key, order = "asc") => {
  return (a, b) => {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) return 0;
    const first =
      a[key] in sortingOrder ? sortingOrder[a[key]] : Number.MAX_SAFE_INTEGER;
    const second =
      b[key] in sortingOrder ? sortingOrder[b[key]] : Number.MAX_SAFE_INTEGER;

    let result = 0;
    if (first < second) result = -1;
    else if (first > second) result = 1;
    return order === "desc" ? ~result : result;
  };
};

const AddUserForm = ({
  handleCloseAddUserForm,
  handleAddUser,
  getUsersList,
  usersList,
  getRoles,
  roles,
}) => {
  const {
    setFieldValue,
    handleSubmit,
    errors,
    touched,
    values,
    handleChange,
    handleBlur,
    isValid,
  } = useFormik({
    initialValues: {
      name: "",
      email: "",
      phoneNumber: "",
      roleId: "",
    },
    isInitialValid: false,
    validate: (values) => {
      const errors = {};
      if (!values.phoneNumber) {
        errors.phoneNumber = "Required";
      } else if (validatePhoneNumber(values.phoneNumber)) {
        errors.phoneNumber = "Enter a valid Phone Number";
      }
      if (!values.name) {
        errors.name = "Required";
      }
      if (!values.roleId) {
        errors.roleId = "Required";
      }
      if (!values.email) {
        errors.email = "Required";
      }
      if (!validator.isEmail(values.email)) {
        errors.email = "Please enter a valid email";
      }
      return errors;
    },
    onSubmit: (values, { setSubmitting }) => {
      if(values.phoneNumber[0] === "+") {
        values.phoneNumber = values.phoneNumber.slice(1);
      }
      handleAddUser(values);
    },
  });
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  useEffect(() => {
    getUsersList();
    getRoles();
  }, []);
  const {
    container,
    header,
    headerTitle,
    closeIcon,
    userForm,
    btnContainer,
    cancelBtn,
    addBtn,
    searchIcon,
    username,
    email,
  } = useStyles();
  const debouncedFilter = _.debounce(getUsersList, 500);
  const prepareRoles = (role) => {
    return {
      label: role.name,
      value: role.id,
    };
  };
  return (
    <Box className={container}>
      <Box className={header}>
        <Typography className={headerTitle} variant="h6">
          {t("addUserFormTitle")}
        </Typography>
        <CloseIcon onClick={handleCloseAddUserForm} className={closeIcon} />
      </Box>
      <Autocomplete
        style={{ margin: "15px 30px 0 30px" }}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        filterOptions={(a) => a}
        onInputChange={(e, val) => debouncedFilter(val)}
        onChange={(e, val) => {
          setTimeout(() => {
            setFieldValue("name", val ? val.username : "");
          }, 1);
          setTimeout(() => {
            setFieldValue("email", val ? val.email : "");
          }, 1);
          setTimeout(() => {
            setFieldValue("phoneNumber", val ? `+${val.phoneNumber}` : "");
          }, 1);
        }}
        getOptionSelected={(option, value) => option.userId === value.userId}
        getOptionLabel={(option) => option.username}
        options={usersList.data || []}
        renderOption={(option, { selected }) => (
          <Box>
            <Typography className={username}>{option.username}</Typography>
            <Typography className={email}>{option.email}</Typography>
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={t("search")}
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon className={searchIcon} edge="end" />
                </InputAdornment>
              ),
            }}
          />
        )}
      />
      <form className={userForm} onSubmit={handleSubmit}>
        <StyledTextField
          label={t("fullname")}
          name="name"
          value={values.name}
          touched={touched.name}
          error={errors.name}
          handleChange={handleChange}
          handleBlur={handleBlur}
        />
        <StyledTextField
          label={t("workEmail")}
          name="email"
          value={values.email}
          touched={touched.email}
          error={errors.email}
          handleChange={handleChange}
          handleBlur={handleBlur}
        />
        <StyledTextField
          label={t("phoneNumber")}
          name="phoneNumber"
          value={values.phoneNumber}
          touched={touched.phoneNumber}
          error={errors.phoneNumber}
          handleChange={handleChange}
          handleBlur={handleBlur}
        />
        <AppSelect
          label={t("role")}
          name="roleId"
          defaultItem={t("selectRole")}
          items={roles.sort(sortRoleByPriority("raw")).map(prepareRoles)}
          value={values.roleId}
          touched={touched.roleId}
          error={errors.roleId}
          handleChange={handleChange}
          handleBlur={handleBlur}
        />
        <Box className={btnContainer}>
          <AppBtnFab
            className={cancelBtn}
            btnType="light"
            label={t("cancel")}
            onClick={handleCloseAddUserForm}
          />
          <AppBtnFab
            className={addBtn}
            type="submit"
            label={t("addBtn")}
            disabled={!isValid}
          />
        </Box>
      </form>
    </Box>
  );
};
const mapStateToProps = (state) => ({
  usersList: usersListSelector(state),
  roles: rolesSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  getUsersList: bindActionCreators(getUsersListRequest, dispatch),
  getRoles: bindActionCreators(getRolesRequest, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddUserForm);
