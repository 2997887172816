import React, { useEffect, useState } from "react";
import ReactEcharts from "echarts-for-react";
import { AppLoader } from "components/commons";
import moment from "moment";
import { useTranslation } from "react-i18next";

const DevicesCellstateGraph = ({
  devicesCellstateData,
  daterange,
  withScroll = false,
}) => {
  const [chartData, setData] = useState();
  const { t } = useTranslation();

  useEffect(() => {
    if (devicesCellstateData) {
      const schema = ["timestamp"];
      const series = [];
      const colors = [];
      const cellstateData = [];
      const validData = devicesCellstateData.filter(item => typeof item !== "string")
      validData.forEach((data, i) => {
        if (typeof data !== "string") {
          schema.push(data.device.name);
          series.push(data.device.name);
          colors.push(data.device.color);
          data.cellstate.data.forEach((cellstate) => {
            let cellstateArray = new Array(validData.length + 1);
            cellstateArray[0] = cellstate[0];
            cellstateArray[i + 1] = cellstate[1];
            cellstateData.push(cellstateArray);
          });
        }
      });
      setData({ schema, cellstateData: cellstateData.sort(), series, colors });
    }
  }, [devicesCellstateData]);

  if (chartData) {
    return (
      <ReactEcharts
        style={{ height: "100%" }}
        option={{
          tooltip: {
            trigger: "axis",
            appendToBody: true,
            formatter: (data) => {
              if (!data.length) {
                data = [data];
              }
              let tooltip = `${moment(data[0].value[data[0].encode.x]).format(
                "DD-MM YYYY HH:mm"
              )} <br/>`;
              data.forEach(
                ({ value, marker, seriesName, seriesIndex, encode: { y } }) => {
                  tooltip += `<div style="display: flex; align-items: center; direction: ltr;">${marker}<div style="padding-inline-end: 5px">${seriesName}:</div><div style="direction: ltr">${value[
                    y
                  ] || "-"}%</div></div>`;
                }
              );
              return tooltip;
            },
          },
          axisLabel: { hideOverlap: true },
          grid: {
            top: "50",
            left: "50",
            right: "50",
            show: true
          },
          color: chartData.colors,
          legend: {},
          dataZoom: withScroll && [
            {
              type: "inside",
              realtime: true,
              start: 0,
              end: 100,
            },
          ],
          toolbox: {
            show: true,
            feature: {
              mark: { show: true },
              dataZoom: {
                show: true,
                title: { zoom: t("zoomTooltip"), back: t("back") },
              },
              restore: { show: true, title: t("restore") },
              saveAsImage: { show: true, title: t("saveAsImage") },
            },
          },
          xAxis: {
            type: "time",
            boundaryGap: false,
            min: daterange[0].valueOf(),
            max: daterange[1].valueOf(),
            axisLabel: {
              formatter: function(value) {
                return moment(value).format("DD-MM YYYY");
              },
            },
          },
          yAxis: {
            type: "value",
          },
          dataset: {
            source: chartData.cellstateData,
            dimensions: chartData.schema,
          },
          series: chartData.series.map((item) => {
            return {
              name: item,
              type: "line",
              symbol: "none",
              connectNulls: true,
              smooth: true,
              encode: {
                x: "timestamp",
                y: item,
              },
            };
          }),
        }}
      />
    );
  } else {
    return <AppLoader />;
  }
};

export default DevicesCellstateGraph;
