export const SERVER_URL = `https://${process.env.REACT_APP_ENDPOINT}`;
export const ORGANIZATION = `${SERVER_URL}/organizations`;
export const LOGIN = `${SERVER_URL}/login`;
export const OTP = `${SERVER_URL}/auth/otp`;
export const ME = `${SERVER_URL}/me`;
export const USERS = `${SERVER_URL}/users`;
export const ALERTS = `${SERVER_URL}/alerts`;
export const ROLES = `${SERVER_URL}/roles`;
export const CROPS = `${SERVER_URL}/crops`;
export const SOIL = `${SERVER_URL}/soil`;
export const EMAILS = `${SERVER_URL}/emails`;
export const DEVICES = `${SERVER_URL}/devices`;
export const ORGANIZATION_V2 = `${SERVER_URL}/v2.0/organizations`;
export const V2_1 = `${SERVER_URL}/v2.1`;
export const V2_1_ORGANIZATION = `${SERVER_URL}/v2.1/organizations`;
