import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2, 3.8),
    paddingBottom: "70px",
  },
  addBtn: {
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      opacity: 0.7,
    },
  },
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "10px",
  },
  addIcon: {
    color: theme.palette.text.white,
  },
  modalBox: {
    backgroundColor: "white",
    width: "500px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    padding: "30px",
    border: "1px solid #D7D9DF",
  },
  modalBody: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  modalTitle: {
    fontWeight: "bold",
    padding: "0 20px",
  },
  modalBtnsContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  cancelBtn: {
    color: theme.palette.text.lightGray,
    margin: "0 20px",
    textTransform: "none",
  },
  approveModalBtn: {
    textTransform: "none",
  },
  closeIcon: {
    alignSelf: "flex-end",
    cursor: "pointer",
    "&:hover": {
      opacity: 0.7,
    },
  },
  msgModal: {
    fontWeight: "normal",
    padding: "0 20px",
    color: theme.palette.text.lightGray,
  },
}));
