import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  container: {
    padding: "24px",
    height: "100%",
  },
  title: {
    color: "#4A4B54",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "16px",
  },
  content: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  weatherIcon: {
    width: "24px",
    height: "24px",
  },
  tempDataRow: {
    display: "flex",
    alignItems: "center",
  },
  maxTemp: {
    fontWeight: "bold",
    fontSize: "12px",
    lineHeight: "14px",
    padding: "8px 7px",
  },
  minTemp: {
    fontSize: "12px",
    lineHeight: "14px",
  },
  maxTempToday: {
    fontWeight: "bold",
    fontSize: "36px",
    lineHeight: "42px",
    padding: "1px 7px",
  },
  minTempToday: {
    fontSize: "36px",
    lineHeight: "42px",
    fontWeight: "300",
  },
  day: {
    color: "#4A4B54",
    fontWeight: "bold",
    fontSize: "12px",
    lineHeight: "14px",
    textTransform: "uppercase",
  },
  forecastData: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    padding: "10px",
    alignItems: "center",
  },
  humidity: {
    color: "#828B93",
    fontSize: "14px",
    lineHeight: "150%",
  },
  weekForecast: {
    overflow: "hidden"
  },
  weekForecastRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  thisWeek: {
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "16px",
    color: "#4A4B54",
  },
  weatherToday: {
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "16px",
    color: "#4A4B54",
  },
  weatherTodayIcon: {
    width: "40px",
    height: "40px",
  },
  menuItem: {
    color: "#828B93",
    fontSize: "14px",
    padding: "0 10px"
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "27px",
  }
}));
