const getMapCenter = (coordinates) => {
  const minLat = coordinates.reduce(
    (min, p) => (!min || p.lat < min ? p.lat : min),
    0
  );
  const maxLat = coordinates.reduce(
    (max, p) => (!max || p.lat > max ? p.lat : max),
    0
  );
  const minLng = coordinates.reduce(
    (min, p) => (!min || p.lng < min ? p.lng : min),
    0
  );
  const maxLng = coordinates.reduce(
    (max, p) => (!max || p.lng > max ? p.lng : max),
    0
  );
  return { lat: (minLat + maxLat) / 2, lng: (minLng + maxLng) / 2 };
};

const getPolygonArea = (polygon) => {
  const { google } = window;

  return (
    google.maps.geometry.spherical.computeArea(polygon.getPath()) * 0.0001
  ).toFixed(1);
};

const getPolygonCoordinates = (polygon) => {
  return polygon
    .getPath()
    .getArray()
    .map((item) => ({
      lat: item.lat(),
      lng: item.lng(),
    }));
};

export default {
  getMapCenter,
  getPolygonArea,
  getPolygonCoordinates,
};
