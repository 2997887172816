const ISSUES_CODES = {
  red: [5, 4],
  orange: [3, 2],
  green: [1, 0],
};

const groupBy = (plotsData, filter) => {
  let data = {};
  if (Array.isArray(plotsData[0][filter])) {
    plotsData.forEach((plot) => {
      plot[filter].forEach((val) => {
        if (!data[val]) data[val] = [];
        data[val].push(plot);
      });
    });
  } else if (typeof plotsData[0][filter] === "string") {
    plotsData.forEach((plot) => {
      if (!data[plot[filter]]) data[plot[filter]] = [];
      data[plot[filter]].push(plot);
    });
  } else if (typeof plotsData[0][filter] === "number") {
    plotsData.forEach((plot) => {
      if (!data[plot[filter]]) data[plot[filter]] = [];
      data[plot[filter]].push(plot);
    });
  }
  const arrayData = Object.keys(data).map((key) => {
    return {
      name: key,
      plots: data[key],
      fieldSeverity: data[key].reduce((sum, plot) => sum + plot.severity, 0),
    };
  });
  return arrayData;
};

const calculatePlotWp = (deviceWp, plotWp) => {
  if (plotWp === null && deviceWp === null) {
    return null;
  } else if (plotWp === null && deviceWp !== null) {
    return 0 + deviceWp;
  } else if (plotWp !== null && deviceWp !== null) {
    return plotWp + deviceWp;
  } else {
    return plotWp;
  }
};

const buildPlotData = (devices) => {
  let plotData = {
    field: null,
    fieldId: null,
    plot: null,
    plotId: null,
    crops: [],
    cropIds: [],
    soils: [],
    soilIds: [],
    wp: null,
    severity: 0,
    hasAlerts: false,
    devices: [],
  };
  devices.forEach((device) => {
    plotData = {
      field: device.field,
      fieldId: device.fieldId,
      plot: device.plot,
      plotId: device.plotId,
      crops: !plotData.crops.includes(device.crop)
        ? [...plotData.crops, device.crop]
        : plotData.crops,
      cropIds: !plotData.cropIds.includes(device.cropId)
        ? [...plotData.cropIds, device.cropId]
        : plotData.cropIds,
      soils: !plotData.soils.includes(device.soil)
        ? [...plotData.soils, device.soil]
        : plotData.soils,
      soilIds: !plotData.soilIds.includes(device.soilId)
        ? [...plotData.soilIds, device.soilId]
        : plotData.soilIds,
      wp: calculatePlotWp(plotData.wp, device.wp),
      severity: plotData.severity,
      hasAlerts: plotData.hasAlerts || device.hasAlerts,
      devices: [...plotData.devices, device],
    };
  });
  const sum = devices.reduce((severitySum, device) => {
    return severitySum + device.severity;
  }, 0);
  plotData.wp = plotData.wp
    ? (
        plotData.wp / devices.filter((device) => !isNaN(device.wp)).length
      ).toFixed(2)
    : "-";
  plotData.severity = Math.round(sum / devices.length);
  return plotData;
};

const generateSeverityCodesArray = (codesObj) => {
  let codes = [-1];
  if (codesObj.redCode) {
    codes.push(...ISSUES_CODES.red);
  }
  if (codesObj.orangeCode) {
    codes.push(...ISSUES_CODES.orange);
  }
  if (codesObj.greenCode) {
    codes.push(...ISSUES_CODES.green);
  }
  return codes;
};

export default {
  groupBy,
  buildPlotData,
  generateSeverityCodesArray,
};
