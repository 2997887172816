import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Paper,
  Checkbox,
  Typography,
  SvgIcon,
  Button,
  Box,
  Tooltip,
  InputAdornment,
  OutlinedInput,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import useStyles from "./styles";
import { Delete, GetApp } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { MultiselectAccount } from "components/commons";
import clsx from "clsx";
import PermissionsService from "services/PermissionsService";

const EditIcon = ({ className, ...rest }) => (
  <SvgIcon {...rest} viewBox="0 0 30 30" className={className}>
    <path
      d="M22.74 10.5928C23.0867 10.2462 23.0867 9.66852 22.74 9.33967L20.6603 7.25996C20.3315 6.91335 19.7538 6.91335 19.4072 7.25996L17.7718 8.8864L21.1047 12.2193L22.74 10.5928ZM7 19.6671V23H10.3329L20.1626 13.1614L16.8297 9.8285L7 19.6671Z"
      fill="#828B93"
    />
  </SvgIcon>
);

const DetachIcon = ({ className, ...rest }) => (
  <SvgIcon {...rest} viewBox="0 0 30 30" className={className}>
    <path
      d="M18.0909 16.75C15.785 16.75 11.1818 17.9137 11.1818 20.25V22H25V20.25C25 17.9137 20.3968 16.75 18.0909 16.75ZM6 13.25V15H12.9091V13.25H6ZM18.0909 15C19.0071 15 19.8858 14.6313 20.5336 13.9749C21.1815 13.3185 21.5455 12.4283 21.5455 11.5C21.5455 10.5717 21.1815 9.6815 20.5336 9.02513C19.8858 8.36875 19.0071 8 18.0909 8C17.1747 8 16.296 8.36875 15.6482 9.02513C15.0003 9.6815 14.6364 10.5717 14.6364 11.5C14.6364 12.4283 15.0003 13.3185 15.6482 13.9749C16.296 14.6313 17.1747 15 18.0909 15Z"
      fill="#828B93"
    />
  </SvgIcon>
);

const AppTable = ({
  cells,
  data,
  order,
  orderBy,
  selected,
  page,
  rowsPerPage,
  handleSelectAllClick,
  handleRequestSort,
  isSelected,
  handleSelect,
  handleChangePage,
  handleChangeRowsPerPage,
  pageCount,
  accountsList,
  handleOpenDetachModal,
  handleExportUsers,
  handleOpenDeleteModal,
  handleOpenEditUserForm,
  handleSearch,
  handleAccountFilter,
  currentAccount,
}) => {
  const {
    root,
    paper,
    tableStyle,
    selectedRow,
    headerLabel,
    icon,
    headerBtns,
    btnIcon,
    btnLabel,
    headerColumn,
    actionBtn,
    search,
    searchIcon,
    disabledBtn,
  } = useStyles();
  const { t } = useTranslation();
  const emptyRows = rowsPerPage - data.length;
  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };
  return (
    <div className={root}>
      <Paper className={paper}>
        <Box className={headerBtns}>
          <MultiselectAccount
            listData={accountsList}
            handleFilter={handleAccountFilter}
          />
          <OutlinedInput
            id="search"
            placeholder={t("search")}
            className={search}
            onChange={handleSearch}
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon className={searchIcon} edge="end" />
              </InputAdornment>
            }
          />
          <Tooltip title={t("export")}>
            <Button
              className={btnLabel}
              startIcon={<GetApp className={btnIcon} />}
              onClick={handleExportUsers}
              disabled={selected.length === 0}
            >
              {t("export")}
            </Button>
          </Tooltip>
          {PermissionsService.isAllowed("USER_DETACH") && (
            <Tooltip title={t("delete")}>
              <Button
                className={btnLabel}
                startIcon={<Delete className={btnIcon} />}
                onClick={handleOpenDeleteModal}
                disabled={selected.length === 0}
              >
                {t("delete")}
              </Button>
            </Tooltip>
          )}
        </Box>
        <TableContainer>
          <Table className={tableStyle} size="medium">
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    indeterminate={
                      selected.length > 0 && selected.length < data.length
                    }
                    checked={data.length > 0 && selected.length === data.length}
                    onChange={handleSelectAllClick}
                    color="primary"
                  />
                </TableCell>
                {cells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    align="left"
                    style={{ minWidth: headCell.minWidth }}
                    padding={headCell.disablePadding ? "none" : "default"}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <Box className={headerColumn}>
                      {headCell.sortable ? (
                        <TableSortLabel
                          active={orderBy === headCell.id}
                          direction={orderBy === headCell.id ? order : "asc"}
                          onClick={createSortHandler(headCell.id)}
                        >
                          <Typography className={headerLabel} variant="body2">
                            {t(headCell.label)}
                          </Typography>
                        </TableSortLabel>
                      ) : (
                        <Typography className={headerLabel} variant="body2">
                          {t(headCell.label)}
                        </Typography>
                      )}
                    </Box>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => {
                const isItemSelected = isSelected(row);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={Math.random()}
                    selected={isItemSelected}
                    classes={{
                      selected: selectedRow,
                    }}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        onClick={(event) => handleSelect(event, row)}
                        checked={isItemSelected}
                        inputProps={{ "aria-labelledby": labelId }}
                        color="primary"
                      />
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                    >
                      <Typography variant="body2">{row.username}</Typography>
                      <Typography color="primary" variant="body2">
                        {row.email}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography variant="body2">{row.company}</Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography variant="body2">{t(row.role) || row.role}</Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography variant="body2">
                        {row.lastLogin
                          ? moment(row.lastLogin).format("MMM D,YYYY")
                          : "-"}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography variant="body2">
                        {row.createdAt
                          ? moment(row.createdAt).format("MMM D,YYYY")
                          : "-"}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      {PermissionsService.isAllowed("USER_DETACH") && (
                        <Tooltip
                          title={
                            Boolean(currentAccount)
                              ? t("detachUser")
                              : t("disabledBtnMsg")
                          }
                        >
                          <Button
                            className={clsx(actionBtn, {
                              [disabledBtn]: !Boolean(currentAccount),
                            })}
                            disableRipple
                            onClick={
                              Boolean(currentAccount) &&
                              (() => handleOpenDetachModal(row))
                            }
                          >
                            <DetachIcon className={icon} />
                          </Button>
                        </Tooltip>
                      )}
                      {PermissionsService.isAllowed("USER_EDIT") && (
                        <Tooltip
                          title={
                            Boolean(currentAccount)
                              ? t("edit")
                              : t("disabledBtnMsg")
                          }
                        >
                          <Button
                            className={clsx(actionBtn, {
                              [disabledBtn]: !Boolean(currentAccount),
                            })}
                            disableRipple
                            onClick={
                              Boolean(currentAccount) &&
                              (() => handleOpenEditUserForm(row))
                            }
                          >
                            <EditIcon className={icon} />
                          </Button>
                        </Tooltip>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={8} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          labelDisplayedRows={({ from, to, count }) => {
            return t("itemCountOnPageInTable", { from, to, count });
          }}
          labelRowsPerPage={t("labelRowsPerPage")}
          nextIconButtonText={t("nextIconButtonText")}
          backIconButtonText={t("backIconButtonText")}
          rowsPerPageOptions={[3, 5, 10, 25]}
          component="div"
          count={pageCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
};

export default AppTable;
