import React, { useEffect } from "react";
import { AppLoader } from "components/commons";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import Languages from "constants/languages";
import moment from "moment";
import { useTranslation } from "react-i18next";
import history from "history.js";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
}));

const MottechRedirect = ({ location }) => {
  const { i18n } = useTranslation();

  const { container } = useStyles();
  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const paramLang = urlParams.get("language");
    const clientId = urlParams.get("clientId");
    if (paramLang) {
      const lang = Languages.find((lang) => lang.lang === paramLang);
      if (lang) {
        i18n.changeLanguage(paramLang);
        const body = document.querySelector("html");
        body.dir = lang.direction;
        body.lang = paramLang;
        moment.locale(paramLang);
        localStorage.setItem("language", paramLang);
      }
    }
    localStorage.setItem("mottechClientId", clientId);
    const token = localStorage.getItem("token");
    if (!token) {
      history.push("/login");
    } else {
      history.push("/dashboard");
    }
  }, [location]);
  return (
    <Box className={container}>
      <AppLoader />
    </Box>
  );
};

export default MottechRedirect;
