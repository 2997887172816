import axios from "axios";
import { toastr } from "react-redux-toastr";
import * as r from "constants/apiRoutes";
import history from "../history";
import i18n from "../i18n";
import {
  downloadCSVAccounts,
  downloadCSVUsers,
} from "utils/downloadCSVFromJson";
const { grecaptcha } = window;
const AXIOS = axios.create({
  headers: {
    "Access-Control-Allow-Credentials": true,
    "Content-Type": "application/json",
  },
  withCredentials: true,
});

const generateRecaptcha = (action) => {
  return new Promise((resolve, reject) => {
    grecaptcha.ready(() => {
      grecaptcha
        .execute("6Lfp0akUAAAAAAQSDfZnoDSmBWM5us9sK2z6L80b", {
          action,
        })
        .then((token) => {
          resolve(token);
        });
    });
  });
};

export const getOtp = async (phone) => {
  const recaptcha = await generateRecaptcha("login");
  return AXIOS.get(r.OTP, {
    params: { phoneNumber: phone, "g-recaptcha-response": recaptcha },
  })
    .then((result) => {
      return result.data;
    })
    .catch((err) => {
      if (err.response.status === 404) {
        history.push("/no-member");
      }
      throw err;
    });
};

export const login = (data) => {
  return AXIOS.post(r.LOGIN, data)
    .then((result) => {
      localStorage.setItem("token", result.data.data.token);
      localStorage.setItem("role", result.data.data.role);
      return result.data;
    })
    .catch((err) => {
      toastr.error("Error", i18n.t(err.response.data.error));
      throw err;
    });
};

export const getMe = () => {
  const token = localStorage.getItem("token");
  const lang = localStorage.getItem("language") || "en";
  return AXIOS.get(r.ME, {
    headers: {
      "x-access-token": token,
      "accept-language": lang,
    },
    params: {
      responseType: "minimal",
    },
  })
    .then((result) => {
      window.Intercom("boot", {
        app_id: "vj6vcgka",
        email: result.data.data.email,
        user_id: result.data.data.id.toString(),
        name: result.data.data.name,
        phone: result.data.data.phoneNumber,
        job_title: result.data.data.farmRole,
      });
      return result.data.data;
    })
    .catch((err) => {
      toastr.error("Error", i18n.t(err.response.data.error));
      throw err;
    });
};

export const getCountryByIp = () => {
  return fetch(
    "https://api.ipdata.co/?api-key=cc5876932da19a1b0e133a47da30c51a73180f3ad0cfa3d6352809f2"
  )
    .then((res) => res.json())
    .catch((err) => {
      throw err;
    });
};

export const getAccountsByUser = (
  userId,
  search,
  pagination,
  sort,
  parentFilter
) => {
  const token = localStorage.getItem("token");
  const lang = localStorage.getItem("language") || "en";
  return AXIOS.get(`${r.USERS}/${userId}/organizations`, {
    headers: {
      "x-access-token": token,
      "accept-language": lang,
    },
    params: {
      term: search,
      limit: pagination.limit,
      page: pagination.page,
      sort: sort,
      parentFilter: parentFilter,
    },
  })
    .then((result) => {
      return result.data.data;
    })
    .catch((err) => {
      toastr.error("Error", i18n.t(err.response.data.error));
      throw err;
    });
};

export const getAccounts = (
  userId,
  organizationId,
  search,
  pagination,
  sort,
  parentFilter
) => {
  const token = localStorage.getItem("token");
  const lang = localStorage.getItem("language") || "en";
  return AXIOS.get(
    `${r.USERS}/${userId}/organizations/${organizationId}/children`,
    {
      headers: {
        "x-access-token": token,
        "accept-language": lang,
      },
      params: {
        term: search,
        limit: pagination.limit,
        page: pagination.page,
        sort: sort,
        parentFilter: parentFilter,
      },
    }
  )
    .then((result) => {
      return result.data.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const getAccountsList = (userId, filterAccountsIds) => {
  const token = localStorage.getItem("token");
  const lang = localStorage.getItem("language") || "en";
  return AXIOS.get(`${r.USERS}/${userId}/organizations/minimal`, {
    headers: {
      "x-access-token": token,
      "accept-language": lang,
    },
    params: {
      filterOrganizationsIds: filterAccountsIds,
    },
  })
    .then((result) => {
      return result.data.data;
    })
    .catch((err) => {
      toastr.error("Error", i18n.t(err.response.data.error));
      throw err;
    });
};

export const createAccount = (accountData) => {
  const token = localStorage.getItem("token");
  const lang = localStorage.getItem("language") || "en";
  return AXIOS.post(
    `${r.ORGANIZATION}/${accountData.parentAccount}/organizations`,
    {
      name: accountData.name,
      country: accountData.countryISO,
      email: accountData.email,
    },
    {
      headers: {
        "x-access-token": token,
        "accept-language": lang,
      },
    }
  )
    .then((result) => {
      toastr.success("Success", i18n.t("createAccountSuccess"));
      return result.data.data;
    })
    .catch((err) => {
      toastr.error("Error", i18n.t(err.response.data.error));
      throw err;
    });
};

export const getAccount = (userId, accountId) => {
  const token = localStorage.getItem("token");
  const lang = localStorage.getItem("language") || "en";
  return AXIOS.get(`${r.USERS}/${userId}/organizations/${accountId}`, {
    headers: {
      "x-access-token": token,
      "accept-language": lang,
    },
  })
    .then((result) => {
      return result.data.data;
    })
    .catch((err) => {
      toastr.error("Error", i18n.t(err.response.data.error));
      throw err;
    });
};

export const editAccount = (userId, accountId, accountData) => {
  const token = localStorage.getItem("token");
  const lang = localStorage.getItem("language") || "en";
  let body = {};
  if (accountData.parentId) {
    body = {
      name: accountData.name,
      country: accountData.countryISO,
      email: accountData.email,
      parentId: accountData.parentId,
    };
  } else {
    body = {
      name: accountData.name,
      country: accountData.countryISO,
      email: accountData.email,
    };
  }
  return AXIOS.put(`${r.USERS}/${userId}/organizations/${accountId}`, body, {
    headers: {
      "x-access-token": token,
      "accept-language": lang,
    },
  })
    .then((result) => {
      toastr.success("Success", i18n.t("editAccountSuccess"));
      return result.data.data;
    })
    .catch((err) => {
      toastr.error("Error", i18n.t(err.response.data.error));
      throw err;
    });
};

export const deleteAccounts = (userId, accounts) => {
  const token = localStorage.getItem("token");
  const lang = localStorage.getItem("language") || "en";
  return AXIOS.delete(`${r.USERS}/${userId}/organizations`, {
    headers: {
      "x-access-token": token,
      "accept-language": lang,
    },
    params: {
      organizationIds: accounts,
    },
  })
    .then((result) => {
      toastr.success("Success", i18n.t("deleteAccountsSuccess"));
      return result.data.data;
    })
    .catch((err) => {
      toastr.error("Error", i18n.t(err.response.data.error));
      throw err;
    });
};

export const disableAccounts = (userId, accounts) => {
  const token = localStorage.getItem("token");
  const lang = localStorage.getItem("language") || "en";
  return AXIOS.put(
    `${r.USERS}/${userId}/organizations/disable`,
    {},
    {
      headers: {
        "x-access-token": token,
        "accept-language": lang,
      },
      params: {
        organizationIds: accounts,
      },
    }
  )
    .then((result) => {
      toastr.success("Success", i18n.t("disableAccountsSuccess"));
      return result.data.data;
    })
    .catch((err) => {
      toastr.error("Error", i18n.t(err.response.data.error));
      throw err;
    });
};

export const enableAccounts = (userId, accounts) => {
  const token = localStorage.getItem("token");
  const lang = localStorage.getItem("language") || "en";
  return AXIOS.put(
    `${r.USERS}/${userId}/organizations/enable`,
    {},
    {
      headers: {
        "x-access-token": token,
        "accept-language": lang,
      },
      params: {
        organizationIds: accounts,
      },
    }
  )
    .then((result) => {
      toastr.success("Success", i18n.t("enableAccountsSuccess"));
      return result.data.data;
    })
    .catch((err) => {
      toastr.error("Error", i18n.t(err.response.data.error));
      throw err;
    });
};

export const exportAccounts = (userId, accounts) => {
  const token = localStorage.getItem("token");
  const lang = localStorage.getItem("language") || "en";
  return AXIOS.get(`${r.USERS}/${userId}/organizations/export`, {
    headers: {
      "x-access-token": token,
      "accept-language": lang,
    },
    params: {
      organizationIds: accounts,
    },
  })
    .then((result) => {
      downloadCSVAccounts("accounts", result.data.data);
      return result.data.data;
    })
    .catch((err) => {
      toastr.error("Error", i18n.t(err.response.data.error));
      throw err;
    });
};

export const getUsers = (
  organizationId,
  search,
  pagination,
  sort,
  filterRoles,
  filterOrganizationsIds,
  allAccountsSelected
) => {
  const token = localStorage.getItem("token");
  const lang = localStorage.getItem("language") || "en";
  return AXIOS.get(`${r.ORGANIZATION}/${organizationId}/users`, {
    headers: {
      "x-access-token": token,
      "accept-language": lang,
    },
    params: {
      term: search,
      limit: pagination.limit,
      page: pagination.page,
      sort: sort,
      filterRoles: filterRoles,
      filterOrganizationsIds: filterOrganizationsIds,
      allAccounts: allAccountsSelected,
    },
  })
    .then((result) => {
      return result.data.data;
    })
    .catch((err) => {
      toastr.error("Error", i18n.t(err.response.data.error));
      throw err;
    });
};

export const detachUser = (userId, organizationId) => {
  const token = localStorage.getItem("token");
  const lang = localStorage.getItem("language") || "en";
  return AXIOS.delete(`${r.ORGANIZATION}/${organizationId}/users/${userId}`, {
    headers: {
      "x-access-token": token,
      "accept-language": lang,
    },
  })
    .then((result) => {
      toastr.success("Success", i18n.t("detachUserSuccess"));
      return result.data.data;
    })
    .catch((err) => {
      toastr.error("Error", i18n.t(err.response.data.error));
      throw err;
    });
};

export const deleteUsers = (usersIds) => {
  const token = localStorage.getItem("token");
  const lang = localStorage.getItem("language") || "en";
  return AXIOS.delete(`${r.USERS}`, {
    headers: {
      "x-access-token": token,
      "accept-language": lang,
    },
    params: {
      usersIds,
    },
  })
    .then((result) => {
      toastr.success("Success", i18n.t("deleteUsersSuccess"));
      return result.data.data;
    })
    .catch((err) => {
      toastr.error("Error", i18n.t(err.response.data.error));
      throw err;
    });
};

export const exportUsers = (users) => {
  const token = localStorage.getItem("token");
  const lang = localStorage.getItem("language") || "en";
  return AXIOS.get(`${r.USERS}/export`, {
    headers: {
      "x-access-token": token,
      "accept-language": lang,
    },
    params: {
      users,
    },
  })
    .then((result) => {
      downloadCSVUsers("users", result.data.data);
      return result.data.data;
    })
    .catch((err) => {
      toastr.error("Error", i18n.t(err.response.data.error));
      throw err;
    });
};

export const addUser = (userData, organizationId) => {
  const token = localStorage.getItem("token");
  const lang = localStorage.getItem("language") || "en";
  return AXIOS.post(`${r.ORGANIZATION}/${organizationId}/users`, userData, {
    headers: {
      "x-access-token": token,
      "accept-language": lang,
    },
  })
    .then((result) => {
      toastr.success("Success", i18n.t("addUserSuccess"));
      return result.data.data;
    })
    .catch((err) => {
      toastr.error("Error", i18n.t(err.response.data.error));
      throw err;
    });
};

export const editUser = (userId, organizationId, userData) => {
  const token = localStorage.getItem("token");
  const lang = localStorage.getItem("language") || "en";
  return AXIOS.put(
    `${r.ORGANIZATION}/${organizationId}/users/${userId}`,
    userData,
    {
      headers: {
        "x-access-token": token,
        "accept-language": lang,
      },
    }
  )
    .then((result) => {
      toastr.success("Success", i18n.t("editUserSuccess"));
      return result.data.data;
    })
    .catch((err) => {
      toastr.error("Error", i18n.t(err.response.data.error));
      throw err;
    });
};

export const getAccountHierarchy = (userId, organizationId) => {
  const token = localStorage.getItem("token");
  const lang = localStorage.getItem("language") || "en";
  return AXIOS.get(
    `${r.USERS}/${userId}/organizations/${organizationId}/hierarchy`,
    {
      headers: {
        "x-access-token": token,
        "accept-language": lang,
      },
    }
  )
    .then((result) => {
      return result.data.data;
    })
    .catch((err) => {
      toastr.error("Error", i18n.t(err.response.data.error));
      throw err;
    });
};

export const resendInvitation = (organizationId, userId) => {
  const token = localStorage.getItem("token");
  const lang = localStorage.getItem("language") || "en";
  return AXIOS.post(
    `${r.ORGANIZATION}/${organizationId}/users/${userId}/invitation`,
    {},
    {
      headers: {
        "x-access-token": token,
        "accept-language": lang,
      },
    }
  )
    .then((result) => {
      toastr.success("Success", i18n.t("resendIvitationSuccess"));
      return result.data.data;
    })
    .catch((err) => {
      toastr.error("Error", i18n.t(err.response.data.error));
      throw err;
    });
};

export const getUsersForAdd = (search) => {
  const token = localStorage.getItem("token");
  const lang = localStorage.getItem("language") || "en";
  return AXIOS.get(`${r.USERS}`, {
    headers: {
      "x-access-token": token,
      "accept-language": lang,
    },
    params: {
      term: search,
      limit: 50,
    },
  })
    .then((result) => {
      return result.data.data;
    })
    .catch((err) => {
      toastr.error("Error", i18n.t(err.response.data.error));
      throw err;
    });
};

export const getRoles = () => {
  const token = localStorage.getItem("token");
  const lang = localStorage.getItem("language") || "en";
  return AXIOS.get(`${r.ROLES}`, {
    headers: {
      "x-access-token": token,
      "accept-language": lang,
    },
  })
    .then((result) => {
      return result.data.data;
    })
    .catch((err) => {
      toastr.error("Error", i18n.t(err.response.data.error));
      throw err;
    });
};

export const getSubscriptionStatus = (organizationId) => {
  const token = localStorage.getItem("token");
  const lang = localStorage.getItem("language") || "en";
  return AXIOS.get(`${r.ORGANIZATION}/${organizationId}/subscriptions/status`, {
    headers: {
      "x-access-token": token,
      "accept-language": lang,
    },
  })
    .then((result) => {
      return result.data.data;
    })
    .catch((err) => {
      toastr.error("Error", i18n.t(err.response.data.error));
      throw err;
    });
};

export const getPermissions = (userId, organizationId) => {
  const token = localStorage.getItem("token");
  const lang = localStorage.getItem("language") || "en";
  return AXIOS.get(
    `${r.USERS}/${userId}/organizations/${organizationId}/privileges`,
    {
      headers: {
        "x-access-token": token,
        "accept-language": lang,
      },
    }
  )
    .then((result) => {
      return result.data.data.privileges;
    })
    .catch((err) => {
      toastr.error("Error", i18n.t(err.response.data.error));
      throw err;
    });
};
export const getStatisticsForGridView = (since, organizationId) => {
  const token = localStorage.getItem("token");
  const lang = localStorage.getItem("language") || "en";
  return AXIOS.get(
    `${r.V2_1_ORGANIZATION}/${organizationId}/devices/statistics`,
    {
      headers: {
        Authorization: token,
        "accept-language": lang,
      },
      params: {
        since: since,
      },
    }
  )
    .then((result) => {
      return result.data;
    })
    .catch((err) => {
      toastr.error("Error", i18n.t(err.response.data.error));
      throw err;
    });
};

export const getDashboardSettings = (organizationId, plotId) => {
  const token = localStorage.getItem("token");
  const lang = localStorage.getItem("language") || "en";
  return AXIOS.get(
    `${r.ORGANIZATION}/${organizationId}/plots/${plotId}/widgets`,
    {
      headers: {
        "x-access-token": token,
        "accept-language": lang,
      },
    }
  )
    .then((result) => {
      return result.data.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const saveDashboardSettings = (organizationId, plotId, settings) => {
  const token = localStorage.getItem("token");
  const lang = localStorage.getItem("language") || "en";
  return AXIOS.put(
    `${r.ORGANIZATION}/${organizationId}/plots/${plotId}/widgets`,
    {
      config: settings,
    },
    {
      headers: {
        "x-access-token": token,
        "accept-language": lang,
      },
    }
  )
    .then((result) => {
      return result.data.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const getInsightsList = (organizationId, plotId) => {
  const token = localStorage.getItem("token");
  const lang = localStorage.getItem("language") || "en";
  return AXIOS.get(`${r.ORGANIZATION}/${organizationId}/alerts/list`, {
    headers: {
      "x-access-token": token,
      "accept-language": lang,
    },
    params: {
      plotId,
    },
  })
    .then((result) => {
      return result.data.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const addCommentForInsight = (alertId, comment) => {
  const token = localStorage.getItem("token");
  const lang = localStorage.getItem("language") || "en";
  return AXIOS.post(
    `${r.ALERTS}/event/${alertId}`,
    {
      text: comment,
    },
    {
      headers: {
        "x-access-token": token,
        "accept-language": lang,
      },
    }
  )
    .then((result) => {
      toastr.success("Success", i18n.t("addCommentSuccess"));
      return result.data.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const deleteInsight = (alertId, organizationId) => {
  const token = localStorage.getItem("token");
  const lang = localStorage.getItem("language") || "en";
  return AXIOS.put(
    `${r.ORGANIZATION}/${organizationId}/alerts/${alertId}/action`,
    {
      action: "ALERT_DELETE",
    },
    {
      headers: {
        "x-access-token": token,
        "accept-language": lang,
      },
    }
  )
    .then((result) => {
      toastr.success("Success", i18n.t("deleteInsightSuccess"));
      return result.data.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const markAsDone = (alertId, organizationId) => {
  const token = localStorage.getItem("token");
  const lang = localStorage.getItem("language") || "en";
  return AXIOS.put(
    `${r.ORGANIZATION}/${organizationId}/alerts/${alertId}/action`,
    {
      action: "ALERT_MARK_AS_DONE",
    },
    {
      headers: {
        "x-access-token": token,
        "accept-language": lang,
      },
    }
  )
    .then((result) => {
      toastr.success("Success", i18n.t("markAsDoneInsightSuccess"));
      return result.data.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const remindMe = (remindIn, alertId, organizationId) => {
  const token = localStorage.getItem("token");
  const lang = localStorage.getItem("language") || "en";
  return AXIOS.put(
    `${r.ORGANIZATION}/${organizationId}/alerts/${alertId}/snooze`,
    { remindIn: remindIn },
    {
      headers: {
        "x-access-token": token,
        "accept-language": lang,
      },
    }
  )
    .then((result) => {
      toastr.success("Success", i18n.t("successRemind"));
      return result.data.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const turnOffAllNotificationOfType = (alertId) => {
  const token = localStorage.getItem("token");
  const lang = localStorage.getItem("language") || "en";
  return AXIOS.post(
    `${r.ALERTS}/${alertId}/notification`,
    {
      action: "sendNotifications",
      payload: {
        state: "off",
      },
    },
    {
      headers: {
        "x-access-token": token,
        "accept-language": lang,
      },
    }
  )
    .then((result) => {
      toastr.success("Success", i18n.t("turnOffAllNotificationOfTypeSuccess"));
      return result.data.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const getWaterPotentialData = (organizationId, deviceId, from, to) => {
  const token = localStorage.getItem("token");
  const lang = localStorage.getItem("language") || "en";
  return AXIOS.get(
    `${r.V2_1_ORGANIZATION}/${organizationId}/devices/${deviceId}/metrics/waterPotential`,
    {
      headers: {
        Authorization: token,
        "accept-language": lang,
      },
      params: {
        start: new Date(from).toISOString(),
        end: new Date(to).toISOString(),
      },
    }
  )
    .then((result) => {
      return result.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const getAlertInfo = (alertId) => {
  const token = localStorage.getItem("token");
  const lang = localStorage.getItem("language") || "en";
  return AXIOS.get(`${r.ALERTS}/event/${alertId}`, {
    headers: {
      "x-access-token": token,
      "accept-language": lang,
    },
  })
    .then((result) => {
      return result.data.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const getFieldWeatherHistory = (organizationId, fieldId, from, to) => {
  const token = localStorage.getItem("token");
  const lang = localStorage.getItem("language") || "en";
  return AXIOS.get(
    `${r.ORGANIZATION}/${organizationId}/fields/${fieldId}/weather/history`,
    {
      headers: {
        "x-access-token": token,
        "accept-language": lang,
      },
      params: {
        from,
        to,
      },
    }
  )
    .then((result) => {
      return result.data.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const getRainAndIrrigationHistory = (
  organizationId,
  plotId,
  from,
  to
) => {
  const token = localStorage.getItem("token");
  const lang = localStorage.getItem("language") || "en";
  return AXIOS.get(
    `${r.ORGANIZATION}/${organizationId}/plots/${plotId}/widgets/rainAndIrrigation`,
    {
      headers: {
        "x-access-token": token,
        "accept-language": lang,
      },
      params: {
        from,
        to,
      },
    }
  )
    .then((result) => {
      return result.data.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const getDeviceCellstateData = (
  organizationId,
  deviceId,
  from,
  to,
  device
) => {
  const token = localStorage.getItem("token");
  const lang = localStorage.getItem("language") || "en";
  return AXIOS.get(
    `${r.V2_1_ORGANIZATION}/${organizationId}/devices/${deviceId}/metrics/batteryCharge`,
    {
      headers: {
        Authorization: token,
        "accept-language": lang,
      },
      params: {
        start: new Date(from).toISOString(),
        end: new Date(to).toISOString(),
      },
    }
  )
    .then((result) => {
      return { cellstate: result.data, device };
    })
    .catch((err) => {
      return err.response.data.error;
    });
};

export const getWaterPotentialLastValue = (organizationId, deviceId) => {
  const token = localStorage.getItem("token");
  const lang = localStorage.getItem("language") || "en";
  return AXIOS.get(
    `${r.V2_1_ORGANIZATION}/${organizationId}/devices/${deviceId}/metrics/waterPotential`,
    {
      headers: {
        Authorization: token,
        "accept-language": lang,
      },
      params: {
        lastValue: true,
      },
    }
  )
    .then((result) => {
      return result.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const getFieldWeatherLastData = (organizationId, fieldId) => {
  const token = localStorage.getItem("token");
  const lang = localStorage.getItem("language") || "en";
  return AXIOS.get(
    `${r.ORGANIZATION}/${organizationId}/fields/${fieldId}/weather/history`,
    {
      headers: {
        "x-access-token": token,
        "accept-language": lang,
      },
      params: {
        last: true,
      },
    }
  )
    .then((result) => {
      return result.data.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const getFieldWeatherForecast = (organizationId, fieldId) => {
  const token = localStorage.getItem("token");
  const lang = localStorage.getItem("language") || "en";
  return AXIOS.get(
    `${r.ORGANIZATION}/${organizationId}/fields/${fieldId}/weather`,
    {
      headers: {
        "x-access-token": token,
        "accept-language": lang,
      },
    }
  )
    .then((result) => {
      return result.data.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const getDeviceCellstateLastData = (
  organizationId,
  deviceId,
  device
) => {
  const token = localStorage.getItem("token");
  const lang = localStorage.getItem("language") || "en";
  return AXIOS.get(
    `${r.V2_1_ORGANIZATION}/${organizationId}/devices/${deviceId}/metrics/batteryCharge`,
    {
      headers: {
        Authorization: token,
        "accept-language": lang,
      },
      params: {
        lastValue: true,
      },
    }
  )
    .then((result) => {
      return { cellstate: result.data, device };
    })
    .catch((err) => {
      return err.response.data.error;
    });
};

export const getPlotsWithCoordinates = (organizationId, fieldId) => {
  const token = localStorage.getItem("token");
  const lang = localStorage.getItem("language") || "en";
  return AXIOS.get(
    `${r.V2_1_ORGANIZATION}/${organizationId}/fields/${fieldId}/plots`,
    {
      headers: {
        Authorization: token,
        "accept-language": lang,
      },
    }
  )
    .then((result) => {
      return result.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const getDeviceMetricsLastValues = (deviceId) => {
  const token = localStorage.getItem("token");
  const lang = localStorage.getItem("language") || "en";
  const accountFromStorage = localStorage.getItem("accountData");
  const organizationId = JSON.parse(accountFromStorage).id;
  return AXIOS.get(
    `${r.V2_1_ORGANIZATION}/${organizationId}/devices/${deviceId}/metricsLastValues`,
    {
      headers: {
        Authorization: token,
        "accept-language": lang,
      },
    }
  )
    .then((result) => {
      return result.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const getPlotSettings = (organizationId, plotId) => {
  const token = localStorage.getItem("token");
  const lang = localStorage.getItem("language") || "en";
  return AXIOS.get(`${r.ORGANIZATION}/${organizationId}/plots/${plotId}`, {
    headers: {
      "x-access-token": token,
      "accept-language": lang,
    },
  })
    .then((result) => {
      return result.data.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const editPlotSettings = (organizationId, plotId, plotData) => {
  const token = localStorage.getItem("token");
  const lang = localStorage.getItem("language") || "en";
  return AXIOS.put(
    `${r.ORGANIZATION}/${organizationId}/plots/${plotId}`,
    plotData,
    {
      headers: {
        "x-access-token": token,
        "accept-language": lang,
      },
    }
  )
    .then((result) => {
      toastr.success("Success", i18n.t("editPlotSuccess"));
      return result.data.data;
    })
    .catch((err) => {
      toastr.error("Error", i18n.t(err.response.data.error));
      throw err;
    });
};

export const getFieldsList = (organizationId) => {
  const token = localStorage.getItem("token");
  const lang = localStorage.getItem("language") || "en";
  return AXIOS.get(`${r.ORGANIZATION}/${organizationId}/fields`, {
    headers: {
      "x-access-token": token,
      "accept-language": lang,
    },
  })
    .then((result) => {
      return result.data.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const getCropsList = () => {
  const token = localStorage.getItem("token");
  const lang = localStorage.getItem("language") || "en";
  return AXIOS.get(`${r.CROPS}`, {
    headers: {
      "x-access-token": token,
      "accept-language": lang,
    },
  })
    .then((result) => {
      return result.data.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const getSoilList = () => {
  const token = localStorage.getItem("token");
  const lang = localStorage.getItem("language") || "en";
  return AXIOS.get(`${r.SOIL}`, {
    headers: {
      "x-access-token": token,
      "accept-language": lang,
    },
  })
    .then((result) => {
      return result.data.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const getGraphEvents = (organizationId, deviceId, from, to) => {
  const token = localStorage.getItem("token");
  const lang = localStorage.getItem("language") || "en";
  return AXIOS.get(
    `${r.ORGANIZATION}/${organizationId}/devices/${deviceId}/events`,
    {
      headers: {
        "x-access-token": token,
        "accept-language": lang,
      },
      params: {
        from,
        to,
      },
    }
  )
    .then((result) => {
      return result.data.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const getMyProductsList = (organizationId, search, pagination, sort) => {
  const token = localStorage.getItem("token");
  const lang = localStorage.getItem("language") || "en";
  return AXIOS.get(`${r.ORGANIZATION}/${organizationId}/licence`, {
    headers: {
      "x-access-token": token,
      "accept-language": lang,
    },
    params: {
      term: search,
      limit: pagination.limit,
      page: pagination.page,
      sort: sort,
    },
  })
    .then((result) => {
      return result.data.data;
    })
    .catch((err) => {
      toastr.error("Error", i18n.t(err.response.data.error));
      throw err;
    });
};

export const getExpiringLicences = (organizationId) => {
  const token = localStorage.getItem("token");
  const lang = localStorage.getItem("language") || "en";
  return AXIOS.get(`${r.ORGANIZATION}/${organizationId}/licence/expiring`, {
    headers: {
      "x-access-token": token,
      "accept-language": lang,
    },
  })
    .then((result) => {
      return result.data.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const getLicenceStatusBar = (organizationId, licenceIds) => {
  const token = localStorage.getItem("token");
  const lang = localStorage.getItem("language") || "en";
  return AXIOS.get(`${r.ORGANIZATION}/${organizationId}/licence/statusInfo`, {
    headers: {
      "x-access-token": token,
      "accept-language": lang,
    },
    params: {
      licenceIds,
    },
  })
    .then((result) => {
      return result.data.data;
    })
    .catch((err) => {
      toastr.error("Error", i18n.t(err.response.data.error));
      throw err;
    });
};

export const addProductsToCart = (organizationId, productIds) => {
  const token = localStorage.getItem("token");
  const lang = localStorage.getItem("language") || "en";
  return AXIOS.post(
    `${r.ORGANIZATION}/${organizationId}/products/cart`,
    { productIds },
    {
      headers: {
        "x-access-token": token,
        "accept-language": lang,
      },
    }
  )
    .then((result) => {
      toastr.success("Success", i18n.t("addProductToCartSuccess"));
      return result.data.data;
    })
    .catch((err) => {
      toastr.error("Error", i18n.t(err.response.data.error));
      throw err;
    });
};

export const getCartTotals = (organizationId, period) => {
  const token = localStorage.getItem("token");
  const lang = localStorage.getItem("language") || "en";
  return AXIOS.get(`${r.ORGANIZATION}/${organizationId}/products/cart/totals`, {
    headers: {
      "x-access-token": token,
      "accept-language": lang,
    },
    params: {
      period,
    },
  })
    .then((result) => {
      return result.data.data;
    })
    .catch((err) => {
      toastr.error("Error", i18n.t(err.response.data.error));
      throw err;
    });
};

export const getCartProducts = (organizationId, pagination, sort) => {
  const token = localStorage.getItem("token");
  const lang = localStorage.getItem("language") || "en";
  return AXIOS.get(`${r.ORGANIZATION}/${organizationId}/products/cart`, {
    headers: {
      "x-access-token": token,
      "accept-language": lang,
    },
    params: {
      limit: pagination.limit,
      page: pagination.page,
      sort: sort,
    },
  })
    .then((result) => {
      return result.data.data;
    })
    .catch((err) => {
      toastr.error("Error", i18n.t(err.response.data.error));
      throw err;
    });
};

export const removeProductFromCart = (organizationId, licenceId) => {
  const token = localStorage.getItem("token");
  const lang = localStorage.getItem("language") || "en";
  return AXIOS.delete(
    `${r.ORGANIZATION}/${organizationId}/products/cart/${licenceId}`,
    {
      headers: {
        "x-access-token": token,
        "accept-language": lang,
      },
    }
  )
    .then((result) => {
      return result.data.data;
    })
    .catch((err) => {
      toastr.error("Error", i18n.t(err.response.data.error));
      throw err;
    });
};

export const getFastSpringSession = (organizationId, period) => {
  const token = localStorage.getItem("token");
  const lang = localStorage.getItem("language") || "en";
  return AXIOS.get(`${r.ORGANIZATION}/${organizationId}/orders/session`, {
    headers: {
      "x-access-token": token,
      "accept-language": lang,
    },
    params: {
      period: period,
    },
  })
    .then((result) => {
      return result.data.data;
    })
    .catch((err) => {
      toastr.error("Error", i18n.t(err.response.data.error));
      throw err;
    });
};

export const payOffline = (organizationId, period) => {
  const token = localStorage.getItem("token");
  const lang = localStorage.getItem("language") || "en";
  return AXIOS.get(`${r.ORGANIZATION}/${organizationId}/orders/payOffline`, {
    headers: {
      "x-access-token": token,
      "accept-language": lang,
    },
    params: {
      period,
    },
  })
    .then((result) => {
      toastr.success("Success", i18n.t("payOfflineSuccessMsg"));
      return result.data.data;
    })
    .catch((err) => {
      toastr.error("Error", i18n.t(err.response.data.error));
      throw err;
    });
};
export const getLicenceById = (organizationId, licenceId) => {
  const token = localStorage.getItem("token");
  const lang = localStorage.getItem("language") || "en";
  return AXIOS.get(`${r.ORGANIZATION}/${organizationId}/licence/${licenceId}`, {
    headers: {
      "x-access-token": token,
      "accept-language": lang,
    },
  })
    .then((result) => {
      return result.data.data;
    })
    .catch((err) => {
      toastr.error("Error", i18n.t(err.response.data.error));
      throw err;
    });
};

export const getDevicesList = (organizationId) => {
  const token = localStorage.getItem("token");
  const lang = localStorage.getItem("language") || "en";
  return AXIOS.get(
    `${r.ORGANIZATION}/${organizationId}/licence/availableDevices`,
    {
      headers: {
        "x-access-token": token,
        "accept-language": lang,
      },
    }
  )
    .then((result) => {
      return result.data.data;
    })
    .catch((err) => {
      toastr.error("Error", i18n.t(err.response.data.error));
      throw err;
    });
};

export const getProductTypes = () => {
  const token = localStorage.getItem("token");
  const lang = localStorage.getItem("language") || "en";
  return AXIOS.get(`${r.SERVER_URL}/productTypes`, {
    headers: {
      "x-access-token": token,
      "accept-language": lang,
    },
  })
    .then((result) => {
      return result.data.data;
    })
    .catch((err) => {
      toastr.error("Error", i18n.t(err.response.data.error));
      throw err;
    });
};

export const editLicenceData = (organizationId, licenceId, licenceData) => {
  const token = localStorage.getItem("token");
  const lang = localStorage.getItem("language") || "en";
  return AXIOS.put(
    `${r.ORGANIZATION}/${organizationId}/licence/${licenceId}`,
    licenceData,
    {
      headers: {
        "x-access-token": token,
        "accept-language": lang,
      },
    }
  )
    .then((result) => {
      toastr.success("Success", i18n.t("editLicenceSuccess"));
      return result.data.data;
    })
    .catch((err) => {
      toastr.error("Error", i18n.t(err.response.data.error));
      throw err;
    });
};

export const getSoilTempData = (organizationId, deviceId, from, to) => {
  const token = localStorage.getItem("token");
  const lang = localStorage.getItem("language") || "en";
  return AXIOS.get(
    `${r.V2_1_ORGANIZATION}/${organizationId}/devices/${deviceId}/metrics/groundTemperature`,
    {
      headers: {
        Authorization: token,
        "accept-language": lang,
      },
      params: {
        start: new Date(from).toISOString(),
        end: new Date(to).toISOString(),
      },
    }
  )
    .then((result) => {
      return result.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const getTrialSubscription = (organizationId, quantity) => {
  const token = localStorage.getItem("token");
  return AXIOS.post(
    `${r.ORGANIZATION}/${organizationId}/orders/trialLicences`,
    { quantity },
    {
      headers: {
        "x-access-token": token,
      },
    }
  )
    .then((result) => {
      return result.data.data;
    })
    .catch((err) => {
      toastr.error("Error", i18n.t(err.response.data.error));
      throw err;
    });
};

export const getExpirationStatus = (organizationId) => {
  const token = localStorage.getItem("token");
  const lang = localStorage.getItem("language") || "en";
  return AXIOS.get(
    `${r.ORGANIZATION}/${organizationId}/licence/expirationStatus`,
    {
      headers: {
        "x-access-token": token,
        "accept-language": lang,
      },
    }
  )
    .then((result) => {
      return result.data.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const unsubscribe = (email, types) => {
  return AXIOS.get(`${r.EMAILS}/unsubscribe`, {
    params: {
      email,
      types,
    },
  })
    .then((result) => {
      return result.data.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const subsribe = () => {
  const token = localStorage.getItem("token");
  return AXIOS.post(
    `${r.EMAILS}/subscribe`,
    { types: ["OneMothBeforeExpiration", "ExpiredLicences"] },
    {
      headers: {
        "x-access-token": token,
      },
    }
  )
    .then((result) => {
      return result.data.data;
    })
    .catch((err) => {
      toastr.error("Error", i18n.t(err.response.data.error));
      throw err;
    });
};

export const getActivationProductsList = () => {
  const token = localStorage.getItem("token");
  const lang = localStorage.getItem("language") || "en";
  return AXIOS.get(`${r.DEVICES}/activation/products`, {
    headers: {
      "x-access-token": token,
      "accept-language": lang,
    },
  })
    .then((result) => {
      return result.data.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const activateDevice = (deviceData) => {
  const token = localStorage.getItem("token");
  const lang = localStorage.getItem("language") || "en";
  return AXIOS.post(`${r.DEVICES}/activation`, deviceData, {
    headers: {
      "x-access-token": token,
      "accept-language": lang,
    },
  })
    .then((result) => {
      return result.data.data;
    })
    .catch((err) => {
      toastr.error("Error", i18n.t(err.response.data.error));
      throw err;
    });
};

export const getDevicesByPlot = (organizationId, plotId) => {
  const token = localStorage.getItem("token");
  const lang = localStorage.getItem("language") || "en";
  return AXIOS.get(`${r.ORGANIZATION_V2}/${organizationId}/devices`, {
    headers: {
      "x-access-token": token,
      "accept-language": lang,
    },
    params: {
      plotId,
    },
  })
    .then((result) => {
      return result.data.data;
    })
    .catch((err) => {
      toastr.error("Error", i18n.t(err.response.data.error));
      throw err;
    });
};

export const getPipePressureData = (organizationId, deviceId, from, to) => {
  const token = localStorage.getItem("token");
  const lang = localStorage.getItem("language") || "en";
  return AXIOS.get(
    `${r.V2_1_ORGANIZATION}/${organizationId}/devices/${deviceId}/metrics/pipePressure`,
    {
      headers: {
        Authorization: token,
        "accept-language": lang,
      },
      params: {
        start: new Date(from).toISOString(),
        end: new Date(to).toISOString(),
      },
    }
  )
    .then((result) => {
      return result.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const getPowerVoltageData = (organizationId, deviceId, from, to) => {
  const token = localStorage.getItem("token");
  const lang = localStorage.getItem("language") || "en";
  return AXIOS.get(
    `${r.V2_1_ORGANIZATION}/${organizationId}/devices/${deviceId}/metrics/solarPanel`,
    {
      headers: {
        Authorization: token,
        "accept-language": lang,
      },
      params: {
        start: new Date(from).toISOString(),
        end: new Date(to).toISOString(),
      },
    }
  )
    .then((result) => {
      return result.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const getDeviceAirTemperatureData = (
  organizationId,
  deviceId,
  from,
  to
) => {
  const token = localStorage.getItem("token");
  const lang = localStorage.getItem("language") || "en";
  return AXIOS.get(
    `${r.V2_1_ORGANIZATION}/${organizationId}/devices/${deviceId}/metrics/airTemperature`,
    {
      headers: {
        Authorization: token,
        "accept-language": lang,
      },
      params: {
        start: new Date(from).toISOString(),
        end: new Date(to).toISOString(),
      },
    }
  )
    .then((result) => {
      return result.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const getDeviceAirHumidityData = (
  organizationId,
  deviceId,
  from,
  to
) => {
  const token = localStorage.getItem("token");
  const lang = localStorage.getItem("language") || "en";
  return AXIOS.get(
    `${r.V2_1_ORGANIZATION}/${organizationId}/devices/${deviceId}/metrics/airHumidity`,
    {
      headers: {
        Authorization: token,
        "accept-language": lang,
      },
      params: {
        start: new Date(from).toISOString(),
        end: new Date(to).toISOString(),
      },
    }
  )
    .then((result) => {
      return result.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const mottechLogin = (organizationId, serverUrl, username, password) => {
  const token = localStorage.getItem("token");
  const lang = localStorage.getItem("language") || "en";
  return AXIOS.post(
    `${r.V2_1_ORGANIZATION}/${organizationId}/autopilots/connection/mottech`,
    {
      serverUrl,
      username,
      password,
    },
    {
      headers: {
        Authorization: token,
        "accept-language": lang,
      },
    }
  )
    .then((result) => {
      toastr.success("Success", i18n.t("loginMottechSuccess"));
      return result.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const getIrrigationProviderConnection = (organizationId) => {
  const token = localStorage.getItem("token");
  const lang = localStorage.getItem("language") || "en";
  return AXIOS.get(
    `${r.V2_1_ORGANIZATION}/${organizationId}/autopilots/connection`,
    {
      headers: {
        Authorization: token,
        "accept-language": lang,
      },
    }
  )
    .then((result) => {
      return result.data;
    })
    .catch((err) => {
      throw err;
    });
};
export const getConnectionPrograms = (
  organizationId,
  irrigationProviderConnectionId
) => {
  const token = localStorage.getItem("token");
  const lang = localStorage.getItem("language") || "en";
  return AXIOS.get(
    `${r.V2_1_ORGANIZATION}/${organizationId}/autopilots/connection/${irrigationProviderConnectionId}/programs`,
    {
      headers: {
        Authorization: token,
        "accept-language": lang,
      },
    }
  )
    .then((result) => {
      return result.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const irrigationConnect = (
  organizationId,
  plotId,
  providerConnectionId,
  program
) => {
  const token = localStorage.getItem("token");
  const lang = localStorage.getItem("language") || "en";
  return AXIOS.post(
    `${r.V2_1_ORGANIZATION}/${organizationId}/plots/${plotId}/autopilots/controller`,
    {
      providerConnectionId,
      program,
    },
    {
      headers: {
        Authorization: token,
        "accept-language": lang,
      },
    }
  )
    .then((result) => {
      toastr.success("Success", i18n.t("irrigationConnectSuccess"));
      return result.data;
    })
    .catch((err) => {
      toastr.error("Error", i18n.t(err.response.data.error));
      throw err;
    });
};

export const getPlotIrrigationConnection = (organizationId, plotId) => {
  const token = localStorage.getItem("token");
  const lang = localStorage.getItem("language") || "en";
  return AXIOS.get(
    `${r.V2_1_ORGANIZATION}/${organizationId}/plots/${plotId}/autopilots/controller`,
    {
      headers: {
        Authorization: token,
        "accept-language": lang,
      },
    }
  )
    .then((result) => {
      return result.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const disconnectIrrigationController = (organizationId, plotId) => {
  const token = localStorage.getItem("token");
  const lang = localStorage.getItem("language") || "en";
  return AXIOS.delete(
    `${r.V2_1_ORGANIZATION}/${organizationId}/plots/${plotId}/autopilots/controller`,
    {
      headers: {
        Authorization: token,
        "accept-language": lang,
      },
    }
  )
    .then((result) => {
      return result.data;
    })
    .catch((err) => {
      toastr.error("Error", i18n.t(err.response.data.error));
      throw err;
    });
};

export const getPlotIrrigationControllerStatus = (organizationId, plotId) => {
  const token = localStorage.getItem("token");
  const lang = localStorage.getItem("language") || "en";
  return AXIOS.get(
    `${r.V2_1_ORGANIZATION}/${organizationId}/plots/${plotId}/autopilots/controller/status`,
    {
      headers: {
        Authorization: token,
        "accept-language": lang,
      },
    }
  )
    .then((result) => {
      return result.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const getPlotSensorList = (organizationId, plotId) => {
  const token = localStorage.getItem("token");
  const lang = localStorage.getItem("language") || "en";
  return AXIOS.get(
    `${r.V2_1_ORGANIZATION}/${organizationId}/plots/${plotId}/devices/sensors`,
    {
      headers: {
        Authorization: token,
        "accept-language": lang,
      },
    }
  )
    .then((result) => {
      return result.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const updateIrrigationSettings = (
  organizationId,
  plotId,
  irrigationRules
) => {
  const token = localStorage.getItem("token");
  const lang = localStorage.getItem("language") || "en";
  return AXIOS.put(
    `${r.V2_1_ORGANIZATION}/${organizationId}/plots/${plotId}/autopilots/controller/rules`,
    irrigationRules,
    {
      headers: {
        Authorization: token,
        "accept-language": lang,
      },
    }
  )
    .then((result) => {
      toastr.success("Success", i18n.t("saved"));
      return result.data;
    })
    .catch((err) => {
      toastr.error("Error", i18n.t(err.response.data.error));
      throw err;
    });
};

export const getIrrigationHistory = (organizationId, plotId, from, to) => {
  const token = localStorage.getItem("token");
  const lang = localStorage.getItem("language") || "en";
  return AXIOS.get(
    `${r.V2_1_ORGANIZATION}/${organizationId}/plots/${plotId}/irrigation/history`,
    {
      headers: {
        Authorization: token,
        "accept-language": lang,
      },
      params: {
        start: new Date(from).toISOString(),
        end: new Date(to).toISOString(),
      },
    }
  )
    .then((result) => {
      return result.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const getPlotsListWithoutLicence = (organizationId) => {
  const token = localStorage.getItem("token");
  const lang = localStorage.getItem("language") || "en";
  return AXIOS.get(
    `${r.ORGANIZATION_V2}/${organizationId}/licence/availablePlots`,
    {
      headers: {
        "x-access-token": token,
        "accept-language": lang,
      },
    }
  )
    .then((result) => {
      return result.data.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const addAutopilotLicence = (organizationId, licenceData) => {
  const token = localStorage.getItem("token");
  const lang = localStorage.getItem("language") || "en";
  return AXIOS.post(
    `${r.ORGANIZATION_V2}/${organizationId}/licence`,
    licenceData,
    {
      headers: {
        "x-access-token": token,
        "accept-language": lang,
      },
    }
  )
    .then((result) => {
      toastr.success("Success", i18n.t("licenceAddedSuccessfully"));
      return result.data;
    })
    .catch((err) => {
      toastr.error("Error", i18n.t(err.response.data.error));
      throw err;
    });
};

export const getAvailableAutopilotLicence = (organizationId, plotId) => {
  const token = localStorage.getItem("token");
  const lang = localStorage.getItem("language") || "en";
  return AXIOS.get(
    `${r.ORGANIZATION_V2}/${organizationId}/licence/availableAutopilot`,
    {
      headers: {
        "x-access-token": token,
        "accept-language": lang,
      },
      params: {
        plotId,
      },
    }
  )
    .then((result) => {
      return result.data.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const getPlotAutopilotLicence = (organizationId, plotId) => {
  const token = localStorage.getItem("token");
  const lang = localStorage.getItem("language") || "en";
  return AXIOS.get(
    `${r.ORGANIZATION_V2}/${organizationId}/plots/${plotId}/licence`,
    {
      headers: {
        "x-access-token": token,
        "accept-language": lang,
      },
    }
  )
    .then((result) => {
      return result.data.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const setPlotAutopilotLicence = (organizationId, plotId, licenceId) => {
  const token = localStorage.getItem("token");
  const lang = localStorage.getItem("language") || "en";
  return AXIOS.put(
    `${r.ORGANIZATION_V2}/${organizationId}/plots/${plotId}/licence`,
    {
      licenceId,
    },
    {
      headers: {
        "x-access-token": token,
        "accept-language": lang,
      },
    }
  )
    .then((result) => {
      return result.data;
    })
    .catch((err) => {
      toastr.error("Error", i18n.t(err.response.data.error));
      throw err;
    });
};

export const getPlotsIrrigationStatus = (organizationId) => {
  const token = localStorage.getItem("token");
  const lang = localStorage.getItem("language") || "en";
  return AXIOS.get(
    `${r.V2_1_ORGANIZATION}/${organizationId}/plotsIrrigationStatus`,
    {
      headers: {
        Authorization: token,
        "accept-language": lang,
      },
    }
  )
    .then((result) => {
      return result.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const editIrrigationConnection = (
  organizationId,
  plotId,
  providerConnectionId,
  program
) => {
  const token = localStorage.getItem("token");
  const lang = localStorage.getItem("language") || "en";
  return AXIOS.put(
    `${r.V2_1_ORGANIZATION}/${organizationId}/plots/${plotId}/autopilots/controller`,
    {
      providerConnectionId,
      program,
    },
    {
      headers: {
        Authorization: token,
        "accept-language": lang,
      },
    }
  )
    .then((result) => {
      toastr.success("Success", i18n.t("irrigationConnectionUpdatedSuccess"));
      return result.data;
    })
    .catch((err) => {
      toastr.error("Error", i18n.t(err.response.data.error));
      throw err;
    });
};

export const editMottechServer = (
  organizationId,
  serverId,
  serverUrl,
  username,
  password
) => {
  const token = localStorage.getItem("token");
  const lang = localStorage.getItem("language") || "en";
  return AXIOS.put(
    `${r.V2_1_ORGANIZATION}/${organizationId}/autopilots/connection/mottech/${serverId}`,
    {
      serverUrl,
      username,
      password,
    },
    {
      headers: {
        Authorization: token,
        "accept-language": lang,
      },
    }
  )
    .then((result) => {
      toastr.success("Success", i18n.t("loginMottechSuccess"));
      return result.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const getVirtualDeviceWp = (organizationId, plotId, from, to) => {
  const token = localStorage.getItem("token");
  const lang = localStorage.getItem("language") || "en";
  return AXIOS.get(
    `${r.V2_1_ORGANIZATION}/${organizationId}/plots/${plotId}/autopilotDevice`,
    {
      headers: {
        Authorization: token,
        "accept-language": lang,
      },
      params: {
        start: new Date(from).toISOString(),
        end: new Date(to).toISOString(),
      },
    }
  )
    .then((result) => {
      return result.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const talgilLogin = (organizationId, controllerId) => {
  const token = localStorage.getItem("token");
  const lang = localStorage.getItem("language") || "en";
  return AXIOS.post(
    `${r.V2_1_ORGANIZATION}/${organizationId}/autopilots/connection/talgil`,
    {
      controllerId,
    },
    {
      headers: {
        Authorization: token,
        "accept-language": lang,
      },
    }
  )
    .then((result) => {
      toastr.success("Success", i18n.t("loginTalgilSuccess"));
      return result.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const editTalgilServer = (organizationId, serverId, controllerId) => {
  const token = localStorage.getItem("token");
  const lang = localStorage.getItem("language") || "en";
  return AXIOS.put(
    `${r.V2_1_ORGANIZATION}/${organizationId}/autopilots/connection/talgil/${serverId}`,
    {
      controllerId,
    },
    {
      headers: {
        Authorization: token,
        "accept-language": lang,
      },
    }
  )
    .then((result) => {
      toastr.success("Success", i18n.t("loginTalgilSuccess"));
      return result.data;
    })
    .catch((err) => {
      throw err;
    });
};
