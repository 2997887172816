import React from "react";
import ReactCodeInput from "react-code-input";
import useStyles from "./styles";

const props = {
  inputStyle: {
    fontFamily: "Roboto",
    margin: "8px",
    flex: "25%",
    fontSize: "3vw",
    color: "rgba(0, 0, 0, 0.6)",
    border: "none",
    outline: "none",
    textAlign: "center",
    borderBottom: "1px solid #d2d2d2",
    backgroundColor: "transparent",
  },
};

const CodeField = ({
  type,
  fields,
  name,
  touched,
  error,
  value,
  handleChange,
  handleBlur,
}) => {
  const {
    container
  } = useStyles();
  return (
    <ReactCodeInput
      type={type}
      fields={fields}
      value={value}
      className={container}
      onChange={(val) => {
        const event = {
          target: {
            name: name,
            value: val,
          },
        };
        handleChange(event);
      }}
      onBlur={handleBlur}
      inputMode="numeric"
      name={name}
      {...props}
    />
  );
};

export default CodeField;
