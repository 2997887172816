import React, { useEffect, useRef, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box, IconButton, Menu, MenuItem } from "@material-ui/core";
import {
  getPlotsCoordinatesRequest,
  plotsCoordinatesSelector,
} from "redux/plotsCoordinates";
import moment from "moment";
import clsx from "clsx";
import history from "history.js";
import MapStyles from "constants/sampleMapStyles";
import DrawPolygonIcon from "media/drawPolygonIcon.svg";
import useStyles from "./styles";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { settingsSelector } from "redux/dashboardSettings";
import { saveDashboardSettingsRequest } from "redux/saveDashboardSettings";
import DeleteIcon from "media/deleteIcon.svg";
import MapIcon from "media/mapIcon.svg";
import { getDeviceMetricsLastValues } from "api";
const SEVERITY_LEVEL_COLOR = {
  "-1": "rgba(152, 165, 166)",
  0: "rgba(145, 181, 77, 0.75)",
  1: "rgba(145, 181, 77, 0.75)",
  2: "rgba(244, 166, 35, 0.75)",
  3: "rgba(244, 166, 35, 0.75)",
  4: "rgba(218, 80, 98, 0.75)",
  5: "rgba(218, 80, 98, 0.75)",
};

const MapWidget = ({
  getPlotsCoordinates,
  fieldId,
  plotsCoordinates,
  selectedPlot,
  handleSelectPlot,
  plotsData,
  devices,
  plotId,
  saveDashboardSettings,
  dashboardSettings,
}) => {
  let map = null;
  const mapContainer = useRef(null);
  const [plot, selectPlot] = useState();
  const [plotArea, setPlotArea] = useState();
  const { google } = window;
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const deleteWidget = () => {
    const index = dashboardSettings.findIndex(
      (item) => item.key === "mapWidget"
    );
    dashboardSettings[index].enabled = false;
    saveDashboardSettings({
      plotId: plotId,
      settings: dashboardSettings,
      updateData: true,
    });
  };
  const deviceInfoTemplate = ({
    name,
    metricsLastValues: {
      lastUpdated: {
        lastValues: { time },
      },
      batteryCharge: {
        lastValues: { cellstate },
      },
      waterPotential: { lastValues: wpData },
      groundTemperature: { lastValues: soilTempData },
    },
    depths: { port1Depth, port2Depth, port3Depth },
  }) => {
    return `
    <div class="deviceInfoMapContainer">
      <div class="deviceInfoRow">
        <div class="deviceInfoLabel">${t("deviceName")}</div>
        <div class="deviceInfoValue">${name}</div>
      </div>
      <div class="deviceInfoRow">
        <div class="deviceInfoLabel">${t("lastUpdate")}</div>
        <div class="deviceInfoValue">${
          time ? moment(time).fromNow() : "-"
        }</div>
      </div>
      <div class="deviceInfoRow">
        <div class="deviceInfoLabel">${t("battery")}</div>
        <div class="deviceInfoValue">${cellstate || "-"}%</div>
      </div>
      <div class="deviceInfoRow">
        <div class="deviceInfoLabel">${port1Depth || "-"}cm</div>
        <div class="deviceInfoValue">${wpData["rt1-cent"] || "-"}</div>
      </div>
      <div class="deviceInfoRow">
        <div class="deviceInfoLabel">${port2Depth || "-"}cm</div>
        <div class="deviceInfoValue">${wpData["rt2-cent"] || "-"}</div>
      </div>
      ${
        port3Depth
          ? `<div class="deviceInfoRow">
          <div class="deviceInfoLabel">${port3Depth || "-"}cm</div>
          <div class="deviceInfoValue">${wpData["rt3-cent"] || "-"}</div>
        </div>`
          : ""
      } 
      <div class="deviceInfoRow">
        <div class="deviceInfoLabel">tempg1</div>
        <div class="deviceInfoValue">${soilTempData["tempg1"] || "-"}°C</div>
      </div>
      <div class="deviceInfoRow">
        <div class="deviceInfoLabel">tempg2</div>
        <div class="deviceInfoValue">${soilTempData["tempg2"] || "-"}°C</div>
      </div>
      ${
        Object.keys(soilTempData).some((key) => key === "tempg3")
          ? `<div class="deviceInfoRow">
          <div class="deviceInfoLabel">tempg3</div>
          <div class="deviceInfoValue">${soilTempData["tempg3"] || "-"}°C</div>
        </div>`
          : ""
      }
    </div>
  `;
  };
  const { header, widgetTitle, deviceInfo, menuItem } = useStyles();
  useEffect(() => {
    getPlotsCoordinates(fieldId);
  }, [fieldId]);
  useEffect(() => {
    if (mapContainer && plotsCoordinates) {
      const currentPlot = plotsCoordinates.plots.find(
        (plot) => plot.id === selectedPlot.plotId
      );

      if (
        currentPlot &&
        currentPlot.coordinates &&
        currentPlot.coordinates.length
      ) {
        const minLat = currentPlot.coordinates.reduce(
          (min, p) => (!min || p.lat < min ? p.lat : min),
          0
        );
        const maxLat = currentPlot.coordinates.reduce(
          (max, p) => (!max || p.lat > max ? p.lat : max),
          0
        );
        const minLng = currentPlot.coordinates.reduce(
          (min, p) => (!min || p.lng < min ? p.lng : min),
          0
        );
        const maxLng = currentPlot.coordinates.reduce(
          (max, p) => (!max || p.lng > max ? p.lng : max),
          0
        );
        map = new google.maps.Map(mapContainer.current, {
          center: { lat: (minLat + maxLat) / 2, lng: (minLng + maxLng) / 2 },
          gestureHandling: "cooperative",
          mapTypeControl: false,
          zoomControlOptions: {
            position: google.maps.ControlPosition.RIGHT_CENTER,
          },
          fullscreenControlOptions: {
            position: google.maps.ControlPosition.RIGHT_CENTER,
          },
          streetViewControl: false,
          zoom: 18,
          mapTypeId: "hybrid",
          styles: MapStyles,
        });
      } else if (
        currentPlot &&
        currentPlot.devices &&
        currentPlot.devices.length
      ) {
        map = new google.maps.Map(mapContainer.current, {
          center: {
            lat: currentPlot.devices[0].latitude,
            lng: currentPlot.devices[0].longitude,
          },
          gestureHandling: "cooperative",
          mapTypeControl: false,
          zoomControlOptions: {
            position: google.maps.ControlPosition.RIGHT_CENTER,
          },
          fullscreenControlOptions: {
            position: google.maps.ControlPosition.RIGHT_CENTER,
          },
          streetViewControl: false,
          zoom: 18,
          mapTypeId: "hybrid",
          styles: MapStyles,
        });
      }
      plotsCoordinates.plots.forEach((plot) => {
        if (
          plot.coordinates &&
          plot.coordinates.length &&
          plot.devices.length
        ) {
          const plotData = plotsData.find(
            (plotData) => plotData.plotId === plot.id
          );
          if (plotData) {
            let plotPolygon = new google.maps.Polygon({
              paths: plot.coordinates,
              strokeColor:
                SEVERITY_LEVEL_COLOR[
                  plotData.severity === null ? -1 : plotData.severity
                ],
              strokeOpacity: 1,
              strokeWeight: 2,
              fillColor:
                SEVERITY_LEVEL_COLOR[
                  plotData.severity === null ? -1 : plotData.severity
                ],
              fillOpacity: 0.7,
              clickable: true,
            });
            plotPolygon.addListener("click", () => switchPlot(plot.id));
            plotPolygon.setMap(map);
            if (currentPlot && currentPlot.id === plot.id) {
              setPlotArea(
                (
                  google.maps.geometry.spherical.computeArea(
                    plotPolygon.getPath()
                  ) * 0.0001
                ).toFixed(1)
              );
            }
          }
        }
        if (currentPlot && plot.id === currentPlot.id) {
          plot.devices.forEach((device) => {
            if (device.latitude && device.longitude) {
              // let infowindow = new google.maps.InfoWindow({
              //   content:
              //     device.sensors && device.sensorsLastValues
              //       ? deviceInfoTemplate(device)
              //       : `No Data`,
              // });
              const currentDevice = devices.find((dev) => device.id === dev.id);
              const deviceMarker = new google.maps.Marker({
                position: {
                  lat: device.latitude,
                  lng: device.longitude,
                },
                icon: {
                  url: `data:image/svg+xml;utf-8, \
                    <svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"> \
                      <path fill="${
                        currentDevice ? currentDevice.color : "black"
                      }" d="M22 11.5C22 14.5982 21.0983 18 17.5 22C13.9017 26 11.5 30.5 11.5 30.5C11.5 30.5 9.29771 26.5 5.5 22C1.70229 17.5 1 14.5982 1 11.5C1 5.70101 5.70101 1 11.5 1C17.299 1 22 5.70101 22 11.5Z" ></path> \
                      <path fill="white" d="M7.72677 6L7.24124 6.40193C4.82483 8.40228 3.94399 12.7208 6.72775 15.3887C7.96124 16.5709 9.0884 17.6303 9.90718 18.3942C10.3166 18.7761 10.649 19.0843 10.8792 19.2971C10.9943 19.4035 11.0838 19.486 11.1446 19.542C11.175 19.57 11.1983 19.5914 11.2139 19.6058L11.2377 19.6276L11.2378 19.6277C11.2378 19.6277 11.2378 19.6277 11.6464 19.1905L11.2378 19.6277L11.6436 20L12.052 19.6305C14.1843 17.7012 14.91 13.7709 12.4586 11.3021C10.9568 9.78959 9.86525 8.58438 9.15101 7.75974C8.7939 7.34742 8.5311 7.03024 8.35852 6.81737C8.27223 6.71093 8.2085 6.63057 8.1668 6.57743C8.14595 6.55086 8.13061 6.5311 8.12071 6.51828C8.11577 6.51188 8.11218 6.50721 8.10995 6.50429L8.10761 6.50124L8.1073 6.50083L8.10723 6.50073L8.10719 6.50068L7.72677 6ZM7.5659 7.74124C7.73643 7.94832 7.95994 8.21454 8.23824 8.53586C8.96972 9.3804 10.0797 10.6056 11.6012 12.1379C13.3302 13.8791 13.0425 16.6943 11.6239 18.3556C11.4001 18.1485 11.0967 17.867 10.7317 17.5265C9.91574 16.7653 8.79263 15.7097 7.56399 14.5321C5.57358 12.6246 5.94103 9.50933 7.5659 7.74124Z" ></path> \
                      <path fill="white" d="M15.3306 6L15.784 6.38019C18.1732 8.38373 19.0441 12.7092 16.2917 15.3814C15.0721 16.5654 13.9576 17.6265 13.148 18.3916C12.7432 18.7742 12.4145 19.0828 12.1869 19.2959C12.0732 19.4025 11.9846 19.4852 11.9245 19.5413C11.8944 19.5693 11.8715 19.5907 11.856 19.6051L11.8325 19.627L11.8324 19.6271C11.8324 19.6271 11.8324 19.6271 11.4283 19.1892L11.8324 19.6271L11.4312 20L11.0274 19.6299C9.96039 18.652 9.22533 17.1935 9.04372 15.6798C8.86092 14.1562 9.23628 12.5319 10.4554 11.2881C11.9443 9.76922 13.0698 8.5578 13.822 7.72736C14.1981 7.31214 14.4809 6.99217 14.6691 6.77664C14.7632 6.66888 14.8337 6.58722 14.8804 6.53283C14.9037 6.50563 14.9211 6.48525 14.9325 6.47183C14.9382 6.46511 14.9425 6.46014 14.9452 6.45693L14.9489 6.45253L15.3306 6ZM15.4435 7.70033C15.2582 7.91113 15.0108 8.18943 14.702 8.53033C13.9398 9.37174 12.8036 10.5946 11.3032 12.1253C10.3834 13.0636 10.0765 14.3035 10.2245 15.5371C10.3503 16.5856 10.8029 17.5974 11.4494 18.3541C11.6708 18.1466 11.9713 17.8642 12.3328 17.5226C13.1396 16.7601 14.25 15.7028 15.4649 14.5234C17.4408 12.6051 17.0666 9.46743 15.4435 7.70033Z" ></path> \
                    </svg>`,
                },
                map: map,
              });
              deviceMarker.addListener("click", () => {
                generateInfoWindow(device, deviceMarker);
                // infowindow.open(map, deviceMarker);
              });
              // google.maps.event.addListener(map, "click", () => {
              //   infowindow.close();
              // });
            }
          });
        }
      });
      const centerControlDiv = document.createElement("div");
      selectPlot(currentPlot);
      CenterControl(centerControlDiv, mapContainer.current);
      if (map && map.controls.length) {
        map.controls[google.maps.ControlPosition.RIGHT_CENTER].push(
          centerControlDiv
        );
      }
    }
  }, [plotsCoordinates]);
  const generateInfoWindow = async (device, deviceMarker) => {
    const data = await getDeviceMetricsLastValues(device.id);
    let infowindow = new google.maps.InfoWindow({
      content: deviceInfoTemplate(data),
    });
    infowindow.open(map, deviceMarker);
    google.maps.event.addListener(map, "click", () => {
      infowindow.close();
    });
  };
  const switchPlot = (plotId) => {
    const plotData = plotsData.find((plot) => +plot.plotId === +plotId);
    handleSelectPlot(plotData);
  };

  const CenterControl = (controlDiv, map) => {
    const controlUI = document.createElement("div");

    controlUI.style.backgroundColor = "#fff";
    controlUI.style.border = "2px solid #fff";
    controlUI.style.borderRadius = "3px";
    controlUI.style.boxShadow = "0 2px 6px rgba(0,0,0,.3)";
    controlUI.style.cursor = "pointer";
    controlUI.style.marginTop = "10px";
    controlUI.style.textAlign = "center";
    controlUI.style.marginRight = "10px";
    controlUI.style.padding = "8px";
    controlDiv.appendChild(controlUI);

    const icon = document.createElement("img");
    icon.src = DrawPolygonIcon;
    controlUI.appendChild(icon);

    controlUI.addEventListener("click", () => {
      history.push(`/plot-settings/${plotId}?tab=Map`);
    });
  };
  const open = Boolean(anchorEl);
  return (
    <Box style={{ height: "100%" }}>
      <Box className={clsx("widgetHeader", header)}>
        <Box style={{ display: "flex" }}>
          <img
            width="32px"
            height="32px"
            style={{ marginInlineEnd: "10px" }}
            src={MapIcon}
            alt="icon"
          />
          <Box>
            <Box className={widgetTitle}>{t("map")}</Box>
            <Box className={deviceInfo}>
              {plotArea} ha | {plot && plot.devices.length} {t("devices")}
            </Box>
          </Box>
        </Box>
        <IconButton onClick={handleClick} size="small">
          <MoreVertIcon />
        </IconButton>
      </Box>
      <Box
        className="no-draggable"
        style={{ height: "75%" }}
        ref={mapContainer}
      />
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem
          style={{
            width: "200px",
          }}
          onClick={deleteWidget}
        >
          <img src={DeleteIcon} alt="DeleteIcon" />
          <Box className={menuItem}>{t("delete")}</Box>
        </MenuItem>
      </Menu>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  plotsCoordinates: plotsCoordinatesSelector(state),
  dashboardSettings: settingsSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  getPlotsCoordinates: bindActionCreators(getPlotsCoordinatesRequest, dispatch),
  saveDashboardSettings: bindActionCreators(
    saveDashboardSettingsRequest,
    dispatch
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MapWidget);
