import { put, takeEvery, all, call } from "redux-saga/effects";
import { getSubscriptionStatus } from "api";
/* *
 * Constants
 * */
export const moduleName = "subscriptionData";
const prefix = `${process.env.REACT_APP_NAME}/${moduleName}`;

export const INIT = `${prefix}/INIT`;
export const SUCCESS = `${prefix}/SUCCESS`;
export const REJECT = `${prefix}/REJECT`;

/* *
 * Reducer
 * */
const initialState = {
  subscription: {},
  loading: false,
  error: {},
  success: false,
};

export default function reducer(
  state = initialState,
  { type, payload, meta, ...rest }
) {
  switch (type) {
    case INIT:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        subscription: payload,
      };
    case REJECT:
      return {
        ...state,
        loading: false,
        success: false,
        error: payload.error,
      };
    default:
      return state;
  }
}

/* *
 * Selectors
 * */
export const moduleSelector = (state) => state[moduleName];

export const subscriptionSelector = (state) => moduleSelector(state).subscription;

export const isLoadingSelector = (state) => moduleSelector(state).loading;

export const isSuccessSelector = (state) => moduleSelector(state).success;

/* *
 * Action Creators
 * */
export const getSubscriptionRequest = () => ({
  type: INIT,
});

export const successAction = (payload) => ({
  type: SUCCESS,
  payload,
});

export const errorAction = (error) => ({
  type: REJECT,
  payload: { error },
});

/* *
 * Sagas
 * */
export function* getSubscriptionSaga() {
  try {
    const accountFromStorage = localStorage.getItem("accountData");
    const accountId = JSON.parse(accountFromStorage).id;
    const res = yield call(getSubscriptionStatus, accountId);
    yield put(successAction(res));
  } catch (error) {
    yield put(errorAction(error.message));
  }
}

export function* saga() {
  yield all([takeEvery(INIT, getSubscriptionSaga)]);
}
