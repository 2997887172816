import React, { useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import validator from "validator";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import {
  StyledTextField,
  AppBtnFab,
  CountrySelect,
  AppAutocomplete,
} from "components/commons";
import {
  isLoadingSelector,
  accountsListSelector,
  getAccountsListRequest,
} from "redux/accountsList";
import {
  isLoadingSelector as createAccountLoading,
  createAccountRequest,
} from "redux/createAccount";
import { userIdSelector, defaultAccountSelector } from "redux/me";
import { Box, Typography, CircularProgress, Backdrop } from "@material-ui/core";
import _ from "lodash";
import useStyles from "./styles";
import clsx from "clsx";

const CreateAccount = ({
  loadingAccountsList,
  loadingCreateAccount,
  accountsList,
  getAccountsList,
  userId,
  createAccount,
  defaultAccount,
}) => {
  const { t } = useTranslation();
  const {
    container,
    content,
    btnContainer,
    inputMargin,
    alignCenter,
    backdrop,
  } = useStyles();
  useEffect(() => {
    if (userId) {
      getAccountsList();
    }
  }, [userId]);
  const accountFromStorage = localStorage.getItem("accountData");
  return (
    <Box className={container}>
      <Backdrop className={backdrop} open={loadingCreateAccount}>
        <CircularProgress color="primary" />
      </Backdrop>
      {loadingAccountsList && (
        <Box className={clsx(content, alignCenter)}>
          <CircularProgress color="primary" />
        </Box>
      )}
      {accountsList.length > 0 && !loadingAccountsList && (
        <Box className={content}>
          <Typography variant="h5" align="center">
            {t("createAccount")}
          </Typography>
          <Formik
            initialValues={{
              name: "",
              countryISO: "",
              email: "",
              parentAccount: JSON.parse(accountFromStorage) || defaultAccount,
            }}
            validate={(values) => {
              const errors = {};
              if (!values.name) {
                errors.name = "Required";
              }
              if (!values.countryISO) {
                errors.countryISO = "Required";
              }
              if (!values.email) {
                errors.email = "Required";
              }
              if (_.isEmpty(values.parentAccount)) {
                errors.parentAccount = "Required";
              }
              if (!validator.isEmail(values.email)) {
                errors.email = "Please enter a valid email";
              }
              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              createAccount({
                name: values.name,
                countryISO: values.countryISO,
                email: values.email,
                parentAccount: values.parentAccount.id,
              });
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit}>
                <StyledTextField
                  label={t("enterAccountName")}
                  name="name"
                  value={values.name}
                  touched={touched.name}
                  error={errors.name}
                  className={inputMargin}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />
                <CountrySelect
                  label={t("location")}
                  name="countryISO"
                  value={values.countryISO}
                  touched={touched.countryISO}
                  error={errors.countryISO}
                  className={inputMargin}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />
                <AppAutocomplete
                  label={t("parentAccount")}
                  name="parentAccount"
                  value={values.parentAccount}
                  touched={touched.parentAccount}
                  error={errors.parentAccount}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  className={inputMargin}
                  optionLabelKey="name"
                  options={accountsList}
                />
                <StyledTextField
                  label={t("billingEmail")}
                  name="email"
                  value={values.email}
                  touched={touched.email}
                  className={inputMargin}
                  error={errors.email}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />
                <Box className={btnContainer}>
                  <AppBtnFab
                    type="submit"
                    label={t("continue")}
                    disabled={!_.isEmpty(errors)}
                  />
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      )}
    </Box>
  );
};
const mapStateToProps = (state) => ({
  loadingAccountsList: isLoadingSelector(state),
  loadingCreateAccount: createAccountLoading(state),
  accountsList: accountsListSelector(state),
  userId: userIdSelector(state),
  defaultAccount: defaultAccountSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  getAccountsList: bindActionCreators(getAccountsListRequest, dispatch),
  createAccount: bindActionCreators(createAccountRequest, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateAccount);
