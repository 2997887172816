import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";
import {
  heatMapLoadingSelector,
  dataSelector,
  getDepthsDataRequest,
  thresholdSelector,
  errorMsgSelector,
} from "redux/heatMap";
import { AppLoader } from "components/commons";
import ReactEcharts from "echarts-for-react";
import { calcY } from "utils/heatMap";
import moment from "moment";
import Grass from "media/grass.png";
import Ground from "media/ground.png";
import history from "history.js";
import { Box } from "@material-ui/core";

const HeatMap = ({
  deviceId,
  daterange,
  data,
  getDepthsData,
  deviceDepths,
  threshold,
  heatMapLoading,
  errorMsg,
  isForInsight = false,
}) => {
  const [depthsByPriority, setDepthsByPriority] = useState([]);
  const { t } = useTranslation();
  useEffect(() => {
    setDepthsByPriority(
      Object.keys(deviceDepths)
        .map((depthKey) => ({
          depthIntex: depthKey.replace(/^\D+/g, ""),
          value: deviceDepths[depthKey],
        }))
        .sort((a, b) => a.value - b.value)
    );
    getDepthsData({
      deviceId: deviceId,
      from: daterange[0].valueOf(),
      to: daterange[1].valueOf(),
      isForInsight: isForInsight,
    });
  }, [deviceId, daterange]);
  if (
    errorMsg &&
    errorMsg[deviceId] &&
    errorMsg[deviceId] === "DEVICE_DONT_HAVE_ACTIVE_LICENCE"
  ) {
    return (
      <Box
        style={{
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          color: "#828B93",
          fontSize: "16px",
          fontWeight: "500",
          marginTop: "-28px",
        }}
      >
        {t("licenseExpired")}&nbsp;
        <span
          style={{
            textDecoration: "underline",
            cursor: "pointer",
            "&:hover": {
              opacity: "0.7",
            },
          }}
          onClick={() => history.push("/my-products")}
        >
          {t("clickToRenewLink")}
        </span>
      </Box>
    );
  } else if (
    data &&
    data[isForInsight ? `${deviceId}-insight` : deviceId] &&
    depthsByPriority.length
  ) {
    return (
      <div style={{ position: "relative" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            paddingBottom: "55px",
            paddingTop: "20px",
            position: "absolute",
            height: "calc(100% - 75px)",
          }}
        >
          {depthsByPriority.map((depth) => (
            <div
              key={Math.random()}
              style={{ fontSize: "12px", color: "#828B93" }}
            >
              {depth.value}cm
            </div>
          ))}
        </div>
        <div style={{ flex: 1 }}>
          <div
            style={{
              backgroundImage: `url(${Grass})`,
              height: "20px",
              margin: "0 35px",
            }}
          />
          {depthsByPriority.length === 3 ? (
            <div>
              {depthsByPriority[0].value !== depthsByPriority[1].value ? (
                <div
                  style={{
                    display: "flex",
                    width: isForInsight
                      ? "calc(100% - 70px)"
                      : "calc(100% - 70px)",
                    padding: isForInsight ? "0px 35px" : "0px 35px",
                  }}
                >
                  {data[isForInsight ? `${deviceId}-insight` : deviceId].map(
                    (item) => (
                      <div
                        key={Math.random()}
                        style={{
                          height: "73px",
                          background: `linear-gradient(180deg, rgba(${parseInt(
                            calcY(
                              50,
                              255,
                              0,
                              threshold[deviceId] || -20,
                              item[`rt${depthsByPriority[0].depthIntex}Value`]
                            )
                          )}, ${parseInt(
                            calcY(
                              255,
                              100,
                              0,
                              threshold[deviceId] || -20,
                              item[`rt${depthsByPriority[0].depthIntex}Value`]
                            )
                          )}, 0) 0%, rgba(${parseInt(
                            calcY(
                              50,
                              255,
                              0,
                              threshold[deviceId] || -20,
                              item[`rt${depthsByPriority[1].depthIntex}Value`]
                            )
                          )}, ${parseInt(
                            calcY(
                              255,
                              100,
                              0,
                              threshold[deviceId] || -20,
                              item[`rt${depthsByPriority[1].depthIntex}Value`]
                            )
                          )}, 0) 100%)`,
                          flex: 1,
                        }}
                      />
                    )
                  )}
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    width: isForInsight
                      ? "calc(100% -70px)"
                      : "calc(100% - 70px)",
                    padding: isForInsight ? "0px 35px" : "0px 35px",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "#828B93",
                  }}
                >
                  {t("sameDepthsMsg")}
                </div>
              )}
              {depthsByPriority[1].value !== depthsByPriority[2].value ? (
                <div
                  style={{
                    display: "flex",
                    width: isForInsight
                      ? "calc(100% - 70px)"
                      : "calc(100% - 70px)",
                    padding: isForInsight ? "0px 35px" : "0px 35px",
                  }}
                >
                  {data[isForInsight ? `${deviceId}-insight` : deviceId].map(
                    (item) => (
                      <div
                        key={Math.random()}
                        style={{
                          height: "73px",
                          background: `linear-gradient(180deg, rgba(${parseInt(
                            calcY(
                              50,
                              255,
                              0,
                              threshold[deviceId] || -20,
                              item[`rt${depthsByPriority[1].depthIntex}Value`]
                            )
                          )}, ${parseInt(
                            calcY(
                              255,
                              100,
                              0,
                              threshold[deviceId] || -20,
                              item[`rt${depthsByPriority[1].depthIntex}Value`]
                            )
                          )}, 0) 0%, rgba(${parseInt(
                            calcY(
                              50,
                              255,
                              0,
                              threshold[deviceId] || -20,
                              item[`rt${depthsByPriority[2].depthIntex}Value`]
                            )
                          )}, ${parseInt(
                            calcY(
                              255,
                              100,
                              0,
                              threshold[deviceId] || -20,
                              item[`rt${depthsByPriority[2].depthIntex}Value`]
                            )
                          )}, 0) 100%)`,
                          flex: 1,
                        }}
                      />
                    )
                  )}
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    width: isForInsight
                      ? "calc(100% - 70px)"
                      : "calc(100% - 70px)",
                    padding: isForInsight ? "0px 35px" : "0px 35px",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "#828B93",
                  }}
                >
                  {t("sameDepthsMsg")}
                </div>
              )}
            </div>
          ) : depthsByPriority.length === 2 ? (
            <div
              style={
                depthsByPriority[0].value === depthsByPriority[1].value
                  ? {
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "80%",
                    }
                  : {}
              }
            >
              {depthsByPriority[0].value !== depthsByPriority[1].value ? (
                <div
                  style={{
                    display: "flex",
                    width: isForInsight
                      ? "calc(100% - 70px)"
                      : "calc(100% - 70px)",
                    padding: isForInsight ? "0px 35px" : "0px 35px",
                  }}
                >
                  {data[isForInsight ? `${deviceId}-insight` : deviceId].map(
                    (item) => (
                      <div
                        key={Math.random()}
                        style={{
                          height: "146px",
                          background: `linear-gradient(180deg, rgba(${parseInt(
                            calcY(
                              50,
                              255,
                              0,
                              threshold[deviceId] || -20,
                              item[`rt${depthsByPriority[0].depthIntex}Value`]
                            )
                          )}, ${parseInt(
                            calcY(
                              255,
                              100,
                              0,
                              threshold[deviceId] || -20,
                              item[`rt${depthsByPriority[0].depthIntex}Value`]
                            )
                          )}, 0) 0%, rgba(${parseInt(
                            calcY(
                              50,
                              255,
                              0,
                              threshold[deviceId] || -20,
                              item[`rt${depthsByPriority[1].depthIntex}Value`]
                            )
                          )}, ${parseInt(
                            calcY(
                              255,
                              100,
                              0,
                              threshold[deviceId] || -20,
                              item[`rt${depthsByPriority[1].depthIntex}Value`]
                            )
                          )}, 0) 100%)`,
                          flex: 1,
                        }}
                      />
                    )
                  )}
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    width: isForInsight
                      ? "calc(100% - 70px)"
                      : "calc(100% - 70px)",
                    padding: isForInsight ? "0px 35px" : "0px 35px",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "#828B93",
                  }}
                >
                  {t("sameDepthsMsg")}
                </div>
              )}
            </div>
          ) : depthsByPriority.length === 1 && (
            <div
                  style={{
                    display: "flex",
                    width: isForInsight
                      ? "calc(100% - 70px)"
                      : "calc(100% - 70px)",
                    padding: isForInsight ? "0px 35px" : "0px 35px",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "#828B93",
                  }}
                >
                  {t("sameDepthsMsg")}
                </div>
          )}
          <div
            style={{
              backgroundImage: `url(${Ground})`,
              height: "20px",
              margin: "0 35px",
            }}
          />
          {data && data[deviceId] && (
            <ReactEcharts
              style={{ height: "35px" }}
              option={{
                grid: {
                  top: "0",
                  left: "35px",
                  right: "35px",
                  show: true
                },
                axisLabel: { hideOverlap: true },
                xAxis: {
                  type: "time",
                  axisLabel: {
                    formatter: function(value) {
                      return moment(value).format("DD-MM YYYY");
                    },
                  },
                },
                yAxis: {
                  type: "value",
                },
                dataset: {
                  source: data[deviceId].map((item) => [item.timestamp]),
                  dimensions: ["timestamp", "rt1-cent"],
                },
                series: [
                  {
                    type: "line",
                    symbol: "none",
                    connectNulls: true,
                    encode: {
                      x: "timestamp",
                      y: "rt1-cent",
                    },
                  },
                ],
              }}
            />
          )}
        </div>
      </div>
    );
  } else {
    return <AppLoader />;
  }
};

const mapStateToProps = (state) => ({
  heatMapLoading: heatMapLoadingSelector(state),
  data: dataSelector(state),
  threshold: thresholdSelector(state),
  errorMsg: errorMsgSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  getDepthsData: bindActionCreators(getDepthsDataRequest, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HeatMap);
