import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  heatMapLoadingSelector,
  dataSelector,
  getDepthsDataRequest,
  thresholdSelector,
} from "redux/heatMap";

import { AppLoader } from "components/commons";
import { calcY } from "utils/heatMap";

const HeatMapMini = ({
  deviceId,
  daterange,
  data,
  getDepthsData,
  deviceDepths,
  threshold,
}) => {
  const [firstDepth, setFirstDepth] = useState("depth1");

  useEffect(() => {
    if (deviceDepths && deviceDepths.depth2 < deviceDepths.depth1) {
      setFirstDepth("depth2");
    }
    getDepthsData({
      deviceId: deviceId,
      from: daterange[0].valueOf(),
      to: daterange[1].valueOf(),
      isForInsight: true,
    });
  }, [deviceId, daterange, deviceDepths]);
  if (data && data[`${deviceId}-insight`]) {
    return (
      <div style={{ height: "60px" }}>
        <div
          style={{
            height: "100%",
            display: "flex",
            width: "cacl(100% - 40px)",
            padding: "0 20px",
          }}
        >
          {data[`${deviceId}-insight`].map((item) => (
            <div
              key={Math.random()}
              style={{
                height: "100%",
                background: `linear-gradient(180deg, rgba(${parseInt(
                  calcY(
                    50,
                    255,
                    0,
                    threshold[deviceId] || -20,
                    firstDepth === "depth1" ? item.rt1Value : item.rt2Value
                  )
                )}, ${parseInt(
                  calcY(
                    255,
                    100,
                    0,
                    threshold[deviceId] || -20,
                    firstDepth === "depth1" ? item.rt1Value : item.rt2Value
                  )
                )}, 0) 0%, rgba(${parseInt(
                  calcY(
                    50,
                    255,
                    0,
                    threshold[deviceId] || -20,
                    firstDepth === "depth1" ? item.rt2Value : item.rt1Value
                  )
                )}, ${parseInt(
                  calcY(
                    255,
                    100,
                    0,
                    threshold[deviceId] || -20,
                    firstDepth === "depth1" ? item.rt2Value : item.rt1Value
                  )
                )}, 0) 100%)`,
                flex: 1,
              }}
            />
          ))}
        </div>
      </div>
    );
  } else {
    return (
      <div style={{ height: "60px" }}>
        <AppLoader />
      </div>
    );
  }
};

const mapStateToProps = (state) => ({
  heatMapLoading: heatMapLoadingSelector(state),
  data: dataSelector(state),
  threshold: thresholdSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  getDepthsData: bindActionCreators(getDepthsDataRequest, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HeatMapMini);
