import { put, takeEvery, all, call } from "redux-saga/effects";
import { getPlotIrrigationConnection } from "api";
/* *
 * Constants
 * */
export const moduleName = "plotIrrigationConnection";
const prefix = `${process.env.REACT_APP_NAME}/${moduleName}`;

export const INIT = `${prefix}/INIT`;
export const SUCCESS = `${prefix}/SUCCESS`;
export const REJECT = `${prefix}/REJECT`;
/* *
 * Reducer
 * */
const initialState = {
  plotIrrigationConnection: {},
  loading: false,
  error: {},
  success: false,
};

export default function reducer(
  state = initialState,
  { type, payload, meta, ...rest }
) {
  switch (type) {
    case INIT:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        plotIrrigationConnection: {
          ...state.plotIrrigationConnection,
          [payload.plotId]: payload.res,
        },
      };
    case REJECT:
      return {
        ...state,
        loading: false,
        success: false,
        error: payload.error,
        plotIrrigationConnection: {
          ...state.plotIrrigationConnection,
          [payload.plotId]: null,
        },
      };
    default:
      return state;
  }
}

/* *
 * Selectors
 * */
export const moduleSelector = (state) => state[moduleName];

export const plotIrrigationConnectionSelector = (state) =>
  moduleSelector(state).plotIrrigationConnection;

export const isLoadingSelector = (state) => moduleSelector(state).loading;

export const isSuccessSelector = (state) => moduleSelector(state).success;

/* *
 * Action Creators
 * */
export const getPlotIrrigationConnectionRequest = (plotId) => ({
  type: INIT,
  plotId,
});

export const successAction = (payload) => ({
  type: SUCCESS,
  payload,
});

export const errorAction = (payload) => ({
  type: REJECT,
  payload
});

/* *
 * Sagas
 * */
export function* getPlotIrrigationConnectionSaga({ plotId }) {
  try {
    let accountId = null;
    const accountFromStorage = localStorage.getItem("accountData");
    accountId = JSON.parse(accountFromStorage).id;
    const res = yield call(getPlotIrrigationConnection, accountId, plotId);
    yield put(successAction({ res, plotId }));
  } catch (error) {
    yield put(errorAction({ error: error.message, plotId }));
  }
}

export function* saga() {
  yield all([takeEvery(INIT, getPlotIrrigationConnectionSaga)]);
}
