import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, Tabs, Tab } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import WpIcon from "media/wpIcon.svg";
import HeatmapIcon from "media/heatmapIcon.svg";
import MapIcon from "media/mapIcon.svg";
import InsightsIcon from "media/insightsIcon.svg";
import RainIcon from "media/rainIcon.svg";
import ForecastIcon from "media/forecastIcon.svg";
import BatteryIcon from "media/batteryIcon.svg";
import ChargingIcon from "media/chargingIcon.svg";
import AirTempIcon from "media/airTemp.svg";
import HumidityIcon from "media/humidity.svg";
import CheckIcon from "media/check.svg";
import SoilTemperature from "media/soilTemperature.svg";
import PipePressureIcon from "media/pipePressure.png";
import PowerVoltageIcon from "media/powerVoltage.png";
import AirTemperatureSensor from "media/airTemperatureSensor.png";
import AirHumiditySensor from "media/airHumiditySensor.png";
import AutopilotIcon from "media/autopilotIcon.svg";
import NewBadge from "media/newBadge.svg";
import PermissionsService from "services/PermissionsService";

import clsx from "clsx";

import useStyles from "./styles";

const a11yProps = (index) => {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
};

const IconWidget = ({ icon, label, checked, textStyle, ...rest }) => {
  const {
    categoryItem,
    categoryItemName,
    checkIcon,
    checkedWidget,
    categoryItemNameChecked,
  } = useStyles();
  return (
    <Box className={clsx(categoryItem, { [checkedWidget]: checked })} {...rest}>
      <img
        hidden={!checked}
        src={CheckIcon}
        alt="check"
        className={checkIcon}
      />
      <img src={icon} style={{ height: "40px" }} alt="icon" />
      <Typography
        className={clsx(categoryItemName, textStyle, {
          [categoryItemNameChecked]: checked,
        })}
      >
        {label}
      </Typography>
    </Box>
  );
};

const DataWidget = ({ value, label, checked, ...rest }) => {
  const {
    categoryItem,
    categoryItemName,
    checkIcon,
    categoryItemValue,
    checkedWidget,
    categoryItemNameChecked,
  } = useStyles();
  return (
    <Box className={clsx(categoryItem, { [checkedWidget]: checked })} {...rest}>
      <img
        hidden={!checked}
        src={CheckIcon}
        alt="check"
        className={checkIcon}
      />
      <Typography className={categoryItemValue}>{value}</Typography>
      <Typography
        className={clsx(categoryItemName, {
          [categoryItemNameChecked]: checked,
        })}
      >
        {label}
      </Typography>
    </Box>
  );
};

const AddNewWidget = ({
  selectedPlot,
  toggleAddWidgetDrawer,
  dashboardSettings,
  saveDashboardSettings,
  devices,
}) => {
  const { t } = useTranslation();
  const {
    container,
    header,
    title,
    closeBtn,
    tabLabel,
    content,
    category,
    categoryItems,
    autopilotLabel,
    noMargin,
  } = useStyles();
  const [selectedTab, selectTab] = useState(0);

  const handleChangeTab = (event, newValue) => {
    selectTab(newValue);
  };

  const handlePressSetting = (settingKey) => {
    let settings = JSON.parse(JSON.stringify(dashboardSettings));
    const keyIndex = dashboardSettings.findIndex(
      (setting) => setting.key === settingKey
    );
    if (keyIndex === -1) {
      settings = [
        {
          key: settingKey,
          enabled: true,
          options: {
            gridConfig: null,
            defaultGridConfig: { x: 0, y: 0, w: 12, h: 6 },
          },
        },
        ...settings,
      ];
    } else {
      settings[keyIndex] = {
        ...settings[keyIndex],
        enabled: !dashboardSettings[keyIndex].enabled,
        options: {
          ...settings[keyIndex].options,
          gridConfig: null,
        },
      };
    }
    saveDashboardSettings({
      plotId: selectedPlot.plotId,
      settings: settings,
      updateData: true,
    });
  };

  const isEnabled = (settingKey) => {
    const setting = dashboardSettings.find(
      (setting) => setting.key === settingKey
    );
    return setting ? setting.enabled : false;
  };
  return (
    <Box className={container}>
      <Box className={header}>
        <Typography className={title}>{t("addNewWidget")}</Typography>
        <CloseIcon
          className={closeBtn}
          onClick={() => toggleAddWidgetDrawer(false)}
        />
      </Box>
      {dashboardSettings && (
        <Box className={content}>
          <Tabs
            value={selectedTab}
            onChange={handleChangeTab}
            indicatorColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            TabIndicatorProps={{
              style: {
                height: "3px",
              },
            }}
          >
            <Tab className={tabLabel} label={t("general")} {...a11yProps(0)} />
            {devices.map((device, i) => (
              <Tab
                key={Math.random()}
                className={tabLabel}
                label={device.name}
                {...a11yProps(i + 1)}
              />
            ))}
          </Tabs>
          <Box hidden={selectedTab !== 0}>
            {PermissionsService.isAllowed("AUTOPILOT_WIDGETS_VIEW") && (
              <Box>
                <Box
                  style={{
                    display: "flex",
                    marginTop: "40px",
                    marginBottom: "16px",
                  }}
                >
                  <Typography className={clsx(category, noMargin)}>
                    {t("irrigationController")}
                  </Typography>
                  <img src={NewBadge} alt="new" />
                </Box>
                <Box className={categoryItems}>
                  <IconWidget
                    icon={AutopilotIcon}
                    textStyle={autopilotLabel}
                    label={t("autopilot")}
                    checked={isEnabled("autopilotWidget")}
                    onClick={() => handlePressSetting("autopilotWidget")}
                  />
                  <IconWidget
                    icon={WpIcon}
                    label={t("virtualDeviceWp")}
                    checked={isEnabled("virtualDevice")}
                    onClick={() => handlePressSetting("virtualDevice")}
                  />
                </Box>
              </Box>
            )}
            <Box>
              <Typography className={category}>{t("general")}</Typography>
              <Box className={categoryItems}>
                <IconWidget
                  icon={MapIcon}
                  label={t("map")}
                  checked={isEnabled("mapWidget")}
                  onClick={() => handlePressSetting("mapWidget")}
                />
                <IconWidget
                  icon={InsightsIcon}
                  label={t("insights")}
                  checked={isEnabled("insightsWidget")}
                  onClick={() => handlePressSetting("insightsWidget")}
                />
              </Box>
            </Box>
            <Box>
              <Typography className={category}>{t("weather")}</Typography>
              <Box className={categoryItems}>
                <IconWidget
                  icon={ForecastIcon}
                  label={t("forecast")}
                  checked={isEnabled("forecastWidget")}
                  onClick={() => handlePressSetting("forecastWidget")}
                />
                <IconWidget
                  icon={RainIcon}
                  label={t("rain")}
                  checked={isEnabled("rainWidget")}
                  onClick={() => handlePressSetting("rainWidget")}
                />
                <IconWidget
                  icon={AirTempIcon}
                  label={t("virtualAirTemperature")}
                  checked={isEnabled("airTemperatureWidget")}
                  onClick={() => handlePressSetting("airTemperatureWidget")}
                />
                <IconWidget
                  icon={HumidityIcon}
                  label={t("virtualAirHumidity")}
                  checked={isEnabled("airHumidityWidget")}
                  onClick={() => handlePressSetting("airHumidityWidget")}
                />
              </Box>
            </Box>
            <Box>
              <Typography className={category}>{t("battery")}</Typography>
              <Box className={categoryItems}>
                <IconWidget
                  icon={BatteryIcon}
                  label={t("chargingHistory")}
                  checked={isEnabled("chargingHisory")}
                  onClick={() => handlePressSetting("chargingHisory")}
                />
                <IconWidget
                  icon={ChargingIcon}
                  label={t("batteriesStatus")}
                  checked={isEnabled("batteryStatus")}
                  onClick={() => handlePressSetting("batteryStatus")}
                />
              </Box>
            </Box>
            <Box>
              <Typography className={category}>{t("realTimeValue")}</Typography>
              <Box className={categoryItems}>
                <DataWidget
                  value="-0.5°C"
                  label={t("airTemperature")}
                  checked={isEnabled("realTimeAirTemp")}
                  onClick={() => handlePressSetting("realTimeAirTemp")}
                />
                <DataWidget
                  value="40%"
                  label={t("humidity")}
                  checked={isEnabled("realTimeHumidity")}
                  onClick={() => handlePressSetting("realTimeHumidity")}
                />
              </Box>
            </Box>
          </Box>
          {devices.map((device, i) => (
            <Box key={i} hidden={selectedTab !== i + 1}>
              <Box>
                <Typography className={category}>{t("device")}</Typography>
                <Box className={categoryItems}>
                  <IconWidget
                    icon={WpIcon}
                    label={t("waterPotential")}
                    checked={isEnabled(`waterPotential-${device.id}`)}
                    onClick={() =>
                      handlePressSetting(`waterPotential-${device.id}`)
                    }
                  />
                  <IconWidget
                    icon={HeatmapIcon}
                    label={t("depth")}
                    checked={isEnabled(`depths-${device.id}`)}
                    onClick={() => handlePressSetting(`depths-${device.id}`)}
                  />
                  <IconWidget
                    icon={SoilTemperature}
                    label={t("soilTemperature")}
                    checked={isEnabled(`soilTemp-${device.id}`)}
                    onClick={() => handlePressSetting(`soilTemp-${device.id}`)}
                  />
                  {device.sensors.some(
                    (sensor) =>
                      sensor.sensorType &&
                      sensor.sensorType.type === "solarPanel"
                  ) && (
                    <IconWidget
                      icon={PowerVoltageIcon}
                      label={t("powerVoltage")}
                      checked={isEnabled(`powerVoltage-${device.id}`)}
                      onClick={() =>
                        handlePressSetting(`powerVoltage-${device.id}`)
                      }
                    />
                  )}
                  {device.sensors.some(
                    (sensor) =>
                      sensor.sensorType &&
                      sensor.sensorType.type === "airTemperatureAndHumidity"
                  ) && (
                    <IconWidget
                      icon={AirTemperatureSensor}
                      label={t("airTemperatureSensorWidget")}
                      checked={isEnabled(
                        `airTemperatureSensorWidget-${device.id}`
                      )}
                      onClick={() =>
                        handlePressSetting(
                          `airTemperatureSensorWidget-${device.id}`
                        )
                      }
                    />
                  )}
                  {device.sensors.some(
                    (sensor) =>
                      sensor.sensorType &&
                      sensor.sensorType.type === "airTemperatureAndHumidity"
                  ) && (
                    <IconWidget
                      icon={AirHumiditySensor}
                      label={t("airHumiditySensorWidget")}
                      checked={isEnabled(
                        `airHumiditySensorWidget-${device.id}`
                      )}
                      onClick={() =>
                        handlePressSetting(
                          `airHumiditySensorWidget-${device.id}`
                        )
                      }
                    />
                  )}
                  {device.sensors.some(
                    (sensor) =>
                      sensor.sensorType &&
                      sensor.sensorType.type === "pipePressure"
                  ) && (
                    <IconWidget
                      icon={PipePressureIcon}
                      label={t("pipePressure")}
                      checked={isEnabled(`pipePressure-${device.id}`)}
                      onClick={() =>
                        handlePressSetting(`pipePressure-${device.id}`)
                      }
                    />
                  )}
                </Box>
              </Box>
              <Box>
                <Typography className={category}>
                  {t("realTimeValue")}
                </Typography>
                <Box className={categoryItems}>
                  <DataWidget
                    value="-0.5cb"
                    label={t("waterPotential")}
                    checked={isEnabled(`realTimeWp-${device.id}`)}
                    onClick={() =>
                      handlePressSetting(`realTimeWp-${device.id}`)
                    }
                  />
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default AddNewWidget;
