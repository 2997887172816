import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  container: {
    flex: 1,
    minWidth: "482px",
  },
  header: {
    height: "128px",
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 30px",
  },
  headerTitle: {
    color: theme.palette.text.white,
    fontWeight: "bold",
  },
  closeIcon: {
    color: theme.palette.text.white,
    cursor: "pointer",
    "&:hover": {
      opacity: 0.7,
    },
  },
  userForm: {
    padding: "30px",
  },
  btnContainer: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    marginTop: "40px",
  },
  cancelBtn: {
    flex: 1,
    marginInlineEnd: "4px",
  },
  saveBtn: {
    flex: 1,
    marginInlineStart: "4px",
  },
  codeBox: {
    marginTop: "30px",
  },
  code: {
    fontWeight: "bold",
  },
  codeMsg: {
    fontSize: "14px",
    lineHeight: "16px",
    color: theme.palette.text.lightGray,
  },
  resendInvitationBtn:{
    fontSize: "14px",
    lineHeight: "16px",
    fontWeight: "bold",
    color: theme.palette.primary.main,
    margin: "37px 15px",
    width: "fit-content",
    cursor: "pointer",
    "&:hover": {
      opacity: 0.7
    }
  }
}));
