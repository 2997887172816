import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  getExpirationStatusRequest,
  expirationStatusSelector,
} from "redux/expirationStatus";
import { addProductsToCartRequest } from "redux/addProductsToCart";
import clsx from "clsx";
import {
  Header,
  SideBar,
  HelpFooter,
  MobileHeader,
  SubscriptionAlertBar,
} from "components/commons";
import { Box, Drawer, AppBar, IconButton } from "@material-ui/core";
import Intercom from "media/intercom.svg";
import { useGA4React } from "ga-4-react";
import history from "history.js";
import i18n from "i18next";
import useStyles from "./styles";

const AuthLayout = ({
  children,
  whiteHeader,
  getExpirationStatus,
  expirationStatus,
  addProductsToCart,
  hideHeader = false,
  hideSidebar = false,
  displayHelpFooter = false,
}) => {
  const ga = useGA4React();
  const [open, toggleDrawer] = React.useState(
    localStorage.getItem("drawerIsOpen") === "true" ? true : false
  );
  const [showAlertBar, switchAlertBarStatus] = useState(false);
  const {
    root,
    appBar,
    drawer,
    drawerOpen,
    drawerClose,
    toolbar,
    content,
    footer,
    intercomBtn,
    drawerCloseMobile,
    appBarMobile,
    contentMobile,
  } = useStyles({
    drawerWidth: 255,
    drawerWidthClose: 88,
    isRtl: i18n.dir() === "rtl",
    open,
    whiteHeader,
    displayHelpFooter,
    showAlertBar,
  });
  useEffect(() => {
    getExpirationStatus();
  }, []);
  useEffect(() => {
    if (
      expirationStatus &&
      (expirationStatus.expired || expirationStatus.aboutToExpire)
    ) {
      if (ga) {
        ga.gtag("event", "see_alert_bar", {});
      }
      switchAlertBarStatus(true);
    } else {
      switchAlertBarStatus(false);
    }
  }, [expirationStatus]);
  const closeAlertBar = () => {
    ga.gtag("event", "close_alert_bar", {});
    switchAlertBarStatus(false);
  };
  const openIntercom = () => {
    window.Intercom("show");
  };
  const handleToggleDrawer = () => {
    toggleDrawer(!open);
    localStorage.setItem("drawerIsOpen", !open);
    setTimeout(() => {
      let resizeEvent = window.document.createEvent("UIEvents");
      resizeEvent.initUIEvent("resize", true, false, window, 0);
      window.dispatchEvent(resizeEvent);
    }, 500);
  };
  const handlePressRenewNow = () => {
    ga.gtag("event", "press_renew_from_alert_bar", {});
    if(expirationStatus.aboutToExpireIds || expirationStatus.expiredIds) {
      addProductsToCart({
        productIds: [
          ...expirationStatus.aboutToExpireIds,
          ...expirationStatus.expiredIds,
        ],
        callback: () => history.push("/cart"),
      });
    } else {
      history.push("/my-products")
    }
  };
  return (
    <Box>
      {showAlertBar && (
        <SubscriptionAlertBar
          closeAlertBar={closeAlertBar}
          showAlertBar={showAlertBar}
          expirationStatus={expirationStatus}
          handlePressRenewNow={handlePressRenewNow}
        />
      )}
      <Box className={root}>
        {!hideHeader && window.outerWidth > 414 ? (
          <AppBar className={appBar} position="fixed">
            <Header />
          </AppBar>
        ) : (
          !hideHeader && (
            <AppBar position="fixed" className={appBarMobile}>
              <MobileHeader toggleDrawer={() => handleToggleDrawer(!open)} />
            </AppBar>
          )
        )}
        {!hideSidebar && window.outerWidth > 414 && (
          <Drawer
            variant="permanent"
            className={clsx(drawer, {
              [drawerOpen]: open,
              [drawerClose]: !open && window.outerWidth > 414,
              [drawerCloseMobile]: !open && window.outerWidth <= 414,
            })}
            classes={{
              paper: clsx({
                [drawerOpen]: open,
                [drawerClose]: !open && window.outerWidth > 414,
                [drawerCloseMobile]: !open && window.outerWidth <= 414,
              }),
            }}
          >
            <SideBar
              toggleDrawer={() => handleToggleDrawer(!open)}
              isOpen={open}
            />
          </Drawer>
        )}
        <main
          className={clsx({
            [contentMobile]: window.outerWidth <= 414,
            [content]: window.outerWidth > 414,
          })}
        >
          <div className={toolbar} />
          {children}
          {displayHelpFooter ? (
            <HelpFooter footerStyle={window.outerWidth > 414 && footer} />
          ) : (
            <IconButton onClick={openIntercom} className={intercomBtn}>
              <img src={Intercom} alt="Intercom" />
            </IconButton>
          )}
        </main>
        {!hideSidebar && window.outerWidth <= 414 && (
          <Drawer
            anchor="right"
            variant="permanent"
            className={clsx(drawer, {
              [drawerOpen]: open,
              [drawerClose]: !open && window.outerWidth > 414,
              [drawerCloseMobile]: !open && window.outerWidth <= 414,
            })}
            classes={{
              paper: clsx({
                [drawerOpen]: open,
                [drawerClose]: !open && window.outerWidth > 414,
                [drawerCloseMobile]: !open && window.outerWidth <= 414,
              }),
            }}
          >
            <SideBar
              toggleDrawer={() => handleToggleDrawer(!open)}
              isOpen={open}
            />
          </Drawer>
        )}
      </Box>
    </Box>
  );
};
const mapStateToProps = (state) => ({
  expirationStatus: expirationStatusSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  getExpirationStatus: bindActionCreators(getExpirationStatusRequest, dispatch),
  addProductsToCart: bindActionCreators(addProductsToCartRequest, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthLayout);
