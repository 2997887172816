import { put, takeEvery, all, call } from "redux-saga/effects";
import { getTrialSubscription } from "api";

/* *
 * Constants
 * */
export const moduleName = "getTrialSubscription";
const prefix = `${process.env.REACT_APP_NAME}/${moduleName}`;

export const INIT = `${prefix}/INIT`;
export const SUCCESS = `${prefix}/SUCCESS`;
export const ERROR = `${prefix}/ERROR`;
export const CLEAR = `${prefix}/CLEAR`;

/* *
 * Reducer
 * */
const initialState = {
  loading: false,
  success: false,
  error: false,
};

export default function reducer(
  state = initialState,
  { type, payload, meta, ...rest }
) {
  switch (type) {
    case INIT:
      return {
        ...state,
        loading: true,
      };
    case SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };
    case ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        error: true,
      };
    case CLEAR:
      return initialState;
    default:
      return state;
  }
}

/* *
 * Selectors
 * */
export const moduleSelector = (state) => state[moduleName];

export const loadingSelector = (state) => moduleSelector(state).loading;
export const successSelector = (state) => moduleSelector(state).success;
export const errorSelector = (state) => moduleSelector(state).error;

/* *
 * Action Creators
 * */
export const getTrialSubscriptionRequest = ({ organizationId, quantity }) => ({
  type: INIT,
  organizationId,
  quantity,
});

export const successAction = () => ({
  type: SUCCESS,
});
export const errorAction = () => ({
  type: ERROR,
});
export const clearGetTrial = () => ({
  type: CLEAR,
});
/* *
 * Sagas
 * */
export function* getTrialSubscriptionSaga({ organizationId, quantity }) {
  try {
    const res = yield call(getTrialSubscription, organizationId, quantity);
    yield put(successAction(res));
  } catch (error) {
    yield put(errorAction(error));
  }
}

export function* saga() {
  yield all([takeEvery(INIT, getTrialSubscriptionSaga)]);
}
