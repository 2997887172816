import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  container: {
    width: "483px",
  },
  header: {
    height: "86px",
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 30px",
  },
  headerTitle: {
    fontWeight: "bold",
    fontSize: "24px",
    lineHeight: "28px",
    color: "white",
  },
  btnContainer: {
    display: "flex",
  },
  deleteBtn: {
    color: "white",
    borderRadius: "28px",
    textTransform: "none",
    margin: "0 10px",
    width: "76px",
  },
  markAsDone: {
    backgroundColor: "white",
    color: theme.palette.primary.main,
    borderRadius: "28px",
    textTransform: "none",
    width: "122px",
    "&:hover": {
      backgroundColor: "white",
      color: theme.palette.primary.main,
      opacity: 0.7,
    },
  },
  content: {
    padding: "30px",
    overflow: "auto",
  },
  insightIconContainer: {
    width: "45px",
    height: "45px",
    borderRadius: "50%",
    minWidth: "45px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginInlineEnd: "15px",
  },
  icon: {
    width: "22px",
    height: "22px",
  },
  alert: {
    display: "flex",
  },
  alertTitle: {
    fontWeight: "bold",
    fontSize: "18px",
    lineHeight: "150%",
    color: "#4A4B54",
  },
  alertInstr: {
    fontSize: "16px",
    lineHeight: "150%",
    color: "#4A4B54",
    marginTop: "6px",
  },
  fieldPlotInfo: {
    color: "#828B93",
    fontSize: "16px",
    lineHeight: "19px",
    marginTop: "16.5px",
  },
  btnsRow: {
    display: "flex",
    marginTop: "16.5px",
  },
  actionBtn: {
    border: "1px solid #EBEDF3",
    width: "40px",
    height: "40px",
    marginInlineEnd: "14px",
  },
  comments: {
    fontWeight: "bold",
    fontSize: "18px",
    lineHeight: "150%",
    color: "#4A4B54",
  },
  comment: {
    marginTop: "18px",
  },
  commentText: {
    fontSize: "14px",
    lineHeight: "150%",
    color: "#4A4B54",
  },
  commentInfo: {
    display: "flex",
    marginTop: "8px",
    alignItems: "center",
  },
  author: {
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "16px",
    color: "#4A4B54",
    marginInlineEnd: "14px",
  },
  date: {
    fontSize: "14px",
    lineHeight: "150%",
    color: "#828B93",
  },
  commentsContainer: {
    marginTop: "32px",
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: "underline",
    display: "inline",
  },
  recommendations: {
    fontWeight: "bold",
    fontSize: "18px",
    lineHeight: "150%",
    color: "#4A4B54",
    marginBottom: "24px",
  },
  recommendation: {
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "19px",
    color: "#4A4B54",
    marginTop: "24px",
    whiteSpace: "pre-line",
  },
  helpMsg: {
    fontSize: "16px",
    lineHeight: "150%",
    color: "#4A4B54",
    marginTop: "32px",
  },
  supportLink: {
    display: "inline",
    color: theme.palette.primary.main,
    textDecoration: "underline",
    cursor: "pointer",
    "&:hover": {
      opacity: 0.7,
    },
  },
  chartTitle: {
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "19px",
    color: "#4A4B54",
    marginTop: "24px",
  },
  deviceName: {
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "19px",
    color: theme.palette.primary.main,
  },
}));
