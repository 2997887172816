import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box, IconButton, Menu, MenuItem  } from "@material-ui/core";
import {
  getAirTempLastValueRequest,
  airTempLastValueSelector,
} from "redux/airTempLastData";
import { AppLoader } from "components/commons";
import moment from "moment";

import useStyles from "./styles";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import clsx from "clsx";
import { settingsSelector } from "redux/dashboardSettings";
import { saveDashboardSettingsRequest } from "redux/saveDashboardSettings";
import DeleteIcon from "media/deleteIcon.svg";
import AirTempIcon from "media/airTemp.svg";

const AirTempLastData = ({
  plotId,
  fieldId,
  airTempLastValue,
  getAirTempLastValue,
  saveDashboardSettings,
  dashboardSettings,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { t } = useTranslation();
  const {
    container,
    header,
    variable,
    content,
    lastValue,
    row,
    value,
    lastUpdated,
    lastUpdatedDate,
    menuItemText,
  } = useStyles();
  useEffect(() => {
    getAirTempLastValue(fieldId);
  }, [fieldId]);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const deleteWidget = () => {
    const index = dashboardSettings.findIndex(item => item.key === "realTimeAirTemp");
    dashboardSettings[index].enabled = false;
    saveDashboardSettings({
      plotId: plotId,
      settings: dashboardSettings,
      updateData: true
    })
  }
  const open = Boolean(anchorEl);
  return (
    <Box className={container}>
      <Box className={clsx("widgetHeader", header)}>
      <Box style={{ display: "flex", alignItems: "center" }}>
          <img
            width="32px"
            height="32px"
            style={{ marginInlineEnd: "10px" }}
            src={AirTempIcon}
            alt="icon"
          />
          <Box className={variable}>{t("airTemperature")}</Box>
        </Box>
        <IconButton onClick={handleClick} size="small">
          <MoreVertIcon />
        </IconButton>
      </Box>
      {airTempLastValue ? (
        <Box className={content}>
          <Box className={lastValue}>{t("lastValue")}</Box>
          <Box className={row}>
            <Box className={value}>{airTempLastValue.value} °C</Box>
          </Box>
          <Box className={lastUpdated}>{t("lastUpdated")}</Box>
          <Box className={lastUpdatedDate}>
            {moment(airTempLastValue.timestamp).format("DD/MM/YYYY, hh:mm")}
          </Box>
        </Box>
      ) : (
        <Box height="80%">
          <AppLoader />
        </Box>
      )}
      <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <MenuItem
            style={{
              width: "200px",
            }}
            onClick={deleteWidget}
          >
            <img src={DeleteIcon} alt="DeleteIcon" />
            <Box className={menuItemText}>{t("delete")}</Box>
          </MenuItem>
        </Menu>
    </Box>
  );
};
const mapStateToProps = (state) => ({
  airTempLastValue: airTempLastValueSelector(state),
  dashboardSettings: settingsSelector(state)
});

const mapDispatchToProps = (dispatch) => ({
  getAirTempLastValue: bindActionCreators(getAirTempLastValueRequest, dispatch),
  saveDashboardSettings: bindActionCreators(saveDashboardSettingsRequest, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AirTempLastData);
