import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  Box,
  Container,
  Typography,
  Divider,
  Breadcrumbs,
  Link,
  Popover,
  MenuItem,
} from "@material-ui/core";
import clsx from "clsx";
import history from "history.js";
import { userNameSelector, userIdSelector } from "redux/me";
import { accountsCountSelector } from "redux/accountsCount";
import { getCartTotalsRequest, cartTotalsSelector } from "redux/cartTotals";
import {
  getAccountHierarchyRequest,
  accountHierarchySelector,
} from "redux/accountHierarchy";
import { LanguagePicker } from "components/commons";
import { useTranslation } from "react-i18next";
import LogOut from "media/logout.svg";

import AppLogo from "media/logoWithText.svg";
import Cart from "media/cart.svg";
import useStyles from "./styles";

const Header = ({
  userName,
  userId,
  getAccountHierarchy,
  accountHierarchy,
  accountsCount,
  getCartTotals,
  cartTotals,
  signOut
}) => {
  const { t } = useTranslation();
  const accountData = localStorage.getItem("accountData");
  const {
    container,
    userNameStyle,
    divider,
    containerAuthenticated,
    link,
    mainAccount,
    separatorStyle,
    cartContainer,
    cartNumber,
    logoutBtn
  } = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClickUser = (event) => {
    setAnchorEl(event.currentTarget);
  };
  useEffect(() => {
    if (userId && accountData) {
      getAccountHierarchy();
      getCartTotals({ period: 1 });
    }
  }, [userId]);
  const isAuthenticated = !!localStorage.getItem("token");
  const handleClick = (event, account) => {
    event.preventDefault();
    if (account !== "all") {
      history.push(
        `/account-login?organizationId=${account.id}&name=${
          account.name
        }&parentId=${account.parent}&redirectUrl=${window.location.pathname}`
      );
    } else {
      const forbiddenRoutes = ["/dashboard", "/my-products", "/cart"];
      history.push(
        `/account-login?clearAccount=${true}&redirectUrl=${
          forbiddenRoutes.includes(window.location.pathname)
            ? "/accounts"
            : window.location.pathname
        }`
      );
    }
  };
  const handlePressCart = () => {
    history.push("/cart");
  };
  const open = Boolean(anchorEl);
  return (
    <Container
      maxWidth={!isAuthenticated && "lg"}
      className={clsx(container, {
        [containerAuthenticated]: isAuthenticated,
      })}
    >
      <Box display="flex" alignItems="center">
        {!isAuthenticated && (
          <Box>
            <img src={AppLogo} height="50px" alt="logo" />
          </Box>
        )}
        {accountsCount > 1 ? (
          <Breadcrumbs
            separator={<Typography className={separatorStyle}>/</Typography>}
            aria-label="breadcrumb"
          >
            {accountData ? (
              <Link
                color="inherit"
                href="/"
                className={link}
                onClick={(e) => handleClick(e, "all")}
              >
                {t("allAccounts")}
              </Link>
            ) : (
              <Typography className={mainAccount}>
                {t("allAccounts")}
              </Typography>
            )}
            {isAuthenticated &&
              accountHierarchy
                .slice(0)
                .reverse()
                .map((account, i) => {
                  if (accountHierarchy[i + 1]) {
                    return (
                      <Link
                        key={Math.random()}
                        color="inherit"
                        href="/"
                        className={link}
                        onClick={(e) => handleClick(e, account)}
                      >
                        {account.name}
                      </Link>
                    );
                  } else {
                    return (
                      <Typography key={Math.random()} className={mainAccount}>
                        {account.name}
                      </Typography>
                    );
                  }
                })}
          </Breadcrumbs>
        ) : (
          <Typography className={mainAccount}>
            {accountData && JSON.parse(accountData).name}
          </Typography>
        )}
      </Box>
      <Box display="flex" alignItems="center">
        <LanguagePicker whitePicker={isAuthenticated} />
        {isAuthenticated && cartTotals && (
          <Box className={cartContainer} onClick={handlePressCart}>
            <img src={Cart} alt="cart" />
            <Box className={cartNumber}>{cartTotals.productsNr}</Box>
          </Box>
        )}
        {isAuthenticated && (
          <Divider orientation="vertical" flexItem className={divider} />
        )}
        {isAuthenticated && (
          <Typography
            className={userNameStyle}
            variant="subtitle2"
            onClick={handleClickUser}
          >
            {userName}
          </Typography>
        )}
      </Box>
      <Popover
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuItem
          style={{
            height: "46px"
          }}
          onClick={signOut}
        >
          <img src={LogOut} alt="LogOut" />
          <Box className={logoutBtn}>{t("logOut")}</Box>
        </MenuItem>
      </Popover>
    </Container>
  );
};
const mapStateToProps = (state) => ({
  accountsCount: accountsCountSelector(state),
  userName: userNameSelector(state),
  accountHierarchy: accountHierarchySelector(state),
  userId: userIdSelector(state),
  cartTotals: cartTotalsSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  signOut: bindActionCreators(() => ({ type: "LOGOUT" }), dispatch),
  getAccountHierarchy: bindActionCreators(getAccountHierarchyRequest, dispatch),
  getCartTotals: bindActionCreators(getCartTotalsRequest, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header);
