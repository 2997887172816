import React, { useEffect, useState } from "react";
import { TextField, MenuItem, Box } from "@material-ui/core";

import countries from "media/countries.json";
import useStyles from "./styles";
import { connect } from "react-redux";

const CountrySelect = ({
  margin = "normal",
  className = "",
  label,
  touched,
  error,
  value,
  handleChange,
  handleBlur,
  name,
  countryData,
  callingCodePicker = false,
}) => {
  const [countryInitialized, setCountryInitialized] = useState(false);
  const {
    cssFocused,
    cssFocusedLabel,
    flag,
    countryName,
    fieldContainer,
    errorStyles,
  } = useStyles();
  useEffect(() => {
    if (countryData && !countryInitialized && !value) {
      setCountryInitialized(true);
      handleChange({
        target: {
          name: name,
          value: callingCodePicker
            ? countryData.calling_code
            : countryData.country_code,
        },
      });
    }
  });
  return (
    <Box className={fieldContainer}>
      <TextField
        select
        onChange={handleChange}
        onBlur={handleBlur}
        label={label}
        value={value}
        name={name}
        className={className}
        error={!!(touched && error)}
        SelectProps={{
          className: cssFocused,
        }}
        InputLabelProps={{
          classes: {
            focused: cssFocusedLabel,
          },
        }}
        margin={margin}
      >
        {countries.map((country) => {
          if (country.calling_code && country.name && country.country_code) {
            return (
              <MenuItem
                value={
                  callingCodePicker
                    ? country.calling_code
                    : country.country_code
                }
                key={country.country_code}
              >
                <img
                  src={country.flag}
                  alt={country.country_code}
                  className={flag}
                />
                <span className={countryName}>
                  +({country.calling_code}) {country.name}
                </span>
              </MenuItem>
            );
          } else {
            return null;
          }
        })}
      </TextField>
      {touched && error && <small className={errorStyles}>{error}</small>}
    </Box>
  );
};
const mapStateToProps = (state) => ({
  countryData: state.countryData && state.countryData.countryData,
});

export default connect(
  mapStateToProps,
  null
)(CountrySelect);
