import { put, takeEvery, all, call } from "redux-saga/effects";
import { getDevicesByPlot } from "api";
/* *
 * Constants
 * */
export const moduleName = "devicesByPlot";
const prefix = `${process.env.REACT_APP_NAME}/${moduleName}`;

export const INIT = `${prefix}/INIT`;
export const SUCCESS = `${prefix}/SUCCESS`;
export const REJECT = `${prefix}/REJECT`;

/* *
 * Reducer
 * */
const initialState = {
  devicesByPlot: [],
  loading: false,
  error: {},
  success: false,
};

export default function reducer(
  state = initialState,
  { type, payload, meta, ...rest }
) {
  switch (type) {
    case INIT:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        devicesByPlot: payload,
      };
    case REJECT:
      return {
        ...state,
        loading: false,
        success: false,
        error: payload.error,
      };
    default:
      return state;
  }
}

/* *
 * Selectors
 * */
export const moduleSelector = (state) => state[moduleName];

export const devicesByPlotSelector = (state) => moduleSelector(state).devicesByPlot;

export const isLoadingSelector = (state) => moduleSelector(state).loading;

export const isSuccessSelector = (state) => moduleSelector(state).success;

/* *
 * Action Creators
 * */
export const getDevicesByPlotRequest = (plotId) => ({
  type: INIT,
  plotId
});

export const successAction = (payload) => ({
  type: SUCCESS,
  payload,
});

export const errorAction = (error) => ({
  type: REJECT,
  payload: { error },
});

/* *
 * Sagas
 * */
export function* getDevicesByPlotSaga({plotId}) {
  try {
    let accountId = null;
    const accountFromStorage = localStorage.getItem("accountData");
    accountId = JSON.parse(accountFromStorage).id;
    const res = yield call(getDevicesByPlot, accountId, plotId);
    yield put(successAction(res));
  } catch (error) {
    yield put(errorAction(error.message));
  }
}

export function* saga() {
  yield all([takeEvery(INIT, getDevicesByPlotSaga)]);
}
