import { put, takeEvery, all, call } from "redux-saga/effects";
import { getPipePressureData } from "api";
/* *
 * Constants
 * */
export const moduleName = "pipePressure";
const prefix = `${process.env.REACT_APP_NAME}/${moduleName}`;

export const INIT = `${prefix}/INIT`;
export const SUCCESS = `${prefix}/SUCCESS`;
export const REJECT = `${prefix}/REJECT`;
export const CLEAR = `${prefix}/CLEAR`;

/* *
 * Reducer
 * */
const initialState = {
  pipePressure: null,
  loading: false,
  error: {},
  success: false,
  errorMsg: null,
};

export default function reducer(
  state = initialState,
  { type, payload, meta, ...rest }
) {
  switch (type) {
    case INIT:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        pipePressure: {
          ...state.pipePressure,
          [payload.isForInsight
            ? `${payload.deviceId}-insight`
            : payload.deviceId]: payload.data,
        },
      };
    case REJECT:
      return {
        ...state,
        loading: false,
        success: false,
        error: true,
        errorMsg: {
          ...state.errorMsg,
          [payload.error.deviceId]: payload.error.msg,
        },
      };
    case CLEAR:
      return initialState;
    default:
      return state;
  }
}

/* *
 * Selectors
 * */
export const moduleSelector = (state) => state[moduleName];

export const pipePressureSelector = (state) =>
  moduleSelector(state).pipePressure;

export const isLoadingSelector = (state) => moduleSelector(state).loading;

export const isSuccessSelector = (state) => moduleSelector(state).success;

export const errorMsgSelector = (state) => moduleSelector(state).errorMsg;

/* *
 * Action Creators
 * */
export const getPipePressureRequest = ({
  deviceId,
  from,
  to,
  isForInsight = false,
}) => ({
  type: INIT,
  deviceId,
  from,
  to,
  isForInsight,
});

export const successAction = (payload) => ({
  type: SUCCESS,
  payload,
});

export const errorAction = (error) => ({
  type: REJECT,
  payload: { error },
});

export const clearWPData = () => ({
  type: CLEAR,
});

/* *
 * Sagas
 * */
export function* getPipePressureSaga({ deviceId, from, to, isForInsight }) {
  try {
    const accountFromStorage = localStorage.getItem("accountData");
    const res = yield call(
      getPipePressureData,
      JSON.parse(accountFromStorage).id,
      deviceId,
      from,
      to
    );
    yield put(successAction({ deviceId, data: res, isForInsight }));
  } catch (error) {
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.error
    ) {
      yield put(errorAction({ deviceId, msg: error.response.data.error }));
    } else {
      yield put(errorAction({ deviceId, msg: "" }));
    }
  }
}

export function* saga() {
  yield all([takeEvery(INIT, getPipePressureSaga)]);
}
