import React, { useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import validator from "validator";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import {
  StyledTextField,
  AppBtnFab,
  CountrySelect,
  AppAutocomplete,
} from "components/commons";
import {
  isLoadingSelector as accountsListLoading,
  accountsListSelector,
  getAccountsListRequest,
} from "redux/accountsList";
import {
  isLoadingSelector as getAccountLoading,
  getAccountRequest,
  accountDataSelector,
} from "redux/account";
import {
  isLoadingSelector as editAccountLoading,
  editAccountRequest,
} from "redux/editAccount";
import { userIdSelector } from "redux/me";
import { Box, Typography, CircularProgress, Backdrop } from "@material-ui/core";
import _ from "lodash";
import useStyles from "./styles";
import PermissionsService from "services/PermissionsService";

import clsx from "clsx";

const EditAccount = ({
  loadingAccountsList,
  loadingAccountData,
  accountsList,
  getAccountsList,
  userId,
  accountData,
  getAccount,
  editAccountRequest,
  loadingEditAccount,
  match,
}) => {
  const { t } = useTranslation();
  const {
    container,
    content,
    btnContainer,
    inputMargin,
    alignCenter,
    backdrop,
  } = useStyles();
  useEffect(() => {
    if (userId) {
      const { accountId } = match.params;
      getAccountsList();
      getAccount(accountId);
    }
  }, [userId]);
  return (
    <Box className={container}>
      <Backdrop className={backdrop} open={loadingEditAccount}>
        <CircularProgress color="primary" />
      </Backdrop>
      {loadingAccountsList ||
        (loadingAccountData && (
          <Box className={clsx(content, alignCenter)}>
            <CircularProgress color="primary" />
          </Box>
        ))}
      {accountsList.length > 0 &&
        accountData &&
        !loadingAccountsList &&
        !loadingAccountData && (
          <Box className={content}>
            <Typography variant="h5" align="center">
              {t("editAccount")}
            </Typography>
            <Formik
              initialValues={{
                name: accountData.name,
                countryISO: accountData.country,
                email: accountData.email,
                parentAccount: accountData.parentId
                  ? accountsList.find(
                      (account) => account.id === accountData.parentId
                    )
                  : {},
              }}
              validate={(values) => {
                const errors = {};
                if (!values.name) {
                  errors.name = "Required";
                }
                if (!values.countryISO) {
                  errors.countryISO = "Required";
                }
                if (!values.email) {
                  errors.email = "Required";
                }
                if (
                  _.isEmpty(values.parentAccount) &&
                  accountData.parentId
                ) {
                  errors.parentAccount = "Required";
                }
                if (!validator.isEmail(values.email)) {
                  errors.email = "Please enter a valid email";
                }
                return errors;
              }}
              onSubmit={(values, { setSubmitting }) => {
                if (accountData.parentId) {
                  editAccountRequest(accountData.id, {
                    name: values.name,
                    countryISO: values.countryISO,
                    email: values.email,
                    parentId: values.parentAccount.id,
                  });
                } else {
                  editAccountRequest(accountData.id, {
                    name: values.name,
                    countryISO: values.countryISO,
                    email: values.email,
                  });
                }
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
              }) => (
                <form onSubmit={handleSubmit}>
                  <StyledTextField
                    label={t("enterAccountName")}
                    name="name"
                    value={values.name}
                    touched={touched.name}
                    error={errors.name}
                    className={inputMargin}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                  />
                  <CountrySelect
                    label={t("location")}
                    name="countryISO"
                    value={values.countryISO}
                    touched={touched.countryISO}
                    error={errors.countryISO}
                    className={inputMargin}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                  />
                  {accountData.parentId && (
                    <AppAutocomplete
                      label={t("parentAccount")}
                      name="parentAccount"
                      value={values.parentAccount}
                      touched={touched.parentAccount}
                      error={errors.parentAccount}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      className={inputMargin}
                      optionLabelKey="name"
                      options={accountsList}
                      disabled={!PermissionsService.isAllowed('ACCOUNT_CHANGE_PARENT')}
                    />
                  )}
                  <StyledTextField
                    label={t("billingEmail")}
                    name="email"
                    value={values.email}
                    touched={touched.email}
                    className={inputMargin}
                    error={errors.email}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                  />
                  <Box className={btnContainer}>
                    <AppBtnFab
                      type="submit"
                      label={t("continue")}
                      disabled={!_.isEmpty(errors)}
                    />
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
        )}
    </Box>
  );
};
const mapStateToProps = (state) => ({
  loadingAccountsList: accountsListLoading(state),
  loadingAccountData: getAccountLoading(state),
  loadingEditAccount: editAccountLoading(state),
  accountsList: accountsListSelector(state),
  userId: userIdSelector(state),
  accountData: accountDataSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  getAccountsList: bindActionCreators(getAccountsListRequest, dispatch),
  getAccount: bindActionCreators(getAccountRequest, dispatch),
  editAccountRequest: bindActionCreators(editAccountRequest, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditAccount);
