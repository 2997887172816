import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Paper,
  Checkbox,
  Typography,
  SvgIcon,
  Button,
  Box,
  Tooltip,
  InputAdornment,
  OutlinedInput,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import useStyles from "./styles";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import ActiveLicence from "media/activeLicence.svg";
import EndsLicence from "media/endsLicence.svg";
import ExpiredLicence from "media/expiredLicence.svg";

import moment from "moment";
import history from "history.js"
const EditIcon = ({ className, ...rest }) => (
  <SvgIcon {...rest} viewBox="0 0 30 30" className={className}>
    <path
      d="M22.74 10.5928C23.0867 10.2462 23.0867 9.66852 22.74 9.33967L20.6603 7.25996C20.3315 6.91335 19.7538 6.91335 19.4072 7.25996L17.7718 8.8864L21.1047 12.2193L22.74 10.5928ZM7 19.6671V23H10.3329L20.1626 13.1614L16.8297 9.8285L7 19.6671Z"
      fill="#70B7E1"
    />
  </SvgIcon>
);

const AppTable = ({
  cells,
  data,
  order,
  orderBy,
  selected,
  page,
  rowsPerPage,
  handleSelectAllClick,
  handleRequestSort,
  isSelected,
  handleSelect,
  handleChangePage,
  handleChangeRowsPerPage,
  pageCount,
  handleEditLicense,
  handleSearch,
  noAccess,
}) => {
  const {
    root,
    paper,
    tableStyle,
    selectedRow,
    headerLabel,
    icon,
    headerTable,
    headerColumn,
    actionBtn,
    search,
    searchIcon,
    borderStart,
    errorBox,
    errContent,
    noAccessMsg,
    active,
    ends,
    expired,
    tableColumn,
    expiredLicence,
    endingLicence,
    activeLicence,
    regionAndPlotLink,
    regionAndPlot,
    deviceId,
    productNameStyle,
    accountName
  } = useStyles();
  const { t } = useTranslation();
  // const emptyRows = rowsPerPage - data.length;
  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };
  const handleOpenGoogleMaps = (device) => {
    window.open(
      `https://maps.google.com/?q=${device.latitude},${device.longitude}`,
      "_blank"
    );
  };
  const handleChangeAccount = (licence) => {
    history.push(
      `/account-login?organizationId=${licence.organization.id}&name=${
        licence.organization.name
      }&parentId=${licence.organization.parentOrganization}&redirectUrl=${"/my-products"}`
    );
  };
  return (
    <div className={root}>
      <Paper className={paper}>
        <Box className={headerTable}>
          <OutlinedInput
            id="search"
            placeholder={t("search")}
            className={search}
            onChange={handleSearch}
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon className={searchIcon} edge="end" />
              </InputAdornment>
            }
          />
        </Box>
        <TableContainer>
          <Table className={tableStyle} size="medium">
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    indeterminate={
                      selected.length > 0 &&
                      selected.length <
                        data.filter((n) => n.status !== "auto").length
                    }
                    checked={
                      data.length > 0 &&
                      selected.length ===
                        data.filter((n) => n.status !== "auto").length
                    }
                    onChange={handleSelectAllClick}
                    color="primary"
                  />
                </TableCell>
                {cells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    align={headCell.numeric ? "right" : "left"}
                    style={{
                      minWidth: headCell.minWidth,
                      maxWidth: headCell.maxWidth,
                      width: headCell.width,
                    }}
                    className={clsx({
                      [borderStart]: headCell.borderStart,
                    })}
                    padding={headCell.disablePadding ? "none" : "default"}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <Box className={headerColumn}>
                      {headCell.sortable ? (
                        <TableSortLabel
                          active={orderBy === headCell.id}
                          direction={orderBy === headCell.id ? order : "asc"}
                          onClick={createSortHandler(headCell.id)}
                        >
                          <Typography className={headerLabel} variant="body2">
                            {t(headCell.label)}
                          </Typography>
                        </TableSortLabel>
                      ) : (
                        <Typography className={headerLabel} variant="body2">
                          {t(headCell.label)}
                        </Typography>
                      )}
                    </Box>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {!noAccess &&
                data.map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={Math.random()}
                      selected={isItemSelected}
                      classes={{
                        selected: selectedRow,
                      }}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          onClick={(event) => handleSelect(event, row)}
                          checked={isItemSelected}
                          inputProps={{ "aria-labelledby": labelId }}
                          color="primary"
                          disabled={row.status === "auto"}
                        />
                      </TableCell>
                      <TableCell align="left">
                        <Box
                          className={clsx({
                            [active]:
                              row.status === "active" || row.status === "auto",
                            [expired]: row.status === "expired",
                            [ends]:
                              row.status === "ending" ||
                              row.status === "disabled",
                          })}
                        >
                          <img
                            alt="status"
                            src={
                              row.status === "active" || row.status === "auto"
                                ? ActiveLicence
                                : row.status === "expired"
                                ? ExpiredLicence
                                : (row.status === "ending" ||
                                    row.status === "disabled") &&
                                  EndsLicence
                            }
                          />
                          <Typography>{t(row.status)}</Typography>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Typography
                          className={clsx(tableColumn, productNameStyle)}
                        >
                          {row.product.name}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography className={tableColumn}>
                          {row.device ? row.device.name : "-"}
                        </Typography>
                        <Typography className={deviceId}>
                          {row.device ? row.device.externalName : "-"}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography className={clsx(tableColumn, accountName)} onClick={() => handleChangeAccount(row)}>
                          {row.organization.name}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          className={clsx(tableColumn, {
                            [expiredLicence]: row.status === "expired",
                            [endingLicence]: row.status === "ending",
                            [activeLicence]:
                              row.status === "active" || row.status === "auto",
                          })}
                        >
                          {moment(row.endDate).format("MMM D, YYYY")}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        {row.device ? (
                          <Typography
                            className={regionAndPlotLink}
                            onClick={() => handleOpenGoogleMaps(row.device)}
                          >
                            {row.device.field.name} - {row.device.plot.name}
                          </Typography>
                        ) : row.plot ? (
                          <Typography
                            className={regionAndPlot}
                          >
                            {row.plot.field.name} - {row.plot.name}
                          </Typography>
                        ) : (
                          <Typography>-</Typography>
                        )}
                      </TableCell>
                      <TableCell className={borderStart} align="center">
                        {!row.isDisabled && (
                          <Tooltip title={t("edit")}>
                            <Button
                              onClick={() => handleEditLicense(row.id)}
                              className={actionBtn}
                            >
                              <EditIcon className={icon} />
                            </Button>
                          </Tooltip>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              {/* {data.length > 0 && emptyRows > 0 && !noAccess && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={9} />
                </TableRow>
              )} */}
            </TableBody>
          </Table>
        </TableContainer>
        {data.length > 0 && !noAccess && (
          <TablePagination
            labelDisplayedRows={({ from, to, count }) => {
              return t("itemCountOnPageInTable", { from, to, count });
            }}
            labelRowsPerPage={t("labelRowsPerPage")}
            nextIconButtonText={t("nextIconButtonText")}
            backIconButtonText={t("backIconButtonText")}
            rowsPerPageOptions={[3, 5, 10, 25, 50]}
            component="div"
            count={pageCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        )}
        {noAccess && (
          <Box className={errorBox}>
            <Box className={errContent}>
              <Typography className={noAccessMsg}>
                {t("noAccessMsg")}
              </Typography>
            </Box>
          </Box>
        )}
      </Paper>
    </div>
  );
};

export default AppTable;
