import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import {
  getWaterPotentialRequest,
  waterPotentialSelector,
  errorMsgSelector,
} from "redux/waterPotential";
import {
  getIrrigationHistoryRequest,
  irrigationHistorySelector,
} from "redux/irrigationHistory";
import { getGraphEventsRequest, graphEventsSelector } from "redux/graphEvents";
import { connect } from "react-redux";
import { AppLoader } from "components/commons";
import TwoRootense from "./2Rootense";
import ThreeRootenseTwoPipePressure from "./3Rootense2PipePressure";
import ThreeRootenseOnePipePressure from "./3Rootense1PipePressure";
import ThreeRootense from "./3Rootense";
import TwoRootenseTwoPipePressure from "./2Rootense2PipePressure";
import TwoRootenseOnePipePressure from "./2Rootense1PipePressure";
import { Box, Menu, MenuItem } from "@material-ui/core";
import ExportIcon from "media/exportIcon.svg";
import useStyles from "./styles";
import { useTranslation } from "react-i18next";
import moment from "moment";
import history from "history.js";
import { settingsSelector } from "redux/dashboardSettings";
import { saveDashboardSettingsRequest } from "redux/saveDashboardSettings";
import DeleteIcon from "media/deleteIcon.svg";

const SENSOR_TO_DEPTH = {
  "rt1-cent": "depth1",
  "rt2-cent": "depth2",
  "rt3-cent": "depth3",
};

const WPChart = ({
  deviceId,
  plotId,
  getWPdata,
  waterPotential,
  daterange,
  getGraphEvents,
  graphEvents,
  irrigationHistory,
  getIrrigationHistory,
  depths,
  handleCloseMenu,
  anchorEl,
  device,
  pairedDate,
  errorMsg,
  saveDashboardSettings,
  dashboardSettings,
  withScroll = false,
  isForInsight = false,
}) => {
  const { t } = useTranslation();
  const [deviceType, setDeviceType] = useState();
  const [wpData, setWpData] = useState();
  const [eventsData, setEventsData] = useState();
  const { exportText, linkToRenew, renewLicenseMsg } = useStyles();

  useEffect(() => {
    getWPdata({
      deviceId: deviceId,
      from: daterange[0].valueOf(),
      to: daterange[1].valueOf(),
      isForInsight: isForInsight,
    });
    getGraphEvents({
      deviceId: deviceId,
      from: daterange[0].valueOf(),
      to: daterange[1].valueOf(),
      isForInsight: isForInsight,
    });
  }, [deviceId, daterange]);

  useEffect(() => {
    if (
      waterPotential &&
      waterPotential[isForInsight ? `${deviceId}-insight` : deviceId]
    ) {
      const deviceData =
        waterPotential[isForInsight ? `${deviceId}-insight` : deviceId];
      setWpData(deviceData);
    }
  }, [waterPotential]);
  useEffect(() => {
    if (
      graphEvents &&
      graphEvents[isForInsight ? `${deviceId}-insight` : deviceId]
    ) {
      const graphEventsData =
        graphEvents[isForInsight ? `${deviceId}-insight` : deviceId];
      setEventsData(graphEventsData);
    }
  }, [graphEvents]);
  useEffect(() => {
    if (plotId) {
      getIrrigationHistory(
        plotId,
        daterange[0].valueOf(),
        daterange[1].valueOf()
      );
    }
  }, [plotId, daterange]);
  useEffect(() => {
    if (wpData) {
      const deviceType = getDeviceTypeBySensors(wpData.schema);
      setDeviceType(deviceType);
    }
  }, [wpData]);
  const getDeviceTypeBySensors = (schema) => {
    const sensors = schema.reduce((acc, item) => {
      if (item.name !== "Time") acc = [...acc, item.name];
      return acc;
    }, []);
    if (
      sensors.includes("rt3-cent") &&
      sensors.includes("port5") &&
      sensors.includes("port6")
    ) {
      return "threeRootenseTwoPipePressure";
    } else if (
      sensors.includes("rt3-cent") &&
      (sensors.includes("port5") || sensors.includes("port6"))
    ) {
      return "threeRootenseOnePipePressure";
    } else if (sensors.includes("rt3-cent")) {
      return "threeRootense";
    } else if (sensors.includes("port5") && sensors.includes("port6")) {
      return "twoRootenseTwoPipePressure";
    } else if (sensors.includes("port5") || sensors.includes("port6")) {
      return "twoRootenseOnePipePressure";
    } else {
      return "twoRootense";
    }
  };
  const getDeviceSensors = (schema) => {
    const sensors = schema.reduce((acc, item) => {
      if (item.name !== "Time") acc = [...acc, item.name];
      return acc;
    }, []);
    return sensors;
  };
  const open = Boolean(anchorEl);

  const exportToCsv = () => {
    const data = [
      wpData.schema
        .map((item) =>
          SENSOR_TO_DEPTH[item.name]
            ? `${depths[SENSOR_TO_DEPTH[item.name]]}cm`
            : item.name === "port5"
            ? "Pipe Pressure (Port5)"
            : item.name === "port6"
            ? "Pipe Pressure (Port6)"
            : item.name
        )
        .join(","),
      ...wpData.data.map(([time, ...rest]) =>
        [moment(time).format("DD/MM/YYYY HH:mm"), ...rest].join(",")
      ),
    ];
    const csv = data.join("\r\n");
    let link = document.createElement("a");
    link.setAttribute(
      "href",
      "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURIComponent(csv)
    );
    link.setAttribute("download", `${t("waterPotential")} (${device.name})`);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const deleteWidget = () => {
    const index = dashboardSettings.findIndex(
      (item) => item.key === `waterPotential-${deviceId}`
    );
    dashboardSettings[index].enabled = false;
    saveDashboardSettings({
      plotId: plotId,
      settings: dashboardSettings,
      updateData: true,
    });
  };
  if (
    errorMsg &&
    errorMsg[deviceId] &&
    errorMsg[deviceId] === "DEVICE_DONT_HAVE_ACTIVE_LICENCE"
  ) {
    return (
      <Box className={renewLicenseMsg}>
        {t("licenseExpired")}&nbsp;
        <span
          className={linkToRenew}
          onClick={() => history.push("/my-products")}
        >
          {t("clickToRenewLink")}
        </span>
      </Box>
    );
  } else if (deviceType === "twoRootense") {
    return (
      <Box style={{ height: "100%" }}>
        <TwoRootense
          daterange={daterange}
          depths={depths}
          irrigationHistory={irrigationHistory}
          eventsData={eventsData}
          wpData={wpData}
          isForInsight={isForInsight}
          sensors={getDeviceSensors(wpData.schema)}
          pairedDate={pairedDate}
        />
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleCloseMenu}
          onClick={handleCloseMenu}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <MenuItem
            style={{
              width: "200px",
            }}
            onClick={exportToCsv}
          >
            <img src={ExportIcon} alt="Export" />
            <Box className={exportText}>{t("export")}</Box>
          </MenuItem>
          <MenuItem
            style={{
              width: "200px",
            }}
            onClick={deleteWidget}
          >
            <img src={DeleteIcon} alt="DeleteIcon" />
            <Box className={exportText}>{t("delete")}</Box>
          </MenuItem>
        </Menu>
      </Box>
    );
  } else if (deviceType === "threeRootenseTwoPipePressure") {
    return (
      <Box style={{ height: "100%" }}>
        <ThreeRootenseTwoPipePressure
          daterange={daterange}
          depths={depths}
          irrigationHistory={irrigationHistory}
          eventsData={eventsData}
          wpData={wpData}
          isForInsight={isForInsight}
          sensors={getDeviceSensors(wpData.schema)}
          pairedDate={pairedDate}
        />
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleCloseMenu}
          onClick={handleCloseMenu}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <MenuItem
            style={{
              width: "200px",
            }}
            onClick={exportToCsv}
          >
            <img src={ExportIcon} alt="Export" />
            <Box className={exportText}>{t("export")}</Box>
          </MenuItem>
          <MenuItem
            style={{
              width: "200px",
            }}
            onClick={deleteWidget}
          >
            <img src={DeleteIcon} alt="DeleteIcon" />
            <Box className={exportText}>{t("delete")}</Box>
          </MenuItem>
        </Menu>
      </Box>
    );
  } else if (deviceType === "threeRootenseOnePipePressure") {
    return (
      <Box style={{ height: "100%" }}>
        <ThreeRootenseOnePipePressure
          daterange={daterange}
          depths={depths}
          irrigationHistory={irrigationHistory}
          eventsData={eventsData}
          wpData={wpData}
          isForInsight={isForInsight}
          sensors={getDeviceSensors(wpData.schema)}
          pairedDate={pairedDate}
        />
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleCloseMenu}
          onClick={handleCloseMenu}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <MenuItem
            style={{
              width: "200px",
            }}
            onClick={exportToCsv}
          >
            <img src={ExportIcon} alt="Export" />
            <Box className={exportText}>{t("export")}</Box>
          </MenuItem>
          <MenuItem
            style={{
              width: "200px",
            }}
            onClick={deleteWidget}
          >
            <img src={DeleteIcon} alt="DeleteIcon" />
            <Box className={exportText}>{t("delete")}</Box>
          </MenuItem>
        </Menu>
      </Box>
    );
  } else if (deviceType === "threeRootense") {
    return (
      <Box style={{ height: "100%" }}>
        <ThreeRootense
          daterange={daterange}
          depths={depths}
          irrigationHistory={irrigationHistory}
          eventsData={eventsData}
          wpData={wpData}
          isForInsight={isForInsight}
          sensors={getDeviceSensors(wpData.schema)}
          pairedDate={pairedDate}
        />
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleCloseMenu}
          onClick={handleCloseMenu}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <MenuItem
            style={{
              width: "200px",
            }}
            onClick={exportToCsv}
          >
            <img src={ExportIcon} alt="Export" />
            <Box className={exportText}>{t("export")}</Box>
          </MenuItem>
          <MenuItem
            style={{
              width: "200px",
            }}
            onClick={deleteWidget}
          >
            <img src={DeleteIcon} alt="DeleteIcon" />
            <Box className={exportText}>{t("delete")}</Box>
          </MenuItem>
        </Menu>
      </Box>
    );
  } else if (deviceType === "twoRootenseTwoPipePressure") {
    return (
      <Box style={{ height: "100%" }}>
        <TwoRootenseTwoPipePressure
          daterange={daterange}
          depths={depths}
          irrigationHistory={irrigationHistory}
          eventsData={eventsData}
          wpData={wpData}
          isForInsight={isForInsight}
          sensors={getDeviceSensors(wpData.schema)}
          pairedDate={pairedDate}
        />
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleCloseMenu}
          onClick={handleCloseMenu}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <MenuItem
            style={{
              width: "200px",
            }}
            onClick={exportToCsv}
          >
            <img src={ExportIcon} alt="Export" />
            <Box className={exportText}>{t("export")}</Box>
          </MenuItem>
          <MenuItem
            style={{
              width: "200px",
            }}
            onClick={deleteWidget}
          >
            <img src={DeleteIcon} alt="DeleteIcon" />
            <Box className={exportText}>{t("delete")}</Box>
          </MenuItem>
        </Menu>
      </Box>
    );
  } else if (deviceType === "twoRootenseOnePipePressure") {
    return (
      <Box style={{ height: "100%" }}>
        <TwoRootenseOnePipePressure
          daterange={daterange}
          depths={depths}
          irrigationHistory={irrigationHistory}
          eventsData={eventsData}
          wpData={wpData}
          isForInsight={isForInsight}
          sensors={getDeviceSensors(wpData.schema)}
          pairedDate={pairedDate}
        />
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleCloseMenu}
          onClick={handleCloseMenu}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <MenuItem
            style={{
              width: "200px",
            }}
            onClick={exportToCsv}
          >
            <img src={ExportIcon} alt="Export" />
            <Box className={exportText}>{t("export")}</Box>
          </MenuItem>
          <MenuItem
            style={{
              width: "200px",
            }}
            onClick={deleteWidget}
          >
            <img src={DeleteIcon} alt="DeleteIcon" />
            <Box className={exportText}>{t("delete")}</Box>
          </MenuItem>
        </Menu>
      </Box>
    );
  } else {
    return <AppLoader />;
  }
};

const mapStateToProps = (state) => ({
  waterPotential: waterPotentialSelector(state),
  graphEvents: graphEventsSelector(state),
  irrigationHistory: irrigationHistorySelector(state),
  errorMsg: errorMsgSelector(state),
  dashboardSettings: settingsSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  getWPdata: bindActionCreators(getWaterPotentialRequest, dispatch),
  getGraphEvents: bindActionCreators(getGraphEventsRequest, dispatch),
  getIrrigationHistory: bindActionCreators(
    getIrrigationHistoryRequest,
    dispatch
  ),
  saveDashboardSettings: bindActionCreators(
    saveDashboardSettingsRequest,
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(WPChart);
