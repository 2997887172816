import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { useGA4React } from "ga-4-react";
import Languages from "constants/languages";
import moment from "moment";
import { useTranslation } from "react-i18next";

const LoginRoute = ({
  layout: Layout,
  component: Component,
  path,
  location,
  exact = false,
}) => {
  const ga = useGA4React();
  const { i18n } = useTranslation();

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const paramLang = urlParams.get("language");
    if (paramLang) {
      const lang = Languages.find((lang) => lang.lang === paramLang);
      if (lang) {
        i18n.changeLanguage(paramLang);
        const body = document.querySelector("html");
        body.dir = lang.direction;
        body.lang = paramLang;
        moment.locale(paramLang);
        localStorage.setItem("language", paramLang);
      }
    }
    if (location.pathname !== "/account-login" && ga) {
      ga.pageview(
        location.pathname,
        location.pathname,
        location.pathname.slice(1).toUpperCase()
      );
    }
  }, [location]);
  const isAuthed = !!localStorage.getItem("token");
  return (
    <Route
      exact={exact}
      path={path}
      render={(props) =>
        !isAuthed ? (
          <Layout>
            <Component {...props} />
          </Layout>
        ) : (
          <Redirect to="/accounts" />
        )
      }
    />
  );
};

export default LoginRoute;
