import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Button } from "@material-ui/core";
import useStyles from "./../styles";
import EditIcon from "@material-ui/icons/Edit";
import DoneIcon from "@material-ui/icons/Done";
import MapUtils from "utils/mapUtils";
import MapStyles from "constants/sampleMapStyles";

import MapMarker from "media/mapMarker.svg";
import DrawPolygon from "media/drawPolygon.svg";
const MapTab = ({
  coordinates,
  plotName,
  devices,
  handleSavePlotSetttings,
}) => {
  const mapContainer = useRef(null);
  const {
    bottomContainer,
    plotNameStyle,
    plotAreaStyle,
    plotInfoContainer,
    editBtn,
    cancelBtn,
    saveBtn,
  } = useStyles();
  const [plotArea, setPlotArea] = useState();
  const [editMode, toogleEditMode] = useState(false);
  const [plotPolygon, setPlotPolygon] = useState(false);
  const [drawingManager, setDrawingManager] = useState(false);
  const [map, setMap] = useState(false);
  const [newPlotCoodinates, setNewPlotCoodinates] = useState(false);
  const { google } = window;
  useEffect(() => {
    let map = null;
    setPlotArea(0);
    if (coordinates && coordinates.length) {
      map = new google.maps.Map(mapContainer.current, {
        center: MapUtils.getMapCenter(coordinates),
        mapTypeControl: false,
        zoomControl: false,
        fullscreenControl: false,
        streetViewControl: false,
        zoom: 16,
        mapTypeId: "hybrid",
        styles: MapStyles
      });
      devices.forEach((device) => {
        new google.maps.Marker({
          position: {
            lat: device.latitude,
            lng: device.longitude,
          },
          icon: MapMarker,
          map: map,
        });
      });
      let plotPolygon = new google.maps.Polygon({
        paths: coordinates,
        strokeColor: "#FFFFFF",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#A8D8F4",
        fillOpacity: 0.5,
        clickable: false,
        editable: false,
        draggable: false,
      });
      plotPolygon.setMap(map);
      google.maps.event.addListener(plotPolygon.getPath(), "set_at", () => {
        setPlotArea(MapUtils.getPolygonArea(plotPolygon));
        setNewPlotCoodinates(MapUtils.getPolygonCoordinates(plotPolygon));
      });
      google.maps.event.addListener(plotPolygon.getPath(), "insert_at", () => {
        setPlotArea(MapUtils.getPolygonArea(plotPolygon));
        setNewPlotCoodinates(MapUtils.getPolygonCoordinates(plotPolygon));
      });
      setPlotPolygon(plotPolygon);
      setPlotArea(MapUtils.getPolygonArea(plotPolygon));
    } else if (devices) {
      map = new google.maps.Map(mapContainer.current, {
        center: { lat: devices[0].latitude, lng: devices[0].longitude },
        mapTypeControl: false,
        zoomControl: false,
        fullscreenControl: false,
        streetViewControl: false,
        zoom: 16,
        mapTypeId: "hybrid",
        styles: MapStyles
      });
      devices.forEach((device) => {
        new google.maps.Marker({
          position: {
            lat: device.latitude,
            lng: device.longitude,
          },
          icon: MapMarker,
          map: map,
        });
      });
      const drawingManager = new google.maps.drawing.DrawingManager({
        drawingMode: google.maps.drawing.OverlayType.POLYGON,
        drawingControl: true,
        drawingControlOptions: {
          position: google.maps.ControlPosition.TOP_CENTER,
          drawingModes: [google.maps.drawing.OverlayType.POLYGON],
        },
        polygonOptions: {
          strokeColor: "#FFFFFF",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "#A8D8F4",
          fillOpacity: 0.5,
          clickable: true,
          editable: true,
          draggable: true,
          zIndex: 1,
        },
      });
      drawingManager.addListener("polygoncomplete", (polygon) => {
        setNewPlotCoodinates(MapUtils.getPolygonCoordinates(polygon));
        setPlotPolygon(polygon);
        setPlotArea(MapUtils.getPolygonArea(polygon));

        google.maps.event.addListener(polygon.getPath(), "set_at", () => {
          setPlotArea(MapUtils.getPolygonArea(polygon));
          setNewPlotCoodinates(MapUtils.getPolygonCoordinates(polygon));
        });
        google.maps.event.addListener(polygon.getPath(), "insert_at", () => {
          setPlotArea(MapUtils.getPolygonArea(polygon));
          setNewPlotCoodinates(MapUtils.getPolygonCoordinates(polygon));
        });
        drawingManager.setMap(null);
        toogleEditMode(true);
      });
      setDrawingManager(drawingManager);
      setMap(map);
    }
  }, [coordinates, devices]);

  const { t } = useTranslation();

  const handlePressEditBtn = () => {
    plotPolygon.setOptions({
      clickable: true,
      editable: true,
      draggable: true,
    });
    toogleEditMode(true);
  };

  const handleCancelEdit = () => {
    if (plotPolygon) {
      plotPolygon.setOptions({
        clickable: false,
        editable: false,
        draggable: false,
      });
      plotPolygon.setPaths(coordinates || []);
    }
    if (coordinates && coordinates.length) {
      setPlotArea(MapUtils.getPolygonArea(plotPolygon));
    } else {
      setPlotArea(0);
    }
    toogleEditMode(false);
  };

  const handlePressDrawBtn = () => {
    drawingManager.setMap(map);
    toogleEditMode(true);
  };

  const handlePressSave = () => {
    handleSavePlotSetttings({
      coordinates: newPlotCoodinates,
    });
    toogleEditMode(false);
  };

  return (
    <Box style={{ height: "calc(100vh - 230px)", position: "relative" }}>
      <Box style={{ height: "100%" }} ref={mapContainer} />
      <Box className={bottomContainer}>
        <Box className={plotInfoContainer}>
          <Box className={plotNameStyle}>{plotName}</Box>
          <Box className={plotAreaStyle}>{plotArea || 0} {t("hectare")}</Box>
        </Box>
        {!editMode && coordinates && coordinates.length && (
          <Button
            variant="contained"
            className={editBtn}
            onClick={handlePressEditBtn}
            startIcon={<EditIcon />}
          >
            {t("changeBoundaries")}
          </Button>
        )}
        {(!coordinates || (coordinates && !coordinates.length)) && !editMode && (
          <Button
            variant="contained"
            className={editBtn}
            onClick={handlePressDrawBtn}
            startIcon={<img src={DrawPolygon} alt="DrawPolygon" />}
          >
            {t("drawPolygon")}
          </Button>
        )}
        {editMode && (
          <Button
            onClick={handleCancelEdit}
            variant="contained"
            className={cancelBtn}
          >
            {t("cancel")}
          </Button>
        )}
        {editMode && (
          <Button
            onClick={handlePressSave}
            variant="contained"
            className={saveBtn}
            startIcon={<DoneIcon />}
          >
            {t("save")}
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default MapTab;
