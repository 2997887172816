import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Box, IconButton, Menu, MenuItem } from "@material-ui/core";
import {
  getIrrigationHistoryRequest,
  irrigationHistorySelector,
  errMsgSelector
} from "redux/irrigationHistory";
import { RainAndIrrigationGraph } from "components/commons";
import useStyles from "./styles";
import ExportIcon from "media/exportIcon.svg";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import clsx from "clsx";
import { settingsSelector } from "redux/dashboardSettings";
import { saveDashboardSettingsRequest } from "redux/saveDashboardSettings";
import DeleteIcon from "media/deleteIcon.svg";
import RainIcon from "media/rainIcon.svg";

const RainHistory = ({
  plotId,
  daterange,
  getIrrigationHistory,
  irrigationHistory,
  saveDashboardSettings,
  dashboardSettings,
  errMsg
}) => {
  const { t } = useTranslation();
  const { title, container, header, exportText } = useStyles();
  useEffect(() => {
    if (plotId) {
      getIrrigationHistory(
        plotId,
        moment(daterange[0]).valueOf(),
        moment(daterange[1]).valueOf()
      );
    }
  }, [plotId, daterange]);
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const exportToCsv = () => {
    const header = [t("time"), t("rain"), t("irrigation")];
    let dataForCsv = irrigationHistory.weather.map((item) => [
      moment(item.time).format("DD/MM/YYYY HH:mm"),
      item.precipitation,
      null,
    ]);
    if (irrigationHistory.irrigation) {
      dataForCsv = [
        ...dataForCsv,
        ...irrigationHistory.irrigation.values.map((item) => [
          moment(item[0]).format("DD/MM/YYYY HH:mm"),
          undefined,
          item[1],
        ]),
      ];
    }
    const data = [
      header.join(","),
      ...dataForCsv.sort((a, b) => b[0] - a[0]).map((item) => item.join(",")),
    ];
    const csv = data.join("\r\n");
    let link = document.createElement("a");
    link.setAttribute(
      "href",
      "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURIComponent(csv)
    );
    link.setAttribute("download", `${t("rain")}`);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const deleteWidget = () => {
    const index = dashboardSettings.findIndex(
      (item) => item.key === "rainWidget"
    );
    dashboardSettings[index].enabled = false;
    saveDashboardSettings({
      plotId: plotId,
      settings: dashboardSettings,
      updateData: true,
    });
  };
  const open = Boolean(anchorEl);
  return (
    <Box className={container}>
      <Box className={clsx("widgetHeader", header)}>
        <Box style={{ display: "flex", alignItems: "center" }}>
          <img
            width="32px"
            height="32px"
            style={{ marginInlineEnd: "10px" }}
            src={RainIcon}
            alt="icon"
          />
          <Box className={title}>{t("rain")}</Box>
        </Box>
        <IconButton onClick={handleClick} size="small">
          <MoreVertIcon />
        </IconButton>
      </Box>
      <div className="no-draggable" style={{ height: "80%" }}>
        <RainAndIrrigationGraph
          daterange={daterange}
          errorMsg={errMsg}
          irrigationHistory={irrigationHistory}
        />
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <MenuItem
            style={{
              width: "200px",
            }}
            onClick={exportToCsv}
          >
            <img src={ExportIcon} alt="Export" />
            <Box className={exportText}>{t("export")}</Box>
          </MenuItem>
          <MenuItem
            style={{
              width: "200px",
            }}
            onClick={deleteWidget}
          >
            <img src={DeleteIcon} alt="DeleteIcon" />
            <Box className={exportText}>{t("delete")}</Box>
          </MenuItem>
        </Menu>
      </div>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  irrigationHistory: irrigationHistorySelector(state),
  dashboardSettings: settingsSelector(state),
  errMsg: errMsgSelector(state)
});

const mapDispatchToProps = (dispatch) => ({
  getIrrigationHistory: bindActionCreators(
    getIrrigationHistoryRequest,
    dispatch
  ),
  saveDashboardSettings: bindActionCreators(
    saveDashboardSettingsRequest,
    dispatch
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RainHistory);
