import { put, takeEvery, all, call, select } from "redux-saga/effects";
import { getAccounts, getAccountsByUser } from "api";
import { userIdSelector } from "./me";
/* *
 * Constants
 * */
export const moduleName = "userAccounts";
const prefix = `${process.env.REACT_APP_NAME}/${moduleName}`;

export const INIT = `${prefix}/INIT`;
export const SUCCESS = `${prefix}/SUCCESS`;
export const REJECT = `${prefix}/REJECT`;

/* *
 * Reducer
 * */
const initialState = {
  accounts: [],
  loading: false,
  error: "",
  success: false,
};

export default function reducer(
  state = initialState,
  { type, payload, meta, ...rest }
) {
  switch (type) {
    case INIT:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        accounts: payload,
        error: ""
      };
    case REJECT:
      return {
        ...state,
        loading: false,
        success: false,
        error: payload.error,
      };
    default:
      return state;
  }
}

/* *
 * Selectors
 * */
export const moduleSelector = (state) => state[moduleName];

export const userAccountsSelector = (state) => moduleSelector(state).accounts;

export const isLoadingSelector = (state) => moduleSelector(state).loading;

export const isSuccessSelector = (state) => moduleSelector(state).success;

export const errorSelector = (state) => moduleSelector(state).error;

/* *
 * Action Creators
 * */
export const getUserAccountsRequest = (
  search,
  pagination,
  sort,
  parentFilter
) => ({
  type: INIT,
  search,
  pagination,
  sort,
  parentFilter,
});

export const successAction = (payload) => ({
  type: SUCCESS,
  payload,
});

export const errorAction = (error) => ({
  type: REJECT,
  payload: { error },
});

/* *
 * Sagas
 * */
export function* getUserAccountsSaga({
  search,
  pagination,
  sort,
  parentFilter,
}) {
  try {
    const userId = yield select(userIdSelector);
    let accountId = null;
    let res = null;
    const accountFromStorage = localStorage.getItem("accountData");
    if(!accountFromStorage) {
      res = yield call(
        getAccountsByUser,
        userId,
        search,
        pagination,
        sort,
        parentFilter
      )
    } else {
      accountId = JSON.parse(accountFromStorage).id;
      res = yield call(
        getAccounts,
        userId,
        accountId,
        search,
        pagination,
        sort,
        parentFilter
      );
    }
    yield put(successAction(res));
  } catch (error) {
    yield put(errorAction(error.message));
  }
}

export function* saga() {
  yield all([takeEvery(INIT, getUserAccountsSaga)]);
}
