import { makeStyles } from "@material-ui/core";



export default makeStyles((theme) => ({
  root: {
    display: "flex",
    position: "relative",
    height: "100vh",
  },
  appBar: {
    position: "absolute",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: ({ whiteHeader }) =>
      whiteHeader ? "white" : "transparent",
    boxShadow: "none",
    marginInlineStart: ({ drawerWidth, drawerWidthClose, open }) =>
      `${open ? drawerWidth : drawerWidthClose}px`,
    width: ({ drawerWidth, drawerWidthClose, open }) =>
      `calc(100% - ${open ? drawerWidth : drawerWidthClose}px)`,
  },
  appBarMobile: {
    backgroundColor: "#f4f4f7",
    boxShadow: "none",
  },
  drawer: {
    // width: ({ drawerWidth }) => drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    height: ({ showAlertBar }) =>
      showAlertBar ? "calc(100vh - 40px)" : "100vh",
    position: "relative",
    "& > div": {
      position: "absolute",
    },
  },
  drawerOpen: {
    width: ({ drawerWidth }) => drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    backgroundColor: theme.palette.background.sidebar,
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: "88px",
    backgroundColor: theme.palette.background.sidebar,
  },
  drawerCloseMobile: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: "0px",
    backgroundColor: theme.palette.background.sidebar,
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    minHeight:
      window.outerWidth > 414
        ? theme.headerHeight
        : theme.headerContainerMobile,
    height:
      window.outerWidth > 414
        ? theme.headerHeight
        : theme.headerContainerMobile,
  },
  content: {
    width: ({ drawerWidth, drawerWidthClose, open }) =>
      `calc(100% - ${open ? drawerWidth : drawerWidthClose}px)`,
    flex: 1,
    display: "flex",
    flexDirection: "column",
    position: "relative",
    paddingBottom: ({ displayHelpFooter }) =>
      displayHelpFooter
        ? "128px"
        : window.location.pathname.includes("dashboard")
        ? "0px"
        : "80px",

    overflow: "auto",
  },
  contentMobile: {
    width: ({ drawerWidth, drawerWidthClose, open }) =>
      `calc(100% - ${open ? drawerWidth : 0}px)`,
    flex: 1,
    display: "flex",
    flexDirection: "column",
    position: "relative",
    height: "100vh",
    overflow: "auto",
  },
  footer: {
    width: ({ drawerWidth, drawerWidthClose, open }) =>
      `calc(100% - ${open ? drawerWidth : drawerWidthClose}px)`,
  },
  intercomBtn: {
    backgroundColor: theme.palette.primary.main,
    width: "60px",
    height: "60px",
    position: "fixed",
    right: ({isRtl}) => !isRtl ? "30px" : "auto",
    left: ({isRtl}) => isRtl ? "30px" : "auto",
    bottom: "48px",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      opacity: 0.8,
    },
  },
}));
