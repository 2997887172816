import { put, takeEvery, all, call } from "redux-saga/effects";
import { getWaterPotentialData } from "api";

import { normalizeData } from "../utils/heatMap";
// import moment from "moment";

/* *
 * Constants
 * */
export const moduleName = "heatMap";
const prefix = `${process.env.REACT_APP_NAME}/${moduleName}`;

export const INIT = `${prefix}/INIT`;
export const SUCCESS = `${prefix}/SUCCESS`;
export const REJECT = `${prefix}/REJECT`;
export const CLEAR = `${prefix}/CLEAR`;

/* *
 * Reducer
 * */
const initialState = {
  loading: false,
  error: {},
  depths: {},
  threshold: null,
  errorMsg: null,
};

export default function reducer(
  state = initialState,
  { type, payload, meta, ...rest }
) {
  switch (type) {
    case INIT:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        depths: {
          ...state.depths,
          [payload.isForInsight
            ? `${payload.deviceId}-insight`
            : payload.deviceId]: payload.data,
        },
        threshold: {
          ...state.threshold,
          [payload.deviceId]: payload.threshold,
        },
      };
    case REJECT:
      return {
        ...state,
        loading: false,
        success: false,
        error: true,
        errorMsg: {
          ...state.errorMsg,
          [payload.error.deviceId]: payload.error.msg,
        },
      };
    case CLEAR:
      return initialState;
    default:
      return state;
  }
}

/* *
 * Selectors
 * */
export const moduleSelector = (state) => state[moduleName];

export const dataSelector = (state) => moduleSelector(state).depths;
export const thresholdSelector = (state) => moduleSelector(state).threshold;
export const heatMapLoadingSelector = (state) => moduleSelector(state).loading;
export const errorMsgSelector = (state) => moduleSelector(state).errorMsg;

/* *
 * Action Creators
 * */
export const getDepthsDataRequest = ({
  deviceId,
  from,
  to,
  isForInsight = false,
}) => ({
  type: INIT,
  deviceId,
  from,
  to,
  isForInsight,
});

export const successAction = (payload) => ({
  type: SUCCESS,
  payload,
});

export const errorAction = (error) => ({
  type: REJECT,
  payload: { error },
});

export const clearHeatMapData = () => ({
  type: CLEAR,
});

/* *
 * Sagas
 * */
// const generate = () => {
//   let time = moment().subtract(1, 'month').valueOf();
//   console.log(1)
//   let result = new Array(2000).fill('0').reduce((acc, i) => {
//     acc = [...acc, [time, -Math.floor(Math.random() * 20), -Math.floor(Math.random() * 10), -Math.floor(Math.random() * 5)]]
//     time -= 100000;
//     return acc;
//   }, [])
//   return result;
// }

export function* formatDataSaga({ deviceId, from, to, isForInsight }) {
  try {
    const accountFromStorage = localStorage.getItem("accountData");
    const { data, lastValues, schema } = yield call(
      getWaterPotentialData,
      JSON.parse(accountFromStorage).id,
      deviceId,
      from,
      to
    );
    const isRt3Sensor = schema.some((sensor) => sensor.name === "rt3-cent");
    const normalizedData = normalizeData(data, isRt3Sensor);
    let start = data[data.length - 1][0];
    let nextStep = data[data.length - 1][0];
    let result = {};
    let interval = +((data[0][0] - data[data.length - 1][0]) / 500).toFixed();
    let depthsData = [];
    for (let i = 0; i < 500; i++) {
      if (i !== 0) {
        start = +start + interval;
      }
      nextStep = +nextStep + interval;
      result[+((start + nextStep) / 2).toFixed()] = normalizedData.filter(
        // eslint-disable-next-line
        (item) => item[0] >= start && item[0] < nextStep
      );
    }
    let alignedData = Object.keys(result).map((timeKey) => {
      if (!result[timeKey].length) {
        return [+timeKey, null, null, null];
      }
      return [
        +timeKey,
        result[timeKey].reduce(
          (min, p) => (!min || p[1] < min ? p[1] : min),
          0
        ),
        result[timeKey].reduce(
          (min, p) => (!min || p[2] < min ? p[2] : min),
          0
        ),
        result[timeKey].reduce(
          (min, p) => (!min || p[3] < min ? p[3] : min),
          0
        ),
      ];
    });
    if (isRt3Sensor) {
      depthsData = normalizeData(alignedData, isRt3Sensor).map(
        ([timestamp, rt1, rt2, rt3], i) => ({
          timestamp: timestamp,
          rt1Value: rt1,
          rt2Value: rt2,
          rt3Value: rt3,
          x: i + 1,
          index: i,
        })
      );
    } else {
      depthsData = normalizeData(alignedData, isRt3Sensor).map(
        ([timestamp, rt1, rt2], i) => ({
          timestamp: timestamp,
          rt1Value: rt1,
          rt2Value: rt2,
          x: i + 1,
          index: i,
        })
      );
    }
    yield put(
      successAction({
        deviceId,
        data: depthsData,
        threshold: lastValues.threshold,
        isForInsight: isForInsight,
      })
    );
  } catch (error) {
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.error
    ) {
      yield put(errorAction({ deviceId, msg: error.response.data.error }));
    } else {
      yield put(errorAction({ deviceId, msg: "" }));
    }
  }
}

export function* saga() {
  yield all([takeEvery(INIT, formatDataSaga)]);
}
