import {
  Switch,
  withStyles,
} from "@material-ui/core";

const AppSwitch = withStyles((theme) => ({
  root: {
    width: 50,
    height: 28,
    padding: 0,
    display: "inline-flex",
    verticalAlign: "initial"
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    "&$checked": {
      transform: "translateX(22px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 24,
    height: 24,
    boxShadow: "none",
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    height: "26px",
    borderRadius: 28 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);

export default AppSwitch;