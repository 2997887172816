import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, IconButton } from "@material-ui/core";
import clsx from "clsx";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import WpIcon from "media/wpIcon.svg";

import { WPChart } from "components/commons";
import useStyles from "./styles";

const DeviceWp = ({ deviceId, daterange, device, plotId }) => {
  const { t } = useTranslation();
  const { title, container, subtitle, header, deviceInfo } = useStyles();
  const generateDepth = (device) => {
    const rootenseSensors = device.sensors.filter(
      (sensor) =>
        sensor && sensor.sensorType && sensor.sensorType.type === "rootense"
    );
    const depths = {};
    rootenseSensors.forEach((sensor) => {
      if (sensor) {
        depths[`depth${sensor.port}`] = sensor.settings.depth;
      }
    });
    return depths;
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  return (
    <Box className={container}>
      <Box className={clsx("widgetHeader", header)}>
        <Box className={deviceInfo}>
          <img
            width="32px"
            height="32px"
            src={WpIcon}
            alt="icon"
          />
          <Box padding="0 15px">
            <Box className={title}>
              {device.name} ({device.externalName || "-"})
            </Box>
            <Box className={subtitle}>{t("waterPotential")}</Box>
          </Box>
        </Box>
        <IconButton onClick={handleClick} size="small">
          <MoreVertIcon />
        </IconButton>
      </Box>
      <div className="no-draggable" style={{ height: "75%" }}>
        <WPChart
          plotId={plotId}
          deviceId={deviceId}
          daterange={daterange}
          depths={generateDepth(device)}
          device={device}
          handleCloseMenu={handleClose}
          anchorEl={anchorEl}
          pairedDate={device.pairedDate}
        />
      </div>
    </Box>
  );
};

export default DeviceWp;
