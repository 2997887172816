import React, { useEffect, useState } from "react";
import ReactEcharts from "echarts-for-react";
import { AppLoader } from "components/commons";
import moment from "moment";
import { useTranslation } from "react-i18next";
import history from "history.js"
import { Box } from "@material-ui/core";

const UNITS_BY_SERIES_INDEX = {
  0: "bar",
  1: "bar",
};

const SoilTempGraph = ({
  pipePressure,
  depths,
  deviceId,
  daterange,
  errorMsg,
  withScroll = false,
}) => {
  const [chartData, setData] = useState();
  const { t } = useTranslation();

  useEffect(() => {
    if (pipePressure && pipePressure[deviceId]) {
      setData(pipePressure[deviceId].data);
    }
  }, [pipePressure]);

  if (
    errorMsg &&
    errorMsg[deviceId] &&
    errorMsg[deviceId] === "DEVICE_DONT_HAVE_ACTIVE_LICENCE"
  ) {
    return (
      <Box
        style={{
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          color: "#828B93",
          fontSize: "16px",
          fontWeight: "500",
          marginTop: "-28px",
        }}
      >
        {t("licenseExpired")}&nbsp;
        <span
          style={{
            textDecoration: "underline",
            cursor: "pointer",
            "&:hover": {
              opacity: "0.7",
            },
          }}
          onClick={() => history.push("/my-products")}
        >
          {t("clickToRenewLink")}
        </span>
      </Box>
    );
  } else if (chartData) {
    const sensors = pipePressure[deviceId].schema.map((sensor) => sensor.name);
    return (
      <ReactEcharts
        style={{ height: "100%" }}
        option={{
          tooltip: {
            trigger: "axis",
            appendToBody: true,
            formatter: (data) => {
              if (!data.length) {
                data = [data];
              }
              let tooltip = `${moment(data[0].value[data[0].encode.x]).format(
                "DD-MM YYYY HH:mm"
              )} <br/>`;
              data.forEach(
                ({ value, marker, seriesName, seriesIndex, encode: { y } }) => {
                  tooltip += `<div style="display: flex; align-items: center; direction: ltr;">${marker}<div style="padding-inline-end: 5px">${seriesName}:</div><div style="direction: ltr">${
                    value[y] === null ? "-" : value[y]
                  }${UNITS_BY_SERIES_INDEX[seriesIndex]}</div></div>`;
                }
              );
              return tooltip;
            },
          },
          axisLabel: { hideOverlap: true },
          grid: {
            top: "50",
            left: "50",
            right: "50",
            show: true
          },
          color: ["#4A4B54", "#6c737a"],
          legend: {},
          dataZoom: withScroll && [
            {
              type: "inside",
              realtime: true,
              start: 0,
              end: 100,
            },
          ],
          toolbox: {
            show: true,
            feature: {
              mark: { show: true },
              dataZoom: {
                show: true,
                title: { zoom: t("zoomTooltip"), back: t("back") },
              },
              restore: { show: true, title: t("restore") },
              saveAsImage: { show: true, title: t("saveAsImage") },
            },
          },
          xAxis: {
            type: "time",
            boundaryGap: false,
            min: daterange[0].valueOf(),
            max: daterange[1].valueOf(),
            axisLabel: {
              formatter: function(value) {
                return moment(value).format("DD-MM YYYY");
              },
            },
          },
          yAxis: {
            type: "value",
          },
          dataset: {
            source: chartData.sort((a, b) => b[0]-a[0]),
            dimensions:
              sensors.includes("port5") && sensors.includes("port6")
                ? ["timestamp", "port5", "port6"]
                : sensors.includes("port5")
                ? ["timestamp", "port5"]
                : sensors.includes("port6") && ["timestamp", "port6"],
          },
          series:
            sensors.includes("port5") && sensors.includes("port6")
              ? [
                  {
                    name: `Pipe Pressure (Port 5)`,
                    type: "line",
                    symbol: "none",
                    connectNulls: true,
                    sampling: "max",
                    step: true,
                    encode: {
                      x: "timestamp",
                      y: "port5",
                    },
                  },
                  {
                    name: `Pipe Pressure (Port 6)`,
                    type: "line",
                    symbol: "none",
                    connectNulls: true,
                    sampling: "max",
                    step: true,
                    encode: {
                      x: "timestamp",
                      y: "port6",
                    },
                  },
                ]
              : sensors.includes("port5")
              ? [
                  {
                    name: `Pipe Pressure (Port 5)`,
                    type: "line",
                    symbol: "none",
                    connectNulls: true,
                    sampling: "max",
                    step: true,
                    encode: {
                      x: "timestamp",
                      y: "port5",
                    },
                  },
                ]
              : sensors.includes("port6") && [
                  {
                    name: `Pipe Pressure (Port 6)`,
                    type: "line",
                    symbol: "none",
                    connectNulls: true,
                    sampling: "max",
                    step: true,
                    encode: {
                      x: "timestamp",
                      y: "port6",
                    },
                  },
                ],
        }}
      />
    );
  } else {
    return <AppLoader />;
  }
};

export default SoilTempGraph;
