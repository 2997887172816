import { combineReducers } from "redux";
import history from "history.js";
import { reducer as toastrReducer } from "react-redux-toastr";

import { countryReducer } from "redux/userCountry";
import heatMap, { moduleName as heatMapModule } from "redux/heatMap";
import otp, { moduleName as otpModule } from "redux/otp";
import login, { moduleName as loginModule } from "redux/login";
import me, { moduleName as meModule } from "redux/me";
import accounts, { moduleName as accountsModule } from "redux/accounts";
import accountsList, {
  moduleName as accountsListModule,
} from "redux/accountsList";
import createAccount, {
  moduleName as createAccountModule,
} from "redux/createAccount";
import account, { moduleName as accountModule } from "redux/account";
import editAccount, {
  moduleName as editAccountModule,
} from "redux/editAccount";
import deleteAccounts, {
  moduleName as deleteAccountsModule,
} from "redux/deleteAccounts";
import disableAccounts, {
  moduleName as disableAccountsModule,
} from "redux/disableAccounts";
import enableAccounts, {
  moduleName as enableAccountsModule,
} from "redux/enableAccounts";
import users, { moduleName as usersModule } from "redux/users";
import detachUser, { moduleName as detachUserModule } from "redux/detachUser";
import deleteUsers, {
  moduleName as deleteUsersModule,
} from "redux/deleteUsers";
import addUser, { moduleName as addUserModule } from "redux/addUser";
import editUser, { moduleName as editUserModule } from "redux/editUser";
import accountHierarchy, {
  moduleName as accountHierarchyModule,
} from "redux/accountHierarchy";
import usersList, { moduleName as usersListModule } from "redux/usersList";
import roles, { moduleName as rolesModule } from "redux/roles";
import subscriptionStatus, {
  moduleName as subscripttionModule,
} from "redux/subscription";
import permissions, {
  moduleName as permissionsModule,
} from "redux/permissions";
import accountsCount, {
  moduleName as accountsCountModule,
} from "redux/accountsCount";
import statisticsByPlots, {
  moduleName as statisticsByPlotsModule,
} from "redux/statisticsByPlots";
import dashboardSettings, {
  moduleName as dashboardSettingsModule,
} from "redux/dashboardSettings";
import saveDashboardSettings, {
  moduleName as saveDashboardSettingsModule,
} from "redux/saveDashboardSettings";
import insightsList, {
  moduleName as insightsListModule,
} from "redux/insightsList";
import waterPotential, {
  moduleName as waterPotentialModule,
} from "redux/waterPotential";
import alertInfo, { moduleName as alertInfoModule } from "redux/alertInfo";
import fieldWeatherHistory, {
  moduleName as fieldWeatherHistoryModule,
} from "redux/fieldWeatherHistory";
import rainAndIrrigationHistory, {
  moduleName as rainAndIrrigationHistoryModule,
} from "redux/rainAndIrrigation";
import airHumidity, {
  moduleName as airHumidityModule,
} from "redux/airHumidity";
import devicesCellstate, {
  moduleName as devicesCellstateModule,
} from "redux/devicesCellstate";
import waterPotentialLast, {
  moduleName as waterPotentialLastModule,
} from "redux/waterPotentialLast";
import airTempLast, {
  moduleName as airTempLastModule,
} from "redux/airTempLastData";
import airHumidityLast, {
  moduleName as airHumidityLastModule,
} from "redux/airHumidityLastData";
import weatherForecast, {
  moduleName as weatherForecastModule,
} from "redux/weatherForecast";
import devicesCellstateLastData, {
  moduleName as devicesCellstateLastDataModule,
} from "redux/devicesCellstateLastData";
import plotsCoordinates, {
  moduleName as plotsCoordinatesModule,
} from "redux/plotsCoordinates";
import plotSettings, {
  moduleName as plotSettingsModule,
} from "redux/plotSettings";
import editPlot, { moduleName as editPlotModule } from "redux/editPlotSettings";
import fieldsList, { moduleName as fieldsListModule } from "redux/fieldsList";
import cropsList, { moduleName as cropsListModule } from "redux/cropsList";
import soilList, { moduleName as soilListModule } from "redux/soilList";
import graphEvents, {
  moduleName as graphEventsModule,
} from "redux/graphEvents";
import myProducts, {
  moduleName as myProductsModule,
} from "redux/productsList";
import expiringLicences, {
  moduleName as expiringLicencesModule,
} from "redux/expiringLicences";
import licenceStatusInfo, {
  moduleName as licenceStatusInfoModule,
} from "redux/licenceStatusBar";
import addProductsToCart, {
  moduleName as addProductsToCartModule,
} from "redux/addProductsToCart";
import cartTotals, {
  moduleName as cartTotalsModule,
} from "redux/cartTotals";
import productsCartList, {
  moduleName as productsCartListModule,
} from "redux/productsCartList";
import fastSpringSession, {
  moduleName as fastSpringSessionModule,
} from "redux/fastSpringSession";
import payOffline, {
  moduleName as payOfflineModule,
} from "redux/payOffline";
import licenceById, {
  moduleName as licenceByIdModule,
} from "redux/licenceById";
import devicesList, {
  moduleName as devicesListModule,
} from "redux/devicesList";
import productTypeList, {
  moduleName as productTypeListModule,
} from "redux/productTypeList";
import editLicence, {
  moduleName as editLicenceModule,
} from "redux/editLicence";
import soilTemp, {
  moduleName as soilTempModule,
} from "redux/soilTemp";
import getTrialSubscription, { moduleName as getTrialSubscriptionModule } from "redux/getTrialSubscription";
import expirationStatus, { moduleName as expirationStatusModule } from "redux/expirationStatus";
import activationProductsList, { moduleName as activationProductsListModule } from "redux/activationProductsList";
import activateDevice, { moduleName as activateDeviceModule } from "redux/activateDevice";
import devicesByPlot, { moduleName as devicesByPlotModule } from "redux/devicesByPlot";
import pipePressure, { moduleName as pipePressureModule } from "redux/pipePressure";
import powerVoltage, { moduleName as powerVoltageModule } from "redux/powerVoltage";
import airTemperatureSensor, { moduleName as airTemperatureSensorModule } from "redux/airTemperatureSensor";
import airHumiditySensor, { moduleName as airHumiditySensorModule } from "redux/airHumiditySensor";
import deviceBattery, { moduleName as deviceBatteryModule } from "redux/deviceBattery";
import mottechLogin, { moduleName as mottechLoginModule } from "redux/mottechLogin";
import irrigationProviderConnection, { moduleName as irrigationProviderConnectionModule } from "redux/irrigationProviderConnection";
import connectionPrograms, { moduleName as connectionProgramsModule } from "redux/connectionPrograms";
import irrigationConnect, { moduleName as irrigationConnectModule } from "redux/irrigationConnect";
import plotIrrigationConnection, { moduleName as plotIrrigationConnectionModule } from "redux/plotIrrigationConnection";
import irrigationDisconnect, { moduleName as irrigationDisconnectModule } from "redux/irrigationDisconnect";
import plotIrrigationControllerStatus, { moduleName as plotIrrigationControllerStatusModule } from "redux/plotIrrigationControllerStatus";
import updateIrrigationSettings, { moduleName as updateIrrigationSettingsModule } from "redux/updateIrrigationSettings";
import irrigationHistory, { moduleName as irrigationHistoryModule } from "redux/irrigationHistory";
import plotsListWithoutAutopilotLicence, { moduleName as plotsListWithoutAutopilotLicenceModule } from "redux/plotsListWithoutAutopilotLicence";
import addAutopilotLicence, { moduleName as addAutopilotLicenceModule } from "redux/addAutopilotLicence";
import availableAutopilotLicence, { moduleName as availableAutopilotLicenceModule } from "redux/availableAutopilotLicence";
import plotAutopilotLicence, { moduleName as plotAutopilotLicenceModule } from "redux/plotAutopilotLicence";
import plotsIrrigationStatus, { moduleName as plotsIrrigationStatusModule } from "redux/plotsIrrigationStatus";
import editIrrigationConnection, { moduleName as editIrrigationConnectionModule } from "redux/editIrrigationConnection";
import mottechEditServer, { moduleName as mottechEditServerModule } from "redux/mottechEditServer";
import plotSensorList, { moduleName as plotSensorListModule } from "redux/plotSensorList";
import virtualDeviceWp, { moduleName as virtualDeviceWpModule } from "redux/virtualDeviceWp";
import talgilLogin, { moduleName as talgilLoginModule } from "redux/talgilLogin";
import talgilEditServer, { moduleName as talgilEditServerModule } from "redux/talgilEditServer";

const LOGOUT = "LOGOUT";

const appReducer = combineReducers({
  toastr: toastrReducer,
  countryData: countryReducer,
  [heatMapModule]: heatMap,
  [otpModule]: otp,
  [loginModule]: login,
  [meModule]: me,
  [accountsModule]: accounts,
  [accountsListModule]: accountsList,
  [createAccountModule]: createAccount,
  [accountModule]: account,
  [editAccountModule]: editAccount,
  [deleteAccountsModule]: deleteAccounts,
  [disableAccountsModule]: disableAccounts,
  [enableAccountsModule]: enableAccounts,
  [usersModule]: users,
  [detachUserModule]: detachUser,
  [deleteUsersModule]: deleteUsers,
  [addUserModule]: addUser,
  [editUserModule]: editUser,
  [accountHierarchyModule]: accountHierarchy,
  [usersListModule]: usersList,
  [rolesModule]: roles,
  [subscripttionModule]: subscriptionStatus,
  [permissionsModule]: permissions,
  [accountsCountModule]: accountsCount,
  [statisticsByPlotsModule]: statisticsByPlots,
  [dashboardSettingsModule]: dashboardSettings,
  [saveDashboardSettingsModule]: saveDashboardSettings,
  [insightsListModule]: insightsList,
  [waterPotentialModule]: waterPotential,
  [alertInfoModule]: alertInfo,
  [fieldWeatherHistoryModule]: fieldWeatherHistory,
  [rainAndIrrigationHistoryModule]: rainAndIrrigationHistory,
  [airHumidityModule]: airHumidity,
  [devicesCellstateModule]: devicesCellstate,
  [waterPotentialLastModule]: waterPotentialLast,
  [airTempLastModule]: airTempLast,
  [airHumidityLastModule]: airHumidityLast,
  [weatherForecastModule]: weatherForecast,
  [devicesCellstateLastDataModule]: devicesCellstateLastData,
  [plotsCoordinatesModule]: plotsCoordinates,
  [plotSettingsModule]: plotSettings,
  [editPlotModule]: editPlot,
  [fieldsListModule]: fieldsList,
  [cropsListModule]: cropsList,
  [soilListModule]: soilList,
  [graphEventsModule]: graphEvents,
  [myProductsModule]: myProducts,
  [expiringLicencesModule]: expiringLicences,
  [licenceStatusInfoModule]: licenceStatusInfo,
  [addProductsToCartModule]: addProductsToCart,
  [cartTotalsModule]: cartTotals,
  [productsCartListModule]: productsCartList,
  [fastSpringSessionModule]: fastSpringSession,
  [payOfflineModule]: payOffline,
  [licenceByIdModule]: licenceById,
  [devicesListModule]: devicesList,
  [productTypeListModule]: productTypeList,
  [editLicenceModule]: editLicence,
  [soilTempModule]: soilTemp,
  [getTrialSubscriptionModule]: getTrialSubscription,
  [expirationStatusModule]: expirationStatus,
  [activationProductsListModule]: activationProductsList,
  [activateDeviceModule]: activateDevice,
  [devicesByPlotModule]: devicesByPlot,
  [pipePressureModule]: pipePressure,
  [powerVoltageModule]: powerVoltage,
  [airTemperatureSensorModule]: airTemperatureSensor,
  [airHumiditySensorModule]: airHumiditySensor,
  [deviceBatteryModule]: deviceBattery,
  [mottechLoginModule]: mottechLogin,
  [irrigationProviderConnectionModule]: irrigationProviderConnection,
  [connectionProgramsModule]: connectionPrograms,
  [irrigationConnectModule]: irrigationConnect,
  [plotIrrigationConnectionModule]: plotIrrigationConnection,
  [irrigationDisconnectModule]: irrigationDisconnect,
  [plotIrrigationControllerStatusModule]: plotIrrigationControllerStatus,
  [updateIrrigationSettingsModule]: updateIrrigationSettings,
  [irrigationHistoryModule]: irrigationHistory,
  [plotsListWithoutAutopilotLicenceModule]: plotsListWithoutAutopilotLicence,
  [addAutopilotLicenceModule]: addAutopilotLicence,
  [availableAutopilotLicenceModule]: availableAutopilotLicence,
  [plotAutopilotLicenceModule]: plotAutopilotLicence,
  [plotsIrrigationStatusModule]: plotsIrrigationStatus,
  [editIrrigationConnectionModule]: editIrrigationConnection,
  [mottechEditServerModule]: mottechEditServer,
  [plotSensorListModule]: plotSensorList,
  [virtualDeviceWpModule]: virtualDeviceWp,
  [talgilLoginModule]: talgilLogin,
  [talgilEditServerModule]: talgilEditServer
});

const rootReducer = (state, action) => {
  if (action.type === LOGOUT) {
    const { countryData } = state;
    state = undefined;
    state = { countryData };
    localStorage.clear();
    history.push("/login");
  }

  return appReducer(state, action);
};

export default rootReducer;
