import React, { useEffect, useState } from "react";
import ReactEcharts from "echarts-for-react";
import { AppLoader } from "components/commons";
import moment from "moment";
import { useTranslation } from "react-i18next";
import history from "history.js";
import { Box } from "@material-ui/core";

const UNITS_BY_SERIES_INDEX = {
  0: "%",
};

const AirHumiditySensorGraph = ({
  airHumidity,
  depths,
  deviceId,
  daterange,
  errorMsg,
  withScroll = false,
}) => {
  const [chartData, setData] = useState();
  const { t } = useTranslation();

  useEffect(() => {
    if (airHumidity && airHumidity[deviceId]) {
      setData(airHumidity[deviceId].data);
    }
  }, [airHumidity]);

  if (
    errorMsg &&
    errorMsg[deviceId] &&
    errorMsg[deviceId] === "DEVICE_DONT_HAVE_ACTIVE_LICENCE"
  ) {
    return (
      <Box
        style={{
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          color: "#828B93",
          fontSize: "16px",
          fontWeight: "500",
          marginTop: "-28px",
        }}
      >
        {t("licenseExpired")}&nbsp;
        <span
          style={{
            textDecoration: "underline",
            cursor: "pointer",
            "&:hover": {
              opacity: "0.7",
            },
          }}
          onClick={() => history.push("/my-products")}
        >
          {t("clickToRenewLink")}
        </span>
      </Box>
    );
  } else if (chartData) {
    return (
      <ReactEcharts
        style={{ height: "100%" }}
        option={{
          tooltip: {
            trigger: "axis",
            appendToBody: true,
            formatter: (data) => {
              if (!data.length) {
                data = [data];
              }
              let tooltip = `${moment(data[0].value[data[0].encode.x]).format(
                "DD-MM YYYY HH:mm"
              )} <br/>`;
              data.forEach(
                ({ value, marker, seriesName, seriesIndex, encode: { y } }) => {
                  tooltip += `<div style="display: flex; align-items: center; direction: ltr;">${marker}<div style="padding-inline-end: 5px">${seriesName}:</div><div style="direction: ltr">${
                    value[y] === null ? "-" : value[y]
                  }${UNITS_BY_SERIES_INDEX[seriesIndex]}</div></div>`;
                }
              );
              return tooltip;
            },
          },
          axisLabel: { hideOverlap: true },
          grid: {
            top: "50",
            left: "50",
            right: "50",
            show: true
          },
          color: ["#70B7E1"],
          legend: {},
          dataZoom: withScroll && [
            {
              type: "inside",
              realtime: true,
              start: 0,
              end: 100,
            },
          ],
          toolbox: {
            show: true,
            feature: {
              mark: { show: true },
              dataZoom: {
                show: true,
                title: { zoom: t("zoomTooltip"), back: t("back") },
              },
              restore: { show: true, title: t("restore") },
              saveAsImage: { show: true, title: t("saveAsImage") },
            },
          },
          xAxis: {
            type: "time",
            boundaryGap: false,
            min: daterange[0].valueOf(),
            max: daterange[1].valueOf(),
            axisLabel: {
              formatter: function(value) {
                return moment(value).format("DD-MM YYYY");
              },
            },
          },
          yAxis: {
            type: "value",
            scale: true,
          },
          dataset: {
            source: chartData.sort((a, b) => b[0] - a[0]),
            dimensions: ["timestamp", "airHumidity"],
          },
          series: [
            {
              name: "Air Humidity",
              type: "line",
              symbol: "none",
              connectNulls: true,
              smooth: true,
              encode: {
                x: "timestamp",
                y: "airHumidity",
              },
            },
          ],
        }}
      />
    );
  } else {
    return <AppLoader />;
  }
};

export default AirHumiditySensorGraph;
