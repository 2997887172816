import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  btn: {
    backgroundColor: theme.palette.primary.main,
    color: "#ffffff",
    boxShadow: "0 11px 25px 0 rgba(112, 183, 225, 0.15)",
    textTransform: "none",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      opacity: 0.8,
    },
  },
  light: {
    backgroundColor: "#ffffff",
    color: theme.palette.primary.main,
    boxShadow: "0 11px 25px 0 rgba(112, 183, 225, 0.15)",
    textTransform: "none",
    border: "1px solid #D7D9DF",
    "&:hover": {
      backgroundColor: "#ffffff",
      opacity: 0.8,
    },
  },
  green: {
    backgroundColor: "#91B54D",
    color: theme.palette.text.white,
    boxShadow: "0 11px 25px 0 rgba(112, 183, 225, 0.15)",
    textTransform: "none",
    border: "1px solid #D7D9DF",
    "&:hover": {
      backgroundColor: "#91B54D",
      opacity: 0.8,
    },
  },
}));
