import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Box } from "@material-ui/core";
import AppLogo from "media/verticalLogo.svg";
import FarmerCry from "media/farmerCry.svg";
import useStyles from "./styles";
import { unsubscribe } from "api";

const Unsubscribe = ({ location }) => {
  const { t } = useTranslation();
  const { container, content, title, msg, msg2 } = useStyles();
  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const email = urlParams.get("email");
    const types = urlParams.get("types");
    unsubscribe(email, types.split(','));
  }, []);
  return (
    <Box className={container}>
      <img style={{ margin: "72px" }} src={AppLogo} alt="logo" />
      <Box className={content}>
        <img src={FarmerCry} alt="FarmerCry" />
        <Box className={title}>{t("unsubscribe")}</Box>
        <Box className={msg}>{t("unsubscribeMsg1")}</Box>
        <Box className={msg2}>{t("unsubscribeMsg2")}</Box>
      </Box>
    </Box>
  );
};

export default Unsubscribe;
