import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  container: {
    display: "flex",
    flex: 1,
    height: "100%",
  },
  notConnectedContainer: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column"
  },
  connectBtn: {
    height: "40px",
    fontSize: "14px",
    fontWeight: "bold"
  },
  header: {
    display: "flex",
    alignItems: "center",
    marginBottom: "27px",
    justifyContent: "space-between"
  },
  headerLeft: {
    display: "flex",
    alignItems: "center",
  },
  title: {
    color: "#4A4B54",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "16px",
  },
  subtitle: {
    fontSize: "12px",
    lineHeight: "140%",
    color: "#828B93",
  },
  menuItemText: {
    color: "#828B93",
    fontSize: "14px",
    padding: "0 10px",
  }
}));