import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  container: {
    padding: "24px",
    height: "100%",
  },
  title: {
    color: "#4A4B54",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "16px",
  },
  subtitle: {
    fontSize: "12px",
    lineHeight: "140%",
    color: "#4A4B54",
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "27px",
  },
  menuItemText: {
    color: "#828B93",
    fontSize: "14px",
    padding: "0 10px",
  }
}));
