import React, { useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  Box,
  Typography,
  Divider,
  Breadcrumbs,
  Link,
} from "@material-ui/core";
import clsx from "clsx";
import history from "history.js";
import { userNameSelector, userIdSelector } from "redux/me";
import { accountsCountSelector } from "redux/accountsCount";
import {
  getAccountHierarchyRequest,
  accountHierarchySelector,
} from "redux/accountHierarchy";
import { LanguagePicker } from "components/commons";
import { useTranslation } from "react-i18next";
import OpenMenu from "media/closeMenu.svg";

import AppLogo from "media/logo.svg";
import useStyles from "./styles";

const MobileHeader = ({
  userName,
  userId,
  getAccountHierarchy,
  accountHierarchy,
  accountsCount,
  toggleDrawer,
}) => {
  const { t } = useTranslation();
  const accountData = localStorage.getItem("accountData");
  const {
    container,
    userNameStyle,
    divider,
    containerAuthenticated,
    link,
    mainAccount,
    separatorStyle,
    menuToggle,
    hierarchyContainer
  } = useStyles();
  useEffect(() => {
    if (userId && accountData) {
      getAccountHierarchy();
    }
  }, [userId]);
  const isAuthenticated = !!localStorage.getItem("token");
  const handleClick = (event, account) => {
    event.preventDefault();
    if (account !== "all") {
      history.push(
        `/account-login?organizationId=${account.id}&name=${
          account.name
        }&parentId=${account.parent}&redirectUrl=${window.location.pathname}`
      );
    } else {
      history.push(
        `/account-login?clearAccount=${true}&redirectUrl=${
          window.location.pathname
        }`
      );
    }
  };
  return (
    <Box>
      <Box
        className={clsx(container, {
          [containerAuthenticated]: isAuthenticated,
        })}
      >
        <Box display="flex" alignItems="center">
          <Box>
            <img src={AppLogo} height="37px" alt="logo" />
          </Box>
        </Box>
        <Box display="flex" alignItems="center" height="100%">
          {isAuthenticated && (
            <Typography className={userNameStyle} variant="subtitle2">
              {userName}
            </Typography>
          )}
          {isAuthenticated && (
            <Divider orientation="vertical" flexItem className={divider} />
          )}
          <LanguagePicker />
          <Divider orientation="vertical" flexItem className={divider} />
          <img
            src={OpenMenu}
            className={menuToggle}
            alt="toogleMenu"
            onClick={toggleDrawer}
          />
        </Box>
      </Box>
      <Box className={hierarchyContainer}>
        {accountsCount > 1 ? (
          <Breadcrumbs
            separator={<Typography className={separatorStyle}>/</Typography>}
            aria-label="breadcrumb"
          >
            {accountData ? (
              <Link
                color="inherit"
                href="/"
                className={link}
                onClick={(e) => handleClick(e, "all")}
              >
                {t("allAccounts")}
              </Link>
            ) : (
              <Typography className={mainAccount}>
                {t("allAccounts")}
              </Typography>
            )}
            {isAuthenticated &&
              accountHierarchy
                .slice(0)
                .reverse()
                .map((account, i) => {
                  if (accountHierarchy[i + 1]) {
                    return (
                      <Link
                        key={Math.random()}
                        color="inherit"
                        href="/"
                        className={link}
                        onClick={(e) => handleClick(e, account)}
                      >
                        {account.name}
                      </Link>
                    );
                  } else {
                    return (
                      <Typography key={Math.random()} className={mainAccount}>
                        {account.name}
                      </Typography>
                    );
                  }
                })}
          </Breadcrumbs>
        ) : (
          <Typography className={mainAccount}>
            {accountData && JSON.parse(accountData).name}
          </Typography>
        )}
      </Box>
    </Box>
  );
};
const mapStateToProps = (state) => ({
  accountsCount: accountsCountSelector(state),
  userName: userNameSelector(state),
  accountHierarchy: accountHierarchySelector(state),
  userId: userIdSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  getAccountHierarchy: bindActionCreators(getAccountHierarchyRequest, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MobileHeader);
