import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Router, Switch, Redirect } from "react-router-dom";
import * as r from "./constants/routes";
import {
  SignIn,
  Checkout,
  NoMember,
  Accounts,
  Users,
  CreateAccount,
  EditAccount,
  AccountLogin,
  MyProducts,
  Dashboard,
  PlotSettings,
  Cart,
  Unsubscribe,
  DeviceActivate,
  MottechRedirect
} from "./components/pages";
import i18n from "i18next";
import Languages from "./constants/languages";
import { getMeRequest } from "./redux/me";
import LoginRoute from "./utils/loginRoute";
import AuthRoute from "./utils/authRoute";
import SimpleRoute from "./utils/simpleRoute";
import history from "./history";
import { LoginLayout, AuthLayout } from "./layouts";
import _ from "lodash";

import moment from "moment";
import "moment/locale/he";
import "moment/locale/es";
import "moment/locale/it";
import "moment/locale/pt";
import "moment/locale/ro";
import "moment/locale/tr";
import "moment/locale/de";
import "moment/locale/ar";
import "moment/locale/en-au";

const useForceUpdate = () => {
  // eslint-disable-next-line
  const [value, setValue] = useState(0);
  return () => setValue((value) => ++value);
};

const App = ({ getMeRequest }) => {
  const forceUpdate = useForceUpdate();
  useEffect(() => {
    window.Intercom("update", {
      hide_default_launcher: true,
    });
    const currentLang = localStorage.getItem("language");

    if (currentLang) {
      const language = Languages.find((lang) => lang.lang === currentLang);
      i18n.changeLanguage(language.lang);
      const body = document.querySelector("html");
      body.dir = language.direction;
      body.lang = language.lang;
      moment.locale(language.lang);
    } else {
      moment.locale("en");
    }
  }, []);
  useEffect(() => {
    if (localStorage.getItem("token")) getMeRequest();
    window.onresize = _.debounce(handleWindowResize, 200);
  }, []);
  const handleWindowResize = (e) => forceUpdate();
  return (
    <Router history={history}>
      <React.Fragment>
        <Switch>
          <SimpleRoute
            exact
            path={r.INDEX}
            component={() => {
              return localStorage.getItem("token") &&
                localStorage.getItem("accountData") ? (
                <Redirect to={r.DASHBOARD} />
              ) : localStorage.getItem("token") ? (
                <Redirect to={"/accounts"} />
              ) : (
                <Redirect to={r.LOGIN} />
              );
            }}
          />
          <SimpleRoute path={r.ACCOUNT_LOGIN} component={AccountLogin} />
          <SimpleRoute path={r.MOTTECH} component={MottechRedirect} />
          <LoginRoute path={r.LOGIN} component={SignIn} layout={LoginLayout} />
          <LoginRoute
            path={r.NO_MEMBER}
            component={NoMember}
            layout={LoginLayout}
          />
          <AuthRoute
            path={r.ACCOUNTS}
            layout={AuthLayout}
            component={Accounts}
          />
          <AuthRoute
            path={r.ACTIVATION_TOOL}
            layout={AuthLayout}
            component={DeviceActivate}
          />
          <AuthRoute path={r.USERS} layout={AuthLayout} component={Users} />
          <AuthRoute
            path={r.MY_PRODUCTS}
            layout={AuthLayout}
            component={MyProducts}
          />
          <AuthRoute
            path={r.CART}
            layout={AuthLayout}
            component={Cart}
            layoutProps={{
              displayHelpFooter: true,
            }}
          />
          <AuthRoute
            path={r.CREATE_ACCOUNT}
            layout={AuthLayout}
            component={CreateAccount}
          />
          <AuthRoute
            path={r.EDIT_ACCOUNT}
            layout={AuthLayout}
            component={EditAccount}
          />
          <AuthRoute
            path={r.DASHBOARD}
            layout={AuthLayout}
            component={Dashboard}
          />
          <AuthRoute
            path={r.PLOT_SETTINGS}
            layout={AuthLayout}
            component={PlotSettings}
          />
          <SimpleRoute
            path={r.MOBILE}
            render={(props) => {
              const parseQuery = (queryString) => {
                var query = {};
                var pairs = (queryString[0] === "?"
                  ? queryString.substr(1)
                  : queryString
                ).split("&");
                for (var i = 0; i < pairs.length; i++) {
                  var pair = pairs[i].split("=");
                  query[decodeURIComponent(pair[0])] = decodeURIComponent(
                    pair[1] || ""
                  );
                }
                return query;
              };
              const { token, redirectTo } = parseQuery(props.location.search);
              if (token) {
                localStorage.setItem("token", token);
                return <Redirect to={redirectTo} />;
              } else {
                return <Redirect to={r.LOGIN} />;
              }
            }}
          />
          <SimpleRoute path={r.CHECKOUT} component={Checkout} />
          <SimpleRoute path={r.UNSUBSRIBE} component={Unsubscribe} />
        </Switch>
      </React.Fragment>
    </Router>
  );
};

export default connect(
  null,
  { getMeRequest }
)(App);
