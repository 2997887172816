import React from "react";
import { TextField, Box } from "@material-ui/core";
import useStyles from "./styles";

const StyledTextField = ({
  margin = "normal",
  type = "text",
  className = "",
  disabled = false,
  placeholder,
  label,
  touched,
  error,
  value,
  handleChange,
  handleBlur,
  name,
}) => {
  const {
    cssFocused,
    cssFocusedLabel,
    fieldContainer,
    errorStyles,
  } = useStyles();
  return (
    <Box className={fieldContainer}>
      <TextField
        label={label}
        value={value}
        type={type}
        disabled={disabled}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder={placeholder}
        name={name}
        className={className}
        error={!!(touched && error)}
        InputProps={{
          classes: {
            root: cssFocused,
          },
        }}
        InputLabelProps={{
          shrink: placeholder && true,
          classes: {
            focused: cssFocusedLabel,
          },
        }}
        margin={margin}
      />
      {touched && error && <small className={errorStyles}>{error}</small>}
    </Box>
  );
};

export default StyledTextField;
