export const INDEX = "/";
export const LOGIN = `/login`;
export const NO_MEMBER = `/no-member`;
export const ACCOUNTS = `/accounts`;
export const CREATE_ACCOUNT = `/create-account`;
export const EDIT_ACCOUNT = `/edit-account/:accountId`;
export const DEVICES = `/devices/:organizationId?`;
export const USERS = `/users/:organizationId?`;
export const MOBILE = `/mobile-redirect`;
export const CHECKOUT = `/checkout`;
export const MOBILE_HEATMAP = `/heatmap-mobile`;
export const ACCOUNT_LOGIN = `/account-login`;
export const MY_PRODUCTS = `/my-products`;
export const DASHBOARD = `/dashboard`;
export const PLOT_SETTINGS = `/plot-settings/:plotId?`;
export const CART = `/cart`;
export const TRIAL = `/trial/:organizationId`;
export const UNSUBSRIBE = `/unsubscribe`;
export const ACTIVATION_TOOL = `/activate`;
export const MOTTECH = `/mottech`;