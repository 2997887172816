import { put, takeEvery, all, call } from "redux-saga/effects";
import { getMyProductsList } from "api";
import { getExpirationStatusRequest } from "./expirationStatus";
/* *
 * Constants
 * */
export const moduleName = "myProducts";
const prefix = `${process.env.REACT_APP_NAME}/${moduleName}`;

export const INIT = `${prefix}/INIT`;
export const SUCCESS = `${prefix}/SUCCESS`;
export const REJECT = `${prefix}/REJECT`;

/* *
 * Reducer
 * */
const initialState = {
  myProducts: [],
  loading: false,
  error: "",
  success: false,
};

export default function reducer(
  state = initialState,
  { type, payload, meta, ...rest }
) {
  switch (type) {
    case INIT:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        myProducts: payload,
        error: "",
      };
    case REJECT:
      return {
        ...state,
        loading: false,
        success: false,
        error: payload.error,
      };
    default:
      return state;
  }
}

/* *
 * Selectors
 * */
export const moduleSelector = (state) => state[moduleName];

export const myProductsSelector = (state) => moduleSelector(state).myProducts;

export const isLoadingSelector = (state) => moduleSelector(state).loading;

export const isSuccessSelector = (state) => moduleSelector(state).success;

export const errorSelector = (state) => moduleSelector(state).error;

/* *
 * Action Creators
 * */
export const getMyProductsRequest = (search, pagination, sort) => ({
  type: INIT,
  search,
  pagination,
  sort,
});

export const successAction = (payload) => ({
  type: SUCCESS,
  payload,
});

export const errorAction = (error) => ({
  type: REJECT,
  payload: { error },
});

/* *
 * Sagas
 * */
export function* getMyProductsSaga({ search, pagination, sort }) {
  try {
    let accountId = null;
    let res = null;
    const accountFromStorage = localStorage.getItem("accountData");
    accountId = JSON.parse(accountFromStorage).id;
    res = yield call(getMyProductsList, accountId, search, pagination, sort);
    yield put(getExpirationStatusRequest());
    yield put(successAction(res));
  } catch (error) {
    yield put(errorAction(error.message));
  }
}

export function* saga() {
  yield all([takeEvery(INIT, getMyProductsSaga)]);
}
