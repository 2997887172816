import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  SvgIcon,
  Popover,
  Box,
  Checkbox,
  OutlinedInput,
  InputAdornment,
  Button,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  popover: {
    overflow: "hidden",
  },
  popoverContent: {
    width: "418px",
    maxHeight: "300px",
    overflow: "scroll",
  },
  typography: {
    padding: theme.spacing(2),
  },
  filterIcon: {
    width: "16px",
    height: "16px",
    cursor: "pointer",
    "&:hover": {
      opacity: 0.6,
    },
  },
  itemRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "11px 0",
  },
  search: {
    backgroundColor: "white",
    width: "100%",
    "&>fieldset": {
      border: "none",
    },
  },
  searchIcon: {
    color: theme.palette.text.lightGray,
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid #D7D9DF",
  },
  btnIcon: {
    color: theme.palette.text.lightGray,
  },
  btnLabel: {
    color: theme.palette.text.lightGray,
    textTransform: "none",
    marginInlineEnd: "15px",
  },
  footer: {
    padding: "21px 0",
    display: "flex",
    justifyContent: "flex-end",
  },
  cancelBtn: {
    color: theme.palette.text.lightGray,
    margin: "0 20px",
    fontWeight: "bold",
    textTransform: "none",
    fontSize: "14px",
  },
  selectBtn: {
    color: theme.palette.primary.main,
    margin: "0 20px",
    fontWeight: "bold",
    textTransform: "none",
    fontSize: "14px",
  },
  allLabel: {
    color: theme.palette.text.lightGray,
  },
}));

const FilterIcon = ({ className, color, ...rest }) => (
  <SvgIcon {...rest} viewBox="0 0 16 16" className={className}>
    <path
      d="M13.4369 2H2.56315C2.06351 2 1.8114 2.60623 2.16542 2.96026L6.5 7.29549V12.125C6.5 12.3085 6.58955 12.4805 6.73993 12.5858L8.61493 13.8979C8.98484 14.1568 9.5 13.8944 9.5 13.437V7.29549L13.8347 2.96026C14.188 2.60694 13.9376 2 13.4369 2Z"
      fill={color}
    />
  </SvgIcon>
);

const MultiselectFilter = ({ listData, handleFilter }) => {
  const {
    itemRow,
    filterIcon,
    popoverContent,
    search,
    searchIcon,
    row,
    btnIcon,
    btnLabel,
    popover,
    footer,
    cancelBtn,
    selectBtn,
    allLabel,
  } = useStyles();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedItems, setSelected] = useState([]);
  const [chechedAlready, setAlreadyChecked] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [allCheked, setAllChecked] = useState(false);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setSelected(chechedAlready);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelected(chechedAlready);
    setSearchValue("");
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleSelect = (item) => {
    const selectedIndex = selectedItems.findIndex(
      (selectedItem) => selectedItem.id === item.id
    );
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedItems, item);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedItems.slice(1));
    } else if (selectedIndex === item.length - 1) {
      newSelected = newSelected.concat(selectedItems.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedItems.slice(0, selectedIndex),
        selectedItems.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };
  const handleSearch = (e) => {
    setSearchValue(e.target.value);
  };
  const handleClearSelections = () => {
    setSelected([]);
    setAllChecked(false);
  };
  const handleSelectAllClick = () => {
    if (!allCheked) {
      const newSelecteds = listData.map((n) => n);
      setSelected(newSelecteds);
      setAllChecked(!allCheked);
      return;
    }
    setAllChecked(!allCheked);
    setSelected([]);
  };
  const handlePressSelect = () => {
    handleFilter(selectedItems);
    setAlreadyChecked(selectedItems);
    setAnchorEl(null);
    setSearchValue("");
  };
  return (
    <div>
      <FilterIcon
        onClick={handleClick}
        color={selectedItems.length > 0 ? "#70B7E1" : "#D7D9DF"}
        className={filterIcon}
      />
      <Popover
        id={id}
        className={popover}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <OutlinedInput
          id="search"
          placeholder={t("search")}
          className={search}
          onChange={handleSearch}
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon className={searchIcon} edge="end" />
            </InputAdornment>
          }
        />
        <Box className={row}>
          <Box className={itemRow} onClick={() => handleSelectAllClick()}>
            <Checkbox checked={allCheked} color="primary" />
            <Typography className={allLabel} variant="subtitle2">
              {t("all")}
            </Typography>
          </Box>
          <Button
            className={btnLabel}
            startIcon={<CloseIcon className={btnIcon} />}
            onClick={handleClearSelections}
          >
            {t("clear")}
          </Button>
        </Box>
        <Box className={popoverContent}>
          {listData
            .filter((item) =>
              item.name.toLowerCase().includes(searchValue.toLowerCase())
            )
            .map((item) => {
              return (
                <Box
                  className={itemRow}
                  key={item.id}
                  onClick={() => handleSelect(item)}
                >
                  <Checkbox
                    checked={selectedItems.some(
                      (selectedItem) => selectedItem.id === item.id
                    )}
                    color="primary"
                  />
                  <Typography variant="subtitle2">{item.name}</Typography>
                </Box>
              );
            })}
        </Box>
        <Box className={footer}>
          <Button className={cancelBtn} onClick={handleClose}>
            {t("cancel")}
          </Button>
          <Button className={selectBtn} onClick={handlePressSelect}>
            {t("selectBtnLabel")}
          </Button>
        </Box>
      </Popover>
    </div>
  );
};
export default MultiselectFilter;
