export const USERS_COLUMNS = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "userDetails", //User details
    sortable: true,
    filterable: false,
    minWidth: null,
  },
  {
    id: "organization",
    numeric: false,
    disablePadding: false,
    label: "account",
    sortable: true,
    filterable: false,
    minWidth: null,
  },
  {
    id: "role",
    numeric: false,
    disablePadding: false,
    label: "role",
    sortable: true,
    filterable: false,
    minWidth: null,
  },
  {
    id: "lastLogin",
    numeric: false,
    disablePadding: false,
    label: "lastLogin",
    sortable: true,
    filterable: false,
    minWidth: null,
  },
  {
    id: "createdAt",
    numeric: true,
    disablePadding: false,
    label: "createdAt",
    sortable: true,
    filterable: false,
    minWidth: null,
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: false,
    label: "actions",
    sortable: false,
    filterable: false,
    minWidth: "60px",
  },
];
