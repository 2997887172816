import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  picker: {
    minWidth: "67px",
    color: ({whitePicker}) => `${whitePicker ? "#FFFFFF" : "#828B93"} !important`,
    "& svg": {
      fill: ({whitePicker}) => `${whitePicker ? "#A8D8F4" : "#828B93"} !important`,
    },
    "&:after": {
      display: "none",
    },
    "&:before": {
      display: "none",
    },
    "&:first-child div": {
      backgroundColor: "transparent !important",
    },
  },
}));
