import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  container: {
    padding: "32px",
    height: "100%"
  },
  content: {
    backgroundColor: "#FFFFFF",
    border: "1px solid #EBEDF3",
    borderRadius: "8px",
    padding: "26px 40px",
    marginTop: "26px",
    display: "flex",
    height: "70%"
  },
  btnContainer: {
    display: "flex",
    flexDirection: "column",
    padding: "31px 50% 0 0",
  },
  inputMargin: {
    marginTop: "2em",
    marginBottom: "1em",
  },
  marginInlineStart: {
    marginInlineStart: "5px",
  },
  marginInlineEnd: {
    marginInlineEnd: "5px",
  },
  row: {
    display: "flex",
    marginTop: "2em",
    marginBottom: "1em",
    "& > div": {
      flex: 1,
    },
  },
  infoBox: {
    flex: 1,
    backgroundColor: "#363740",
    borderRadius: "8px",
    marginInlineStart: "10px",
    padding: "53px",
    maxHeight: "350px",
    overflow: "auto",
  },
  infoRow: {
    display: "flex",
    marginTop: "5px"
  },
  infoLabel: {
    flex: 1,
    color: "#828B93"
  },
  infoValue: {
    flex: 1,
    color: "#91B54D"
  },
  rowMarginTop: {
    display: "flex",
    marginTop: "20px"
  },
  infoValueError: {
    flex: 1,
    color: "#DA5062"
  }
}));
