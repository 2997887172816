import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  container: {
    width: "calc(100% - 40px)",
    height: "calc(100% - 40px)",
    padding: "20px",
    minWidth: "800px"
  },
  title: {
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "16px",
    padding: "0 5px",
  },
  headerRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "20px 0",
  },
  insightsColumnTitle: {
    flex: 3,
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "16px",
    color: "#828B93",
  },
  deviceNameColumnTitle: {
    flex: 1,
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "16px",
    color: "#828B93",
    paddingInlineEnd: "20px"
  },
  dateColumnTitle: {
    flex: 1,
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "16px",
    color: "#828B93",
  },
  graphColumnTitle: {
    flex: 2,
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "16px",
    color: "#828B93",
  },
  actionsColumnTitle: {
    flex: 1,
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "16px",
    color: "#828B93",
  },
  insightRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "5px"
  },
  insightData: {
    display: "flex",
    flex: 3,
    alignItems: "center",
  },
  flexColumn: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    cursor: "pointer",
  },
  insightTitle: {
    fontSize: "14px",
    lineHeight: "150%",
  },
  insightInstr: {
    fontSize: "12px",
    lineHeight: "140%",
    color: "#828B93",
  },
  dateColumn: {
    flex: 1,
    fontSize: "14px",
    lineHeight: "150%",
    color: "#828B93",
  },
  graphColumn: {
    flex: 2,
  },
  actionsColumn: {
    flex: 1,
    display: "flex",
    justifyContent: "space-between",
  },
  insightIconContainer: {
    width: "26px",
    height: "26px",
    borderRadius: "50%",
    minWidth: "26px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginInlineEnd: "15px",
    cursor: "pointer",
  },
  icon: {
    width: "16px",
    height: "16px",
  },
  deleteBtn: {
    backgroundColor: "#F7F8FC",
    border: "1px solid #EBEDF3",
    color: "#DA5062",
    width: "32px",
    height: "32px",
  },
  approveBtn: {
    backgroundColor: "#F7F8FC",
    border: "1px solid #EBEDF3",
    color: "#91B54D",
    width: "32px",
    height: "32px",
  },
  moreBtn: {
    color: "#C8D1D9",
    width: "32px",
    height: "32px",
  },
  moreBoxContainer: {
    width: "306px",
  },
  turnOnIrrigation: {
    display: "flex",
    height: "46px",
    alignItems: "center",
    borderBottom: "1px solid #EBEDF3",
    cursor: "pointer",
    "&:hover": {
      opacity: 0.7,
    },
  },
  turnOnIrrigationIcon: {
    margin: "0 15px",
  },
  turnOnIrrigationLabel: {
    color: "#298BC5",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "16px",
  },
  btnRow: {
    display: "flex",
    height: "46px",
    alignItems: "center",
    cursor: "pointer",
    "&:hover": {
      opacity: 0.7,
    },
  },
  btnRowIcon: {
    margin: "0 15px",
    width: "16px",
  },
  btnRowLabel: {
    color: "#828B93",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "150%",
  },
  deleteModal: {
    width: "377px",
    height: "166px",
    padding: "50px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  turnOffNotificationsModal: {
    width: "377px",
    height: "166px",
    padding: "50px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  modalTitle: {
    fontWeight: "bold",
    fontSize: "24px",
    lineHeight: "28px",
    textAlign: "center",
    color: "#4A4B54",
  },
  modalSubtitle: {
    fontSize: "14px",
    lineHeight: "150%",
    textAlign: "center",
    color: "#828B93",
  },
  cancelBtn: {
    backgroundColor: "#FFFFFF",
    border: "1px solid #EBEDF3",
    borderRadius: "28px",
    height: "50px",
    width: "155px",
    color: "#70B7E1",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "16px",
    textTransform: "none",
  },
  modalApproveBtn: {
    backgroundColor: "#DA5062",
    border: "1px solid #DA5062",
    borderRadius: "28px",
    height: "50px",
    width: "155px",
    color: "white",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "16px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#DA5062",
      opacity: 0.7,
    },
  },
  btnModalRow: {
    display: "flex",
    justifyContent: "space-around",
  },
  commentModal: {
    width: "358px",
    padding: "32px",
  },
  modalApproveBtnBlue: {
    backgroundColor: "#70B7E1",
    border: "1px solid #70B7E1",
    borderRadius: "28px",
    height: "50px",
    width: "155px",
    color: "white",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "16px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#70B7E1",
      opacity: 0.7,
    },
  },
  commentInput: {
    width: "100%",
    margin: "20px 0 35px 0",
    fontSize: "14px",
    lineHeight: "150%",
  },
  turnOnIrrigationModal: {
    padding: "50px",
    height: "130px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  remindMe: {
    padding: "50px",
    width: "335px",
  },
  timeBtn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "14px",
    height: "40px",
    fontWeight: "normal",
    cursor: "pointer",
    borderRadius: "3px",
    marginTop: "8px",
    marginBottom: "8px",
    border: "1px solid transparent",
    "& p": {
      fontSize: "14px",
    },
    "&:hover": {
      border: "1px solid #A8D8F4",
    },
  },
  timeBtnSelected: {
    border: "1px solid #A8D8F4",
    background: "#F7F8FC",
    "& p": {
      fontWeight: "bold",
    },
  },
  allGoodBox: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
  },
  allGoodMsg: {
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "19px",
    color: "#828B93",
    marginTop: "5px"
  },
  deviceNameColumn: {
    flex: 1,
    fontSize: "14px",
    lineHeight: "150%",
    color: "#828B93",
    paddingInlineEnd: "20px"
  },
  header: {
    display: "flex",
    alignItems: "center",
    marginBottom: "27px",
    justifyContent: "space-between"
  },
  menuItemText: {
    color: "#828B93",
    fontSize: "14px",
    padding: "0 10px"
  }
}));
