import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Typography, Box } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Formik } from "formik";
import { StyledTextField, AppSelect, AppBtnFab } from "components/commons";
import { getRolesRequest, rolesSelector } from "redux/roles";
import _ from "lodash";
import validator from "validator";
import { resendInvitation } from "api";

import useStyles from "./styles";
import { useEffect } from "react";
import PermissionsService from "../../../services/PermissionsService";
import { findPhoneNumbersInText } from "libphonenumber-js";

const validatePhoneNumber = (number) => {
  if (number[0] === "+") {
    return findPhoneNumbersInText(number).length === 0;
  } else {
    return findPhoneNumbersInText(`+${number}`).length === 0;
  }
};
let sortingOrder = {
  USER_ROLE: 1,
  OPERATOR_ROLE: 2,
  LOCAL_ADMIN_ROLE: 3,
  RESELLER_ROLE: 4,
};
const sortRoleByPriority = (key, order = "asc") => {
  return (a, b) => {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) return 0;
    const first =
      a[key] in sortingOrder ? sortingOrder[a[key]] : Number.MAX_SAFE_INTEGER;
    const second =
      b[key] in sortingOrder ? sortingOrder[b[key]] : Number.MAX_SAFE_INTEGER;

    let result = 0;
    if (first < second) result = -1;
    else if (first > second) result = 1;
    return order === "desc" ? ~result : result;
  };
};

const EditUserForm = ({
  userData,
  handleCloseEditUserForm,
  handleEditUser,
  getRoles,
  roles,
}) => {
  useEffect(() => {
    getRoles();
  }, []);
  const { t } = useTranslation();
  const {
    container,
    header,
    headerTitle,
    closeIcon,
    userForm,
    btnContainer,
    cancelBtn,
    saveBtn,
    codeBox,
    code,
    codeMsg,
    resendInvitationBtn,
  } = useStyles();
  const handleResendInvitation = () => {
    resendInvitation(userData.organizationId, userData.userId);
  };
  const prepareRoles = (role) => {
    return {
      label: role.name,
      value: role.id,
    };
  };
  return (
    <Box className={container}>
      <Box className={header}>
        <Typography className={headerTitle} variant="h6">
          {t("ediitUserFormTitle")}
        </Typography>
        <CloseIcon onClick={handleCloseEditUserForm} className={closeIcon} />
      </Box>
      <Formik
        initialValues={{
          name: userData.username,
          email: userData.email,
          phoneNumber: `+${userData.phoneNumber}`,
          roleId: userData.roleId,
        }}
        validate={(values) => {
          const errors = {};
          if (!values.phoneNumber) {
            errors.phoneNumber = "Required";
          } else if (validatePhoneNumber(values.phoneNumber)) {
            errors.phoneNumber = "Enter a valid Phone Number";
          }
          if (!values.name) {
            errors.name = "Required";
          }
          if (!values.roleId) {
            errors.roleId = "Required";
          }
          if (!values.email) {
            errors.email = "Required";
          }
          if (!validator.isEmail(values.email)) {
            errors.email = "Please enter a valid email";
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          handleEditUser(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <form className={userForm} onSubmit={handleSubmit}>
            <StyledTextField
              label={t("fullname")}
              name="name"
              value={values.name}
              touched={touched.name}
              error={errors.name}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
            <StyledTextField
              label={t("workEmail")}
              name="email"
              value={values.email}
              touched={touched.email}
              error={errors.email}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
            <StyledTextField
              label={t("phoneNumber")}
              name="phoneNumber"
              value={values.phoneNumber}
              touched={touched.phoneNumber}
              error={errors.phoneNumber}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
            <AppSelect
              label={t("role")}
              name="roleId"
              defaultItem={t("selectRole")}
              items={
                userData.roleId === 6
                  ? [
                      ...roles
                        .sort(sortRoleByPriority("raw"))
                        .map(prepareRoles),
                      { label: t("superAdmin"), value: 6, disabled: true },
                    ]
                  : roles.sort(sortRoleByPriority("raw")).map(prepareRoles)
              }
              value={values.roleId}
              touched={touched.roleId}
              error={errors.roleId}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
            <Typography
              className={resendInvitationBtn}
              onClick={handleResendInvitation}
            >
              {t("resendIvitation")}
            </Typography>
            {PermissionsService.isAllowed("USER_VIEW_LOGIN_CODE") && (
              <Box className={codeBox}>
                <Typography className={code} variant="h6">
                  {userData.code || "-"}
                </Typography>
                <Typography className={codeMsg}>
                  {t("pendingCodeMsg")}
                </Typography>
              </Box>
            )}
            <Box className={btnContainer}>
              <AppBtnFab
                className={cancelBtn}
                btnType="light"
                label={t("cancel")}
                onClick={handleCloseEditUserForm}
              />
              <AppBtnFab
                className={saveBtn}
                type="submit"
                label={t("save")}
                disabled={!_.isEmpty(errors)}
              />
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  roles: rolesSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  getRoles: bindActionCreators(getRolesRequest, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditUserForm);
