import { fork, all } from "redux-saga/effects";

import { watchGetUserCountryData } from "redux/userCountry";
import { saga as heatMapSaga } from "redux/heatMap";
import { saga as otpSaga } from "redux/otp";
import { saga as loginSaga } from "redux/login";
import { saga as meSaga } from "redux/me";
import { saga as accountsSaga } from "redux/accounts";
import { saga as accountsListSaga } from "redux/accountsList";
import { saga as createAccountSaga } from "redux/createAccount";
import { saga as getAccountSaga } from "redux/account";
import { saga as editAccountSaga } from "redux/editAccount";
import { saga as deleteAccountsSaga } from "redux/deleteAccounts";
import { saga as disableAccountsSaga } from "redux/disableAccounts";
import { saga as enableAccountsSaga } from "redux/enableAccounts";
import { saga as getUsersSaga } from "redux/users";
import { saga as detachUserSaga } from "redux/detachUser";
import { saga as deleteUsersSaga } from "redux/deleteUsers";
import { saga as addUserSaga } from "redux/addUser";
import { saga as editUserSaga } from "redux/editUser";
import { saga as getAccountHierarchySaga } from "redux/accountHierarchy";
import { saga as getUsersListSaga } from "redux/usersList";
import { saga as getRolesSaga } from "redux/roles";
import { saga as getSubscriptionSaga } from "redux/subscription";
import { saga as getPermissionsSaga } from "redux/permissions";
import { saga as getAccountsCountSaga } from "redux/accountsCount";
import { saga as getStatisticsByPlotsSaga } from "redux/statisticsByPlots";
import { saga as getDashboardSettingsSaga } from "redux/dashboardSettings";
import { saga as saveDashboardSettingsSaga } from "redux/saveDashboardSettings";
import { saga as getInsightsListSaga } from "redux/insightsList";
import { saga as deleteInsightSaga } from "redux/deleteInsight";
import { saga as markAsDoneInsightSaga } from "redux/markAsDoneInsight";
import { saga as remindMeSaga } from "redux/remindMe";
import { saga as turnOffAllNotificationOfTypeSaga } from "redux/turnOffNotification";
import { saga as getWaterPotentialSaga } from "redux/waterPotential";
import { saga as getAlertInfoSaga } from "redux/alertInfo";
import { saga as getFieldWeatherHistorySaga } from "redux/fieldWeatherHistory";
import { saga as getRainAndIrrigationHistorySaga } from "redux/rainAndIrrigation";
import { saga as getAirHumiditySaga } from "redux/airHumidity";
import { saga as getDevicesCellstateSaga } from "redux/devicesCellstate";
import { saga as getWaterPotentialLastValueSaga } from "redux/waterPotentialLast";
import { saga as getAirTempLastValueSaga } from "redux/airTempLastData";
import { saga as getAirHumidityLastValueSaga } from "redux/airHumidityLastData";
import { saga as getWeatherForecastSaga } from "redux/weatherForecast";
import { saga as getDevicesCellstateLastDataSaga } from "redux/devicesCellstateLastData";
import { saga as getPlotsCoordinatesSaga } from "redux/plotsCoordinates";
import { saga as getPlotSettingsSaga } from "redux/plotSettings";
import { saga as editPlotSettingsSaga } from "redux/editPlotSettings";
import { saga as getFieldsListSaga } from "redux/fieldsList";
import { saga as getCropsListSaga } from "redux/cropsList";
import { saga as getSoilListSaga } from "redux/soilList";
import { saga as getGraphEventsSaga } from "redux/graphEvents";
import { saga as getMyProductsSaga } from "redux/productsList";
import { saga as getExpiringLicencesSaga } from "redux/expiringLicences";
import { saga as getLicenceStatusBarSaga } from "redux/licenceStatusBar";
import { saga as addProductsToCartSaga } from "redux/addProductsToCart";
import { saga as getCartTotalsSaga } from "redux/cartTotals";
import { saga as getProductsCartListSaga } from "redux/productsCartList";
import { saga as removeProductFromCartSaga } from "redux/removeProductFromCart";
import { saga as getFastSpringSessionSaga } from "redux/fastSpringSession";
import { saga as payOfflineSaga } from "redux/payOffline";
import { saga as getLicenceByIdSaga } from "redux/licenceById";
import { saga as getDevicesListSaga } from "redux/devicesList";
import { saga as getProductTypesSaga } from "redux/productTypeList";
import { saga as editLicenceDataSaga } from "redux/editLicence";
import { saga as getSoilTemperatureSaga } from "redux/soilTemp";
import { saga as getTrialSubscriptionSaga } from "redux/getTrialSubscription";
import { saga as getExpirationStatusSaga } from "redux/expirationStatus";
import { saga as getActivationProductsListSaga } from "redux/activationProductsList";
import { saga as activateDeviceSaga } from "redux/activateDevice";
import { saga as devicesByPlotSaga } from "redux/devicesByPlot";
import { saga as getPipePressureSaga } from "redux/pipePressure";
import { saga as getPowerVoltageSaga } from "redux/powerVoltage";
import { saga as getAirTemperatureSensorSaga } from "redux/airTemperatureSensor";
import { saga as getDeviceBatterySaga } from "redux/deviceBattery";
import { saga as getAirHumiditySensorSaga } from "redux/airHumiditySensor";
import { saga as mottechLoginSaga } from "redux/mottechLogin";
import { saga as getIrrigationProviderConnectionSaga } from "redux/irrigationProviderConnection";
import { saga as getConnectionProgramsSaga } from "redux/connectionPrograms";
import { saga as irrigationConnectSaga } from "redux/irrigationConnect";
import { saga as getPlotIrrigationConnectionSaga } from "redux/plotIrrigationConnection";
import { saga as irrigationDisconnectSaga } from "redux/irrigationDisconnect";
import { saga as getPlotIrrigationControllerStatusSaga } from "redux/plotIrrigationControllerStatus";
import { saga as updateIrrigationSettingsSaga } from "redux/updateIrrigationSettings";
import { saga as getIrrigationHistorySaga } from "redux/irrigationHistory";
import { saga as getPlotsListWithoutAutopilotLicenceSaga } from "redux/plotsListWithoutAutopilotLicence";
import { saga as addAutopilotLicenceSaga } from "redux/addAutopilotLicence";
import { saga as getAvailableAutopilotLicenceSaga } from "redux/availableAutopilotLicence";
import { saga as getPlotAutopilotLicenceSaga } from "redux/plotAutopilotLicence";
import { saga as getPlotsIrrigationStatusSaga } from "redux/plotsIrrigationStatus";
import { saga as editIrrigationConnectionSaga } from "redux/editIrrigationConnection";
import { saga as mottechEditServerSaga } from "redux/mottechEditServer";
import { saga as getPlotSensorListSaga } from "redux/plotSensorList";
import { saga as getVirtualDeviceWPSaga } from "redux/virtualDeviceWp";
import { saga as talgilLoginSaga } from "redux/talgilLogin";
import { saga as talgilEditServerSaga } from "redux/talgilEditServer";

export default function* rootSaga() {
  yield all([
    fork(watchGetUserCountryData),
    heatMapSaga(),
    otpSaga(),
    loginSaga(),
    meSaga(),
    accountsSaga(),
    accountsListSaga(),
    createAccountSaga(),
    getAccountSaga(),
    editAccountSaga(),
    deleteAccountsSaga(),
    disableAccountsSaga(),
    enableAccountsSaga(),
    getUsersSaga(),
    detachUserSaga(),
    deleteUsersSaga(),
    addUserSaga(),
    editUserSaga(),
    getAccountHierarchySaga(),
    getUsersListSaga(),
    getRolesSaga(),
    getSubscriptionSaga(),
    getPermissionsSaga(),
    getAccountsCountSaga(),
    getStatisticsByPlotsSaga(),
    getDashboardSettingsSaga(),
    saveDashboardSettingsSaga(),
    getInsightsListSaga(),
    deleteInsightSaga(),
    markAsDoneInsightSaga(),
    remindMeSaga(),
    turnOffAllNotificationOfTypeSaga(),
    getWaterPotentialSaga(),
    getAlertInfoSaga(),
    getFieldWeatherHistorySaga(),
    getRainAndIrrigationHistorySaga(),
    getAirHumiditySaga(),
    getDevicesCellstateSaga(),
    getWaterPotentialLastValueSaga(),
    getAirTempLastValueSaga(),
    getAirHumidityLastValueSaga(),
    getWeatherForecastSaga(),
    getDevicesCellstateLastDataSaga(),
    getPlotsCoordinatesSaga(),
    getPlotSettingsSaga(),
    editPlotSettingsSaga(),
    getFieldsListSaga(),
    getCropsListSaga(),
    getSoilListSaga(),
    getGraphEventsSaga(),
    getMyProductsSaga(),
    getExpiringLicencesSaga(),
    getLicenceStatusBarSaga(),
    addProductsToCartSaga(),
    getCartTotalsSaga(),
    getProductsCartListSaga(),
    removeProductFromCartSaga(),
    getFastSpringSessionSaga(),
    payOfflineSaga(),
    getLicenceByIdSaga(),
    getDevicesListSaga(),
    getProductTypesSaga(),
    editLicenceDataSaga(),
    getSoilTemperatureSaga(),
    getTrialSubscriptionSaga(),
    getExpirationStatusSaga(),
    getActivationProductsListSaga(),
    activateDeviceSaga(),
    devicesByPlotSaga(),
    getPipePressureSaga(),
    getPowerVoltageSaga(),
    getAirTemperatureSensorSaga(),
    getAirHumiditySensorSaga(),
    getDeviceBatterySaga(),
    mottechLoginSaga(),
    getIrrigationProviderConnectionSaga(),
    getConnectionProgramsSaga(),
    irrigationConnectSaga(),
    getPlotIrrigationConnectionSaga(),
    irrigationDisconnectSaga(),
    getPlotIrrigationControllerStatusSaga(),
    updateIrrigationSettingsSaga(),
    getIrrigationHistorySaga(),
    getPlotsListWithoutAutopilotLicenceSaga(),
    addAutopilotLicenceSaga(),
    getAvailableAutopilotLicenceSaga(),
    getPlotAutopilotLicenceSaga(),
    getPlotsIrrigationStatusSaga(),
    editIrrigationConnectionSaga(),
    mottechEditServerSaga(),
    getPlotSensorListSaga(),
    getVirtualDeviceWPSaga(),
    talgilLoginSaga(),
    talgilEditServerSaga(),
  ]);
}
