import React from "react";
import { Header } from "components/commons";
import { Box, AppBar } from "@material-ui/core";

import useStyles from "./styles";

const LoginLayout = ({ children }) => {
  const { root, appBar, content } = useStyles();
  return (
    <Box className={root}>
      <AppBar className={appBar} position="fixed">
        <Header />
      </AppBar>
      <main className={content}>{children}</main>
    </Box>
  );
};

export default LoginLayout;
