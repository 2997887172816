import React, { useEffect, useState } from "react";
import { Box, InputLabel, Select, Dialog } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import history from "history.js";
import {
  successSelector,
  getTrialSubscriptionRequest,
  loadingSelector,
  clearGetTrial
} from "redux/getTrialSubscription";
import useStyles from "./styles";

import { AppBtnFab } from "components/commons";

import logo from "media/logoText.svg";
import subscription from "media/subscription.png";
import successPayImg from "media/successPayImg.svg";

const subscriptionInfoList = [
  "subscriptionPrivilegies1",
  "subscriptionPrivilegies2",
  "subscriptionPrivilegies3",
  "subscriptionPrivilegies4",
  "subscriptionPrivilegies5",
  "subscriptionPrivilegies6",
];

const Trial = ({
  match: {
    params: { organizationId },
  },
  getTrialSubscription,
  getTrialSuccess,
  getTrialLoading,
  location,
  clearGetTrialData
}) => {
  const { t } = useTranslation();
  const {
    container,
    content,
    header,
    logoStyle,
    title,
    subscriptionBox,
    subscriptionInfo,
    subscriptionTitle,
    listItem,
    select,
    mainContainer,
    nextRenewal,
    addBtn,
    termsLink,
    modal,
    successTitle,
    successMsg,
    flexRow,
    infoLabel,
    infoValue,
    nextBtn,
    mobileSuccessMsg
  } = useStyles();
  const [selectedQuantity, selectQuantity] = useState(1);
  const [succesModalIsOpen, triggerSuccessModal] = useState(false);
  const [isFromMobile, setIsFromMobile] = useState(false);
  useEffect(() => {
    var urlParams = new URLSearchParams(location.search);
    const isFromMobileApp = urlParams.get("isFromMobile");
    if (isFromMobileApp === "true") {
      setIsFromMobile(true);
    }
  }, []);
  useEffect(() => {
    if (getTrialSuccess) {
      triggerSuccessModal(true);
    }
  }, [getTrialSuccess]);
  const handleChange = (e) => {
    selectQuantity(+e.target.value);
  };
  const handleClose = () => {
    triggerSuccessModal(false);
  };
  const handleAddTrial = () => {
    getTrialSubscription({ organizationId, quantity: selectedQuantity });
  };
  const handlePressNext = () => {
    triggerSuccessModal(false);
    clearGetTrialData();
    var urlParams = new URLSearchParams(location.search);
    const redirectUrl = urlParams.get("redirectUrl");
    if (redirectUrl) {
      history.push(redirectUrl);
    } else {
      history.push(`/`);
    }
  };
  return (
    <Box className={mainContainer}>
      <Box className={header}>
        <img src={logo} alt="logo" className={logoStyle} />
      </Box>
      <Box className={container}>
        <Box className={content}>
          <Box className={title}>{t("getFreeTrialTitle")}</Box>
          <Box className={subscriptionBox}>
            <img src={subscription} alt="subscription" />
            <Box className={subscriptionInfo}>
              <Box className={subscriptionTitle}>
                {t("viridixYearlySubscription")}
              </Box>
              {subscriptionInfoList.map((item) => (
                <Box className={listItem} key={Math.random()}>
                  ✓ {t(item)}
                </Box>
              ))}
              <Box className={select}>
                <InputLabel htmlFor="quantity-native-simple">
                  {t("quantity")}
                </InputLabel>
                <Select
                  native
                  value={selectedQuantity}
                  onChange={handleChange}
                  style={{ width: "100px" }}
                  inputProps={{
                    name: "quantity",
                    id: "quantity-native-simple",
                  }}
                >
                  {new Array(10).fill().map((item, i) => (
                    <option key={Math.random()} value={i + 1}>
                      {i + 1}
                    </option>
                  ))}
                </Select>
              </Box>
            </Box>
          </Box>
          <Box className={nextRenewal}>
            {t("nextRenewalOn", {
              date: moment()
                .add(1, "year")
                .format("MMM DD, YYYY"),
            })}
          </Box>
          <Box className={addBtn}>
            <AppBtnFab
              label={
                <div>
                  <span>{t("addSubscriptions")}</span>
                </div>
              }
              disabled={getTrialLoading}
              onClick={() => handleAddTrial()}
              size="large"
            />
          </Box>
          <a
            className={termsLink}
            rel="noopener noreferrer"
            href="https://www.viridix.com/terms-conditions/"
            target="_blank"
          >
            {t("subscriptionTerms")}
          </a>
        </Box>
      </Box>
      <Dialog
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        onClose={handleClose}
        open={succesModalIsOpen}
      >
        <Box className={modal}>
          <img src={successPayImg} alt="successPayImg" />
          <Box className={successTitle}>{t("subscriptionsAddedSuccess")}</Box>
          <Box className={successMsg}>{t("subscriptionsAddedModalMsg")}</Box>
          <Box className={flexRow}>
            <Box className={infoLabel}>{t("licences")}</Box>
            <Box className={infoValue}>{selectedQuantity}</Box>
          </Box>
          <Box className={flexRow}>
            <Box className={infoLabel}>{t("nextPayment")}</Box>
            <Box className={infoValue}>
              {moment()
                .add(1, "year")
                .format("MMM DD, YYYY")}
            </Box>
          </Box>
          {!isFromMobile ? (
            <Box className={nextBtn}>
              <AppBtnFab
                label={
                  <div>
                    <span>{t("next")}</span>
                  </div>
                }
                onClick={handlePressNext}
                size="large"
              />
            </Box>
          ) : (
            <Box className={mobileSuccessMsg}>{t("getSubscriptionSuccesMsgMobile")}</Box>
          )}
        </Box>
      </Dialog>
    </Box>
  );
};
const mapStateToProps = (state) => ({
  getTrialSuccess: successSelector(state),
  getTrialLoading: loadingSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  getTrialSubscription: bindActionCreators(
    getTrialSubscriptionRequest,
    dispatch
  ),
  clearGetTrialData: bindActionCreators(clearGetTrial, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Trial);
