import React, { useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box } from "@material-ui/core";
import {
  StyledTextField,
  AppBtnFab,
  AppAutocomplete,
} from "components/commons";
import { getFieldsListRequest, fiedlsListSelector } from "redux/fieldsList";
import { getCropsListRequest, cropsListSelector } from "redux/cropsList";
import { getSoilListRequest, soilListSelector } from "redux/soilList";
import _ from "lodash";
import { Formik } from "formik";

import useStyles from "./../styles";

const getPlotCrop = (devices) => {
  let crop = null;
  devices.forEach((device) => {
    if (!crop) {
      crop = device.crop;
    } else if (!_.isEqual(device.crop, crop)) {
      crop = {
        id: 0,
        name: "Mixed",
        disabled: true,
      };
    }
  });
  return crop;
};

const getPlotSoil = (devices) => {
  let soil = null;
  devices.forEach((device) => {
    if (!soil) {
      soil = device.soil;
    } else if (!_.isEqual(device.soil, soil)) {
      soil = {
        id: 0,
        name: "Mixed",
        disabled: true,
      };
    }
  });
  return soil;
};

const GeneralTab = ({
  plotSettings,
  fiedlsList,
  cropsList,
  soilList,
  getFieldsList,
  getCropsList,
  getSoilList,
  handleSavePlotSetttings,
}) => {
  const { t } = useTranslation();
  const {
    generalTabContainer,
    tabHeaderTitle,
    btnContainer,
    inputRow,
    inputContainer,
    secondInputContainer,
    submitBtn,
  } = useStyles();

  useEffect(() => {
    getFieldsList();
    getCropsList();
    getSoilList();
  }, [plotSettings]);
  return (
    <Box className={generalTabContainer}>
      <Box className={tabHeaderTitle}>{t("general")}</Box>
      {plotSettings && fiedlsList && cropsList && soilList && (
        <Formik
          initialValues={{
            name: plotSettings.name,
            field: plotSettings.field,
            crop: getPlotCrop(plotSettings.devices),
            soil: getPlotSoil(plotSettings.devices),
          }}
          validate={(values) => {
            const errors = {};
            if (!values.name) {
              errors.name = "Required";
            }
            if (_.isEmpty(values.field)) {
              errors.field = "Required";
            }
            if (_.isEmpty(values.crop)) {
              errors.crop = "Required";
            }
            if (_.isEmpty(values.soil)) {
              errors.soil = "Required";
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            let plotData = {
              name: values.name,
              fieldId: values.field.id,
            };
            if (values.crop.id !== 0) {
              plotData.cropId = values.crop.id;
            }
            if (values.soil.id !== 0) {
              plotData.soilId = values.soil.id;
            }
            handleSavePlotSetttings(plotData);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => {
            const soilTypes = soilList.map((soil) => ({
              id: soil.id,
              name: t(soil.name.toLowerCase()),
            }));
            return (
              <form onSubmit={handleSubmit}>
                <Box className={inputRow}>
                  <Box className={inputContainer}>
                    <AppAutocomplete
                      label={t("Region")}
                      name="field"
                      value={values.field}
                      touched={touched.field}
                      error={errors.field}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      optionLabelKey="name"
                      options={fiedlsList}
                    />
                  </Box>
                  <Box className={secondInputContainer}>
                    <StyledTextField
                      label={t("Plot name")}
                      name="name"
                      value={values.name}
                      touched={touched.name}
                      error={errors.name}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                    />
                  </Box>
                </Box>
                <Box className={inputRow}>
                  <Box className={inputContainer}>
                    <AppAutocomplete
                      label={t("cropType")}
                      name="crop"
                      value={[
                        { id: 0, name: t("mixed"), disabled: true },
                        ...cropsList,
                      ].find((crop) => crop.id === values.crop.id)}
                      touched={touched.crop}
                      error={errors.crop}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      optionLabelKey="name"
                      options={[
                        { id: 0, name: t("mixed"), disabled: true },
                        ...cropsList,
                      ]}
                    />
                  </Box>
                  <Box className={secondInputContainer}>
                    <AppAutocomplete
                      label={t("soilType")}
                      name="soil"
                      value={[
                        { id: 0, name: t("mixed"), disabled: true },
                        ...soilTypes,
                      ].find((soil) => soil.id === values.soil.id)}
                      touched={touched.soil}
                      error={errors.soil}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      optionLabelKey="name"
                      options={[
                        { id: 0, name: t("mixed"), disabled: true },
                        ...soilTypes,
                      ]}
                    />
                  </Box>
                </Box>
                <Box className={btnContainer}>
                  <AppBtnFab
                    type="submit"
                    label={t("save")}
                    className={submitBtn}
                    disabled={!_.isEmpty(errors)}
                  />
                </Box>
              </form>
            );
          }}
        </Formik>
      )}
    </Box>
  );
};

const mapStateToProps = (state) => ({
  fiedlsList: fiedlsListSelector(state),
  cropsList: cropsListSelector(state),
  soilList: soilListSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  getFieldsList: bindActionCreators(getFieldsListRequest, dispatch),
  getCropsList: bindActionCreators(getCropsListRequest, dispatch),
  getSoilList: bindActionCreators(getSoilListRequest, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GeneralTab);
