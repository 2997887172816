import { put, takeEvery, all, call } from "redux-saga/effects";
import { removeProductFromCart } from "api";
import { getProductsCartListRequest } from "./productsCartList";
import { getCartTotalsRequest } from "./cartTotals";
/* *
 * Constants
 * */
export const moduleName = "removeProductFromCart";
const prefix = `${process.env.REACT_APP_NAME}/${moduleName}`;

export const INIT = `${prefix}/INIT`;
export const SUCCESS = `${prefix}/SUCCESS`;
export const REJECT = `${prefix}/REJECT`;

/* *
 * Action Creators
 * */
export const removeProductFromCartRequest = (
  licenceId,
  pagination,
  sort,
  period = 1
) => ({
  type: INIT,
  licenceId,
  pagination,
  sort,
  period,
});

export const successAction = (payload) => ({
  type: SUCCESS,
  payload,
});

export const errorAction = (error) => ({
  type: REJECT,
  payload: { error },
});

/* *
 * Sagas
 * */
export function* removeProductFromCartSaga({
  licenceId,
  pagination,
  sort,
  period,
}) {
  try {
    const accountFromStorage = localStorage.getItem("accountData");
    const res = yield call(
      removeProductFromCart,
      JSON.parse(accountFromStorage).id,
      licenceId
    );
    yield put(getProductsCartListRequest(pagination, sort));
    yield put(getCartTotalsRequest({ period }));
    yield put(successAction(res));
  } catch (error) {
    yield put(errorAction(error.message));
  }
}

export function* saga() {
  yield all([takeEvery(INIT, removeProductFromCartSaga)]);
}
