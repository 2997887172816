import { put, takeEvery, all, call } from "redux-saga/effects";
import { irrigationConnect, setPlotAutopilotLicence } from "api";
import { getPlotIrrigationConnectionRequest } from "./plotIrrigationConnection";

/* *
 * Constants
 * */
export const moduleName = "irrigationConnect";
const prefix = `${process.env.REACT_APP_NAME}/${moduleName}`;

export const INIT = `${prefix}/INIT`;
export const SUCCESS = `${prefix}/SUCCESS`;
export const REJECT = `${prefix}/REJECT`;

/* *
 * Reducer
 * */
const initialState = {
  loading: false,
  error: null,
  success: false,
};

export default function reducer(
  state = initialState,
  { type, payload, meta, ...rest }
) {
  switch (type) {
    case INIT:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };
    case REJECT:
      return {
        ...state,
        loading: false,
        success: false,
        error: payload.error,
      };
    default:
      return state;
  }
}

/* *
 * Selectors
 * */
export const moduleSelector = (state) => state[moduleName];

export const isLoadingSelector = (state) => moduleSelector(state).loading;

export const isSuccessSelector = (state) => moduleSelector(state).success;

export const errorSelector = (state) => moduleSelector(state).error;

/* *
 * Action Creators
 * */
export const irrigationConnectRequest = ({
  plotId,
  providerConnectionId,
  program,
  licenceId
}) => ({
  type: INIT,
  plotId,
  providerConnectionId,
  program,
  licenceId
});

export const successAction = (payload) => ({
  type: SUCCESS,
  payload,
});

export const errorAction = (error) => ({
  type: REJECT,
  payload: { error },
});

/* *
 * Sagas
 * */
export function* irrigationConnectSaga({
  plotId,
  providerConnectionId,
  program,
  licenceId
}) {
  try {
    let currentAccountId = null;
    const accountFromStorage = localStorage.getItem("accountData");
    currentAccountId = JSON.parse(accountFromStorage).id;
    yield call(
      setPlotAutopilotLicence,
      currentAccountId,
      plotId,
      licenceId
    );
    const programConnectRes = yield call(
      irrigationConnect,
      currentAccountId,
      plotId,
      providerConnectionId,
      program
    );
    yield put(getPlotIrrigationConnectionRequest(plotId))
    yield put(successAction(programConnectRes));
  } catch (error) {
    yield put(errorAction(error.response.data));
  }
}

export function* saga() {
  yield all([takeEvery(INIT, irrigationConnectSaga)]);
}
