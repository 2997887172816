import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Paper,
  Checkbox,
  Typography,
  SvgIcon,
  Button,
  Box,
  Tooltip,
  InputAdornment,
  OutlinedInput,
  Popover,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import useStyles from "./styles";
import { Delete, GetApp } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { MultiselectFilter } from "components/commons";
import clsx from "clsx";
import PermissionsService from "services/PermissionsService";
import NoAccount from "media/no-account.svg";
import Add from "media/add.svg";
import FarmerCircle from "media/farmer-circle-white.svg";

import moment from "moment";
import history from "history.js"
// const DevicesIcon = ({ className, ...rest }) => (
//   <SvgIcon {...rest} className={className}>
//     <path
//       fillRule="evenodd"
//       clipRule="evenodd"
//       d="M14.1779 18.8238C14.4287 18.937 14.707 19 15 19H19C20.1046 19 21 18.1046 21 17V11C21 9.89543 20.1046 9 19 9H17V15C17 16.7992 15.8121 18.3209 14.1779 18.8238ZM13 17C14.1046 17 15 16.1046 15 15V9C13.8954 9 13 9.89543 13 11V17Z"
//       fill="#828B93"
//     />
//     <path
//       fillRule="evenodd"
//       clipRule="evenodd"
//       d="M2 0C0.895431 0 0 0.89543 0 2V15C0 16.1046 0.89543 17 2 17H13C14.1046 17 15 16.1046 15 15V2C15 0.895431 14.1046 0 13 0H2ZM7.66425 8.2619C5.62437 9.32973 4.86931 10.1729 4.06874 12.02C1.19233 8.67101 4.21679 3.03906 12 4C12 4 11.2699 5.50445 11 6.5C10.8409 7.08684 10.8261 7.82818 10.8112 8.56884C10.7919 9.53333 10.7726 10.4967 10.4349 11.1162C8.79577 14.1227 4.98876 12.6289 4.98876 12.6289C5.74758 10.9067 6.30434 9.94885 7.66425 8.2619Z"
//       fill="#828B93"
//     />
//     <path d="M6 19H9V22H6V19Z" fill="#828B93" />
//   </SvgIcon>
// );
const UsersIcon = ({ className, ...rest }) => (
  <SvgIcon {...rest} viewBox="0 0 22 13" className={className}>
    <path
      d="M11 0C10.2044 0 9.44129 0.316071 8.87868 0.87868C8.31607 1.44129 8 2.20435 8 3C8 3.79565 8.31607 4.55871 8.87868 5.12132C9.44129 5.68393 10.2044 6 11 6C11.7956 6 12.5587 5.68393 13.1213 5.12132C13.6839 4.55871 14 3.79565 14 3C14 2.20435 13.6839 1.44129 13.1213 0.87868C12.5587 0.316071 11.7956 0 11 0ZM5 2.17C4.33696 2.17 3.70107 2.43339 3.23223 2.90223C2.76339 3.37107 2.5 4.00696 2.5 4.67C2.5 4.9983 2.56466 5.32339 2.6903 5.62671C2.81594 5.93002 3.00009 6.20562 3.23223 6.43777C3.46438 6.66991 3.73998 6.85406 4.04329 6.9797C4.34661 7.10534 4.6717 7.17 5 7.17C5.88 7.17 6.65 6.71 7.09 6.03C6.42 5.18 6 4.15 6 3C6 2.8 6 2.6 6.04 2.4C5.72 2.25 5.37 2.17 5 2.17ZM17 2.17C16.63 2.17 16.28 2.25 15.96 2.4C16 2.6 16 2.8 16 3C16 4.15 15.58 5.18 14.91 6.03C15.35 6.71 16.12 7.17 17 7.17C17.3283 7.17 17.6534 7.10534 17.9567 6.9797C18.26 6.85406 18.5356 6.66991 18.7678 6.43777C18.9999 6.20562 19.1841 5.93002 19.3097 5.62671C19.4353 5.32339 19.5 4.9983 19.5 4.67C19.5 4.3417 19.4353 4.01661 19.3097 3.71329C19.1841 3.40998 18.9999 3.13438 18.7678 2.90223C18.5356 2.67009 18.26 2.48594 17.9567 2.3603C17.6534 2.23466 17.3283 2.17 17 2.17ZM11 8C9 8 5 9 5 11V13H17V11C17 9 13 8 11 8ZM3.67 8.97C2 9.26 0 10.04 0 11.33V13H3V11C3 10.22 3.29 9.53 3.67 8.97ZM18.33 8.97C18.71 9.53 19 10.22 19 11V13H22V11.33C22 10.04 20 9.26 18.33 8.97Z"
      fill="#828B93"
    />
  </SvgIcon>
);
const EditIcon = ({ className, ...rest }) => (
  <SvgIcon {...rest} viewBox="0 0 30 30" className={className}>
    <path
      d="M22.74 10.5928C23.0867 10.2462 23.0867 9.66852 22.74 9.33967L20.6603 7.25996C20.3315 6.91335 19.7538 6.91335 19.4072 7.25996L17.7718 8.8864L21.1047 12.2193L22.74 10.5928ZM7 19.6671V23H10.3329L20.1626 13.1614L16.8297 9.8285L7 19.6671Z"
      fill="#70B7E1"
    />
  </SvgIcon>
);
const DeleteIcon = ({ className, ...rest }) => (
  <SvgIcon {...rest} viewBox="0 0 30 30" className={className}>
    <path
      d="M21.4444 7.88889H18.3333L17.4444 7H13L12.1111 7.88889H9V9.66667H21.4444V7.88889ZM9.88889 21.2222C9.88889 21.6937 10.0762 22.1459 10.4096 22.4793C10.743 22.8127 11.1952 23 11.6667 23H18.7778C19.2493 23 19.7015 22.8127 20.0349 22.4793C20.3683 22.1459 20.5556 21.6937 20.5556 21.2222V10.5556H9.88889V21.2222Z"
      fill="#828B93"
    />
  </SvgIcon>
);

const MenuIcon = ({ className, ...rest }) => (
  <SvgIcon {...rest} viewBox="0 0 16 4" className={className}>
    <path
      d="M12 2C12 1.46957 12.2107 0.96086 12.5858 0.585787C12.9609 0.210714 13.4696 0 14 0C14.5304 0 15.0391 0.210714 15.4142 0.585787C15.7893 0.96086 16 1.46957 16 2C16 2.53043 15.7893 3.03914 15.4142 3.41421C15.0391 3.78929 14.5304 4 14 4C13.4696 4 12.9609 3.78929 12.5858 3.41421C12.2107 3.03914 12 2.53043 12 2ZM6 2C6 1.46957 6.21071 0.96086 6.58579 0.585787C6.96086 0.210714 7.46957 0 8 0C8.53043 0 9.03914 0.210714 9.41421 0.585787C9.78929 0.96086 10 1.46957 10 2C10 2.53043 9.78929 3.03914 9.41421 3.41421C9.03914 3.78929 8.53043 4 8 4C7.46957 4 6.96086 3.78929 6.58579 3.41421C6.21071 3.03914 6 2.53043 6 2ZM0 2C0 1.46957 0.210714 0.96086 0.585786 0.585787C0.960859 0.210714 1.46957 0 2 0C2.53043 0 3.03914 0.210714 3.41421 0.585787C3.78929 0.96086 4 1.46957 4 2C4 2.53043 3.78929 3.03914 3.41421 3.41421C3.03914 3.78929 2.53043 4 2 4C1.46957 4 0.960859 3.78929 0.585786 3.41421C0.210714 3.03914 0 2.53043 0 2Z"
      fill="#828B93"
    />
  </SvgIcon>
);

const DisableIcon = ({ className, ...rest }) => (
  <SvgIcon {...rest} viewBox="0 0 20 20" className={className}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 0C4.5 0 0 4.5 0 10C0 15.5 4.5 20 10 20C15.5 20 20 15.5 20 10C20 4.5 15.5 0 10 0ZM2 10C2 5.6 5.6 2 10 2C11.8 2 13.5 2.6 14.9 3.7L3.7 14.9C2.6 13.5 2 11.8 2 10ZM10 18C8.2 18 6.5 17.4 5.1 16.3L16.3 5.1C17.4 6.5 18 8.2 18 10C18 14.4 14.4 18 10 18Z"
      fill="#828B93"
    />
  </SvgIcon>
);

const AppTable = ({
  cells,
  data,
  order,
  orderBy,
  selected,
  page,
  rowsPerPage,
  handleSelectAllClick,
  handleRequestSort,
  isSelected,
  handleSelect,
  handleChangePage,
  handleChangeRowsPerPage,
  pageCount,
  handleGoDevices,
  handleGoUsers,
  handleEditAccount,
  handleOpenDeleteModal,
  openDeleteModal,
  handleOpenDisableModal,
  handleOpenEnableModal,
  exportAccounts,
  accountsList,
  handleParentFilter,
  handleChangeAccount,
  handleSearch,
  handleCreateAccount,
  noAccess,
  loadingAccountsList
}) => {
  const selectedAccount = localStorage.getItem("accountData");
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRowItem, setSelectedRow] = useState(null);
  const {
    root,
    paper,
    tableStyle,
    selectedRow,
    headerLabel,
    icon,
    headerBtns,
    deleteBtn,
    headerColumn,
    accountNameBtn,
    actionBtn,
    search,
    searchIcon,
    exportBtn,
    iconMenu,
    menuItem,
    menuItemLabel,
    borderStart,
    noAccountBox,
    noAccContent,
    noAccountMsg,
    addBtn,
    noAccessBox,
    noAccessContent,
    accountName,
    noAccessMsg,
    goToDashboardBtn
  } = useStyles();
  const { t } = useTranslation();
  const emptyRows = rowsPerPage - data.length;
  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };
  const handleClick = (event, row) => {
    setSelectedRow(row);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <div className={root}>
      <Paper className={paper}>
        <Box className={headerBtns}>
          <OutlinedInput
            id="search"
            placeholder={t("search")}
            className={search}
            onChange={handleSearch}
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon className={searchIcon} edge="end" />
              </InputAdornment>
            }
          />
          <Box>
            {PermissionsService.isAllowed("ACCOUNT_DELETE") && (
              <Tooltip title={t("delete")}>
                <Button
                  className={deleteBtn}
                  startIcon={<Delete />}
                  onClick={openDeleteModal}
                  disabled={selected.length === 0}
                >
                  {t("delete")}
                </Button>
              </Tooltip>
            )}
            <Tooltip title={t("export")}>
              <Button
                className={exportBtn}
                startIcon={<GetApp />}
                onClick={exportAccounts}
                disabled={selected.length === 0}
              >
                {t("export")}
              </Button>
            </Tooltip>
          </Box>
        </Box>
        <TableContainer>
          <Table className={tableStyle} size="medium">
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    indeterminate={
                      selected.length > 0 && selected.length < data.length
                    }
                    checked={data.length > 0 && selected.length === data.length}
                    onChange={handleSelectAllClick}
                    color="primary"
                  />
                </TableCell>
                {cells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    align={headCell.numeric ? "right" : "left"}
                    style={{
                      minWidth: headCell.minWidth,
                      maxWidth: headCell.maxWidth,
                      width: headCell.width,
                    }}
                    className={clsx({
                      [borderStart]: headCell.borderStart,
                    })}
                    padding={headCell.disablePadding ? "none" : "default"}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <Box className={headerColumn}>
                      {headCell.sortable ? (
                        <TableSortLabel
                          active={orderBy === headCell.id}
                          direction={orderBy === headCell.id ? order : "asc"}
                          onClick={createSortHandler(headCell.id)}
                        >
                          <Typography className={headerLabel} variant="body2">
                            {t(headCell.label)}
                          </Typography>
                        </TableSortLabel>
                      ) : (
                        <Typography className={headerLabel} variant="body2">
                          {t(headCell.label)}
                        </Typography>
                      )}
                      {headCell.filterable && (
                        <MultiselectFilter
                          listData={accountsList}
                          handleFilter={handleParentFilter}
                        />
                      )}
                    </Box>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {!noAccess &&
                data.map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.name}
                      selected={isItemSelected}
                      classes={{
                        selected: selectedRow,
                      }}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          onClick={(event) => handleSelect(event, row)}
                          checked={isItemSelected}
                          inputProps={{ "aria-labelledby": labelId }}
                          color="primary"
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        <Typography
                          color={!row.isDisabled ? "primary" : "initial"}
                          variant="body2"
                          className={clsx({
                            [accountNameBtn]: !row.isDisabled,
                          })}
                          onClick={() =>
                            !row.isDisabled && handleChangeAccount(row)
                          }
                        >
                          {row.name}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="body2">
                          {row.devicesCount}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="body2">
                          {row.license.available}{" "}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="body2">
                          {row.license.assigned}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="body2">
                          {row.usersCount}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography variant="body2">
                          {row.parentOrganization || "-"}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography variant="body2">
                          {row.renewalDate
                            ? moment(row.renewalDate).format("MMM D,YYYY")
                            : "-"}
                        </Typography>
                      </TableCell>
                      <TableCell className={borderStart} align="left">
                        {!row.isDisabled &&
                          PermissionsService.isAllowed("ACCOUNT_EDIT") && (
                            <Tooltip title={t("edit")}>
                              <Button
                                onClick={() => handleEditAccount(row.id)}
                                className={actionBtn}
                              >
                                <EditIcon className={icon} />
                              </Button>
                            </Tooltip>
                          )}

                        {PermissionsService.isAllowed("ACCOUNT_DELETE") && (
                          <Tooltip title={t("Delete")}>
                            <Button
                              onClick={() => handleOpenDeleteModal(row)}
                              className={actionBtn}
                            >
                              <DeleteIcon className={icon} />
                            </Button>
                          </Tooltip>
                        )}
                        <Button
                          className={actionBtn}
                          onClick={(e) => handleClick(e, row)}
                        >
                          <MenuIcon className={iconMenu} />
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {data.length > 0 && emptyRows > 0 && !noAccess && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={9} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {data.length > 0 && !noAccess && (
          <TablePagination
            labelDisplayedRows={({ from, to, count }) => {
              return t("itemCountOnPageInTable", { from, to, count });
            }}
            labelRowsPerPage={t("labelRowsPerPage")}
            nextIconButtonText={t("nextIconButtonText")}
            backIconButtonText={t("backIconButtonText")}
            rowsPerPageOptions={[3, 5, 10, 25]}
            component="div"
            count={pageCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        )}
        {noAccess && (
          <Box style={{padding: "23px"}}>
            <Box className={noAccessBox}>
              <Box className={noAccessContent}>
                <img src={FarmerCircle} alt="FarmerCircle"/>
                <Box className={accountName}>{selectedAccount && JSON.parse(selectedAccount).name}</Box>
                <Box className={noAccessMsg}>{t("noChildAccountAccessMsg")}</Box>
                <Button
                  className={goToDashboardBtn}
                  onClick={() => history.push("/dashboard")}
                >
                  {t("goToDashboard")}
                </Button>
              </Box>
            </Box>
          </Box>
        )}
        {!data.length && !noAccess && (
          <Box className={noAccountBox}>
            <Box className={noAccContent}>
              <img src={NoAccount} alt="NoAccount" />
              <Typography className={noAccountMsg}>
                {t("noAccountMsg", {
                  accountName:
                    selectedAccount && JSON.parse(selectedAccount).name,
                })}
              </Typography>
              {PermissionsService.isAllowed("ACCOUNT_CREATE") && (
                <Button
                  className={addBtn}
                  startIcon={<img src={Add} alt="Add" />}
                  onClick={handleCreateAccount}
                >
                  {t("addAccount")}
                </Button>
              )}
            </Box>
          </Box>
        )}
      </Paper>
      {selectedRowItem && (
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          {/* {!selectedRowItem.isDisabled && (
            <Box
              className={menuItem}
              onClick={() => handleGoDevices(selectedRowItem)}
            >
              <DevicesIcon />
              <Typography className={menuItemLabel}>{t("devices")}</Typography>
            </Box>
          )} */}
          {!selectedRowItem.isDisabled && (
            <Box
              className={menuItem}
              onClick={() => handleGoUsers(selectedRowItem)}
            >
              <UsersIcon />
              <Typography className={menuItemLabel}>{t("users")}</Typography>
            </Box>
          )}
          {selectedRowItem &&
          !selectedRowItem.isDisabled &&
          PermissionsService.isAllowed("ACCOUNT_DISABLE") ? (
            <Box
              className={menuItem}
              onClick={() => {
                handleClose();
                handleOpenDisableModal(selectedRowItem);
              }}
            >
              <DisableIcon />
              <Typography className={menuItemLabel}>
                {t("disableAccount")}
              </Typography>
            </Box>
          ) : (
            PermissionsService.isAllowed("ACCOUNT_DISABLE") && (
              <Box
                className={menuItem}
                onClick={() => {
                  handleClose();
                  handleOpenEnableModal(selectedRowItem);
                }}
              >
                <DisableIcon />
                <Typography className={menuItemLabel}>
                  {t("enableAccount")}
                </Typography>
              </Box>
            )
          )}
        </Popover>
      )}
    </div>
  );
};

export default AppTable;
