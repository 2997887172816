import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Box } from "@material-ui/core";
// import useStyles from "./styles";
import {
  Drops,
  Device,
  IrrigationGifComponent,
  Ruler,
  StartIrrigationInfo,
  StopIrrigationInfo,
  WaterLevel,
  SoilLayers,
  AutopilotLogo,
  IrrigationInfo,
} from "./components";

import {
  getPlotIrrigationControllerStatusRequest,
  plotIrrigationControllerStatusSelector,
} from "redux/plotIrrigationControllerStatus";
import {
  plotSensorListSelector,
  getPlotSensorListRequest,
} from "redux/plotSensorList";
import { updateIrrigationSettingsRequest } from "redux/updateIrrigationSettings";
import PermissionsService from "services/PermissionsService";

const Autopilot = ({
  getPlotIrrigationControllerStatus,
  plotIrrigationControllerStatus,
  plotId,
  getPlotSensorList,
  plotSensorList,
  updateIrrigationSettings,
}) => {
  let interval = null;
  const [irrigationStatus, setIrrigationStatus] = useState(null);
  useEffect(() => {
    getPlotIrrigationControllerStatus(plotId);
    interval = setInterval(() => {
      getPlotIrrigationControllerStatus(plotId);
    }, 60000);
    getPlotSensorList(plotId);
    return () => {
      clearInterval(interval);
    };
  }, [plotId]);
  useEffect(() => {
    if (
      plotIrrigationControllerStatus &&
      plotIrrigationControllerStatus[plotId]
    ) {
      setIrrigationStatus(plotIrrigationControllerStatus[plotId]);
    }
  }, [plotIrrigationControllerStatus]);
  const getStopIrrigationRules = () => {
    return (
      irrigationStatus.irrigationRules.find(
        (rule) => rule.irrigationCommand === "stop"
      ) || {}
    );
  };
  const getStartIrrigationRules = () => {
    return (
      irrigationStatus.irrigationRules.find(
        (rule) => rule.irrigationCommand === "start"
      ) || {}
    );
  };
  if (irrigationStatus) {
    return (
      <Box
        className="autopilotWidget"
        style={{
          width: "447px",
          height: "285px",
          backgroundColor: "#F5F7F8",
          borderRadius: "16px",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <AutopilotLogo
          provider={irrigationStatus.provider}
          position={{ top: 18, left: 24 }}
        />
        <IrrigationInfo
          position={{ top: 52, left: 24 }}
          lastIrrigation={irrigationStatus.lastIrrigationTime}
          irrigationStatus={irrigationStatus.status}
        />
        <SoilLayers position={{ bottom: 0, left: 0 }} />
        {irrigationStatus.status === "watering" && (
          <Drops position={{ bottom: 155, left: 0 }} />
        )}
        {getStartIrrigationRules() &&
        getStartIrrigationRules().lastValue !== null &&
        getStopIrrigationRules().lastValue !== null ? (
          <WaterLevel
            startIrrigationRules={getStartIrrigationRules()}
            stopIrrigationRules={getStopIrrigationRules()}
            position={{ bottom: 0, left: 0 }}
          />
        ) : (
          <div />
        )}
        <IrrigationGifComponent
          position={{ bottom: 158, left: 0 }}
          irrigationActive={irrigationStatus.status === "watering"}
        />
        {getStartIrrigationRules().sensors &&
          getStartIrrigationRules().sensors.length &&
          getStopIrrigationRules().sensors.length && (
            <Device
              startIrrigationRules={getStartIrrigationRules()}
              stopIrrigationRules={getStopIrrigationRules()}
              position={{ bottom: 15, right: 16 }}
            />
          )}
        {getStartIrrigationRules().sensors &&
          getStartIrrigationRules().sensors.length &&
          getStopIrrigationRules().sensors.length && (
            <Ruler
              startIrrigationRules={getStartIrrigationRules()}
              stopIrrigationRules={getStopIrrigationRules()}
              position={{ bottom: 0, left: 0 }}
            />
          )}
        <StartIrrigationInfo
          position={{ top: 137, left: 70 }}
          irrigationActive={irrigationStatus.status === "watering"}
          plotSensorList={plotSensorList}
          updateIrrigationSettings={updateIrrigationSettings}
          plotId={plotId}
          allowChangeSettings={PermissionsService.isAllowed(
            "AUTOPILOT_CHANGE_SETTINGS"
          )}
          programName={
            (getStartIrrigationRules().irrigationType === "byProgram" ||
              !getStartIrrigationRules().irrigationType) &&
            irrigationStatus.settings.program.name
          }
          threshold={
            getStartIrrigationRules().irrigationType === "bySensor" &&
            getStartIrrigationRules().settings.thresholdRange
          }
          startIrrigationRules={getStartIrrigationRules()}
          period={
            getStartIrrigationRules().irrigationType === "bySensor" &&
            getStartIrrigationRules().settings.atLeastTime
          }
          sensors={
            getStartIrrigationRules().irrigationType === "bySensor" &&
            getStartIrrigationRules().sensors
          }
          groupedBy={
            getStartIrrigationRules().irrigationType === "bySensor" &&
            getStartIrrigationRules().settings.groupedBy
          }
          sensorName={
            getStartIrrigationRules().irrigationType === "bySensor" &&
            getStartIrrigationRules().name
          }
        />
        <StopIrrigationInfo
          position={{ top: 210, left: 70 }}
          irrigationFinished={false}
          plotSensorList={plotSensorList}
          updateIrrigationSettings={updateIrrigationSettings}
          plotId={plotId}
          allowChangeSettings={PermissionsService.isAllowed(
            "AUTOPILOT_CHANGE_SETTINGS"
          )}
          stopIrrigationRules={getStopIrrigationRules()}
          programName={
            (getStopIrrigationRules().irrigationType === "byProgram" ||
              !getStopIrrigationRules().irrigationType) &&
            irrigationStatus.settings.program.name
          }
          threshold={
            getStopIrrigationRules().irrigationType === "bySensor" &&
            getStopIrrigationRules().settings.thresholdRange
          }
          period={
            getStopIrrigationRules().irrigationType === "bySensor" &&
            getStopIrrigationRules().settings.atLeastTime
          }
          sensors={
            getStopIrrigationRules().irrigationType === "bySensor" &&
            getStopIrrigationRules().sensors
          }
          groupedBy={
            getStopIrrigationRules().irrigationType === "bySensor" &&
            getStopIrrigationRules().settings.groupedBy
          }
          sensorName={
            getStopIrrigationRules().irrigationType === "bySensor" &&
            getStopIrrigationRules().name
          }
        />
      </Box>
    );
  } else {
    return <Box />;
  }
};

const mapStateToProps = (state) => ({
  plotIrrigationControllerStatus: plotIrrigationControllerStatusSelector(state),
  plotSensorList: plotSensorListSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  getPlotIrrigationControllerStatus: bindActionCreators(
    getPlotIrrigationControllerStatusRequest,
    dispatch
  ),
  getPlotSensorList: bindActionCreators(getPlotSensorListRequest, dispatch),
  updateIrrigationSettings: bindActionCreators(
    updateIrrigationSettingsRequest,
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Autopilot);
