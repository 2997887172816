import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box, IconButton, Menu, MenuItem } from "@material-ui/core";
import {
  getWaterPotentialLastValueRequest,
  waterPotentialLastValueSelector,
  errorMsgSelector,
} from "redux/waterPotentialLast";
import { AppLoader } from "components/commons";
import moment from "moment";
import clsx from "clsx";
import history from "history.js";
import useStyles from "./styles";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { settingsSelector } from "redux/dashboardSettings";
import { saveDashboardSettingsRequest } from "redux/saveDashboardSettings";
import DeleteIcon from "media/deleteIcon.svg";
import WpIcon from "media/wpIcon.svg";

const sensorDepthKey = {
  "rt1-cent": "depth1",
  "rt2-cent": "depth2",
  "rt3-cent": "depth3",
};

const WPLastValue = ({
  plotId,
  deviceId,
  device,
  waterPotentialLastValue,
  getWaterPotentialLastValue,
  errorMsg,
  saveDashboardSettings,
  dashboardSettings,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { t } = useTranslation();
  const {
    container,
    header,
    deviceName,
    variable,
    content,
    lastValue,
    row,
    value,
    sensorDepth,
    lastUpdated,
    lastUpdatedDate,
    menuItemText,
  } = useStyles();
  useEffect(() => {
    getWaterPotentialLastValue(deviceId);
  }, [deviceId]);
  const generateDepth = (device) => {
    const rootenseSensors = device.sensors.filter(
      (sensor) =>
        sensor && sensor.sensorType && sensor.sensorType.type === "rootense"
    );
    const depths = {};
    rootenseSensors.forEach((sensor) => {
      if (sensor) {
        depths[`depth${sensor.port}`] = sensor.settings.depth;
      }
    });
    return depths;
  };
  const depths = generateDepth(device);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const deleteWidget = () => {
    const index = dashboardSettings.findIndex(
      (item) => item.key === `realTimeWp-${deviceId}`
    );
    dashboardSettings[index].enabled = false;
    saveDashboardSettings({
      plotId: plotId,
      settings: dashboardSettings,
      updateData: true,
    });
  };
  const open = Boolean(anchorEl);
  return (
    <Box className={container}>
      <Box className={clsx("widgetHeader", header)}>
        <Box display="flex" alignItems="center">
        <img width="32px" height="32px" src={WpIcon} alt="icon" />
          <Box padding="0 15px">
            <Box className={deviceName}>{device.name}</Box>
            <Box className={variable}>{t("waterPotential")}</Box>
          </Box>
        </Box>
        <IconButton onClick={handleClick} size="small">
          <MoreVertIcon />
        </IconButton>
      </Box>
      {errorMsg &&
      errorMsg[deviceId] &&
      errorMsg[deviceId] === "DEVICE_DONT_HAVE_ACTIVE_LICENCE" ? (
        <Box
          style={{
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            color: "#828B93",
            fontSize: "16px",
            fontWeight: "500",
            marginTop: "-28px",
          }}
        >
          {t("licenseExpired")}&nbsp;
          <span
            style={{
              textDecoration: "underline",
              cursor: "pointer",
              "&:hover": {
                opacity: "0.7",
              },
            }}
            onClick={() => history.push("/my-products")}
          >
            {t("clickToRenewLink")}
          </span>
        </Box>
      ) : waterPotentialLastValue && waterPotentialLastValue[deviceId] ? (
        <Box className={content}>
          <Box className={lastValue}>{t("lastValue")}</Box>
          <Box className={row}>
            <Box className={value}>
              {waterPotentialLastValue[deviceId].lastValues["rt1-cent"]} cb
            </Box>
            <Box className={sensorDepth}>
              {depths[sensorDepthKey["rt1-cent"]]}cm
            </Box>
          </Box>
          <Box className={row}>
            <Box className={value}>
              {waterPotentialLastValue[deviceId].lastValues["rt2-cent"]} cb
            </Box>
            <Box className={sensorDepth}>
              {depths[sensorDepthKey["rt2-cent"]]}cm
            </Box>
          </Box>
          {waterPotentialLastValue[deviceId].lastValues["rt3-cent"] && (
            <Box className={row}>
              <Box className={value}>
                {waterPotentialLastValue[deviceId].lastValues["rt3-cent"]} cb
              </Box>
              <Box className={sensorDepth}>
                {depths[sensorDepthKey["rt3-cent"]]}cm
              </Box>
            </Box>
          )}
          <Box className={lastUpdated}>{t("lastUpdated")}</Box>
          <Box className={lastUpdatedDate}>
            {moment(waterPotentialLastValue[deviceId].lastValues.time).format(
              "DD/MM/YYYY, hh:mm"
            )}
          </Box>
        </Box>
      ) : (
        <Box height="80%">
          <AppLoader />{" "}
        </Box>
      )}
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem
          style={{
            width: "200px",
          }}
          onClick={deleteWidget}
        >
          <img src={DeleteIcon} alt="DeleteIcon" />
          <Box className={menuItemText}>{t("delete")}</Box>
        </MenuItem>
      </Menu>
    </Box>
  );
};
const mapStateToProps = (state) => ({
  waterPotentialLastValue: waterPotentialLastValueSelector(state),
  errorMsg: errorMsgSelector(state),
  dashboardSettings: settingsSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  getWaterPotentialLastValue: bindActionCreators(
    getWaterPotentialLastValueRequest,
    dispatch
  ),
  saveDashboardSettings: bindActionCreators(
    saveDashboardSettingsRequest,
    dispatch
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WPLastValue);
