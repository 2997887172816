import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  labelStyles: {
    color: "rgba(0, 0, 0, 0.54)",
    fontSize: "12px",
    lineHeight: 1,
    letterSpacing: "0.5px",
  },
  inputStyles: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
    cursor: "pointer",
  },
  inputValue: {
    padding: "6px 9px 7px 0px",
    overflow: "hidden",
    minHeight: "1.1876em",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  container: {
    flex: 1,
    display: "flex",
    marginTop: "16px",
    flexDirection: "column",
  },
  disabledInput: {
    color: "rgb(158, 158, 158)",
    cursor: "not-allowed",
  },
  menuItem: {
    display: "flex",
    height: "46px",
    backgroundColor: "white",
    borderBottom: "1px solid #f4f4f7",
    paddingTop: 0,
    paddingBottom: 0,
    "&:hover": {
      backgroundColor: "white",
      opacity: 0.7,
    },
  },
  selectedItem: {
    backgroundColor: "#70B7E1",
    color: "#ffffff",
    "&:hover": {
      backgroundColor: "#70B7E1",
      opacity: 0.7,
    },
  },
  itemStyles: {
    flex: 1,
    display: "flex",
    justifyContent: "space-between",
    height: "100%",
    alignItems: "center"
  },
  btnLabel: {
    color: "#70B7E1",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "150%",
    marginInlineStart: "14px"
  },
  actionStyles: {
    color: "#70B7E1",
    cursor: "pointer",
    "&:hover": {
      opacity: 0.7,
    },
  },
  whiteText: {
    color: "#ffffff"
  }
}));
