import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box, IconButton, Menu, MenuItem } from "@material-ui/core";
import AutopilotLogo from "media/autopilotLogo.svg";
import DeleteIcon from "media/deleteIcon.svg";
import AutopilotWidgetLogo from "media/autopilotIcon.svg";
import {
  AppBtnFab,
  Autopilot,
  AppLoader,
  IrrigationHistoryGraph,
} from "components/commons";
import useStyles from "./styles";
import clsx from "clsx";
import moment from "moment";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { settingsSelector } from "redux/dashboardSettings";
import { saveDashboardSettingsRequest } from "redux/saveDashboardSettings";
import history from "history.js";
import {
  getPlotIrrigationControllerStatusRequest,
  plotIrrigationControllerStatusSelector,
  isLoadingSelector as irrigationConnectionLoadingSelector,
} from "redux/plotIrrigationControllerStatus";
import {
  getIrrigationHistoryRequest,
  irrigationHistorySelector,
} from "redux/irrigationHistory";
import PermissionsService from "services/PermissionsService";

const AutopilotWidget = ({
  plotId,
  daterange,
  getIrrigationHistory,
  irrigationHistory,
  plotIrrigationControllerStatus,
  getPlotIrrigationControllerStatus,
  irrigationConnectionLoading,
  saveDashboardSettings,
  dashboardSettings,
}) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const {
    container,
    notConnectedContainer,
    connectBtn,
    header,
    headerLeft,
    subtitle,
    title,
    menuItemText,
  } = useStyles();
  useEffect(() => {
    if (plotId) {
      getIrrigationHistory(
        plotId,
        moment(daterange[0]).valueOf(),
        moment(daterange[1]).valueOf()
      );
      getPlotIrrigationControllerStatus(plotId);
    }
  }, [plotId, daterange]);
  const goToIrrigationSettings = () => {
    history.push(`/plot-settings/${plotId}?tab=Irrigation`);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const deleteWidget = () => {
    const index = dashboardSettings.findIndex(
      (item) => item.key === `autopilotWidget`
    );
    dashboardSettings[index].enabled = false;
    saveDashboardSettings({
      plotId: plotId,
      settings: dashboardSettings,
      updateData: true,
    });
  };
  return (
    <Box className={container}>
      {!plotIrrigationControllerStatus[plotId] &&
      irrigationConnectionLoading ? (
        <AppLoader />
      ) : !plotIrrigationControllerStatus[plotId] &&
        !irrigationConnectionLoading ? (
        <Box
          style={{
            width: "100%",
            padding: "24px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box className={clsx("widgetHeader", header)}>
            <Box className={headerLeft}>
              <img
                src={AutopilotWidgetLogo}
                width="32px"
                height="32px"
                alt="AutopilotWidgetLogo"
              />
              <Box padding="0 15px">
                <Box className={title}>{t("autopilot")}</Box>
                <Box className={subtitle}>{t("autopilotWidgetSubtitle")}</Box>
              </Box>
            </Box>
            <IconButton onClick={handleClick} size="small">
              <MoreVertIcon />
            </IconButton>
          </Box>
          <Box className={clsx("no-draggable", notConnectedContainer)}>
            <img
              src={AutopilotLogo}
              alt="logo"
              style={{ marginBottom: "15px" }}
            />
            <AppBtnFab
              onClick={goToIrrigationSettings}
              label={t("connectToIrrigationController")}
              disabled={
                !PermissionsService.isAllowed("AUTOPILOT_CHANGE_SETTINGS")
              }
              className={connectBtn}
            />
          </Box>
        </Box>
      ) : (
        <Box style={{ width: "100%", padding: "24px" }}>
          <Box className={clsx("widgetHeader", header)}>
            <Box className={headerLeft}>
              <img
                src={AutopilotWidgetLogo}
                width="32px"
                height="32px"
                alt="AutopilotWidgetLogo"
              />
              <Box padding="0 15px">
                <Box className={title}>{t("autopilot")}</Box>
                <Box className={subtitle}>{t("autopilotWidgetSubtitle")}</Box>
              </Box>
            </Box>
            <IconButton onClick={handleClick} size="small">
              <MoreVertIcon />
            </IconButton>
          </Box>
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flex: 1,
            }}
          >
            <Box className="no-draggable" style={{ display: "flex", flex: 1 }}>
              <Box
                style={{
                  flexGrow: 1,
                  border: "1px solid #EBEDF3",
                  borderRadius: "16px",
                  minWidth: "215px",
                }}
              >
                <IrrigationHistoryGraph
                  daterange={daterange}
                  irrigationHistory={irrigationHistory}
                />
              </Box>
              <Box style={{ width: "16px" }} />
              <Box
                style={{
                  width: "447px",
                }}
              >
                <Autopilot plotId={plotId} />
              </Box>
            </Box>
          </Box>
        </Box>
      )}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem
          style={{
            width: "200px",
          }}
          onClick={deleteWidget}
        >
          <img src={DeleteIcon} alt="DeleteIcon" />
          <Box className={menuItemText}>{t("delete")}</Box>
        </MenuItem>
      </Menu>
    </Box>
  );
};
const mapStateToProps = (state) => ({
  plotIrrigationControllerStatus: plotIrrigationControllerStatusSelector(state),
  irrigationConnectionLoading: irrigationConnectionLoadingSelector(state),
  irrigationHistory: irrigationHistorySelector(state),
  dashboardSettings: settingsSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  getPlotIrrigationControllerStatus: bindActionCreators(
    getPlotIrrigationControllerStatusRequest,
    dispatch
  ),
  getIrrigationHistory: bindActionCreators(
    getIrrigationHistoryRequest,
    dispatch
  ),
  saveDashboardSettings: bindActionCreators(
    saveDashboardSettingsRequest,
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(AutopilotWidget);
