import React from "react";
import ReactDOM from "react-dom";
import "react-redux-toastr/lib/css/react-redux-toastr.min.css";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { injectGlobal } from "emotion";
import ReduxToastr from "react-redux-toastr";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "./theme";

import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import createSagaMiddleware from "redux-saga";
import { composeWithDevTools } from "redux-devtools-extension";
import rootReducer from "./reducers";
import rootSaga from "./sagas";
import globalStyle from "./globalStyles";
import { getUserCountryRequest } from "redux/userCountry";
import GA4React from "ga-4-react";
import "./i18n";
const ga4react = new GA4React("G-88NZ1FTTBQ", {
  debug_mode: process.env.NODE_ENV !== "production",
});
const rootEl = document.getElementById("root");
const sagaMiddleware = createSagaMiddleware();
const middlewaresWithDevTools = composeWithDevTools(
  applyMiddleware(sagaMiddleware)
);
const store = createStore(rootReducer, middlewaresWithDevTools);
sagaMiddleware.run(rootSaga);

injectGlobal`
  ${globalStyle}
`;

store.dispatch(getUserCountryRequest());
(async () => {
  await ga4react.initialize();
  ReactDOM.render(
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <App />
        <ReduxToastr
          timeOut={5000}
          newestOnTop={false}
          preventDuplicates
          position="top-right"
          transitionIn="fadeIn"
          transitionOut="fadeOut"
          progressBar
          closeOnToastrClick
        />
      </Provider>
    </ThemeProvider>,
    rootEl
  );
})();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
