import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box, IconButton, Menu, MenuItem } from "@material-ui/core";
import moment from "moment";
import clsx from "clsx";
import {
  getPipePressureRequest,
  pipePressureSelector,
  errorMsgSelector,
} from "redux/pipePressure";
import { PipePressureGraph } from "components/commons";
import useStyles from "./styles";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ExportIcon from "media/exportIcon.svg";
import { settingsSelector } from "redux/dashboardSettings";
import { saveDashboardSettingsRequest } from "redux/saveDashboardSettings";
import DeleteIcon from "media/deleteIcon.svg";
import PipePressureIcon from "media/pipePressure.png";

const PipePressure = ({
  plotId,
  deviceId,
  daterange,
  device,
  getPipePressure,
  pipePressure,
  errorMsg,
  saveDashboardSettings,
  dashboardSettings,
}) => {
  const { t } = useTranslation();
  const {
    title,
    container,
    subtitle,
    header,
    deviceInfo,
    exportText,
  } = useStyles();

  useEffect(() => {
    if (deviceId) {
      getPipePressure({
        deviceId: deviceId,
        from: moment(daterange[0]).valueOf(),
        to: moment(daterange[1]).valueOf(),
        isForInsight: false,
      });
    }
  }, [deviceId, daterange]);
  const generateDepth = (device) => {
    const rootenseSensors = device.sensors.filter(
      (sensor) =>
        sensor && sensor.sensorType && sensor.sensorType.type === "rootense"
    );
    const depths = {};
    rootenseSensors.forEach((sensor) => {
      if (sensor) {
        depths[`depth${sensor.port}`] = sensor.settings.depth;
      }
    });
    return depths;
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const exportToCsv = () => {
    const data = [
      pipePressure[deviceId].schema
        .map((item) =>
          item.name === "port5"
            ? `${t("pipePressure")} (${t("port")} 5)`
            : item.name === "port6"
            ? `${t("pipePressure")} (${t("port")} 6)`
            : t("pipePressure")
        )
        .join(","),
      ...pipePressure[deviceId].data.map(([time, ...rest]) =>
        [moment(time).format("DD/MM/YYYY HH:mm"), ...rest].join(",")
      ),
    ];
    const csv = data.join("\r\n");
    let link = document.createElement("a");
    link.setAttribute(
      "href",
      "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURIComponent(csv)
    );
    link.setAttribute("download", `${t("pipePressure")} (${device.name})`);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const deleteWidget = () => {
    const index = dashboardSettings.findIndex(
      (item) => item.key === `pipePressure-${deviceId}`
    );
    dashboardSettings[index].enabled = false;
    saveDashboardSettings({
      plotId: plotId,
      settings: dashboardSettings,
      updateData: true,
    });
  };
  const open = Boolean(anchorEl);
  return (
    <Box className={container}>
      <Box className={clsx("widgetHeader", header)}>
        <Box className={deviceInfo}>
          <img width="32px" height="32px" src={PipePressureIcon} alt="icon" />
          <Box padding="0 15px">
            <Box className={title}>
              {device.name} ({device.externalName || "-"})
            </Box>
            <Box className={subtitle}>{t("pipePressure")}</Box>
          </Box>
        </Box>
        <IconButton onClick={handleClick} size="small">
          <MoreVertIcon />
        </IconButton>
      </Box>
      <div className="no-draggable" style={{ height: "80%" }}>
        <PipePressureGraph
          pipePressure={pipePressure}
          depths={generateDepth(device)}
          daterange={daterange}
          deviceId={deviceId}
          errorMsg={errorMsg}
        />
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <MenuItem
            style={{
              width: "200px",
            }}
            onClick={exportToCsv}
          >
            <img src={ExportIcon} alt="Export" />
            <Box className={exportText}>{t("export")}</Box>
          </MenuItem>
          <MenuItem
            style={{
              width: "200px",
            }}
            onClick={deleteWidget}
          >
            <img src={DeleteIcon} alt="DeleteIcon" />
            <Box className={exportText}>{t("delete")}</Box>
          </MenuItem>
        </Menu>
      </div>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  pipePressure: pipePressureSelector(state),
  errorMsg: errorMsgSelector(state),
  dashboardSettings: settingsSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  getPipePressure: bindActionCreators(getPipePressureRequest, dispatch),
  saveDashboardSettings: bindActionCreators(
    saveDashboardSettingsRequest,
    dispatch
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PipePressure);
