import i18n from "../i18n";
import moment from "moment";

export const downloadCSVAccounts = (filename, arrayOfJson) => {
  // convert JSON to CSV
  const header = [
    "ID",
    i18n.t("account"),
    i18n.t("availableLicense"),
    i18n.t("assignedLicense"),
    i18n.t("users"),
    i18n.t("devices"),
    i18n.t("parentAccount"),
  ];
  let csv = arrayOfJson.map((item) => {
    return [
      item.id,
      item.name,
      item.license.available,
      item.license.assigned,
      item.usersCount,
      item.devicesCount,
      item.parentOrganization,
    ].join(";");
  });
  csv.unshift(header.join(";")); // add header column
  csv = csv.join("\r\n");
  let link = document.createElement("a");
  link.setAttribute(
    "href",
    "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURIComponent(csv)
  );
  link.setAttribute("download", filename);
  link.style.visibility = "hidden";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const downloadCSVUsers = (filename, arrayOfJson) => {
  // convert JSON to CSV
  const header = [
    "ID",
    i18n.t("username"),
    i18n.t("email"),
    i18n.t("phoneNumber"),
    i18n.t("account"),
    i18n.t("role"),
    i18n.t("lastLogin"),
    i18n.t("createdAt"),
  ];
  let csv = arrayOfJson.map((item) => {
    return [
      item.userId,
      item.username,
      item.email,
      item.phoneNumber,
      item.company,
      item.role, //
      moment(item.lastLogin).format("MMM D,YYYY"),
      moment(item.createdAt).format("MMM D,YYYY"),
    ].join(";");
  });
  csv.unshift(header.join(";")); // add header column
  csv = csv.join("\r\n");
  let link = document.createElement("a");
  link.setAttribute(
    "href",
    "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURIComponent(csv)
  );
  link.setAttribute("download", filename);
  link.style.visibility = "hidden";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
