export const ACCOUNTS_COLUMNS = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "accounts",
    sortable: true,
    filterable: false,
    minWidth: null,
    maxWidth: null,
    width: null
  },
  {
    id: "devicesCount",
    numeric: true,
    disablePadding: false,
    label: "devices",
    sortable: true,
    filterable: false,
    minWidth: null,
    maxWidth: 60,
    width: null
  },
  {
    id: "subscriptionsCount",
    numeric: true,
    disablePadding: false,
    label: "availableLicense",
    sortable: true,
    filterable: false,
    minWidth: null,
    maxWidth: 73,
    width: null
  },
  {
    id: "assignedSubscriptionCount",
    numeric: true,
    disablePadding: false,
    label: "assignedLicense",
    sortable: true,
    filterable: false,
    minWidth: null,
    maxWidth: 73,
    width: null
  },
  {
    id: "usersCount",
    numeric: true,
    disablePadding: false,
    label: "users",
    sortable: true,
    filterable: false,
    minWidth: null,
    maxWidth: 42,
    width: null
  },
  {
    id: "parentOrganization",
    numeric: false,
    disablePadding: false,
    label: "parentAccount",
    sortable: true,
    filterable: true,
    minWidth: null,
    maxWidth: null,
    width: null
  },
  {
    id: "renewalDate",
    numeric: false,
    disablePadding: false,
    label: "renewalDate",
    sortable: true,
    filterable: false,
    minWidth: null,
    maxWidth: null,
    width: null
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: false,
    label: "actions",
    sortable: false,
    filterable: false,
    minWidth: null,
    maxWidth: null,
    borderStart: true,
    width: 90
  },
];
