import { call, put, takeLatest } from "redux-saga/effects";
import Languages from "constants/languages";
import i18n from "i18next";

import { getCountryByIp } from "../api";

const API_GET_USER_COUNTRY_REQUEST = "API_GET_USER_COUNTRY_REQUEST";
const API_GET_USER_COUNTRY_SUCCESS = "API_GET_USER_COUNTRY_SUCCESS";
const API_GET_USER_COUNTRY_ERROR = "API_GET_USER_COUNTRY_ERROR";

// actions
export const getUserCountryRequest = () => ({
  type: API_GET_USER_COUNTRY_REQUEST
});

export const getUserCountrySuccess = payload => ({
  type: API_GET_USER_COUNTRY_SUCCESS,
  payload
});

const getUserCountryError = error => ({
  type: API_GET_USER_COUNTRY_ERROR,
  error
});

// reducers
const initialState = {
  countryData: null,
  loading: false,
  error: false
};

export function countryReducer(state = initialState, action) {
  switch (action.type) {
    case API_GET_USER_COUNTRY_REQUEST:
      return {
        ...state,
        loading: true,
        error: false
      };
    case API_GET_USER_COUNTRY_SUCCESS:
      return {
        ...state,
        countryData: action.payload,
        loading: false,
        error: false
      };
    case API_GET_USER_COUNTRY_ERROR:
      return {
        ...state,
        loading: false,
        error: true
      };
    default:
      return state;
  }
}

// saga
function* getUserCountryData() {
  try {
    const res = yield call(getCountryByIp);
    const language = Languages.find(
      lang => lang.countryCode === res.country_code
    );
    if (language && !localStorage.getItem("language")) {
      i18n.changeLanguage(language.lang);
      const body = document.querySelector("html");
      body.dir = language.direction;
      body.lang = language.lang;
    }
    yield put(getUserCountrySuccess(res));
  } catch (error) {
    yield put(getUserCountryError(error));
  }
}

export function* watchGetUserCountryData() {
  yield takeLatest(API_GET_USER_COUNTRY_REQUEST, getUserCountryData);
}
